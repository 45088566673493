<template>
  <div>
    <h3>Price Agreements general</h3>
    <p>
      Welcome to the Price Agreements Page! Here you can view, create and update your PriceAgreements with different
      customers. In the following text a brief explanation is given how the page works. The general flow is given for
      different actions, and individual buttons are explained in a later section. Do you have any questions or is
      somethign not working as expected? Don't hesitate to send an email to support@innico.nl and we'll sort you right
      out!
    </p>
    <h3>Basics</h3>
    <p>All actions start with the basics. These are:</p>
    <ul>
      <li>Selecting a customer (or an item)</li>
      <li>Selecting a date-range</li>
      <li>Deciding if you want to show <i>default prices</i></li>
    </ul>
    <p>
      The webapp will load all relevant items in the table, after which you can create new Price Agreements based on
      these entries, or edit them if they are still in the future. Default prices are usually not shown in Exact. They
      are however possible to be shown in the webapp, as we use the data to generate the quotations which are described
      later in the help page.
    </p>

    <h3>Creating New Price Agreements in bulk</h3>

    <p>
      Select all items where you want to create a new price agreement. When all the items are selected, go to the
      <i>Actions</i>-tab. Click the <i>Add Data</i> button. This will ask you for a <i>PriceList</i>. After this, you
      can either edit the prices manually in the table, or select multiple and Bulk Edit them in the <i>Actions</i>-tab.
      Don't forget to Save Changes before you exit the page!
    </p>
    <h3>Edit Price Agreements</h3>
    <p>
      When a Price Agreement lies in the future, it can still be edited. This can either be done by using the
      <i>Edit</i> button, or by selecting the rows you want to edit and bulk editing them in the <i>Actions</i> tab.
      Note that if the <i>Valid To</i> date is today or in the past, the Price Agreements are not editable. As they're
      already sent to Exact.
    </p>
    <h3>Buttons explanation table</h3>
    <p>
      There are buttons in the Normal View of the table, and buttons in the "Actions" tab. In the general sense, the
      buttons in the normal view are used to edit single rows, and the buttons in the "Actions" tab are used to edit
      many rows at once. The following table explains buttons that are visible in the standard view.
    </p>

    <b-table :fields="buttonInfo.fields" :items="buttonInfo.items">
      <template #cell(button)="row">
        <b-button :variant="row.item.variant"> <b-icon :icon="row.item.button" /> {{ row.item.name }} </b-button>
      </template>
    </b-table>
    <h3>Actions</h3>
    <p>Here we explain how the different buttons under the "Actions" tab work.</p>
    <b-table :fields="actions.fields" :items="actions.items">
      <template #cell(button)="row">
        <b-button :variant="row.item.variant"> <b-icon :icon="row.item.button" /> {{ row.item.name }} </b-button>
      </template>
    </b-table>
    <h3>Filters</h3>
    <p>
      If you want to have a narrower selection of items, you should use filters. With these you can filter on
      collections, specific Valid To and Valid From dates, and any other data that is included in the table. In the
      following table there is an explanation how the different filters under the 'filter' tab work.
    </p>
    <p>
      Depending on the column you get the choice between a couple of rules. This is either (=, &lt;, ≤, &gt; and ≥) or
      (in, not in, contains and =). Underneath we explain what every rule does:
    </p>
    <b-table :fields="filters.fields" :items="filters.items"> </b-table>
    <br />
    <h3>No answer?</h3>
    <p><strong>If none of this answers your question, please contact support@innico.nl.</strong></p>
  </div>
</template>

<script lang="ts">
const BUTTON_INFO = {
  fields: [{ key: 'button', class: 'tight-column' }, { key: 'description' }],
  items: [
    {
      button: 'pencil',
      variant: 'primary',
      name: 'Edit',
      description: 'Turn the table into edit mode.',
    },
    {
      button: 'plus',
      variant: 'success',
      name: 'Add data',
      description: 'Add singular Price Agreements for this customer',
    },
    {
      variant: 'success',
      name: 'Save changes',
      description: 'Save your made edits and send them to Exact',
    },
  ],
}
const ACTIONS = {
  fields: [{ key: 'button' }, { key: 'description' }],
  items: [
    {
      button: 'box-arrow-up-right',
      variant: 'secondary',
      name: 'Export to Excel',
      description: 'Export the selected Price Agreements to Excel',
    },
    {
      button: 'plus',
      variant: 'success',
      name: 'Add data',
      description: 'Create new Price Agreements based on the selected prices as described above',
    },
    {
      button: 'plus',
      variant: 'b-blue',
      name: 'Generate bulk data',
      description:
        'Choose filters after pressing these buttons and generate Price Agreements for all items which are selected by those',
    },
    {
      button: 'file-earmark',
      variant: 'primary',
      name: 'Generate PDF quotation',
      description: 'Generate a quotation for the customer in the .pdf format.',
    },
    {
      button: 'file-earmark',
      variant: 'primary',
      name: 'Generate Excel quotation',
      description: 'Generate a quotation for the customer in the .xlsx format.',
    },
  ],
}
const FILTERS = {
  fields: [{ key: 'rule' }, { key: 'description' }],
  items: [
    {
      rule: 'in',
      description: 'Include, you can select what to include in the drop-down at the filter value',
    },
    {
      rule: 'not in',
      description: 'Do not include, you can select what not to include in the drop-down at the filter value',
    },
    {
      rule: 'contains',
      description:
        'the filter value can be found somewhere in the column value, does not have to be 1 on 1. (For example contains BASE includes BASE and BASEPERLA.)',
    },
    {
      rule: '=',
      description: 'Is exactly the same as [Filter value(s)]',
    },
    {
      rule: '<',
      description: 'Is smaller than [Filter value(s)]',
    },
    {
      rule: '≤',
      description: 'Is smaller than or the same as [Filter value(s)]',
    },
    {
      rule: '>',
      description: 'Is bigger than [Filter value(s)]',
    },
    {
      rule: '≥',
      description: 'Is bigger than or the same as [Filter value(s)]',
    },
  ],
}
export default {
  name: 'PriceAgreementsHelp',
  data() {
    return {
      buttonInfo: BUTTON_INFO,
      actions: ACTIONS,
      filters: FILTERS,
    }
  },
}
</script>
