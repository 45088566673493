import { formatNumber, parseNumber } from '@brightspace-ui/intl/lib/number.js'
import * as Sentry from '@sentry/vue'

import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'

export default {
  methods: {
    // Exact globe stores floats as string with a ,
    type_to_form_type(t) {
      // takes a js primitive type such as Date, Number, String as input (note: not an instance, the actual type)
      // produces a string that describes the form type that this type corresponds to
      if (t) {
        let stringified_type
        if (t === String) {
          stringified_type = 'text'
        } else {
          stringified_type = t.name.toLowerCase()
        }
        return stringified_type
      } else {
        return t
      }
    },
    parse_num(number) {
      return parseNumber(number)
    },
    format_num(number, decimals = 3) {
      if (number == null) {
        return null
      }
      if (isNaN(number)) {
        return NaN
      }
      // This throws RangeError: Value out of range
      try {
        return formatNumber(number, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
      } catch (e) {
        const scope = new Sentry.Scope()
        scope.setContext('number', number)
        Sentry.captureException(e, scope)
      }
    },
    formatCurrency(num, key = undefined, row = undefined, currencyDecimals = 2) {
      // This check is required to be able to edit new prices, since otherwise those prices would include '€', which
      // breaks the prop type validator
      if (this.tableMode === TABLE_MODE.WRITE || this.tableMode === TABLE_MODE.BULK_ADD) {
        return num
      }
      // Use the currency decimals function from the application if it exists
      if (typeof this.getCurrencyDecimals === 'function' && key && row) {
        currencyDecimals = this.getCurrencyDecimals(key, row)
      }
      num = this.format_num(num, currencyDecimals)
      if (num === null) {
        return '-'
      }
      return '€' + num
    },
    // Get status text by status symbol
    status_text(status) {
      const chars = {
        DRAFT: 'Draft',
        SENT: 'Sent',
        REJECTED: 'Rejected',
        ACCEPTED: 'Accepted',
        COMPLETED: 'Completed',
      }
      return chars[status]
    },
    // Get status color by status symbol
    status_color(status) {
      const variants = {
        DRAFT: 'b-gray',
        SENT: 'b-blue',
        REJECTED: 'danger',
        ACCEPTED: 'success',
        COMPLETED: 'success',
      }
      return variants[status]
    },
    translate_order_unit(package_description) {
      const pdes = {
        'meter   ': 'm',
        'st/pc   ': 'pc',
      }
      return pdes[package_description]
    },
    // Returns css class for form validation
    validation_class(valid) {
      if (valid == null) {
        return ''
      } else if (valid) {
        return 'form-control is-valid'
      } else {
        return 'form-control is-invalid'
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    // Takes file as returned by b-form-file and converts it to a GraphQL Upload type we can pass to the API.
    async toUpload(file) {
      let encoded = await this.getBase64(file)
      encoded = encoded.split(',')[1]
      return {
        name: file.name,
        encoded: encoded,
      }
    },
    default_page_name(default_page) {
      switch (default_page) {
        case 'CUT_LENGTH':
          return 'Cut length'
        case 'PRICE_AGREEMENTS':
          return 'Price agreements'
        case 'PRICE_LIST':
          return 'Price list'
        default:
          return 'Homepage'
      }
    },
    color(color_string, opacity = 1) {
      let rgb = undefined
      switch (color_string) {
        case 'red':
          rgb = [255, 105, 97]
          break
        case 'green':
          rgb = [119, 221, 119]
          break
        case 'blue':
          rgb = [108, 160, 220]
          break
        case 'orange':
          rgb = [255, 150, 79]
          break
        case 'yellow':
          rgb = [245, 245, 59]
          break
        case 'purple':
          rgb = [177, 156, 217]
          break
        case 'darkblue':
          rgb = [0, 0, 180]
          break
        case 'sand':
          rgb = [211, 199, 162]
          break
      }
      return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`
    },
    monthDiff(d1, d2) {
      let months
      months = (d2.getFullYear() - d1.getFullYear()) * 12
      months -= d1.getMonth()
      months += d2.getMonth()
      return months <= 0 ? 0 : months
    },
    ArraySum(array) {
      return array.reduce((a, b) => a + b, 0)
    },
    month_name(month_int) {
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ][month_int - 1]
    },
    format_date(date) {
      return date.toISOString().split('T')[0]
    },
    format_date_time(date) {
      const date_strings = date.toISOString().split('T')
      return date_strings[0] + ' ' + date_strings[1].split(':').slice(0, 2).join(':')
    },
    today() {
      return this.format_date(new Date())
    },
    tomorrow() {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().split('T')[0]
    },
    yesterday() {
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      return yesterday.toISOString().split('T')[0]
    },
    get_current_fc_date() {
      const today = new Date()
      return `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-15`
    },
    format_sysmodified(datetime_string) {
      const d = new Date(datetime_string)
      const t = new Date()
      // If today return time, else return date
      if (d.getDate() == t.getDate() && d.getMonth() == t.getMonth() && d.getMonth() == t.getMonth()) {
        return d.toLocaleTimeString()
      }
      return d.toLocaleDateString()
    },
    format_fc_date(fc_date) {
      const a = fc_date.split('-', 2)
      return this.month_name(Number(a[1])).substring(0, 3) + ' ' + a[0]
    },
    any(bools) {
      return bools.reduce((a, b) => a || b, false)
    },
    all(bools) {
      return bools.reduce((a, b) => a && b, true)
    },
  },
}
