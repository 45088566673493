<template>
  <div>
    <b-card style="max-width: 40rem" class="mt-5 mx-auto" header="Sign up" header-class="card-header">
      <b-form v-if="$route.params.token" @submit.prevent>
        <b-alert :show="alert.message.length > 1" :variant="alert.variant" dismissible @dismissed="alert.message = ''">
          {{ alert.message }}

          <ul v-if="alert.errors.length > 0" className="mb-0">
            <li v-for="(error, index) in alert.errors" :key="index" variant="danger">
              {{ error }}
            </li>
          </ul>
        </b-alert>

        <b-container fluid>
          <b-row v-for="rowIndex in Object.values(formFields).length / 2" :key="rowIndex">
            <b-col
              v-for="(field, index) in Object.values(formFields).slice((rowIndex - 1) * 2, rowIndex * 2)"
              :key="index"
              cols="6"
            >
              <b-form-group :label="field.label" :invalid-feedback="field.invalidFeedback">
                <b-form-input
                  :id="field.id"
                  v-model="field.value"
                  :placeholder="field.label"
                  :state="field.state"
                  :disabled="field.disabled"
                  :type="field.type"
                  :autocomplete="field.autocomplete"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>

        <component
          :is="otpComponent"
          :fluid="true"
          :secret="otpSecret"
          :error="otpError"
          @update:error="(newOtpError) => (otpError = newOtpError)"
          @submit="onOtpSubmit"
        />
      </b-form>
      <b-card-text v-else>
        <!--
          TODO: Implement first version of request signup.
          It should simply send an email to support-webapp@lienesch.com with the filled in information.
          Information: their company (simple text field, not the customer selector), email, first/last name.
        -->
        As the Lienesch webapp is still in its beta version, we are currently handing out accounts to a few select
        customers. If you would like a login to the Lienesch webapp, please contact sales support.
      </b-card-text>

      <template #footer> Already have an account? <router-link :to="{ name: 'Login' }">Login</router-link>. </template>
    </b-card>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue'
import { AxiosError } from 'axios'
import { defineComponent } from 'vue'

import OtpGoogle from 'innicore/components/auth/OtpGoogle.vue'
import apiMixin from 'innicore/mixins/api_mixin'

import { GetUserAndSecretDocument, type GetUserAndSecretQuery, Language, SignUpDocument } from '@/graphql/generated'

export default defineComponent({
  mixins: [apiMixin],
  data() {
    return {
      alert: {
        message: '',
        variant: 'success' as 'success' | 'danger',
        errors: [],
      },
      user: null as GetUserAndSecretQuery['currentUser'] | null,
      formFields: {
        email: {
          id: 'email-input',
          label: 'Email address',
          type: 'email',
          disabled: true,
          invalidFeedback: 'This email is invalid.',
          autocomplete: undefined,
          value: '',
          state: null,
        },
        firstName: {
          id: 'first-name-input',
          label: 'First name',
          type: 'text',
          disabled: false,
          invalidFeedback: 'Please enter your first name.',
          autocomplete: 'given-name',
          value: '',
          state: null,
        },
        lastName: {
          id: 'last-name-input',
          label: 'Last name',
          type: 'text',
          disabled: false,
          invalidFeedback: 'Please enter your last name.',
          autocomplete: 'family-name',
          value: '',
          state: null,
        },
        password: {
          id: 'password-input',
          label: 'Password',
          type: 'password',
          disabled: false,
          invalidFeedback: 'The password is shorter than 8 characters.',
          autocomplete: 'new-password',
          value: '',
          state: null,
        },
        confirmPassword: {
          id: 'confirm-password-input',
          label: 'Confirm password',
          type: 'password',
          disabled: false,
          invalidFeedback: 'The passwords are not equal.',
          autocomplete: 'new-password',
          value: '',
          state: null,
        },
      },
      otpSecret: null as string | null,
      otpError: null as string | null,
    }
  },
  computed: {
    otpComponent() {
      return OtpGoogle
    },
  },
  async mounted() {
    if (!this.$route.params.token) {
      return
    }

    try {
      const response = await this.api_call(GetUserAndSecretDocument, {}, { token: this.$route.params.token })

      if (!response) {
        this.showAlert(
          'This sign-up link has expired. If you still need to sign up, please contact sales support.',
          'danger'
        )
      } else if (!response.data.data.currentUser) {
        this.showAlert(
          'This is an invalid sign-up link. Please confirm if you have copied the link correctly.',
          'danger'
        )
      } else {
        this.otpSecret = response.data.data.otpSecret

        this.user = response.data.data.currentUser
        this.formFields.email.value = this.user.email
        this.formFields.email.value = this.user.email
        this.formFields.firstName.value = this.user.first_name
        this.formFields.lastName.value = this.user.last_name
      }
    } catch (error) {
      const scope = new Sentry.Scope()
      scope.setContext('context', { query: GetUserAndSecretDocument })
      Sentry.captureException(error, scope)

      if (error instanceof AxiosError) {
        if (!error.response) {
          this.$router.replace({ name: 'TemporarilyUnavailable' })
        } else {
          this.showAlert('Something went wrong.', 'danger')
        }
      }
    }
  },
  methods: {
    showAlert(message: string, variant: 'success' | 'danger' = 'success') {
      this.alert.message = message
      this.alert.variant = variant
    },
    async onOtpSubmit(code?: string) {
      if (!this.validate()) {
        return
      }

      try {
        const response = await this.api_call(
          SignUpDocument,
          {
            first_name: this.formFields.firstName.value,
            last_name: this.formFields.lastName.value,
            language: Language.NL,
            password: this.formFields.password.value,
            otp: code,
          },
          {
            token: this.$route.params.token,
          }
        )

        if (!response.data.data.OtpVerify.verified) {
          if (response.data.data.OtpVerify.otp_error) {
            this.otpError = response.data.data.OtpVerify.otp_error
          } else {
            this.otpError = 'Something went wrong, please try again.'
          }
          return
        }

        if (!response.data.data.SignUp || !response.data.data.SignUp.success) {
          try {
            this.alert.errors = JSON.parse((response.data.errors[0].message ?? '[]').replaceAll("'", '"'))
          } catch (error) {
            console.error(error)
            Sentry.captureException(error)
            this.alert.errors = ['Failed to parse error.']
          }

          this.showAlert('Please check the details you entered:', 'danger')
          this.otpError = 'Please try again.'

          return
        }

        this.$router.replace({ name: 'Login' })
      } catch (error) {
        const scope = new Sentry.Scope()
        scope.setContext('context', { query: SignUpDocument })
        Sentry.captureException(error, scope)

        if (error instanceof AxiosError) {
          if (!error.response) {
            this.$router.replace({ name: 'TemporarilyUnavailable' })
          } else {
            this.otpError = 'Something went wrong, please try again.'
          }
        }
      }
    },
    validate() {
      this.resetState()

      if (!this.formFields.firstName.value) {
        this.formFields.firstName.state = false
        return false
      }
      if (!this.formFields.lastName.value) {
        this.formFields.lastName.state = false
        return false
      }
      if (this.formFields.password.value.length < 8) {
        this.formFields.password.state = false
        return false
      }
      if (this.formFields.password.value !== this.formFields.confirmPassword.value) {
        this.formFields.confirmPassword.state = false
        return false
      }
      return true
    },
    resetState() {
      this.errors = []
      this.formFields.firstName.state = null
      this.formFields.lastName.state = null
      this.formFields.password.state = null
      this.formFields.confirmPassword.state = null
    },
  },
})
</script>
