import * as Sentry from '@sentry/vue'
import { BootstrapVue } from 'bootstrap-vue'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import App from 'innicore/App.vue'
import FileImporter from 'innicore/components/FileImporter.vue'
import FancyInput from 'innicore/components/input/FancyInput.vue'
import HitButton from 'innicore/components/input/HitButton.vue'
import TypedFormInput from 'innicore/components/input/TypedFormInput.vue'
import IRouterLink from 'innicore/components/nav/IRouterLink.vue'
import CustomerSelector from 'innicore/components/selectors/CustomerSelector.vue'
import DateRangeSelector from 'innicore/components/selectors/DateRangeSelector.vue'
import DefaultPriceSelector from 'innicore/components/selectors/DefaultPriceSelector.vue'
import GenericSelector from 'innicore/components/selectors/GenericSelector.vue'
import RollingFCDateSelector from 'innicore/components/selectors/RollingFCDateSelector.vue'
import FancyTable from 'innicore/components/table/FancyTable.vue'
import FieldsFilter from 'innicore/components/table/FieldsFilter.vue'
import ITable from 'innicore/components/table/ITable.vue'
import ReimaginedTable from 'innicore/components/table/ReimaginedTable.vue'
import SelectBys from 'innicore/components/table/SelectBys.vue'
import TableTab from 'innicore/components/table/TableTab.vue'
import 'innicore/custom.scss'
import { registerIcons } from 'innicore/icons'
import { Constants } from 'innicore/plugins/constants'
import { store } from 'innicore/store'

export const createApp = (router: VueRouter, domains: string[], cookieDomain: string) => {
  // Library plugins
  Vue.use(BootstrapVue)
  Vue.use(VueCookies)
  Vue.use(VueRouter)
  Vue.use(Vuex)

  // Innico plugins
  Vue.use(Constants)

  // Components
  registerIcons(Vue)
  Vue.component('GenericSelector', GenericSelector)
  Vue.component('CustomerSelector', CustomerSelector)
  Vue.component('DateRangeSelector', DateRangeSelector)
  Vue.component('DefaultPriceSelector', DefaultPriceSelector)
  Vue.component('FancyInput', FancyInput)
  Vue.component('FancyTable', FancyTable)
  Vue.component('FieldsFilter', FieldsFilter)
  Vue.component('FileImporter', FileImporter)
  Vue.component('HitButton', HitButton)
  Vue.component('IRouterLink', IRouterLink)
  // @ts-expect-error: component type incompatibility
  Vue.component('ITable', ITable)
  Vue.component('ReimaginedTable', ReimaginedTable)
  Vue.component('RollingFcDateSelector', RollingFCDateSelector)
  Vue.component('SelectBys', SelectBys)
  Vue.component('TableTab', TableTab)
  Vue.component('TypedFormInput', TypedFormInput)

  Vue.config.productionTip = false
  Vue.prototype.DEVELOPMENT = import.meta.env.MODE === 'development'
  Vue.prototype.STAGING = import.meta.env.MODE === 'staging'
  Vue.prototype.PRODUCTION = import.meta.env.MODE === 'production'
  Vue.$cookies.config('7d', '', cookieDomain, Vue.prototype.PRODUCTION)

  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: ['staging', 'production'].includes(import.meta.env.MODE),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [...domains, /^\//],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    replaysSessionSampleRate: 0.05, // 5% of all sessions are saved as replays
    replaysOnErrorSampleRate: 1.0, // 100% of error sessions will be sent as replays
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_COMMIT_HASH,
    tracesSampleRate: 1,
    trackComponents: true,
    ignoreErrors: ['Request aborted'],
  })

  return new Vue({
    router,
    render: (h) => h(App),
    store: store,
  }).$mount('#app')
}
