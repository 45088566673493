<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ emailContentParsed.subject }}
      </b-card-title>
      <b-card-sub-title>
        {{ emailContentParsed.from_email }}
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <div>
        {{ emailContentParsed.body }}
      </div>
      <br />
      <a :href="orderFile.full_url" target="_blank">
        <hit-button class="mx-1 float-left" variant="primary" :click="() => {}">
          <b-icon icon="file-earmark-arrow-down" />
          Download {{ orderFile.filename }}
        </hit-button>
      </a>
      <template v-if="$route.name !== 'WorkflowOrderEntry'">
        <RouterLink
          v-if="!orderFile.order"
          :to="{ name: 'WorkflowOrderEntry', params: { id: workflowLine.workflow.id } }"
        >
          <hit-button class="mx-1 float-left" variant="success" :click="() => {}">
            <b-icon class="mx-1" icon="box-arrow-up-right" />
            Create order from file
          </hit-button>
        </RouterLink>
        <RouterLink v-else :to="{ name: 'ViewOrder', params: { order_id: orderFile.order.order_id } }">
          <hit-button class="mx-1 float-left" variant="secondary">View order</hit-button>
        </RouterLink>
      </template>
    </b-card-body>
  </b-card>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue'

import { store } from 'innicore/store'

import { WorkflowLine } from '@/views/workflows/Workflow'
import { OrderFile } from '@/views/workflows/order/OrderFile'

const workflowLine: WorkflowLine = inject('workflowLine') as WorkflowLine
const emailContentParsed = computed(() => JSON.parse(workflowLine.json))

const orderFile = ref<OrderFile | null>(null)

onMounted(async () => {
  const response = await store.dispatch('fetchOrderFile', workflowLine.object_id)
  // we know there's only a single match for our fetch
  orderFile.value = new OrderFile(response[0])
})
</script>
