<template>
  <div>
    <h1>Incoming goods shipment overview</h1>
    <p>On this page you can add, release and check new incoming goods shipments.</p>

    <add-shipment-modal
      name="add_shipment_modal"
      title="Add shipment"
      :supplier="supplier"
      :mutate="uploadPackingSlip"
      :callback="add_data_callback"
    />

    <i-table
      ref="ITable"
      :fetch-items="fetchShipments"
      :fields="fields"
      app-name="incoming_goods_release"
      :allowed-selectors="allowed_selectors"
      :mutate-items="mutateShipments"
      :errors="errors"
      :actions="actions"
      default-sort-by="arrival_timestamp"
      :default-sort-desc="true"
      @update:selectBy="(v) => (select_by = v)"
      @update:tableMode="(tm) => (tableMode = tm)"
    >
      <template #cell(reference)="row">
        <router-link :to="{ name: referencePage, query: { shipment: row.item.id } }">{{
          row.item.reference
        }}</router-link>
      </template>
      <template #editcell(arrival_timestamp)="row">
        <typed-form-input
          :value="serializeDate(row.item.arrival_timestamp)"
          :type="Date"
          @input="(date) => (row.item.arrival_timestamp = new Date(date))"
        />
      </template>
      <template #cell(unlocked)="row">
        <b-form-row>
          <b-spinner v-if="row.item._loading" small />
          <div v-else-if="row.item.status === 'SENT'">
            <b-spinner small />
            Still processing...
          </div>
          <div v-else-if="row.item.status === 'DRAFT'">Not arrived</div>

          <b-form-checkbox
            v-else
            v-model="row.item.unlocked"
            :disabled="row.item.unlocked || editing"
            switch
            :unlocked="row.item.unlocked === true ? 'yes' : 'no'"
            @change="
              (value) => {
                releaseShipment(row.item.id, row.item, value)
              }
            "
          />
        </b-form-row>
      </template>
    </i-table>
  </div>
</template>
<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import useTableDefaultFields, { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'
import api_mixin from 'innicore/mixins/api_mixin.js'
import utils from 'innicore/mixins/utils.js'

import {
  FetchIncomingGoodsShipmentsDocument,
  IncomingGoodStatus,
  MutateShipmentUploadDocument,
  MutateShipmentsDocument,
} from '@/graphql/generated'
import AddShipmentModal from '@/views/incoming_goods/AddShipmentModal.vue'

export default {
  name: 'ShipmentOverview',
  components: {
    AddShipmentModal,
  },
  mixins: [api_mixin, utils],
  data() {
    return {
      errors: [],
      select_by: null,
      fields: [],
      test_flag: true, // this.$store.state.user.is_tester,
      referencePage: 'IncomingGoodsLegacy',
      tableMode: null,
      extraFields: [
        {
          key: 'cmp_name',
          label: 'Supplier',
          selected: true,
        },
        {
          key: 'reference',
          label: 'Reference',
          sortable: true,
          optional: false,
          type: Text,
        },
        {
          key: 'arrival_timestamp',
          label: 'Arrival date',
          sortable: true,
          optional: true,
          selected: true,
          formatter: this.serializeDate,
          type: Date,
          insertBefore: 'unit',
          editable: true, // this.$store.state.user.is_tester,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          optional: true,
          selected: false,
          insertBefore: 'unit',
        },
        {
          key: 'unlocked',
          sortable: true,
          label: 'Ready for pickup?',
          selected: true,
          type: Boolean,
        },
      ],
    }
  },
  computed: {
    actions() {
      if (!this.test_flag) return []
      return [
        { key: 'add_data', execute_global: this.show_add_data_modal, disallow: false },
        { key: 'edit', disallow: !this.supplier },
        { key: 'delete', disallow: true },
      ]
    },
    supplier() {
      return this.select_by?.supplier
    },
    allowed_selectors() {
      // if (this.test_flag) return [['shipment_status'], ['supplier']]

      return [['supplier'], ['done']]
    },
    editing() {
      return this.tableMode == TABLE_MODE.WRITE
    },
  },
  mounted() {
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.CustomerAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
    if (this.test_flag) this.referencePage = 'IncomingGoodsNew'
  },
  methods: {
    parseShipmentNode(node) {
      // if the shipment is sent to exact, but not yet unlocked
      if (node.status === IncomingGoodStatus.ACCEPTED) node._rowVariant = 'warning'
      if (node.status === IncomingGoodStatus.SENT) node._rowVariant = 'warning'

      // if the shipment is unlocked
      if (node.unlocked) node._rowVariant = 'success'
      // overwrite the unlocked rowVariant if the shipment is rejected
      if (node.status === IncomingGoodStatus.REJECTED) node._rowVariant = 'danger'

      node._loading = false //used for showing a spinner when the underlying data is being changed
      return {
        ...node,
        ...node.company,
      }
    },
    async fetchShipments(filters) {
      if (!filters?.supplier && this.test_flag) return null
      let released = filters?.done
      // We fetch all statuses by default
      let statuses = [
        IncomingGoodStatus.DRAFT,
        IncomingGoodStatus.SENT,
        IncomingGoodStatus.PROCESSED,
        IncomingGoodStatus.ACCEPTED,
        IncomingGoodStatus.REJECTED,
        IncomingGoodStatus.PROCESSING,
      ]
      // If the flag is set we try to take the the shipment statuses from the shipment status selector
      if (this.test_flag && filters?.shipment_status !== undefined) {
        // filter out the released status as this is not a status in the database but only in the selector
        released = filters.shipment_status.includes('RELEASED')
        statuses = filters.shipment_status.filter((status) => status !== 'RELEASED')
      }
      return {
        query: FetchIncomingGoodsShipmentsDocument,
        parameters: {
          company: this.supplier?.cmp_wwn,
          unlocked: released ? null : false,
          status_list: statuses,
        },
        after: (response) => {
          return response.data.data.incoming_goods_shipment.edges.map((obj) => this.parseShipmentNode(obj.node))
        },
      }
    },
    async releaseShipment(id, item, new_value) {
      item._loading = true
      const result = await this.api_call(MutateShipmentsDocument, {
        input: [{ id: id, unlocked: new_value }],
      })
      if (!result.data.errors) {
        item._rowVariant = result.data.data.MutateShipments.ShipmentFields[0].unlocked ? 'success' : 'danger'
      }
      item._loading = false
    },
    async mutateShipments(rows) {
      const mutation_input = rows.map((row) => this.parametersFromRow(row))
      const result = await this.api_call(MutateShipmentsDocument, {
        input: mutation_input,
      })
      if (result.data.errors) return { successful: [], errors: result.data.errors }
      return { successful: result.data.data.MutateShipments.ShipmentFields, errors: [] }
    },
    parametersFromRow(row) {
      return {
        id: row.id,
        arrival_timestamp: row.arrival_timestamp,
      }
    },
    async uploadPackingSlip(input) {
      const response = await this.api_call(MutateShipmentUploadDocument, { ...input })
      if (response.data.errors) return { errors: response.data.errors }
      if (response.data.data.MutateShipmentUpload.ShipmentField) {
        return { success: this.parseShipmentNode(response.data.data.MutateShipmentUpload.ShipmentField) }
      }
    },
    serializeDate(date) {
      if (date === null) return 'Not set'
      return this.format_date(new Date(date))
    },
    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    show_add_data_modal() {
      this.$bvModal.show('add_shipment_modal')
    },
  },
}
</script>
