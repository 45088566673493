<script lang="ts">
import { WorkflowType } from '@/graphql/generated.ts'
import WorkflowOverview from '@/views/workflows/WorkflowOverview.vue'

export default {
  extends: WorkflowOverview,
  data() {
    return {
      title: 'Email Orders',
      description: 'Here you can see all orders send to order@lienesch.com.',
      selectBy: null,
    }
  },
  methods: {
    getQueryParams() {
      return { type: WorkflowType.ORDER }
    },
  },
}
</script>
