import {
  BIcon,
  BIconArrowClockwise,
  BIconArrowCounterclockwise,
  BIconArrowDownCircle,
  BIconArrowRepeat,
  BIconArrowUpCircle,
  BIconBoxArrowInDownLeft,
  BIconBoxArrowUpRight,
  BIconCalculator,
  BIconCalendar2Month,
  BIconCheckCircle,
  BIconCheckSquareFill,
  BIconClockHistory,
  BIconCloud,
  BIconCurrencyEuro,
  BIconDownload,
  BIconEnvelope,
  BIconExclamationTriangleFill,
  BIconEye,
  BIconFileEarmark,
  BIconFileEarmarkArrowDown,
  BIconFileEarmarkDiff,
  BIconGear,
  BIconGraphUp,
  BIconInfoCircle,
  BIconList,
  BIconPatchPlusFill,
  BIconPencil,
  BIconPerson,
  BIconPlus,
  BIconPower,
  BIconPrinter,
  BIconQuestionCircle,
  BIconSquareFill,
  BIconThreeDotsVertical,
  BIconTools,
  BIconTrash,
  BIconUpload,
  BIconX,
  BIconXCircle,
  BIconXSquareFill,
  BIconstack,
} from 'bootstrap-vue'
import type { VueConstructor } from 'vue'

export const registerIcons = (Vue: VueConstructor) => {
  Vue.component('BIcon', BIcon)
  Vue.component('BIconArrowClockWise', BIconArrowClockwise)
  Vue.component('BIconArrowCounterclockwise', BIconArrowCounterclockwise)
  Vue.component('BIconArrowDownCircle', BIconArrowDownCircle)
  Vue.component('BIconArrowRepeat', BIconArrowRepeat)
  Vue.component('BIconArrowUpCircle', BIconArrowUpCircle)
  Vue.component('BIconBoxArrowInDownLeft', BIconBoxArrowInDownLeft)
  Vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight)
  Vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight)
  Vue.component('BIconCalculator', BIconCalculator)
  Vue.component('BIconCalendar2Month', BIconCalendar2Month)
  Vue.component('BIconCheckCircle', BIconCheckCircle)
  Vue.component('BIconCheckSquareFill', BIconCheckSquareFill)
  Vue.component('BIconClockHistory', BIconClockHistory)
  Vue.component('BIconCloud', BIconCloud)
  Vue.component('BIconCurrencyEuro', BIconCurrencyEuro)
  Vue.component('BIconDownload', BIconDownload)
  Vue.component('BIconEnvelope', BIconEnvelope)
  Vue.component('BIconExclamationTriangleFill', BIconExclamationTriangleFill)
  Vue.component('BIconEye', BIconEye)
  Vue.component('BIconFileEarmark', BIconFileEarmark)
  Vue.component('BIconFileEarmarkArrowDown', BIconFileEarmarkArrowDown)
  Vue.component('BIconFileEarmarkDiff', BIconFileEarmarkDiff)
  Vue.component('BIconGear', BIconGear)
  Vue.component('BIconGraphUp', BIconGraphUp)
  Vue.component('BIconInfoCircle', BIconInfoCircle)
  Vue.component('BIconList', BIconList)
  Vue.component('BIconList', BIconList)
  Vue.component('BIconPatchPlusFill', BIconPatchPlusFill)
  Vue.component('BIconPencil', BIconPencil)
  Vue.component('BIconPerson', BIconPerson)
  Vue.component('BIconPlus', BIconPlus)
  Vue.component('BIconPower', BIconPower)
  Vue.component('BIconPrinter', BIconPrinter)
  Vue.component('BIconQuestionCircle', BIconQuestionCircle)
  Vue.component('BIconSquareFill', BIconSquareFill)
  Vue.component('BIconstack', BIconstack)
  Vue.component('BIconThreeDotsVertical', BIconThreeDotsVertical)
  Vue.component('BIconTools', BIconTools)
  Vue.component('BIconTrash', BIconTrash)
  Vue.component('BIconUpload', BIconUpload)
  Vue.component('BIconX', BIconX)
  Vue.component('BIconXSquareFill', BIconXSquareFill)
  Vue.component('BIconXCircle', BIconXCircle)
}
