<template>
  <div>
    <b-card v-if="order && $store.state.user.is_employee">
      <b-card-header header-tag="b-row" align-h="between">
        <h3>Order: {{ order.order_id }}</h3>
      </b-card-header>
      <b-card-body>
        <b-col cols="9">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Customer</b></b-col>
                <b-col cols="8">
                  <template v-if="editing">
                    <component
                      :is="orderTypeComponent"
                      v-b-tooltip.hover
                      :value="order.company"
                      :disabled="isDraft"
                      :title="tooltip"
                      reset-confirmation-prompt="Are you sure? this will reset your order."
                      @input="(customer) => $emit('editAttribute', 'company', customer)"
                    />
                  </template>
                  <template v-else>
                    {{ order.company?.cmp_name }}
                  </template>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Reference</b></b-col>
                <b-col cols="8">
                  <b-form-input
                    :value="order.reference"
                    :disabled="!editing || !order.company"
                    :state="order.company && !!order.reference"
                    placeholder="Your reference"
                    @input="(reference) => $emit('editAttribute', 'reference', reference)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Order date</b></b-col>
                <b-col cols="8">
                  <b-form-input :value="order.order_date" :disabled="true" />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Requested shipping date</b></b-col>
                <b-col cols="8">
                  <b-form-input
                    :value="order.requested_shipping_date"
                    :disabled="!editing || !order.company"
                    :state="
                      order.company && !!order.requested_shipping_date && dateIsValid(order.requested_shipping_date)
                    "
                    type="date"
                    @input="
                      (requestedShippingDate) =>
                        $emit('editAttribute', 'requested_shipping_date', requestedShippingDate)
                    "
                  />
                  <b-form-invalid-feedback
                    v-if="order.company && order.requested_shipping_date"
                    :state="dateIsValid(order.requested_shipping_date)"
                  >
                    The shipping date can't be more than 30 days ago.
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="editing" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Default order unit</b></b-col>
                <b-col cols="8">
                  <b-form-select
                    :value="order.unit"
                    :disabled="!order.company"
                    :options="orderUnits"
                    :state="order.company && !!order.unit"
                    @input="(unit) => $emit('editAttribute', 'unit', unit)"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled> -- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="editing" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Order with</b></b-col>
                <b-col cols="8">
                  <b-form-select
                    :value="orderWith"
                    :disabled="!order.company"
                    :options="orderWiths"
                    :state="order.company && !!orderWith"
                    @input="(orderWith) => $emit('update:orderWith', orderWith)"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled> -- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="editing" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Discount</b></b-col>
                <b-col cols="8">
                  <b-form-input
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    placeholder="Discount"
                    :value="order.discount"
                    :state="order.company && order.discount && isDiscountValid(order.discount)"
                    :disabled="!order.company"
                    @input="(discount) => $emit('editAttribute', 'discount', discount)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <slot name="fields-employee" :editing="editing"></slot>
            <b-col v-if="order.status" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Status</b></b-col>
                <b-col cols="8">
                  <b-button :variant="order.status_color" block disabled squared style="opacity: 1 !important">
                    {{ order.status_text }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-card-body>
    </b-card>

    <b-card v-if="order && $store.state.user.is_customer">
      <b-card-header header-tag="b-row" align-h="between">
        <h3>Order: {{ order.order_id }}</h3>
      </b-card-header>
      <b-card-body>
        <b-col cols="9">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Reference</b></b-col>
                <b-col cols="8">
                  <b-form-input
                    :value="order.reference"
                    :disabled="!editing || !order.company"
                    :state="order.company && !!order.reference"
                    placeholder="Your reference"
                    @input="(reference) => $emit('editAttribute', 'reference', reference)"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Order date</b></b-col>
                <b-col cols="8">
                  <b-form-input :value="order.order_date" :disabled="true" />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Requested shipping date</b></b-col>
                <b-col cols="8">
                  <b-form-input
                    :value="order.requested_shipping_date"
                    :disabled="!editing || !order.company"
                    :state="
                      order.company && !!order.requested_shipping_date && dateIsValid(order.requested_shipping_date)
                    "
                    type="date"
                    @input="
                      (requestedShippingDate) =>
                        $emit('editAttribute', 'requested_shipping_date', requestedShippingDate)
                    "
                  />
                  <b-form-invalid-feedback
                    v-if="order.company && order.requested_shipping_date"
                    :state="dateIsValid(order.requested_shipping_date)"
                  >
                    The shipping date can't be more than 30 days ago.
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="editing" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Default order unit</b></b-col>
                <b-col cols="8">
                  <b-form-select
                    :value="order.unit"
                    :disabled="!order.company"
                    :options="orderUnits"
                    :state="order.company && !!order.unit"
                    @input="(unit) => $emit('editAttribute', 'unit', unit)"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled> -- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="editing" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Order with</b></b-col>
                <b-col cols="8">
                  <b-form-select
                    :value="orderWith"
                    :disabled="!order.company"
                    :options="orderWiths"
                    :state="order.company && !!orderWith"
                    @input="(orderWith) => $emit('update:orderWith', orderWith)"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled> -- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <slot name="fields-customer" :editing="editing"></slot>
            <b-col v-if="order.status" cols="6">
              <b-row>
                <b-col cols="4" scope="row" class="pr-3"><b>Status</b></b-col>
                <b-col cols="8">
                  <b-button :variant="order.status_color" block disabled squared style="opacity: 1 !important">
                    {{ order.status_text }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Vue from 'vue'

import { OrderStatus } from '@/graphql/generated'

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
  orderWith: {
    type: String,
    default: '',
  },
  purchase: {
    type: Boolean,
    default: false,
  },
})

const orderTypeComponent = computed<string>(() => {
  if (props.purchase) {
    return 'supplier-selector'
  }
  return 'customer-selector'
})

const isDraft = computed<boolean>(() => {
  return props.order.status === OrderStatus.DRAFT
})
const editing = computed<boolean>(() => {
  return !props.order.order_id || isDraft.value
})

const tooltip = computed<string | null>(() => {
  return isDraft.value ? 'Cannot modify company when editing draft order' : null
})

function dateIsValid(shipping_date) {
  if (!this.editing) {
    return true
  }

  return new Date(shipping_date) > cutoffDate.value
}

const cutoffDate = computed<Date>(() => {
  const date = new Date()
  date.setDate(date.getDate() - 30)
  return date
})

const isDiscountValid = (discountText: string) => {
  const discount = Number(discountText)
  return !isNaN(discount) && discount >= 0 && discount <= 100
}

const orderWiths = Vue.prototype.$constants.orders.orderWiths
const orderUnits = Vue.prototype.$constants.orders.orderUnits

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emits = defineEmits(['editProperty', 'update:orderWith'])
</script>

<style scoped></style>
