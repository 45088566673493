<template>
  <div>
    <h1>Email Order</h1>
    <WorkflowLineDetails v-if="workflow" :workflow-line="orderFileWorkflowLine" />
    <template v-if="orderParsingFailed">
      <b-alert show variant="danger">
        Unfortunately automatic parsing failed, please download and inspect the file and try uploading it manually.
      </b-alert>
    </template>

    <OrderEntry ref="OrderEntry" :on-success="onSuccess" />
    <add-company-to-user-modal
      :user-id="workflow.syscreator.id"
      :email="orderFile.uploaded_by.email"
      :on-complete="fetchData"
    />
  </div>
</template>

<script lang="ts">
import OrderEntry from '@/components/order/OrderEntry.vue'
import { WorkflowStatus } from '@/graphql/generated'
import AddCompanyToUserModal from '@/views/workflows/AddCompanyToUserModal.vue'
import { Workflow, WorkflowLine } from '@/views/workflows/Workflow'
import WorkflowLineDetails from '@/views/workflows/WorkflowLineDetails.vue'
import { OrderFile } from '@/views/workflows/order/OrderFile'

export default {
  components: { AddCompanyToUserModal, WorkflowLineDetails, OrderEntry },
  data() {
    return {
      workflow: null,
      orderFile: null,
    }
  },
  computed: {
    orderFileWorkflowLine() {
      return this.workflow?.workflowlines.filter((workflowLine) => workflowLine.containsOrderFile)[0]
    },
    company() {
      return this.$store.getters.getCompanyById(this.workflow.syscreator?.company?.cmp_wwn)
    },
    order() {
      return this.orderFile?.order
    },
    viewOrderRoute() {
      return { name: 'ViewOrder', params: { order_id: this.orderFile?.order.order_id } }
    },
    orderParsingFailed() {
      return !this.orderFileWorkflowLine.parsedJson.parsed_order
    },
  },
  watch: {
    order() {
      if (this.order) {
        this.$router.replace(this.viewOrderRoute)
      }
    },
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.fetchWorkflow()
      await this.fetchOrderFile()
      if (this.company) {
        this.insertCustomer()
        if (this.orderFileWorkflowLine.parsedJson) {
          return this.insertParsedFile()
        }
      } else {
        this.showAddCustomerModal()
      }
    },
    async insertParsedFile() {
      // in this situation there should always be a value on this.orderFile
      this.$refs.OrderEntry.handleParsedFileOrder(this.orderFileWorkflowLine.parsedJson.parsed_order, this.orderFile.id)
    },
    insertCustomer() {
      this.$refs.OrderEntry.setCustomer(this.company)
    },
    async fetchWorkflow() {
      const response = await this.$store.dispatch('fetchWorkflow', this.$route.params.id)
      this.workflow = new Workflow(response[0])
    },
    async fetchOrderFile() {
      if (this.orderFileWorkflowLine.object_id) {
        const response = await this.$store.dispatch('fetchOrderFile', this.orderFileWorkflowLine.object_id)
        this.orderFile = new OrderFile(response[0])
      }
    },
    async mutateOrderFileWorkflowLine() {
      await this.$store.dispatch('mutateWorkflowLine', this.orderFileWorkflowLine)
      return this.fetchWorkflow()
    },
    async createWorkfklowLine(status) {
      const workflowLine = new WorkflowLine(this.workflow, { status })
      await this.$store.dispatch('mutateWorkflowLine', workflowLine)
      await this.fetchWorkflow()
    },
    async updateObjectId(orderFileId) {
      this.orderFileWorkflowLine.object_id = orderFileId
      await this.$store.dispatch('mutateWorkflowLine', this.orderFileWorkflowLine)
      await this.fetchWorkflow()
    },
    async onSuccess(order) {
      if (order.file.id != this.orderFile?.id) {
        this.orderFileWorkflowLine.object_id = order.file.id
        await this.mutateOrderFileWorkflowLine()
      }
      await this.createWorkflowLine(WorkflowStatus.ACCEPTED)
      await this.fetchOrderFile()
    },
    showAddCustomerModal() {
      this.$bvModal.show('add_customer_modal')
    },
  },
}
</script>
