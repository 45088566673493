import utils from 'innicore/mixins/utils'

export default () => {
  return {
    id: 'custom_canvas_vertical_line',
    renderVerticalLine: (chart, pointIndex, label, color) => {
      const { ctx } = chart
      const scaler = 0.75 // Percentage height of vertical line
      const delta_y = chart.scales.y.maxHeight * scaler

      // Calculate the x and y pixel coordinates
      const chartArea = chart.chartArea // Bottom, top, left, right, width, height
      const stepSize = chartArea.width / chart.data.labels.length
      const label_x = chartArea.left + stepSize * pointIndex
      const label_y = chartArea.bottom

      // Note: Coordinates are with respect to the origin, what is, the left top corner.
      // Draw line
      ctx.beginPath()
      ctx.strokeStyle = color
      ctx.lineWidth = 2
      ctx.moveTo(label_x, label_y) // (x, y)
      ctx.lineTo(label_x, label_y - delta_y) // '-' because of coordinate system
      ctx.stroke()

      // Draw label
      ctx.fillStyle = color
      ctx.textAlign = 'center'
      ctx.fillText(label, label_x, label_y - delta_y - 10)
      ctx.save()
    },
    afterDatasetDraw: function (chart) {
      const verticalLines = chart.config.options.verticalLines
      verticalLines?.forEach((line) => {
        if (line.index > -1) this.renderVerticalLine(chart, line.index, line.label, utils.methods.color(line.color))
      })
    },
  }
}
