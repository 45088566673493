import Vue, { computed } from 'vue'

export enum TABLE_MODE {
  READ,
  WRITE,
  BULK_ADD,
}

export default Vue.extend({
  provide() {
    return {
      tableMode: computed(() => this.tableMode),
    }
  },
  props: {
    initialMode: {
      type: Number,
      validator(value: number) {
        return Object.values(TABLE_MODE).includes(value)
      },
      default: TABLE_MODE.READ,
    },
  },

  data() {
    return {
      tableMode: this.initialMode,
    }
  },

  computed: {
    inReadMode() {
      return this.tableMode === TABLE_MODE.READ
    },
    inWriteMode() {
      return this.tableMode === TABLE_MODE.WRITE
    },
    inBulkAddMode() {
      return this.tableMode === TABLE_MODE.BULK_ADD
    },
  },
  watch: {
    tableMode(newTableMode) {
      this.$emit('update:tableMode', newTableMode)
    },
  },
  mounted() {
    this.$emit('update:tableMode', this.tableMode) // necessary for CompaniesPage.vue
  },
})
