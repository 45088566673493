<template>
  <b-modal :id="name" :title="title" centered size="lg" @hidden="resetModal">
    <b-form>
      <b-form-group label="Supplier" label-cols="2">
        <supplier-selector id="SupplierInput" v-model="selectedSupplier" />
      </b-form-group>

      <b-form-group label="Reference" label-cols="2">
        <b-form-input id="ReferenceInput" v-model="reference" />
      </b-form-group>

      <file-importer :file-parse-callback="handleExcel" app-name="shipment" :hide-on-success="true" />
    </b-form>

    <b-row v-if="errors.length">
      <b-col cols="2">
        <b>Error:</b>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
            {{ error }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel, ok }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>
      <hit-button variant="primary" :click="() => handleOK(ok)" :disabled="!is_valid"> OK</hit-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin.js'

export default {
  mixins: [api_mixin],
  props: {
    callback: {
      type: Function,
      default: () => {
        return
      },
    },
    name: { type: String, default: '' },
    mutate: {
      type: Function,
      default: () => {
        return
      },
    },
    supplier: {
      type: Object,
      default: () => null,
    },
    title: { type: String, default: '' },
  },
  data() {
    return {
      selectedSupplier: null,
      reference: null,
      file: null,
      errors: [],
    }
  },
  computed: {
    modal_row() {
      return {
        supplier: this.selectedSupplier.cmp_wwn,
        reference: this.reference,
        file: this.file,
      }
    },
    is_valid() {
      return this.selectedSupplier && this.reference && this.file
    },
  },
  watch: {
    supplier(new_supplier) {
      if (new_supplier?.cmp_wwn !== this.selectedSupplier?.cmp_wwn) this.selectedSupplier = this.supplier
    },
  },
  methods: {
    async handleOK(okFunction) {
      const response = await this.mutate(this.modal_row, [])
      if (response.success) {
        this.callback(response.success)
        okFunction()
      }
    },
    resetModal() {
      this.selectedSupplier = this.supplier
      this.reference = null
      this.file = null
      this.errors = []
    },
    handleExcel(file: File) {
      this.file = file
      return { success: true }
    },
  },
}
</script>
