<template>
  <b-modal
    id="add_customer_modal"
    :title="title"
    centered
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    ok-only
    :ok-disabled="!customer"
    @ok="handleOK"
  >
    <b-container fluid>
      <b-form @keydown.enter.prevent="">
        <b-form-group label="Customer" label-cols="2">
          <customer-selector id="company_selector" v-model="customer" />
        </b-form-group>
      </b-form>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
export default {
  name: 'AddCompanyToUserModal',
  props: {
    email: { required: true, type: String },
    userId: { required: true, type: String },
    onComplete: { required: true, type: Function },
  },
  data() {
    return {
      title: `Add customer to ${this.email}`,
      customer: null,
    }
  },
  methods: {
    async handleOK() {
      const response = await this.$store.dispatch('mutateUser', {
        user_id: this.userId,
        account_code: this.customer.cmp_wwn,
      })
      if (response) {
        // Trigger refresh page so new user is loaded
        this.onComplete()
      }
    },
  },
}
</script>

<style scoped></style>
