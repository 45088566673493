import axios from 'axios'
import Vue from 'vue'

export class LabelData {
  // Data needs to be fetched from the stock and items application.
  // item
  item_code
  color_code
  ean_code

  // stock
  batch_code
  width
  quantity_net
  quantity_gross

  constructor(labelObject: Record<string, unknown> = {}) {
    Object.assign(this, labelObject)
  }

  get squared_meter() {
    return ((Number(this.width) / 100) * this.quantity_net).toFixed(2)
  }

  get csv_row() {
    return [
      this.item_code,
      this.batch_code,
      this.width,
      this.quantity_net,
      this.quantity_gross,
      this.color_code,
      this.squared_meter,
      this.ean_code,
      '2400',
    ].join(';')
  }
}

export default Vue.extend({
  methods: {
    sendToPrinter(data: LabelData[], url: string) {
      const input = { data: this.buildCsv(data) }
      axios
        .post(url, input, {
          headers: { 'Content-Type': 'text/plain' },
        })
        .catch((response) => {
          if (response.message == 'Network Error') {
            this.errors = this.errors.concat('Printer is niet goed ingesteld. Vraag hulp of print handmatig.')
          }
        })
    },
    buildCsv(data: LabelData[]) {
      const header = 'Artikelcode;Batchnummer;Breedte;Netto;Bruto;Kleur;VierkanteMeter;EANCode;BreedteBarcode\n'
      return header.concat(data.map((row) => row.csv_row).join('\n'))
    },
  },
})
