<template>
  <div>
    <b-container class="con">
      <!-- Magic filter -->
      <b-row v-if="magic_filter">
        <b-col cols="2">
          <span
            v-b-tooltip.hover
            title="The magic filter filters on all columns in the table. Type something to see what happens!"
          >
            <b> Magic filter </b>
          </span>
        </b-col>
        <b-col cols="8">
          <b-input-group>
            <b-form-input v-model="magic_filter_value" placeholder="Type to Search"></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!magic_filter_value" @click="magic_filter_value = ''"
                ><b-icon icon="x"></b-icon
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- TODO: Filter generator -->
      <b-row v-if="filter_generator">
        <b-col cols="2"> Column </b-col>
        <b-col cols="2"> Filter </b-col>
        <b-col cols="2"> Value </b-col>
        <b-col cols="2">
          <b-button variant="success" size="" @click="method()">
            <b-icon icon="plus" scale="1.5" aria-hidden="true" /> Add a filter
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="filter_generator">
        <b-col cols="2">
          <b-form-select :options="['Label column 1', 'Label column 2']"></b-form-select>
        </b-col>
        <b-col cols="2">
          <b-form-select :options="['Label column 1', 'Label column 2']"></b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-alert v-model="edit_lock_error.show" variant="warning" dismissible>
            <b-icon icon="exclamation-triangle-fill" variant="warning" /> {{ edit_lock_error.text }}
            <span v-b-tooltip.hover :title="edit_lock_error.info">
              <b-icon icon="question-circle" variant="info" />
            </span>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="optional_fields.length > 0"
        ><b-col><b> Select columns to display:</b></b-col></b-row
      >
    </b-container>

    <b-container class="con" :fluid="fluid">
      <!-- Table checkboxes for optional columns-->
      <b-row>
        <b-col cols="7">
          <b-form-checkbox-group v-model="selected_fields" :options="optional_fields"></b-form-checkbox-group>
        </b-col>

        <!-- Table buttons -->
        <b-col style="text-align: right">
          <template v-if="editable && !edit_mode">
            <!-- Add data button -->
            <b-button variant="success" size="" @click="showAddDataModal()">
              <b-icon icon="plus" scale="1.5" aria-hidden="true" /> Add data
            </b-button>
            <div class="divider" />

            <!-- Edit button. If slot specified, pass it on. Else, use default. -->
            <template>
              <template v-if="'edit_button' in $scopedSlots">
                <slot name="edit_button"></slot>
              </template>
              <b-button v-else variant="primary" @click="startEdit()">
                <b-icon icon="pencil" aria-hidden="true" /> Edit
              </b-button>
              <div class="divider" />
            </template>
          </template>

          <template v-if="editable && edit_mode">
            <b-button v-if="editable && edit_mode" variant="danger" @click="cancelEdit()">
              <b-icon icon="x-circle" aria-hidden="true"> </b-icon> Cancel
            </b-button>
            <div class="divider" />

            <b-button variant="success" @click="saveEdit()">
              <b-icon icon="check-circle" aria-hidden="true" /> Save changes
            </b-button>
            <div class="divider" />
          </template>

          <b-button v-if="excel_export && !edit_mode" variant="secondary" @click="export_to_excel()">
            <b-icon icon="box-arrow-up-right" aria-hidden="true" /> Export to excel
          </b-button>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-row>
        <b-col cols="12">
          <b-skeleton-table v-if="table_is_busy" :rows="10" :columns="table_fields.length"></b-skeleton-table>

          <b-table
            v-else
            :items="table_items"
            :fields="table_fields"
            :filter="magic_filter_value"
            @filtered="set_filtered_items"
          >
            <!-- Pass slots to b-table. -->
            <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
            <template v-for="slot_name in table_slots" #[slot_name]="slot_scope">
              <slot :name="slot_name" v-bind="slot_scope"></slot>
            </template>

            <!-- Actions slot -->
            <template #cell(actions)="row">
              <template v-if="'cell(actions)' in $scopedSlots">
                <slot name="cell(actions)" v-bind="row"></slot>
              </template>
              <!-- If no actions slot specified, use default one with a delete row button. -->
              <template v-else>
                <b-button variant="danger" @click="deleteRow(row)">
                  <b-icon icon="trash" aria-hidden="true" />
                </b-button>
              </template>
            </template>

            <!-- Always pass on row-details slot -->
            <template #row-details="row">
              <slot name="row-details" v-bind="row"></slot>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { writeFile, utils as xlsxUtils } from 'xlsx'

import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  name: 'FancyTable',
  mixins: [api_mixin, utils],
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    filter_generator: {
      // creates filter generator thingy if true
      type: Boolean,
      default: false,
    },
    magic_filter: {
      type: Boolean,
      default: false,
    },
    save_edit: {
      // If table editable, then provide edit save_edit(changes) function
      type: Function,
      default: undefined,
    },
    edit_lock_name: {
      // Name of the edit lock, if not specified, we don't use edit locks.
      type: String,
    },
    add_data_modal: {
      // The add data modal the add data button opens
      type: String,
      default: undefined,
    },
    excel_export: {
      // creates export to excel button if true
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      table_items: [],
      items_backup: null,
      edit_mode: false,
      deleted_rows: [],
      selected_fields: [],
      selected_filters: [],
      magic_filter_value: '',
      filtered_items: [],
      edit_lock_error: { show: false },
      is_busy: true,
    }
  },
  computed: {
    table_fields() {
      const table_fields = []
      for (let field in this.fields) {
        field = this.fields[field]
        // If optional, check if checkbox is selected
        if (field.optional && !this.selected_fields.includes(field.key)) {
          continue
        }
        // You can also hide a column using the visible attribute. Default = true. Use this only for non-selectable columns, that should be triggered by other events.
        if (field.visible != undefined && !field.visible) {
          continue
        }
        // If permissions specified, do permission check
        if (field.permissions != undefined) {
          let permission_check = true
          for (const p in field.permissions) {
            if (!this.$store.state.user[field.permissions[p]]) {
              permission_check = false
              break
            }
          }
          if (!permission_check) {
            continue
          }
        }
        // If keyname starts with an _, it should not be displayed.
        if (field.key.startsWith('_')) {
          continue
        }
        // If it reaches this line, all checks passed, and the column can be shown
        table_fields.push({
          key: field.key,
          label: field.label,
          sortable: field.sortable,
          variant: field.variant,
          class: field.class,
        })
      }
      if (this.edit_mode && !this.fields.map((f) => f.key).includes('actions')) {
        table_fields.push({ key: 'actions', label: 'Actions', class: 'actions-column' })
      }
      return table_fields
    },
    optional_fields() {
      return this.fields
        .filter((f) => f.optional)
        .map((f) => {
          return { value: f.key, text: f.checkbox_label ? f.checkbox_label : f.label }
        })
    },
    table_slots() {
      // Only pass on cell slots if table is editable
      const is_table_slot = (element) => {
        return element.startsWith('head(') || element.startsWith('foot(') || element.startsWith('cell(')
      }
      return Object.keys(this.$scopedSlots).filter(is_table_slot)
    },
    editable() {
      return Boolean(this.save_edit)
    },
    table_is_busy() {
      return this.table_items == undefined
    },
  },
  watch: {
    items: function () {
      if (this.items != undefined) {
        this.is_busy = false
      }
      this.table_items = this.items
    },
  },
  mounted() {
    this.selected_fields = this.fields.filter((f) => f.optional && f.selected).map((f) => f.key)
    this.table_items = this.items
  },
  beforeDestroy() {
    // If destroyed but still in edit mode, release the edit lock
    if (this.edit_mode) {
      this.MutateEditLock(true)
    }
  },
  methods: {
    startEdit() {
      if (this.edit_lock_name == undefined) {
        this.edit_mode = true
        this.items_backup = structuredClone(this.table_items)
      } else {
        this.MutateEditLock(false).then((response) => {
          if (response.data.data.MutateEditLock.success) {
            this.edit_mode = true
            this.items_backup = structuredClone(this.table_items)
          } else {
            this.edit_lock_error.text = response.data.data.MutateEditLock.message.split(' Info: ')[0]
            this.edit_lock_error.info = response.data.data.MutateEditLock.message.split(' Info: ')[1]
            this.edit_lock_error.show = true
          }
        })
      }
    },
    cancelEdit() {
      this.edit_mode = false
      this.table_items = this.items_backup
      this.items_backup = null
      this.deleted_rows = []
      if (this.edit_lock_name != undefined) {
        this.MutateEditLock(true)
      }
    },
    saveEdit() {
      this.edit_mode = false
      const items_backup_stringified = this.items_backup.map((item) => JSON.stringify(item))
      const changed_rows = this.table_items.filter((item) => !items_backup_stringified.includes(JSON.stringify(item)))
      this.save_edit(changed_rows, this.deleted_rows)
    },
    MutateEditLock(deleteLock) {
      const query = `
      mutation MutateEditLock($m: MutateEditLockInput!) {
        MutateEditLock(lock_input: $m) {
          success
          message
        }
      }`
      const params = { m: { name: this.edit_lock_name, delete: deleteLock } }
      return this.api_call(query, params)
    },
    addRow(row) {
      this.table_items.unshift(row)
    },
    deleteRow(row) {
      this.table_items.splice(this.table_items.map((i) => i.ItemCode).indexOf(row.item.ItemCode), 1)
      this.deleted_rows.push(row.item)
    },
    showAddDataModal() {
      if (this.add_data_modal != undefined) {
        this.$bvModal.show(this.add_data_modal)
      } else {
        console.error('add_data_modal is undefined! Pass the ID of a modal as add_data_modal to Fancy Table component')
      }
    },
    ojbects_equal(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },
    export_to_excel() {
      // Initialize Excel workbook
      const workbook = xlsxUtils.book_new()
      workbook.Props = {
        Title: 'Lienesch webapp Excel export',
        Subject: 'Excel export',
        Author: 'Lienesch webapp',
        CreatedDate: new Date(),
      }
      workbook.SheetNames.push('export')

      // Construct Items to export
      const export_items = []
      const fields_to_delete = this.fields
        .map((f) => f.key)
        .filter((f) => !this.table_fields.map((f) => f.key).includes(f))
      const items = this.filtered_items.length != 0 ? this.filtered_items : this.table_items
      for (let item in items) {
        item = structuredClone(items[item])
        //fields_to_delete.forEach(field => delete item[field])
        for (const key in item) {
          if (fields_to_delete.includes(key) || key.startsWith('_')) {
            delete item[key]
          }
        }
        export_items.push(item)
      }

      // Construct Headers from exported items
      const export_headers = []
      Object.keys(export_items[0]).forEach((key) => {
        const field = this.table_fields.find((field) => field.key == key)
        export_headers.push(field ? field.label : key)
      })

      // Initialize worksheet and add to workbook
      const worksheet = xlsxUtils.aoa_to_sheet([export_headers])
      xlsxUtils.sheet_add_json(worksheet, export_items, { origin: 'A2', skipHeader: true })
      workbook.Sheets['export'] = worksheet

      // Trigger browser to download Excel file
      writeFile(workbook, 'export.xlsx')
    },
    set_filtered_items(filtered_items) {
      this.filtered_items = filtered_items
    },
  },
}
</script>
