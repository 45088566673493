<template>
  <table-tab title="Table" selector="floating-tabs">
    <div v-if="items.length === 0">
      <slot name="empty-table" />
    </div>
    <b-table
      v-else
      ref="btable"
      responsive
      :items="sortedItems"
      :fields="tableFields"
      :per-page="tableSettings.pagination.per_page"
      :current-page="tableSettings.pagination.current_page"
      :sticky-header="tableSettings.sticky_header.enabled ? tableSettings.sticky_header.height + 'px' : false"
      head-variant="light"
      primary-key="id"
      :tbody-transition-props="{ name: 'flip-list' }"
      no-local-sorting
      @sort-changed="(ctx) => $emit('sortChanged', ctx)"
    >
      <template #thead-top="data">
        <b-tr>
          <template v-if="data.fields.some((f) => f.type === Number)">
            <b-th v-for="field in data.fields" :key="field.key" style="padding: 0">
              <table-column-aggregation
                v-if="field.type === Number"
                :values="
                  selectedItems.length === 0
                    ? sortedItems.map((x) => x[field.key])
                    : selectedItems.map((x) => x[field.key])
                "
                :formatter="field.formatter"
                :aggregations="field.aggregations"
              />
            </b-th>
          </template>
        </b-tr>
      </template>
      <template #cell(_icons)="row">
        <template v-for="(icon, index) in row.item._icons">
          <span :key="'span' + index" v-b-tooltip.hover :title="icon.hover_message ? icon.hover_message : ''">
            <b-icon :key="index" :icon="icon.icon" :variant="icon.variant" />
          </span>
        </template>
      </template>

      <template v-if="allow_row_selection" #cell(_select)="row">
        <b-checkbox :checked="row.item._select" @change="(new_state) => $set(row.item, '_select', new_state)" />
      </template>
      <template #cell(actions)="row">
        <template v-for="action in allowed_row_actions">
          <template v-if="allowForRow(action, row)">
            <span :key="action.key" v-b-tooltip.hover :title="action.disabled ? action.disabled_message : action.title">
              <b-button
                class="mx-1"
                :variant="action.variant"
                :disabled="action.disabled"
                :data-action="action.key"
                @click="action.execute(row.item)"
              >
                <b-icon :icon="action.icon" aria-hidden="true" />
              </b-button>
            </span>
          </template>
        </template>
      </template>
      <!-- Pass row-details slot along-->
      <template #row-details="row">
        <slot name="row-details" v-bind="row" />
      </template>
      <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </b-table>

    <b-container class="con" fluid>
      <b-row>
        <b-col>
          <b-pagination
            v-if="sortedItems.length > 0"
            v-model="tableSettings.pagination.current_page"
            :per-page="tableSettings.pagination.per_page"
            :total-rows="sortedItems.length"
            style="margin: auto; width: 10%"
          />
        </b-col>
      </b-row>
    </b-container>
  </table-tab>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import TableColumnAggregation from 'innicore/components/input/TableColumnAggregation.vue'

export default defineComponent({
  name: 'TableTabTable',
  components: { TableColumnAggregation },
  inject: [
    'sortedItems',
    'fields',
    'selectedFields',
    'selectedItems',
    'selectedRows',
    'tableFields',
    'allow_row_selection',
    'allowedActions',
    'tableMode',
    'allowed_row_actions',
    'allowForRow',
    'items',
    'tableSettings',
  ],
})
</script>
