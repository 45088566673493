import type { ASTNode as GraphQL_AST } from 'graphql/language/ast'
import { type PropType, computed, defineComponent } from 'vue'

export enum FETCH_STATE {
  BUSY,
  FIRST_PAGE_FETCHED,
  DONE,
}
interface FetchItemsFirstPage {
  query: string | GraphQL_AST //The query
  parameters: Record<string, unknown> //Parameters for the query
  options?: object //Extra options to api_call
  fetchFirstPage?: boolean //Whether the table should first fetch the first page
}

/**
 * To allow the table to fetch the first page first, pass the FetchItemsFirstPage object to Innico Table.
 * If this is not needed, one can pass a function that returns the items, which was the previous
 * implementation
 */
type FetchItems = FetchItemsFirstPage | (() => Array<object>) | Array<object>

export default defineComponent({
  provide() {
    return {
      items: computed(() => this.items),
      fetch: this.fetch,
      selectedItems: computed(() => this.selectedItems),
      selectedRows: computed(() => this.selectedRows),
    }
  },
  props: {
    fetchItems: {
      type: [Object, Function] as PropType<FetchItems>,
      default: () => null,
    },
  },
  data: () => ({
    items: [],
    fetchState: FETCH_STATE.DONE,
  }),
  computed: {
    busy() {
      return this.fetchState === FETCH_STATE.BUSY || this.fetchState === FETCH_STATE.FIRST_PAGE_FETCHED
    },
    selectedItems() {
      return this.sortedItems.filter((item) => item._select)
    },
    selectedRows() {
      return this.selectedItems.length
    },
  },
  watch: {
    items: {
      handler(new_items) {
        this.$emit('items-update', new_items)
      },
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.fetchState = FETCH_STATE.BUSY
      const filters = this.select_bys_value
      const fetchIt = await this.fetchItems(filters)
      let items
      if (fetchIt && 'query' in fetchIt) {
        items = await this.fetchFirstPage(fetchIt)
      } else {
        items = fetchIt
      }
      if (items) {
        this.handleNewItems(items)
      }
      this.fetchState = FETCH_STATE.DONE
      this.edited_items = 0
    },
    removeItem(item) {
      if (this.itemHasID(item) || !this.inBulkAddMode) {
        this.removed_items.push(item)
        item._rowVariant = 'danger'
      } else {
        // no id exists, so the record doesn't yet exist in the database, and we can safely delete it.
        this.removeFromTable(item)
      }
    },
    removeFromTable(item) {
      const index = this.items.indexOf(item)
      if (index > -1) {
        this.items.splice(index, 1)
      }
    },
    async fetchFirstPage({ query, parameters, options = {}, after, firstPageFetch = true }) {
      let done = false
      const firstPageQuery = async () => {
        const response = await this.api_call(query, parameters, {
          ...options,
          first: this.tableSettings.pagination.per_page,
        })
        if (done) {
          return
        }
        const firstPageItems = after(response)
        if (firstPageItems) {
          this.handleNewItems(firstPageItems)
        }
        this.fetchState = FETCH_STATE.FIRST_PAGE_FETCHED
      }
      if (firstPageFetch && this.tableSettings.pagination.per_page < 10000) {
        firstPageQuery()
      }
      const response = await this.api_call(query, parameters, options)
      done = true
      return after(response)
    },

    handleNewItems(items) {
      items.forEach((item, index) => {
        if (!item._showDetails) {
          this.$set(item, '_showDetails', false)
        }
        if (!item._select) {
          item._select = false
        }
        if (!item._rowVariant) {
          item._rowVariant = null
        }
        if (!item._index) {
          item._index = index
        }
      })
      items = this.computeFields(items)
      this.items = items
    },

    computeFields(items) {
      if (!items) {
        return
      }
      items.forEach((item) =>
        this.fields.forEach((field) => {
          if (!item[field.key]) {
            return
          }
          if (field.compute) {
            item[field.key] = field.compute(item)
          }
          switch (field.type) {
            case Number:
              if (item[field.key]) {
                item[field.key] = Number(item[field.key])
              }
              break
            default:
            //pass
          }
        })
      )
      return items
    },
    itemHasID(item) {
      return item.id !== null && item.id !== undefined
    },
  },
})
