import * as Sentry from '@sentry/vue'
import axios from 'axios'
import { print } from 'graphql/language/printer'

const api_address = import.meta.env.VITE_API_ADDRESS

const QUERY_PARAMS_REGEX = /query\s*.*\(.*\)\s*{\s*.*\((?<queryParams>[\s\S]*)\)\s*{(?:.|\n)*}\s*}[\s\S]*/

function addQueryParam(query, paramName, paramValue) {
  return query.replace(QUERY_PARAMS_REGEX, (_0, queryParams, _1, string) => {
    if (!queryParams.includes(paramName)) {
      return string.replace(queryParams, `${paramName}: ${paramValue}, ` + queryParams)
    } else {
      const queryParamsDict = {}
      // split on either "\n" or "," and trailing whitespace. this is necessary because we may be dealing with something
      // else than a 'print'-ed graphql AST.
      // slice because there is an extra preceding as well as trailing \n.
      const queryParamsSplit = queryParams.split(/[\n,]\s*/).slice(1, -1)
      queryParamsSplit.forEach((paramString) => {
        // split on ":" and trailing whitespace
        const paramSplit = paramString.split(/:\s+/)
        queryParamsDict[paramSplit[0]] = paramSplit[1]
      })
      queryParamsDict[paramName] = paramValue
      const newQueryParams = Object.keys(queryParamsDict)
        .map((k) => `${k}: ${queryParamsDict[k]}`)
        .join(', ') // for consistency, I'd rather join on the originally used separator, but that's effort without gain
      return string.replace(queryParams, newQueryParams)
    }
  })
}

export default {
  methods: {
    api_call(
      query,
      variables = null,
      { check_auth = true, store = null, token = null, feedback_options = false, offset = null, first = null } = {}
    ) {
      const str = store ? store : this.$store
      let call
      const tkn = token ? token : str.state.token

      let operation_name = null
      if (typeof query !== 'string') {
        // Set operation_name var for Sentry (only works on ASTs, not strings)
        operation_name = query.definitions[0].name.value
        query = print(query)
      }

      if (offset) {
        query = addQueryParam(query, 'offset', offset)
      }
      if (first) {
        query = addQueryParam(query, 'first', first)
      }

      if (check_auth) {
        call = (query, variables) => {
          return axios.post(
            api_address,
            { query: query, variables: variables, operationName: operation_name },
            { headers: { Authorization: 'JWT ' + tkn }, timeout: 300000 } // 5 minutes in ms
          )
        }
      } else {
        call = (query, variables) => {
          return axios.post(api_address, { query: query, variables: variables, operationName: operation_name })
        }
      }

      return call(query, variables).then((response) => {
        let valid = true
        const unique_errors = new Map()
        // GraphQL can reply with a 200 OK, but put errors in the response.
        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            if (error.message == 'Signature has expired' || error.message == 'Refresh has expired') {
              str.commit('log_out')
              valid = false
            } else if (error.message == 'You do not have permission to perform this action') {
              window.location.replace('/login')
            } else {
              if (feedback_options) {
                this.show_error_modal(response.data.errors, { Query: query, Variables: variables })
              }
              unique_errors.set(error.message, { query: query, data: response.data, full_error: error })
            }
          })
        }

        this.send_errors_to_sentry(unique_errors)

        if (valid) {
          return response
        }
        return false
      })
    },
    api_call_feedback(query, variables, options = {}) {
      return this.api_call(query, variables, { feedback_options: options })
    },
    show_error_modal(errors, options) {
      const feedbackVNode = this.$createElement('error-feedback', {
        props: {
          errors: errors,
          options: options,
        },
      })
      this.$bvModal.msgBoxOk([feedbackVNode], { title: 'Something went wrong...', size: 'lg' })
    },
    send_errors_to_sentry(errors) {
      errors.forEach((error_message, details) => {
        const scope = new Sentry.Scope()
        scope.setContext('context', { query: details.query, data: details.data })
        Sentry.captureMessage(details.full_error, scope)
      })
    },
  },
}
