import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import { createApp } from 'innicore/app'
import 'innicore/custom.scss'
import { store } from 'innicore/store'

import SupportEmailAddress from '@/components/SupportEmailAddress.vue'
import MonthlyForecast from '@/components/forecast/MonthlyForecast.vue'
import AppSelector from '@/components/selectors/AppSelector.vue'
import CollectionItemSelector from '@/components/selectors/CollectionItemSelector.vue'
import DataTypeSelector from '@/components/selectors/DataTypeSelector.vue'
import DoneSelector from '@/components/selectors/DoneSelector.vue'
import FieldSelector from '@/components/selectors/FieldSelector.vue'
import ItemSelector from '@/components/selectors/ItemSelector.vue'
import ShipmentSelector from '@/components/selectors/ShipmentSelector.vue'
import ShipmentStatusSelector from '@/components/selectors/ShipmentStatusSelector.vue'
import SupplierSelector from '@/components/selectors/SupplierSelector.vue'
import TrustedSupplierSelector from '@/components/selectors/TrustedSupplierSelector.vue'
import SelectBy from '@/components/table/SelectBy.vue'
import { router } from '@/router'
import { storeLienesch } from '@/store/lienesch'
import ErrorFeedback from '@/views/core/ErrorFeedback.vue'

// For some reason vue-typeahead-bootstrap can't be loaded when included in Innicore's createApp
Vue.component('VueTypeaheadBootstrap', VueTypeaheadBootstrap)

Vue.component('AppSelector', AppSelector)
Vue.component('CollectionItemSelector', CollectionItemSelector)
Vue.component('DataTypeSelector', DataTypeSelector)
Vue.component('DoneSelector', DoneSelector)
Vue.component('ErrorFeedback', ErrorFeedback)
Vue.component('FieldSelector', FieldSelector)
Vue.component('ItemSelector', ItemSelector)
// @ts-expect-error: component type incompatibility
Vue.component('MonthlyForecast', MonthlyForecast)
Vue.component('SelectBy', SelectBy)
Vue.component('ShipmentSelector', ShipmentSelector)
Vue.component('ShipmentStatusSelector', ShipmentStatusSelector)
Vue.component('TrustedSupplierSelector', TrustedSupplierSelector)
Vue.component('SupplierSelector', SupplierSelector)
Vue.component('SupportEmailAddress', SupportEmailAddress)

store.registerModule('lienesch', storeLienesch)

export const app = createApp(router, ['api.webapp.lienesch.com', 'staging.api.webapp.lienesch.com'], 'lienesch.com')
