<template>
  <b-row>
    <b-col cols="2">
      <b> Search </b>
    </b-col>
    <b-col cols="8">
      <b-input-group>
        <b-form-input
          id="input-magic-filter"
          :value="value"
          placeholder="Type to search all fields."
          debounce="500"
          :disabled="disabled"
          @update="$emit('input', $event)"
        />
        <b-input-group-append>
          <b-button :disabled="disabled || !value" @click="() => $emit('input', '')">
            <b-icon icon="x" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>
<script lang="ts">
export default {
  name: 'MagicFilter',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
