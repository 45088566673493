<template>
  <div>
    <b-container v-if="settings.magic_filter" class="con">
      <MagicFilter
        name="magic-filter"
        :value="magicFilterValue"
        :disabled="loading"
        @input="(value) => filterEvents.$emit('update:magicFilterValue', value)"
      />
    </b-container>

    <slot name="pre-table"></slot>
    <slot name="lock-errors" />
    <b-container class="con" :fluid="fluid">
      <b-row>
        <b-col cols="12">
          <slot name="errors"></slot>
          <portal-target name="floating-tabs" multiple />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-alert v-if="allow_row_selection" :show="selectedRows > 0"
            >You have selected a total of {{ selectedRows }} row(s).</b-alert
          >
          <slot name="bulk-edit-message"></slot>
          <b-card no-body>
            <b-tabs v-model="tabs_index" card fill>
              <template #tabs-start>
                <b-button-toolbar v-if="allow_row_selection">
                  <!-- fancy table select thing -->
                  <b-button-group>
                    <b-dropdown variant="light" split>
                      <template #button-content>
                        <b-form-checkbox
                          style="padding-right: 0"
                          :checked="select_checkbox_checked"
                          :indeterminate="select_checkbox_indeterminate"
                          @change="(checked) => select_rows(checked ? 'page' : 'none')"
                        ></b-form-checkbox>
                      </template>
                      <b-dropdown-item-button @click="select_rows('all')">Select all rows</b-dropdown-item-button>
                      <b-dropdown-item-button @click="select_rows('page')">Select current page</b-dropdown-item-button>
                      <b-dropdown-item-button @click="select_rows('!page')"
                        >Unselect current page
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="select_rows('none')">Unselect all rows</b-dropdown-item-button>
                    </b-dropdown>
                  </b-button-group>
                </b-button-toolbar>
              </template>
              <template #tabs-end>
                <template v-if="pagination_summary">
                  <b-pagination
                    v-model="settings.pagination.current_page"
                    :per-page="settings.pagination.per_page"
                    :total-rows="filteredItems.length"
                    class="mx-1"
                  />
                  <b-nav-item v-b-tooltip.hover="loading ? 'Fetching other pages...' : ''" disabled
                    ><span>{{ pagination_summary }}</span>
                    <b-spinner
                      v-if="loading"
                      v-b-tooltip.hover
                      title=""
                      class="ml-1"
                      small
                      label="Spinning"
                      variant="primary"
                    />
                  </b-nav-item>
                </template>

                <template v-for="action in allowed_global_actions">
                  <span :key="action.key" v-b-tooltip.hover :title="action.disabled ? action.disabled_message : ''">
                    <HitButton
                      class="mx-1"
                      :click="action.execute_global"
                      :variant="action.variant"
                      :disabled="action.disabled || loading"
                      :data-action="action.key"
                    >
                      <b-icon v-if="action.icon" :icon="action.icon" :scale="action.scale" aria-hidden="true" />
                      {{ action.title }}
                    </HitButton>
                  </span>
                </template>

                <template v-if="allowed_extra_global_actions.length > 0">
                  <b-dropdown toggle-class="text-decoration-none" no-caret variant="link" dropup right>
                    <template #button-content>
                      <b-icon icon="three-dots-vertical" variant="secondary" />
                    </template>
                    <template v-for="action in allowed_extra_global_actions">
                      <b-dropdown-item-button
                        :key="action.key"
                        :data-action="action.key"
                        @click="action.execute_global"
                      >
                        {{ action.title }}
                      </b-dropdown-item-button>
                    </template>
                  </b-dropdown>
                </template>
              </template>
              <table-tab-table ref="tableTabTable" :items="items" :settings="settings" @sortChanged="updateSort">
                <slot name="empty-table"></slot>
                <template #row-details="row">
                  <slot name="row-details" v-bind="row"></slot>
                </template>
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                  <slot :name="name" v-bind="slotData" />
                </template>
              </table-tab-table>
              <table-tab-columns :checked="selectedFields" :options="optional_fields" @input="updateSelectedFields">
                <slot name="extra-columns"></slot>
              </table-tab-columns>
              <table-tab-actions v-if="has_any_executable_bulk_actions" @resetTabs="tabs_index = 0">
                <slot
                  name="extra-bulk-actions"
                  :selected_items="selectedItems"
                  :selected_fields="selectedFields"
                ></slot>
              </table-tab-actions>
              <table-tab-filter> </table-tab-filter>

              <table-tab-settings :settings="settings"> </table-tab-settings>
              <table-tab-help>
                <slot name="help-tab"> </slot>
              </table-tab-help>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { PortalTarget } from 'portal-vue'
import { computed } from 'vue'

import HitButton from 'innicore/components/input/HitButton.vue'
import MagicFilter from 'innicore/components/table/MagicFilter.vue'
import TableTabActions from 'innicore/components/table/TableTabActions.vue'
import TableTabColumns from 'innicore/components/table/TableTabColumns.vue'
import TableTabFilter from 'innicore/components/table/TableTabFilter.vue'
import TableTabHelp from 'innicore/components/table/TableTabHelp.vue'
import TableTabSettings from 'innicore/components/table/TableTabSettings.vue'
import TableTabTable from 'innicore/components/table/TableTabTable.vue'

export default {
  name: 'ReimaginedTable',
  components: {
    MagicFilter,
    PortalTarget,
    HitButton,
    TableTabTable,
    TableTabColumns,
    TableTabFilter,
    TableTabActions,
    TableTabHelp,
    TableTabSettings,
  },
  inject: [
    'filterEvents',
    'filteredItems',
    'filterableFields',
    'magicFilterValue',
    'allFilters',
    'sortBy',
    'sortDesc',
    'sortEvents',
    'sortedItems',
    'items',
    'selectedItems',
    'selectedRows',
    'selectedFields',
    'actionAllows',
    'tableMode',
    'fields',
    'allowedActions',
    'settingsEvents',
    'tableSettings',
  ],
  provide() {
    return {
      bulk_actions: computed(() => this.bulk_actions),
      allowed_bulk_actions: computed(() => this.allowed_bulk_actions),
      bulk_actions_enabled: computed(() => this.bulk_actions_enabled),
      allow_row_selection: computed(() => this.allow_row_selection),
      execute_bulk_action: computed(() => this.executable_bulk_action),
    }
  },
  props: {
    fluid: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      input: {},
      tabs_index: 0,
      has_extra_bulk_actions: false,
      settings: this.tableSettings,
    }
  },
  computed: {
    optional_fields() {
      return this.fields
        .filter((f) => f.optional)
        .map((f) => {
          return { value: f.key, text: f.checkbox_label ? f.checkbox_label : f.label, group: f.group }
        })
    },
    bulk_actions() {
      // for now, all actions are also turned into bulk actions.
      // This is done by simply mapping action.execute over an array of rows.
      // filter out actions that define neither execute_bulk nor execute. (such as global_actions)
      return this.allowedActions
        .filter((a) => a.execute_bulk || a.execute)
        .map((a) => {
          if (!a.execute_bulk && a.execute) {
            a.execute_bulk = (rows) => {
              rows.forEach((r) => {
                a.execute(r)
              })
            }
          }
          return a
        })
    },
    allowed_bulk_actions() {
      return this.bulk_actions.filter((a) => this.actionAllows(a, this.tableMode))
    },
    global_actions() {
      return this.allowedActions.filter((a) => a.execute_global && !a.is_extra_global)
    },
    allowed_global_actions() {
      return this.global_actions.filter((a) => this.actionAllows(a, this.tableMode))
    },
    extra_global_actions() {
      return this.allowedActions.filter((a) => a.execute_global && a.is_extra_global)
    },
    allowed_extra_global_actions() {
      return this.extra_global_actions.filter((a) => this.actionAllows(a, this.tableMode))
    },
    select_checkbox_checked() {
      if (this.filteredItems === undefined || this.filteredItems.length === 0) {
        return false
      }
      return this.filteredItems.map((item) => item._select).includes(true)
    },
    select_checkbox_indeterminate() {
      if (this.filteredItems === undefined || this.filteredItems.length === 0) {
        return false
      }
      return !this.filteredItems.map((item) => item._select).every((val, i, arr) => Boolean(arr[0]) === Boolean(val))
    },
    bulk_actions_enabled() {
      return this.selectedRows > 0
    },
    pagination_summary() {
      if (!this.items || this.items.length === 0) {
        return undefined
      } else if (!this.filteredItems) {
        return '0 out of 0'
      } else if (!this.loading) {
        const start = 1 + (this.settings.pagination.current_page - 1) * this.settings.pagination.per_page
        let end = start + this.settings.pagination.per_page - 1
        const total = this.filteredItems.length
        end = end > total ? total : end
        return `${start}-${end} out of ${total}`
      } else {
        return `1-${this.filteredItems.length} out of`
      }
    },
    filterableFields() {
      return this.fields
        .filter((f) => f.type)
        .map((f) => ({ options: [...new Set(this.items.map((i) => i[f.key]))], ...f }))
    },
    selected_fields() {
      return this.fields.filter((f) => f.selected).map((f) => f.key)
    },
    allow_row_selection() {
      return this.allowed_bulk_actions.length > 0 || this.has_extra_bulk_actions
    },
    executable_bulk_actions() {
      return this.allowed_bulk_actions.filter((action) => this.is_executable(action))
    },
    has_any_executable_bulk_actions() {
      return this.executable_bulk_actions.length > 0 || this.has_extra_bulk_actions
      // extra bulk actions are rendered only in a slot, so not part of actions array
    },
  },
  watch: {
    show_tab: {
      handler: function () {
        this.tabs_index = 0
      },
      deep: true,
    },
    settings: {
      handler: function (newSettings) {
        this.settingsEvents.$emit('update:settings', newSettings)
      },
      deep: true,
    },
    tableSettings: {
      handler: function (newSettings) {
        if (newSettings !== this.settings) {
          this.settings = newSettings
        }
      },
      deep: true,
    },
  },
  beforeUpdate() {
    this.has_extra_bulk_actions = Object.keys(this.$scopedSlots).includes('extra-bulk-actions')
  },
  methods: {
    updateSort(ctx) {
      if (ctx.sortBy !== this.sortBy) {
        this.sortEvents.$emit('update:sortBy', ctx.sortBy)
      }
      if (ctx.sortDesc !== this.sortDesc) {
        this.sortEvents.$emit('update:sortDesc', ctx.sortDesc)
      }
    },
    select_rows(select) {
      if (select === 'all') {
        this.filteredItems.forEach((item) => this.$set(item, '_select', true))
      } else if (select === 'page') {
        this.$refs.tableTabTable.$refs.btable.computedItems.forEach((item) =>
          this.$set(
            this.filteredItems.find((o) => o === item),
            '_select',
            true
          )
        )
      } else if (select === '!page') {
        this.$refs.tableTabTable.$refs.btable.computedItems.forEach((item) =>
          this.$set(
            this.filteredItems.find((o) => o.id === item.id),
            '_select',
            false
          )
        )
      } else if (select === 'none') {
        this.filteredItems.forEach((item) => this.$set(item, '_select', false))
      } else {
        throw 'Not a valid select parameter.'
      }
    },
    is_executable(action) {
      return Object.keys(action).filter((k) => k.includes('execute')).length > 0
    },
    updateSelectedFields(values) {
      this.optional_fields.forEach((optional_field) => {
        const field = this.fields.find((field) => field.key === optional_field.value)
        this.$set(field, 'selected', values.indexOf(optional_field.value) !== -1)
      })
    },
  },
}
</script>

<style>
/*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
