<template>
  <b-modal :id="name" centered size="lg" :title="title" @ok="handleOK" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <b-col cols="2">
          <b>Item code</b>
        </b-col>
        <b-col>
          <item-selector v-model="item" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b>Item code account</b>
        </b-col>
        <b-col>
          <b-form-input v-model="company_item_code" placeholder="Input code..." />
        </b-col>
      </b-row>
      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">{{
              error
            }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  props: {
    callback: Function,
    name: String,
    company: String,
    mutate: Function,
    title: String,
  },
  data() {
    return {
      item: null,
      company_item_code: null,
      errors: [],
    }
  },
  computed: {
    ItemCode() {
      return this.itemcode ? this.itemcode : this.item ? this.item.ItemCode : null
    },
    modal_row_is_valid() {
      return this.ItemCode
    },
    modal_row() {
      return {
        company: this.company,
        ItemCode: this.ItemCode,
        company_item_code: this.company_item_code,
      }
    },
  },
  methods: {
    handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.modal_row_is_valid) {
        this.mutate([this.modal_row], []).then((response) => {
          if (response.errors?.length) {
            response.errors.forEach((error) => {
              if (error.message.toLowerCase().includes('unique')) {
                this.errors.push('This customer already has a price agreement for this item.')
              } else {
                this.errors.push(error.message)
              }
            })
          } else {
            this.callback(response.successful[0])
            this.$nextTick(() => this.$bvModal.hide(bvModalEvent.componentId))
          }
        })
      }
    },
    resetModal() {
      this.item = null
      this.company_item_code = null
      this.errors = []
    },
    serialize_item(item) {
      return item ? item.ItemCode + ' - ' + item.Description : ''
    },
  },
}
</script>
