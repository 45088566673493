<template>
  <div>
    <h4>Quality Assurance Page</h4>
    <p>
      Quality Assurance objects hold the logical values that we use to check if a products quality should be checked
      upon arrival. If <b>approved</b> is false, then the quality of the product should be checked.
    </p>
    <div class="mt-4">
      The current way of evaluating what the current <b>approved</b> value of a QualityAssurance object is is by
      following the following steps:
      <ul class="mt-4">
        <li>If <i>Quality at door</i> is set to false, then the <b>approved</b> value is false</li>
        <li>
          Otherwise, if one of the following is true, then the <b>approved</b> value is true:
          <ul>
            <li><i>Trusted supplier</i></li>
            <li><i>Quality precheck</i></li>
            <li><i>Contractors</i></li>
          </ul>
        </li>
        <li>If none are true, then the <b>approved</b> value is false</li>
      </ul>
    </div>
    <p>
      It is important to now that the <b>approved</b> value can not be set manually. It is always calculated based on
      the values of the other fields.
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'QualityAssuranceHelp',
}
</script>
