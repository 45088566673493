<template>
  <div v-if="$store.state.user.is_tester">
    <h1>Item Forecast Overview</h1>
    <p>On this page you can view a forecast of each item. To see more per item, click on that item</p>

    <i-table
      ref="ITable"
      app-name="item-forecast"
      :fetch-items="fetchItems"
      :fields="fields"
      :actions="actions"
      :allowed-selectors="[['rolling_fc_date']]"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #cell(ItemCode)="row">
        <router-link v-b-popover.hover.top="'View item forecast'" :to="viewMoreRoute(row.item)">
          {{ row.item.ItemCode }}
        </router-link>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import useTableDefaultFields, { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { parseItem } from '@/common/parseItem'
import { FetchItemForecastOverviewDocument } from '@/graphql/generated'
import { router } from '@/router'

export default {
  mixins: [api_mixin, utils],
  data() {
    return {
      fields: [],
      extraFields: [
        {
          key: 'rolling_fc',
          label: 'Rolling forecast (m)',
          type: Number,
          sortable: true,
          selected: true,
          formatter: (i) => this.format_num(i, 0),
        },
        {
          key: 'sold_past_year',
          label: 'Sold past year',
          type: Number,
          sortable: true,
          selected: true,
          formatter: (i) => this.format_num(i, 0),
        },
        {
          key: 'expected_growth',
          label: 'Expected growth',
          type: Number,
          sortable: true,
          selected: true,
          formatter: this.formatFraction,
        },
        {
          key: 'price',
          label: 'Price',
          type: Number,
          sortable: true,
          aggregations: ['avg', 'min', 'max'],
          formatter: this.formatPrice,
        },
        {
          key: 'cost_price',
          label: 'Cost price',
          type: Number,
          sortable: true,
          aggregations: ['avg', 'min', 'max'],
          formatter: this.formatPrice,
        },
        {
          key: 'gross_revenue',
          label: 'Gross revenue',
          type: Number,
          sortable: true,
          selected: true,
          formatter: this.formatMoney,
        },
        {
          key: 'gross_profit',
          label: 'Gross profit',
          type: Number,
          sortable: true,
          selected: true,
          formatter: this.formatMoney,
        },
        {
          key: 'gross_profit_margin',
          label: 'Gross profit margin',
          type: Number,
          sortable: true,
          selected: true,
          formatter: this.formatFraction,
          aggregations: ['avg', 'min', 'max'],
        },
        // ],
        // extraFields: [
        { key: 'fc_date', label: 'FC date', type: Date, sortable: true, formatter: this.format_fc_date },

        {
          key: 'last_manual_edit',
          label: 'Last manual edit',
          type: Date,
          sortable: true,
          formatter: (d) => this.format_date_time(new Date(d)),
        },
        { key: 'agreed_price', label: 'Agreed price', type: Number, sortable: true, formatter: this.formatPrice },
        { key: 'default_price', label: 'Default price', type: Number, sortable: true, formatter: this.formatPrice },
        {
          key: 'rollingforecast_count',
          label: 'Rolling forecast count',
          type: Number,
          sortable: true,
          formatter: (i) => this.format_num(i, 0),
        },
      ].map((field) => ({ ...field, optional: true })),
      actions: [
        {
          key: 'overview',
          title: 'View item forecast',
          icon: 'eye',
          variant: 'b-blue',
          execute: (obj) => router.push(this.viewMoreRoute(obj)),
        },
      ],
      select_by: null,
      euroFormatter: Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR' }),
    }
  },
  computed: {
    fc_date() {
      return this.select_by?.rolling_fc_date
    },
  },
  mounted() {
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
  },
  methods: {
    viewMoreRoute(obj) {
      const query = { item: obj.ItemCode }
      if (this.fc_date) query.fc_date = this.fc_date
      return { name: 'company-item-forecast', query }
    },
    parseItemForecastOverview(obj) {
      const item = parseItem(obj.item)
      const price = obj.agreed_price ?? obj.default_price
      let gross_profit_margin: number | null = null
      if (obj.gross_revenue && obj.gross_profit) {
        gross_profit_margin = obj.gross_profit / obj.gross_revenue
      }
      return {
        ...item,
        price,
        fc_date: obj.fc_date,
        rolling_fc: obj.rolling_fc,
        sold_past_year: obj.sold_past_year,
        expected_growth: obj.expected_growth,
        last_manual_edit: obj.last_manual_edit,
        agreed_price: obj.agreed_price,
        default_price: obj.default_price,
        cost_price: obj.cost_price,
        gross_revenue: obj.gross_revenue,
        gross_profit: obj.gross_profit,
        gross_profit_margin,
        rollingforecast_count: obj.rollingforecast_count,
      }
    },
    fetchItems() {
      if (!this.fc_date) return []
      return this.api_call(FetchItemForecastOverviewDocument, { fc_date: this.fc_date }).then((response) => {
        return response.data.data.ItemForecastOverview.edges.map((edge) => {
          return this.parseItemForecastOverview(edge.node)
        })
      })
    },
    formatPrice(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return `€${Number(value).toFixed(4)}`
    },
    formatMoney(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return this.euroFormatter.format(value)
    },
    formatFraction(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return `${Number(value * 100).toFixed(1)}%`
    },
  },
}
</script>
