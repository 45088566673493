<template>
  <b-container v-if="fields.length > 0" class="con">
    <b-row>
      <b-col cols="3"><b>Column</b></b-col>
      <b-col cols="2"><b>Rule</b></b-col>
      <b-col cols="4"><b>Filter value(s)</b></b-col>
    </b-row>
    <template v-for="(filter, index) in allFilters">
      <b-row :key="index">
        <b-col cols="3">
          <b-form-select
            :value="filter.field ? filter.field.key : null"
            :options="field_options"
            @input="(key) => filter.set_field(fields.find((field) => field.key === key))"
          >
            <template #first>
              <b-form-select-option :value="null" disabled> -- Please select an option -- </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-form-select
            v-model="filter.filter_rule"
            :options="get_filter_rule_options(filter.field)"
            :disabled="!filter.field"
          />
        </b-col>
        <b-col cols="6">
          <fancy-input
            v-if="filter.filter_rule && (filter.filter_rule === 'oneof' || filter.filter_rule === 'noneof')"
            v-model="filter.value"
            :options="filter.field.options"
          />
          <typed-form-input
            v-else
            v-model="filter.value"
            :type="filter.field && filter.field.type"
            :step="filter.field && filter.field.step"
            :formatter="filter.field && filter.field.formatter"
          />
        </b-col>
        <b-col cols="1">
          <b-button variant="danger" @click="() => deleteFilter(filter)">
            <b-icon icon="trash" />
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-button variant="success" @click="addFilter">
      <b-icon icon="plus" />
      Add filter
    </b-button>
  </b-container>
</template>

<script lang="ts">
import FancyInput from 'innicore/components/input/FancyInput.vue'
import TypedFormInput from 'innicore/components/input/TypedFormInput.vue'
import Filter from 'innicore/components/table/Filter'
import utils from 'innicore/mixins/utils'

export default {
  // don't mess with dynamic field types please, keep them constant. otherwise bad bad
  name: 'FieldsFilter',
  components: { TypedFormInput, FancyInput },
  mixins: [utils],
  props: {
    fields: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    allFilters: [],
  }),
  computed: {
    field_options() {
      return this.fields
        .map((field) => ({ value: field.key, text: field.label }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },
  },
  watch: {
    allFilters: {
      handler(newAllFilters) {
        const completedFilters = newAllFilters.filter((f) => f.value)
        if (
          !completedFilters.every((x) => this.value.includes(x)) ||
          !this.value.every((x) => completedFilters.includes(x))
        ) {
          this.$emit('input', completedFilters)
        }
        if (newAllFilters.length === 0) {
          this.addFilter()
        }
      },
      deep: true,
    },
    fields(new_fields) {
      this.update_filter_fields(new_fields)
    },
  },
  mounted() {
    this.update_filter_fields(this.fields)
    this.allFilters = this.value
    if (this.allFilters.length === 0) {
      this.addFilter()
    }
  },
  methods: {
    addFilter() {
      this.allFilters = this.allFilters.concat([new Filter()])
    },
    update_filter_fields(new_fields) {
      const allFilters = this.allFilters
      // // add filters for new fields
      // new_fields.forEach(field => {
      //   let filter_present = false
      //   all_filters.forEach(filter => {
      //     if (filter.field.key === field.key) {
      //       filter_present = true
      //
      //     }
      //   })
      //   if (!filter_present) {
      //     all_filters.push(new Filter(field, this.get_filters(field.type)[0].value))
      //   }
      // })
      // remove filters for old fields that are no longer present in new_fields,
      //  and update the field values of the filters corresponding to the new fields
      const removable_filters = []
      allFilters.forEach((filter) => {
        let field_present = false
        new_fields.forEach((field) => {
          if ((filter.field && filter.field.key) === field.key) {
            field_present = true
            filter.set_field(field, { reset_value: false }) // update the filter field, but don't reset the value
          }
        })
        if (!field_present) {
          removable_filters.push(filter)
        }
      })
      removable_filters.forEach((filter) => {
        allFilters.splice(
          allFilters.findIndex((f) => (f.field && f.field.key) === (filter.field && filter.field.key)),
          1
        )
      })
      this.allFilters = allFilters
    },
    type(t) {
      return this.type_to_form_type(t)
    },
    get_filter_rule_options(f) {
      return Filter.get_filter_rule_options(f)
    },
    deleteFilter(filter) {
      this.allFilters = this.allFilters.filter((f) => f !== filter)
    },
  },
}
</script>
