<template>
  <div>
    <b-container v-if="allowed.length > 0" class="con">
      <template v-if="selected === 'date_range'">
        <date-range-selector
          v-model="date_range"
          :disabled="disabled"
          :reset-confirmation-prompt="resetConfirmationPrompt"
        />
      </template>
      <template v-else-if="selected === 'trusted_supplier'">
        <trusted-supplier-selector
          v-model="trusted_supplier"
          :disabled="disabled"
          :reset-confirmation-prompt="resetConfirmationPrompt"
        />
      </template>
      <b-row v-else>
        <b-col cols="2">
          <template v-if="options.length > 1">
            <b-form-select v-model="selected" :disabled="disabled" :options="options" class="text-primary" />
          </template>
          <template v-else-if="options.length === 1">
            <b>{{ options[0].text }}</b>
          </template>
        </b-col>

        <b-col cols="8">
          <span
            v-b-tooltip.hover.topleft
            :title="disabled ? 'You cannot change this filter while data is being loaded or while you are editing' : ''"
          >
            <template v-if="selected === 'customer'">
              <customer-selector
                v-model="customer"
                :disabled="disabled"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>
            <template v-if="selected === 'supplier'">
              <supplier-selector
                v-model="supplier"
                :disabled="disabled"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>

            <template v-else-if="selected === 'item'">
              <item-selector v-model="item" :disabled="disabled" :reset-confirmation-prompt="resetConfirmationPrompt" />
            </template>
            <template v-else-if="selected === 'app'">
              <app-selector v-model="app" :disabled="disabled" :reset-confirmation-prompt="resetConfirmationPrompt" />
            </template>
            <template v-else-if="selected === 'user'">
              <user-selector v-model="user" :disabled="disabled" :reset-confirmation-prompt="resetConfirmationPrompt" />
            </template>
            <template v-else-if="selected === 'field'">
              <field-selector
                v-model="field"
                :disabled="disabled"
                :field-selector-options="fieldSelectorOptions"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>
            <template v-else-if="selected === 'shipment'">
              <shipment-selector
                v-model="shipment"
                :disabled="disabled"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>

            <template v-else-if="selected === 'shipment_status'">
              <shipment-status-selector
                v-model="shipment_status"
                :disabled="disabled"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>

            <template v-else-if="selected === 'data_type'">
              <data-type-selector
                v-model="data_type"
                :disabled="disabled"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>
            <template v-else-if="selected === 'rolling_fc_date'">
              <rolling-fc-date-selector
                v-model="rolling_fc_date"
                :disabled="disabled"
                :reset-confirmation-prompt="resetConfirmationPrompt"
              />
            </template>
            <template v-else-if="selected === 'done'">
              <done-selector v-model="done" :disabled="disabled" />
            </template>
            <template v-else-if="selected === 'default_price'">
              <default-price-selector v-model="default_price" :disabled="disabled" />
            </template>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default {
  name: 'SelectBy',
  props: {
    value: {
      type: Object,
      validator: (val) => {
        if (val) {
          const ks = Object.keys(val)
          return (
            ks.length === 0 ||
            ks
              .map((k) => Vue.prototype.$constants.select_by.select_values.indexOf(k) !== -1 || k === 'selected')
              .reduce((a, b) => a && b)
          )
        } else {
          return true
        }
      },
      default: () => ({}),
    },
    allowed: {
      type: Array,
      default: () => {
        return Vue.prototype.$constants.select_by.select_values
      },
      validator: (allowed) => {
        return (
          allowed.length === 0 ||
          allowed
            .map((f) => Vue.prototype.$constants.select_by.select_values.indexOf(f) !== -1)
            .reduce((a, b) => a && b)
        )
      },
    },
    disabled: {
      type: Boolean,
    },
    resetConfirmationPrompt: {
      type: String,
      default: '',
    },
    fieldSelectorOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      shadow_selected: null, // shadow variable for this.selected
      customer: null,
      supplier: null,
      item: null,
      app: null,
      user: null,
      data_type: null,
      rolling_fc_date: null,
      date_range: {
        valid_selected: ['Today', 'Future'],
        valid_from: null,
        valid_to: null,
      },
      shipment: { shipment: null, supplier: null },
      shipment_status: [],
      field: null,
      done: false,
      default_price: false,
      trusted_supplier: { supplier: null, trusted: false },
    }
  },
  computed: {
    options() {
      return this.$constants.select_by.select_options.filter((option) => this.allowed.includes(option.value))
    },
    values() {
      return this.options.map((option) => option.value)
    },
    selected: {
      get() {
        return this.shadow_selected && this.values.includes(this.shadow_selected)
          ? this.shadow_selected
          : this.values[0]
      },
      set(new_val) {
        this.shadow_selected = new_val
        this.values.forEach((value) => {
          this[value] = this.shadow_selected === value ? this[value] : null
        })
      },
    },
  },
  watch: {
    value(new_value) {
      this.update_values(new_value)
    },
    customer() {
      this.emit()
    },
    supplier() {
      this.emit()
    },
    field() {
      this.emit()
    },
    item() {
      this.emit()
    },
    app() {
      this.emit()
    },
    user() {
      this.emit()
    },
    data_type() {
      this.emit()
    },
    rolling_fc_date() {
      this.emit()
    },
    date_range() {
      this.emit()
    },
    shipment() {
      this.emit()
    },
    shipment_status() {
      this.emit()
    },
    done() {
      this.emit()
    },
    default_price() {
      this.emit()
    },
    selected(new_selected, old_selected) {
      if (old_selected) {
        this[old_selected] = null
      }
      this.emit()
    },
    trusted_supplier() {
      this.emit()
    },
  },
  mounted() {
    if (this.value) {
      this.selected = Object.keys(this.value)[0]
      this.update_values(this.value)
    } else {
      this.emit()
    }
  },
  methods: {
    emit() {
      const v = {}
      this.values.forEach((value) => {
        // not emitting options that are not allowed
        v[value] = this[value]
      })
      // v.selected = select_options.find(option => option.value === this.selected)
      v.selected = this.selected
      this.$emit('update:selectBy', v)
    },
    update_values(new_value) {
      Object.keys(new_value).forEach((k) => {
        this[k] = new_value[k]
      })
    },
  },
}
</script>

<style scoped></style>
