<template>
  <div id="shipment-selector">
    <supplier-selector v-model="supplier" :disabled="disabled" :reset-confirmation-prompt="resetConfirmationPrompt" />
    <b-row>
      <b-col cols="5">
        <generic-selector
          v-if="supplier"
          v-model="shipment"
          :data="shipments"
          :disabled="loading"
          :serializer="serialize_shipment"
          invalid-feedback-message="This shipment does not exist!"
          placeholder="Select or create shipment"
        />
      </b-col>
      <b-col cols="1">
        <b-spinner v-if="loading" small />
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { FetchShipmentsDocument } from '@/graphql/generated'

export default {
  mixins: [utils, api_mixin],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: {
      type: String,
      default: 'Reset',
    },
  },
  data() {
    return { supplier: null, shipment: null, shipments: [{}], loading: false }
  },
  watch: {
    shipment() {
      this.$emit('input', { supplier: this.supplier, shipment: this.shipment })
    },
    async supplier(change) {
      if (change) {
        this.loading = true
        this.shipments = await this.fetchShipments(this.supplier.cmp_wwn)
        this.loading = false
        this.shipments.unshift({})
        this.$emit('input', { supplier: this.supplier, shipment: this.shipment })
      }
    },
  },
  methods: {
    set_data() {
      this.$emit('input', { supplier: this.supplier, shipment: this.shipment })
    },
    parseShipment(node) {
      return {
        id: node.id,
        arrival_timestamp: new Date(node.arrival_timestamp),
        comment: node.comment,
      }
    },
    fetchShipments(account: string) {
      if (account) {
        return this.api_call(FetchShipmentsDocument, {
          account: account,
        }).then((incoming_goods_response) => {
          return incoming_goods_response.data.data.incoming_goods_shipment.edges.map((obj) =>
            this.parseShipment(obj.node)
          )
        })
      } else {
        return null
      }
    },
    serialize_shipment(shipment: { id: string; arrival_timestamp: Date; comment: string }) {
      if (shipment.id == undefined) {
        return 'Create new shipment...'
      }
      return this.format_date_time(shipment?.arrival_timestamp)
    },
  },
}
</script>
