<template>
  <div>
    <template v-if="isOrderWorkflow">
      <OrderWorkflowLineDetails />
    </template>
    <template v-else-if="isMutationWorkflow">
      <MutationWorkflowLineDetails />
    </template>
    <template v-else-if="isERPMutationWorkflow">
      <ERPMutationWorkflowLineDetails />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'

import { WorkflowType } from '@/graphql/generated'
import { WorkflowLine } from '@/views/workflows/Workflow'
import ERPMutationWorkflowLineDetails from '@/views/workflows/erp_mutation/ERPMutationWorkflowLineDetails.vue'
import MutationWorkflowLineDetails from '@/views/workflows/mutation/MutationWorkflowLineDetails.vue'
import OrderWorkflowLineDetails from '@/views/workflows/order/OrderWorkflowLineDetails.vue'

const props = defineProps({
  workflowLine: {
    type: WorkflowLine,
    default: null,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
provide('workflowLine', props.workflowLine)

const type = computed(() => props.workflowLine.workflow.type)
const isOrderWorkflow = computed(() => type.value === WorkflowType.ORDER)
const isMutationWorkflow = computed(() => type.value === WorkflowType.MUTATION)
const isERPMutationWorkflow = computed(() => type.value === WorkflowType.ERP_MUTATION)
</script>
