<template>
  <b-modal :id="name" title="Add quality assurance data" centered size="lg" @ok="handleOK" @hidden="resetModal">
    <!-- Eslint and prettier disagree here, eslint will give you an error -->
    <b-container fluid>
      <b-row>
        <b-col cols="2">
          <b>Supplier</b>
        </b-col>
        <b-col>
          <supplier-selector v-model="supplier" :reset-confirmation-prompt="null" />
        </b-col>
      </b-row>
      <template v-if="supplier">
        <b-tabs class="mt-2" content-class="mt-3">
          <b-tab title="Manual">
            <b-form>
              <b-form-group label="Item" label-cols="2">
                <item-selector v-model="item" />
              </b-form-group>

              <b-form-group label="Original fabric" label-cols="2">
                <b-form-input id="OriginalFabric" v-model="originalFabric" placeholder="11992-01A" />
              </b-form-group>

              <b-form-group label="Entry check" label-cols="3">
                <div style="height: 100%" class="d-flex align-items-center">
                  <b-form-checkbox id="quality_at_door_input" v-model="quality_at_door" switch />
                </div>
              </b-form-group>
              <b-form-group label="Precheck" label-cols="3">
                <div style="height: 100%" class="d-flex align-items-center">
                  <b-form-checkbox id="quality_before_input" v-model="quality_before" switch />
                </div>
              </b-form-group>
              <b-form-group label="Contractors" label-cols="3">
                <div style="height: 100%" class="d-flex align-items-center">
                  <b-form-checkbox id="contractors_input" v-model="contractors" switch />
                </div>
              </b-form-group>
              <b-form-group label="Trusted supplier" label-cols="3">
                <div style="height: 100%" class="d-flex align-items-center">
                  <b-form-checkbox id="trusted_supplier_input" v-model="trusted_supplier" switch />
                </div>
              </b-form-group>
            </b-form>
          </b-tab>
          <b-tab title="Excel">
            <file-importer :file-parse-callback="handleExcel" app-name="quality-assurance" :hide-on-success="true" />
          </b-tab>
        </b-tabs>
      </template>
      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
              {{ error }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

import { MutateExcelQualityAssurancesDocument } from '@/graphql/generated'

export default {
  mixins: [api_mixin],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    name: { type: String, default: '' },
    getItemFromTable: {
      type: Function,
      default: () => {
        return
      },
    },
    mutate: {
      type: Function,
      default: () => {
        return
      },
    },
    refresh: {
      type: Function,
      default: () => {
        return
      },
    },
    callback: {
      type: Function,
      default: () => {
        return
      },
    },
  },
  data() {
    return {
      item: null,
      originalFabric: null,
      quality_at_door: false,
      quality_before: false,
      contractors: false,
      trusted_supplier: false,
      errors: [],
    }
  },
  computed: {
    ItemCode() {
      return this.item?.ItemCode
    },
    items() {
      return this.$store.state.lienesch_items
    },
    modal_row() {
      return {
        ItemCode: this.ItemCode,
        original_fabric: this.originalFabric,
        quality_at_door: this.quality_at_door,
        quality_before: this.quality_before,
        contractors: this.contractors,
        trusted_supplier: this.trusted_supplier,
      }
    },
    supplier: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    async handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.errors = []
      this.errorChecker()
      if (this.errors.length) {
        return
      }
      this.handleManual()
    },
    errorChecker() {
      if (!this.supplier) {
        this.errors.push('Select a supplier first!')
        return
      }
      if (!this.item) {
        this.errors.push('Item code or excel file is required!')
      }
      if (this.item && !this.originalFabric) {
        this.errors.push('Original fabric is required!')
      }
    },
    handleManual() {
      const input = {
        originalFabric: this.originalFabric,
        ItemCode: this.item.ItemCode,
        quality_at_door: this.quality_at_door,
        quality_before: this.quality_before,
        contractors: this.contractors,
        trusted_supplier: this.trusted_supplier,
      }
      const asyncFunction = async () => {
        await this.mutate(input, this.supplier.cmp_wwn)
          .then((response) => {
            this.updateShowBatches(response)
            this.resetAndHide()
          })
          .catch(() => {
            this.resetAndHide()
            this.$bvToast.toast('Unknown error while adding quality assurance data!', {
              title: 'Error',
              variant: 'danger',
              solid: true,
            })
          })
      }
      asyncFunction()
    },
    updateShowBatches(response) {
      const item = this.getItemFromTable(response.item.ItemCode)
      if (item && item._showDetails) {
        // Only refresh if the item is expanded
        item.loading = true
        this.refresh(item)
      } else if (!item) {
        this.callback({
          ItemCode: response.item.ItemCode,
          Description: response.item.Description,
          approved: response.approved,
          loading: false,
          qualityAssurances: [response],
        })
      }
    },
    async handleExcel(upload) {
      const company = this.supplier.cmp_wwn
      const file = upload
      try {
        const response = await this.api_call(MutateExcelQualityAssurancesDocument, { file, company })
        if (response.data.errors) {
          return { errors: response.data.errors }
        }
        this.resetAndHide()
        this.$bvToast.toast('Excel file uploaded and original fabrics added!', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        // Filter out duplicate ItemCodes, but keep the qa data
        const uniqueItems = response.data.data.MutateExcelQualityAssurance.QualityAssuranceFields.filter(
          (qa, index, self) => {
            return self.findIndex((t) => t.item.ItemCode === qa.item.ItemCode) === index
          }
        )
        uniqueItems.forEach((qa) => this.updateShowBatches(qa))
        return { success: true }
      } catch (error) {
        return { errors: ['Unknown error while uploading excel file!'] }
      }
    },
    resetModal() {
      this.supplier = null
      this.item = null
      this.originalFabric = null
      this.approved = false
      this.errors = []
    },
    resetAndHide() {
      this.resetModal()
      this.$bvModal.hide(this.name)
    },
  },
}
</script>
