<template>
  <div>
    <h1>{{ title }}</h1>
    <p>{{ description }}</p>
    <i-table
      ref="ITable"
      :fetch-items="fetchItems"
      :fields="fields"
      :allowed-selectors="allowedSelectors"
      :actions="actions"
      app-name="workflows"
      @update:selectBy="(v) => (selectBy = v)"
    >
      <template #row-details="row">
        <WorkflowDetails :workflow="row.item" />
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

import { FetchWorkflowsDocument } from '@/graphql/generated'
import { Workflow } from '@/views/workflows/Workflow'
import WorkflowDetails from '@/views/workflows/WorkflowDetails.vue'

export default {
  components: {
    WorkflowDetails,
  },
  mixins: [api_mixin],
  data() {
    return {
      title: 'Workflows',
      description: 'Here you can view all workflows.',
      selectBy: null,
    }
  },
  computed: {
    actions() {
      return [
        {
          key: 'toggle_details',
          title: 'Show workflow details',
          disallow: false,
        },
      ]
    },
    fields() {
      return [
        { key: 'status', label: 'Status', sortable: true },
        { key: 'syscreator', label: 'Created by', sortable: true },
        { key: 'syscreated', label: 'Created on', sortable: true },
        { key: 'description', label: 'Description', sortable: false },
        { key: 'type', label: 'Workflow type', sortable: true, visible: false },
      ]
    },
    allowedSelectors() {
      return []
    },
  },
  methods: {
    fetchItems() {
      return {
        query: FetchWorkflowsDocument,
        parameters: this.getQueryParams(),
        after: (response) => response.data.data.workflows.edges.map((edge) => new Workflow(edge.node)),
      }
    },
    getQueryParams() {
      return {}
    },
  },
}
</script>
