<template>
  <div>
    <b-container class="con">
      <b-row>
        <b-col cols="2"> <b> Customer </b> </b-col>
        <b-col cols="6"> {{ customer_description }} </b-col>
      </b-row>
      <b-row>
        <b-col cols="2"> <b> Item </b> </b-col>
        <b-col cols="6"> {{ item_description }} </b-col>
      </b-row>
    </b-container>
    <fancy-table ref="FancyTable" :items="items" :fields="fields" fluid>
      <template #cell(fc_date)="row">
        {{ format_fc_date(row.item.fc_date) }}
      </template>

      <template #cell(sysmodified)="row">
        {{ format_sysmodified(row.item.sysmodified) }}
      </template>

      <template #cell(is_ml_fc)="row">
        <p style="text-align: center">
          <b-icon v-if="row.item.is_ml_fc" icon="check-circle" variant="success" font-scale="1.3" />
          <b-icon v-else icon="x-circle" variant="danger" font-scale="1.3" />
        </p>
      </template>
    </fancy-table>
  </div>
</template>

<script lang="ts">
import { ForecastHistoryDocument } from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  name: 'ForecastHistory',
  mixins: [api_mixin, utils],
  data() {
    return {
      items: [],
      fields: [
        { key: 'fc_date', label: 'FC date', sortable: true },
        { key: 'month1', label: 'Month 1', type: Number },
        { key: 'month2', label: 'Month 2', type: Number },
        { key: 'month3', label: 'Month 3', type: Number },
        { key: 'month4', label: 'Month 4', type: Number },
        { key: 'month5', label: 'Month 5', type: Number },
        { key: 'month6', label: 'Month 6', type: Number },
        { key: 'month7', label: 'Month 7', type: Number },
        { key: 'month8', label: 'Month 8', type: Number },
        { key: 'month9', label: 'Month 9', type: Number },
        { key: 'month10', label: 'Month 10', type: Number },
        { key: 'month11', label: 'Month 11', type: Number },
        { key: 'month12', label: 'Month 12', type: Number },
        {
          key: 'rolling_fc',
          label: 'Rolling forecast (m)',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: Number,
        },
        {
          key: 'rolling_fc_m2',
          label: 'Rolling forecast (m2)',
          sortable: true,
          optional: true,
        },
        {
          key: 'sold_past_year',
          label: 'Sold past 12 months',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
        },
        {
          key: 'computed_expected_growth',
          label: 'Computed EG',
          sortable: true,
          class: 'tight-column',
          optional: true,
        },
        {
          key: 'manual_expected_growth',
          label: 'Manual EG',
          sortable: true,
          class: 'tight-column',
          editable: true,
          optional: true,
        },

        {
          key: 'is_ml_fc',
          label: 'Is ML forecast',
          sortable: true,
          optional: true,
          formatter: (value) => (value ? 'Yes' : 'No'),
        },
        {
          key: 'forecast_type',
          label: 'Forecast Type',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: String,
        },
        { key: 'sysmodified', label: 'Last edit on', optional: true, sortable: true },
        { key: 'sysmodifier', label: 'Last edit by', optional: true },
      ],
      customer_description: '',
      item_description: '',
    }
  },
  mounted() {
    this.api_call(this.getQuery(), { ac: this.$route.query.AccountCode, ic: this.$route.query.ItemCode }).then(
      (response) => {
        this.items = response.data.data.RollingFC.edges.map((obj) => this.parseItem(obj.node))
        const node = response.data.data.RollingFC.edges[0].node
        this.item_description = `${node.ItemCode.ItemCode} - ${node.ItemCode.Description}`
        this.customer_description = `${node.AccountCode.debnr} - ${node.AccountCode.cmp_name}`
      }
    )
  },
  methods: {
    getQuery() {
      return ForecastHistoryDocument
    },
    parseItem(obj) {
      return {
        fc_date: obj.fc_date,
        month1: Number(obj.month1),
        month2: Number(obj.month2),
        month3: Number(obj.month3),
        month4: Number(obj.month4),
        month5: Number(obj.month5),
        month6: Number(obj.month6),
        month7: Number(obj.month7),
        month8: Number(obj.month8),
        month9: Number(obj.month9),
        month10: Number(obj.month10),
        month11: Number(obj.month11),
        month12: Number(obj.month12),
        rolling_fc: Number(obj.rolling_fc),
        sold_past_year: Number(obj.sold_past_year),
        computed_expected_growth: obj.expected_growth,
        manual_expected_growth: obj.manual_expected_growth,
        width: obj.ItemCode.width,
        rolling_fc_m2: Math.floor((obj.rolling_fc * obj.ItemCode.width) / 100),
        sysmodified: obj.sysmodified,
        sysmodifier: obj.sysmodifier.email,
        is_ml_fc: obj.is_ml_fc,
        forecast_type: obj.forecast_type,
      }
    },
  },
}
</script>
