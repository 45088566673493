<template>
  <div>
    <h3>Order entry general</h3>
    <p>
      Welcome to the Order Page! Here you can place an order as either a Lienesch employee or a customer. There are 2
      different ways of placing an order: automated and manual.
      <br />
      If you select a customer and automated order placement is supported, a field will become available where you can
      upload the order file! If not: continue with placing the order manually.
    </p>
    <h5>Automated Order Entry</h5>
    <p>
      Upload the order file to the webapp at the top of the page in the identical field:
      <b-container class="con">
        <b-row>
          <b-col cols="2">
            <b> Upload order </b>
          </b-col>
          <b-col cols="10">
            <b-form-file :disabled="true" placeholder="Choose a file or drop it here..." />
          </b-col>
        </b-row>
      </b-container>
      The webapp will process it and fill a table with the order for you. After checking it for mistakes, select a
      requested shipping date and you will be able to place the order.
    </p>
    <h5>Manual Order Entry</h5>
    <p>
      After filling in the "Order with" and "Default order unit" field, you can start creating the order. Fill the
      fields in every row and press the "Add data"-button if you want new rows to show up. When you are done manually
      inputting the order, don't forget to fill in a requested shipping date and a reference, after which you are ready
      to place the order.
    </p>
    <h3>Place Order vs Save Draft</h3>
    <p>
      In the Lienesch Webapp, there is a differentiation between Draft Orders and Sent Orders. Draft Orders are only
      saved in the webapp and can be changed at a later time. Ideal for when you are just going for lunch and want to
      save your progress. If you click the Place Order button instead, the order will be sent to in Exact as soon as
      ABEI allows us to.
    </p>
    <h3>Buttons explanation table</h3>
    <p>
      There are two buttons in every row, one for deleting a row, and one for showing extra info. The extra info can be
      used when a <b-icon icon="question-circle" variant="warning" /> shows up next to the order amount. This question
      mark means that there will be a different amount ordered than asked, as we only sell per those amounts.
    </p>

    <b-table :fields="buttonInfo.fields" :items="buttonInfo.items">
      <template #cell(button)="row">
        <b-button :variant="row.item.variant">
          <b-icon :icon="row.item.button" />
        </b-button>
      </template>
    </b-table>
    <h3>Actions</h3>
    <p>
      Here we explain how the different Action buttons on this page. Note that some of these are under the "Actions"
      tab!
    </p>
    <b-table :fields="actions.fields" :items="actions.items">
      <template #cell(button)="row">
        <b-button :variant="row.item.variant"> <b-icon :icon="row.item.button" /> {{ row.item.name }} </b-button>
      </template>
    </b-table>
    <h3>Filters</h3>
    <p>Here we explain how the different filters under the 'filter' tab work.</p>
    <p>
      In the filter tab you can select a specific column to filter on. Depending on the column you get the choice
      between a couple of rules. This is either (=, &lt;, ≤, &gt; and ≥) or (in, not in, contains and =). Underneath we
      explain what every rule does:
    </p>
    <b-table :fields="filters.fields" :items="filters.items"> </b-table>
    <br />
    <h3>No answer?</h3>
    <p><strong>If none of this answers your question, please contact support@innico.nl.</strong></p>
  </div>
</template>

<script lang="ts">
const BUTTON_INFO = {
  fields: [{ key: 'button', class: 'tight-column' }, { key: 'description' }],
  items: [
    {
      button: 'info-circle',
      variant: 'b-blue',
      description:
        'Click this button to view the calculation the webapp made to figure out how much should be ordered.',
    },
    {
      button: 'trash',
      variant: 'danger',
      description:
        'Click this button to open a new seperate tab with the farecast history of this specific item and company',
    },
  ],
}
const ACTIONS = {
  fields: [{ key: 'button' }, { key: 'description' }],
  items: [
    {
      button: 'plus',
      variant: 'success',
      name: 'Place Order',
      description: 'Save the order and send it to Exact',
    },
    {
      button: 'plus',
      variant: 'secondary',
      name: 'Save Draft',
      description: 'Save the order as a Draft',
    },

    {
      button: 'info-circle',
      variant: 'b-blue',
      name: 'Toggle details',
      description: 'Show extra info (if applicable) regarding this row.',
    },
    {
      button: 'plus',
      variant: 'success',
      name: 'Add Data',
      description: 'Add a new row to the order',
    },
    {
      button: 'trash',
      variant: 'danger',
      name: 'Delete row',
      description: 'Delete selected rows',
    },
  ],
}
const FILTERS = {
  fields: [{ key: 'rule' }, { key: 'description' }],
  items: [
    {
      rule: 'in',
      description: 'Include, you can select what to include in the drop-down at the filter value',
    },
    {
      rule: 'not in',
      description: 'Do not include, you can select what not to include in the drop-down at the filter value',
    },
    {
      rule: 'contains',
      description:
        'the filter value can be found somewhere in the column value, does not have to be 1 on 1. ' +
        '(For example contains BASE includes BASE and BASEPERLA.)',
    },
    {
      rule: '=',
      description: 'Is exactly the same as [Filter value(s)]',
    },
    {
      rule: '<',
      description: 'Is smaller than [Filter value(s)]',
    },
    {
      rule: '≤',
      description: 'Is smaller than or the same as [Filter value(s)]',
    },
    {
      rule: '>',
      description: 'Is bigger than [Filter value(s)]',
    },
    {
      rule: '≥',
      description: 'Is bigger than or the same as [Filter value(s)]',
    },
  ],
}
export default {
  name: 'OrderPageHelp',
  data() {
    return {
      buttonInfo: BUTTON_INFO,
      actions: ACTIONS,
      filters: FILTERS,
    }
  },
}
</script>
