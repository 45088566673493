export default class Filter {
  constructor(field = null, value = null) {
    // please only mutate the attributes for this class using the setters and constructor.
    this.field = field // please only set this using set_field
    this.filter_rule = null
    if (field) {
      const filter_rule_options = Filter.get_filter_rule_options(this.field)
      if (filter_rule_options) {
        // make sure filter_rule_options are defined
        this.filter_rule = filter_rule_options[0].value
      }
    }
    // this.set_value(value)
    this.value = value
  }

  set_field(field, { reset_value = true } = {}) {
    if (field) {
      if ((this.field && this.field.type) !== field.type) {
        this.filter_rule = null
        this.field = field
        this.filter_rule = Filter.get_filter_rule_options(this.field)[0].value
      } else {
        this.field = field // again, please don't mess with dynamically changing field.type. May conflict with the type of this.value
      }
    } else {
      this.filter_rule = null
      this.field = null
    }
    // Set value to null if reset_value is true, or to the empty list if the filter rule is oneof or noneof
    if (reset_value) {
      if (['oneof', 'noneof'].includes(this.filter_rule)) {
        this.value = []
      } else {
        this.value = null
      }
    }
  }

  set filter_rule(rule) {
    if (
      (this.filter_rule === 'oneof' || this.filter_rule === 'noneof') &&
      ['oneof', 'noneof'].every((x) => x !== rule)
    ) {
      this.value = ''
    } else if (['oneof', 'noneof'].every((x) => x !== this.filter_rule) && (rule === 'oneof' || rule === 'noneof')) {
      this.value = []
    }
    this._filter_rule = rule
  }

  get filter_rule() {
    return this._filter_rule
  }

  apply(item) {
    let satisfied = false
    // make sure there is an actual filter to apply
    if (this.field && this.filter_rule && this.value !== null) {
      if (item) {
        const value = item[this.field.key]
        if (this.filter_rule === 'gt') {
          satisfied = value > this.value
        } else if (this.filter_rule === 'gte') {
          satisfied = value >= this.value
        } else if (this.filter_rule === 'lt') {
          satisfied = value < this.value
        } else if (this.filter_rule === 'lte') {
          satisfied = value <= this.value
        } else if (this.filter_rule === 'equals') {
          satisfied = value === this.value
        } else if (this.filter_rule === 'contains') {
          satisfied = !!value?.includes(this.value)
        } else if (this.filter_rule === 'oneof') {
          satisfied =
            this.value.length === 0 ||
            this.value.includes(value) ||
            (this.value.includes('<empty>') && (value === '' || value === null))
        } else if (this.filter_rule === 'noneof') {
          satisfied =
            this.value.length === 0 ||
            !this.value.includes(value) ||
            (this.value.includes('<empty>') && !(value === '' || value === null))
        }
      }
    } else {
      satisfied = true
    }
    return satisfied
  }

  static get_filter_rule_options(field) {
    const rules = []
    const equals = { value: 'equals', text: '=' }
    const contains = { value: 'contains', text: 'contains' }
    const gt = { value: 'gt', text: '>' }
    const gte = { value: 'gte', text: '≥' }
    const lt = { value: 'lt', text: '<' }
    const lte = { value: 'lte', text: '≤' }
    const oneof = { value: 'oneof', text: 'in' }
    const noneof = { value: 'noneof', text: 'not in' }

    if (field && field.type) {
      // All Django lookups for filter_fields
      // ['exact', 'iexact', 'gt', 'gte', 'lt', 'lte', 'in', 'contains', 'icontains', 'startswith', 'istartswith',
      // 'endswith', 'iendswith', 'range', 'isnull', 'regex', 'iregex']
      if (field.type === String) {
        rules.push(oneof, noneof, contains, equals)
      } else if (field.type === Number || field.type === Date) {
        rules.push(equals, lt, lte, gt, gte)
      } else if (field.type === Boolean) {
        rules.push(equals)
      }
    }
    return rules
  }
}
