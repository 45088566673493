<script lang="ts">
import ForecastHistory from 'innicore/views/forecast/ForecastHistory.vue'

import { ForecastHistoryDocument } from '@/graphql/generated'

export default {
  extends: ForecastHistory,
  methods: {
    getQuery() {
      return ForecastHistoryDocument
    },
  },
}
</script>
