import * as _ from 'lodash'

import type { Field } from 'innicore/components/table/TableFieldsMixin'
import useTableDefaultFields, {
  type DefaultField,
  DefaultFieldGroups,
  mergeFields,
} from 'innicore/components/table/useTableDefaultFields'

//Re-export the default field groups for ease of access
export { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'

// Customer-specific default fields.
// They will be merged with the default fields from innicore/components/table/useTableDefaultFieldsCustomer.ts
const CUSTOMER_DEFAULT_FIELDS: Array<DefaultField> = [
  {
    key: 'ItemCodeAccount',
    label: 'Customer item code',
    optional: true,
    sortable: true,
    type: String,
    defaultGroup: DefaultFieldGroups.CustomerAttributes,
    insertBefore: 'Description',
  },
  {
    key: 'abc',
    label: 'ABC class',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'c02',
    label: 'Collection',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'c06',
    label: 'Collection year',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'color',
    label: 'Color',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'color_code',
    label: 'Color code',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'ean_code',
    label: 'EAN code',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'c01',
    label: 'Label',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'IsStockItem',
  },
  {
    key: 'leadtime',
    label: 'Lead time',
    sortable: true,
    optional: true,
    type: Number,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'c01',
  },
  {
    key: 'material',
    label: 'Material',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'leadtime',
  },
  {
    key: 'minimum_order_quantity',
    label: 'Minimum order quantity',
    checkbox_label: 'Minimum order quantity',
    sortable: true,
    optional: true,
    type: Number,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'material',
  },
  {
    key: 'c03',
    label: 'Pleat height',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'standard_package_quantity',
  },
  {
    key: 'c08',
    label: 'PMC',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'standard_package_quantity',
  },
  {
    key: 'c04',
    label: 'Product group',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'standard_package_quantity',
  },
  {
    key: 'c07',
    label: 'Product sub-group',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'standard_package_quantity',
  },
  {
    key: 'width',
    label: 'Width (cm)',
    sortable: true,
    optional: true,
    type: Number,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'standard_package_quantity',
  },
  {
    key: 'trend_group1',
    label: 'Trend group 1',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'unit',
  },
  {
    key: 'trend_group2',
    label: 'Trend group 2',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'unit',
  },
  {
    key: 'transparency',
    label: 'Transparency',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'unit',
  },
  {
    key: 'weave',
    label: 'Weave',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
    insertBefore: 'debnr',
  },
  {
    key: 'ud01',
    label: 'Item end date',
    sortable: true,
    optional: true,
    editable: false,
    type: Date,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'CollectionName',
    label: 'Collection name',
    optional: true,
    sortable: true,
    editable: false,
    type: String,
    defaultGroup: DefaultFieldGroups.CustomerAttributes,
    insertBefore: 'syscreated',
  },
  {
    key: 'CollectionStart',
    label: 'Collection start',
    optional: true,
    sortable: true,
    editable: false,
    type: Date,
    defaultGroup: DefaultFieldGroups.CustomerAttributes,
    insertBefore: 'syscreated',
  },
  {
    key: 'CollectionEnd',
    label: 'Collection end',
    optional: true,
    sortable: true,
    editable: false,
    type: Date,
    defaultGroup: DefaultFieldGroups.CustomerAttributes,
    insertBefore: 'syscreated',
  },
]
/**
 * Add customer-specific table fields to the supplied field array.
 * The 'fields' option can be used to overwrite/add fields.
 */
export default (defaultFieldGroups: Array<DefaultFieldGroups> = [], fields: Array<Field> = []): Array<Field> => {
  const customerFields: Array<Field> = _.cloneDeep(
    CUSTOMER_DEFAULT_FIELDS.filter((x) => defaultFieldGroups.includes(x.defaultGroup))
  )
  const defaultFields = useTableDefaultFields(defaultFieldGroups, customerFields)
  return mergeFields(defaultFields, fields)
}
