<template>
  <div>
    <template v-if="$store.state.user.is_employee">
      <generic-selector
        v-model="customer"
        :data="customers"
        :disabled="disabled || customers.length === 0"
        :serializer="serialize_customer"
        :disable-sort="true"
        :reset-confirmation-prompt="resetConfirmationPrompt"
        invalid-feedback-message="This customer does not exist!"
        placeholder="Enter a customer..."
      ></generic-selector>
    </template>
    <template v-else-if="$store.state.user.is_customer">
      <input type="hidden" :value="serialize_customer(customer)" :disabled="true" />
    </template>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

//  also: ask niek what he wants with OrderPage.vue when logged in as company

export default {
  name: 'CustomerSelector',
  mixins: [api_mixin],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
  },
  data() {
    return {}
  },
  computed: {
    customer: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    customers() {
      return this.$store.getters.customers
        .map((customer) => {
          // Parse debtor number (strip leading spaces)
          return {
            ...customer,
            debnr: customer.debnr,
          }
        })
        .sort((a, b) => {
          // Sort by debtor number (numeric, not alphabetical)
          return a.debnr < b.debnr ? -1 : 1
        })
    },
  },
  async mounted() {
    if (!this.$store.state.user.is_customer) {
      if (!this.$store.state.customers?.length) {
        await this.$store.dispatch('fetchCompanies')
      }
    } else if (this.$store.state.user.is_customer) {
      this.customer = this.customers[0] // customers should contain only the customer that user belongs to
    }
  },
  methods: {
    serialize_customer(customer) {
      if (!this.$store.state.user.is_customer) {
        return customer ? `${customer.debnr} - ${customer.cmp_name}` : ''
      } else {
        return customer ? customer.cmp_name : ''
      }
    },
  },
}
</script>
