<template>
  <div>
    <b-card style="max-width: 40rem" class="mt-5 mx-auto" header="Forgot your password?" header-class="card-header">
      <b-form @submit.prevent="onSubmit">
        <b-alert :show="alert.message.length > 1" :variant="alert.variant" dismissible @dismissed="alert.message = ''">
          {{ alert.message }}
        </b-alert>

        <b-form-group label="Email address">
          <b-form-input v-model="form.email" type="email" placeholder="Email address" required />
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="secondary" type="submit"> Send reset instructions </b-button>
        </div>
      </b-form>

      <template #footer> Or go back to <router-link :to="{ name: 'Login' }">Login</router-link>. </template>
    </b-card>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue'
import { AxiosError } from 'axios'
import { defineComponent } from 'vue'

import apiMixin from 'innicore/mixins/api_mixin'

import { RequestPasswordResetDocument } from '@/graphql/generated'

export default defineComponent({
  mixins: [apiMixin],
  data() {
    return {
      alert: {
        message: '',
        variant: 'success' as 'success' | 'error',
      },
      form: {
        email: '',
      },
    }
  },
  methods: {
    showAlert(message: string, variant: 'success' | 'danger' = 'success') {
      this.alert.message = message
      this.alert.variant = variant
    },
    async onSubmit() {
      try {
        const response = await this.api_call(
          RequestPasswordResetDocument,
          {
            email: this.form.email,
          },
          {
            check_auth: false,
          }
        )

        if (response.data.data.RequestPasswordReset.message) {
          this.showAlert(response.data.data.RequestPasswordReset.message)
          this.reset()
        } else {
          this.showAlert('Something went wrong.', 'error')
        }
      } catch (error) {
        const scope = new Sentry.Scope()
        scope.setContext('context', { query: RequestPasswordResetDocument })
        Sentry.captureException(error, scope)

        if (error instanceof AxiosError) {
          if (!error.response) {
            this.$router.replace({ name: 'TemporarilyUnavailable' })
          } else {
            this.showAlert('Something went wrong.', 'danger')
          }
        }
      }
    },
    reset() {
      this.form.email = ''
    },
  },
})
</script>
