<template>
  <b-row v-b-tooltip.hover :title="disabled ? 'You need to select one or more rows to edit in bulk' : ''">
    <b-col cols="3">
      <b-form-input disabled="disabled" :value="field.label"> </b-form-input>
    </b-col>
    <b-col cols="2">
      <b-form-select
        v-model="selectedEdit"
        :options="fieldEdits && Object.keys(fieldEdits.edits)"
        :disabled="disabled"
        :data-bulk-edit-rule="field.key"
      >
      </b-form-select>
    </b-col>
    <b-col cols="5">
      <typed-form-input
        v-model="value"
        :name="field.key"
        :type="fieldEdits && selectedEdit && fieldEdits.edits[selectedEdit].type"
        :step="field.step"
        :disabled="disabled"
        :formatter="field.formatter"
      />
    </b-col>
    <b-col cols="2">
      <HitButton
        :click="edit"
        :disabled="disabled"
        :data-action="field.key"
        :extra-loading-seconds="2"
        variant="success"
      >
        <b-icon icon="plus" />
        Edit rows
      </HitButton>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { addDays, addMonths, addYears, formatISO, parseISO } from 'date-fns'

const EDIT_RULES = [
  {
    type: Number,
    edits: {
      '=': {
        rule: (_, value) => value,
        type: Number,
      },
      '+': {
        rule: (currentValue, value) => currentValue + value,
        type: Number,
      },
      '+ (%)': {
        rule: (currentValue, value) => Number((currentValue + currentValue * 0.01 * value).toFixed(4)),
        type: Number,
      },
      x: {
        rule: (currentValue, value) => currentValue * value,
        type: Number,
      },
    },
  },
  {
    type: String,
    edits: {
      '=': {
        rule: (_, value) => value,
        type: String,
      },
    },
  },
  {
    type: Date,
    edits: {
      '=': {
        rule: (_, value) => value,
        type: Date,
      },
      '+ (days)': {
        rule: (currentValue, value) =>
          currentValue && formatISO(addDays(parseISO(currentValue), value), { representation: 'date' }),
        type: Number,
      },
      '+ (months)': {
        rule: (currentValue, value) =>
          currentValue && formatISO(addMonths(parseISO(currentValue), value), { representation: 'date' }),
        type: Number,
      },
      '+ (years)': {
        rule: (currentValue, value) =>
          currentValue && formatISO(addYears(parseISO(currentValue), value), { representation: 'date' }),
        type: Number,
      },
    },
  },
  {
    type: Boolean,
    edits: {
      '=': {
        rule: (_, value) => value,
        type: Boolean,
      },
    },
  },
]

export default {
  name: 'TableTabActionsBulkEditField',
  props: {
    field: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['editField'],
  data() {
    return {
      selectedEdit: null,
      value: null,
      editedItems: 0,
    }
  },
  computed: {
    fieldEdits() {
      return EDIT_RULES.find((u) => u.type === this.field.type)
    },
  },
  mounted() {
    this.selectedEdit = Object.keys(this.fieldEdits.edits)[0]
  },
  methods: {
    edit() {
      if (this.selectedEdit) {
        this.$emit('editField', this.fieldEdits.edits[this.selectedEdit].rule, this.value)
      }
    },
  },
}
</script>
