import Vue from 'vue'

import type { IncomingGoodEANCode, IncomingGoodItemCode } from '@/views/incoming_goods/types'
import { IncomingGood } from '@/views/incoming_goods/types'

export default Vue.extend({
  methods: {
    parseBarcode(barcode: string): IncomingGoodEANCode | void {
      const original_barcode = barcode
      // First clean our input
      barcode = barcode.replace(/[()]+/g, '')

      // We mutate the barcode as we split off information for parsing
      let brut
      let net
      ;[barcode, brut] = this.split(barcode, barcode.length - 10)
      // bruto length with a prefix is stored in the last 10 characters
      ;[barcode, net] = this.split(barcode, barcode.length - 10)
      // netto length with a prefix is stored in the 10 characters before the bruto length

      const brut_val = this.convertLengthPartToCM(brut) / 100
      const net_val = this.convertLengthPartToCM(net) / 100

      let width
      if (barcode.includes('$')) {
        ;[barcode, width] = barcode.split('$', 2)
        // Cut the prefix from the width part of the barcode and parse the rest
        // Result will be in mm so we need to divide by 10 to get cm
        width = Number(width.slice(2)) / 10
      } else {
        ;[barcode, width] = this.split(barcode, barcode.length - 4)
        width = Number(width) / 10
        barcode = barcode.slice(0, barcode.length - 2)
      }

      const batch_code = barcode.slice(18)
      let ean_code = barcode.slice(3, 16) // ean_code without prefix
      if (ean_code[0] == '0') {
        ean_code = ean_code.slice(1, 13)
      }
      if (ean_code.length < 12 || batch_code.length == 0) {
        return
      }

      if (!width) {
        // In some cases the width is not included, so we take the item default width
        width = this.getWidth(ean_code)
      }

      return {
        barcode: original_barcode,
        ean_code: ean_code,
        batch_code: batch_code,
        quantity_net: net_val,
        quantity_gross: brut_val,
        batch_width: width,
      }
    },
    parseOldBarcode(barcode: string): IncomingGoodItemCode | void {
      const original_barcode = barcode

      // First clean our input
      barcode = barcode.replace(/[()]+/g, '')

      // We mutate the barcode as we split off information for parsing
      let brut
      ;[barcode, brut] = this.split(barcode, barcode.length - 10)
      // bruto meters is in last 10 characters
      // barcode is now prefix-ItemCode, prefix-batch

      const brut_val = this.convertLengthPartToCM(brut) / 100
      const net_val = brut_val

      barcode = barcode.replace('$', '')
      barcode = barcode.slice(2, barcode.length)
      // remove prefix from ItemCode
      // barcode is now ItemCode, prefix-batch
      let item_code
      ;[item_code, barcode] = this.split(barcode, 14)

      const batch_code = barcode.slice(2, barcode.length)
      // remove prefix from batch

      if (item_code.length == 0 || batch_code.length == 0) return

      if (item_code[0] == '0') {
        item_code = item_code.slice(1, 14)
      }
      return {
        barcode: original_barcode,
        item_code: item_code,
        batch_code: batch_code,
        quantity_net: net_val,
        quantity_gross: brut_val,
        batch_width: this.getWidth(item_code),
      }
    },

    parseExcel(excel: string): IncomingGoodItemCode[] {
      const rows = excel.split('\n')
      const output: IncomingGoodItemCode[] = []

      for (let index = 0; index < rows.length; index++) {
        const curr_row = rows[index]
        if (curr_row == '') {
          continue
        }
        const split_row = curr_row.split('\t')
        try {
          output.push({
            barcode: '',
            item_code: split_row[0],
            batch_code: split_row[1],
            quantity_net: Number(split_row[2]),
            quantity_gross: Number(split_row[3]),
            batch_width: Number(split_row[4]),
          })
        } catch {
          return []
        }
      }
      return output
    },
    split(str: string, index: number) {
      return [str.slice(0, index), str.slice(index)]
    },
    convertLengthPartToCM(lengthPart: string) {
      const prefix = lengthPart.slice(0, 4)
      const i = prefix.slice(-1)
      const value = Number(lengthPart.slice(-6))
      switch (i) {
        case '0':
          return this.roundValue(value * 100)
        case '1':
          return this.roundValue(value * 10)
        case '2':
          return this.roundValue(value)
        case '3':
          return this.roundValue(value / 10)
        default:
          return this.roundValue(value)
      }
    },
    prepareScanIncomingGood(input) {
      let parsed_barcode = this.parseBarcode(input)

      // Try to find the item based on ean code or item code
      let item
      if (parsed_barcode?.ean_code && parsed_barcode?.batch_code)
        item = this.findBaseItem('ean_code', parsed_barcode?.ean_code)
      else {
        parsed_barcode = this.parseOldBarcode(this.input)
        if (parsed_barcode?.item_code && parsed_barcode?.batch_code) {
          item = this.findEANorItemCode(parsed_barcode?.item_code)
        } else {
          return
        }
      }

      let error
      if (item == undefined) {
        error = parsed_barcode?.ean_code
          ? 'An item for this ean code cannot be found: ' + parsed_barcode?.ean_code
          : 'An item for this item code cannot be found: ' + parsed_barcode?.item_code
      }
      return new IncomingGood({
        ...parsed_barcode,
        arrival_timestamp: new Date(),
        order_nr: '',
        error: error,
        item: item,
      })
    },
    roundValue(value: number): number {
      return Math.round(value * 100) / 100
    },
    getWidth(item_code: string): number | null {
      return this.findEANorItemCode(item_code)?.width
    },
    findEANorItemCode(code: string): Item {
      // If no item is found from an old barcode we try to find one where the old barcode format
      // contains an ean code instead of an item code. This is the case for Elitex and could become the case
      // for other suppliers as well

      let found_item = this.findBaseItem('ItemCode', code)
      if (found_item) return found_item
      found_item = this.findBaseItem('ean_code', code)
      return found_item
    },
    findBaseItem(attribute, value) {
      return this.$store.getters.getAugmentedItemByAttribute(attribute, value)
    },
  },
})
