<template>
  <b-sidebar id="sidebar-border" title="All apps" backdrop shadow>
    <div class="px-3 py-2">
      <template v-if="$store.state.user.is_manager">
        <b>Manage</b>
        <ul>
          <li>
            <a :href="get_url('/log-entries/')" target="blank">
              Logs <b-badge variant="primary" class="ml-2">new frontend</b-badge>
            </a>
          </li>
          <li>
            <a :href="get_url('/users/')" target="blank">
              Manage users <b-badge variant="primary" class="ml-2">new frontend</b-badge>
            </a>
          </li>
          <li v-if="DEVELOPMENT">
            <a :href="get_url('/workflows/')" target="blank">
              Workflows <b-badge variant="primary" class="ml-2">new frontend</b-badge>
            </a>
          </li>
        </ul>
      </template>

      <b>Order</b>
      <ul>
        <li>
          <i-router-link name="Place order">Place order</i-router-link>
        </li>
        <li>
          <i-router-link name="OrderHistory">Order history</i-router-link>
        </li>
        <li v-if="$store.state.user.is_tester || DEVELOPMENT">
          <i-router-link name="OrderWorkflows">Email orders</i-router-link>
        </li>
        <li v-if="false">
          <i-router-link name="TrackOrder">Track order</i-router-link>
        </li>
      </ul>
      <template v-if="check_group(['sales'])">
        <b>Maxxisample</b>
        <ul>
          <li>
            <i-router-link name="MaxxisampleOrder">Place order</i-router-link>
          </li>
          <li>
            <i-router-link name="MaxxisampleOrderHistory">Order history</i-router-link>
          </li>
        </ul>
      </template>

      <b>Collection</b>
      <ul>
        <li>
          <i-router-link name="Collection">Customer collection</i-router-link>
        </li>
        <li v-if="check_group(['sales'])">
          <i-router-link name="CollectionImporter">Import collection</i-router-link>
        </li>
      </ul>

      <template v-if="$store.state.user.is_employee && $store.state.user.is_tester">
        <b>Forecast</b>
        <ul>
          <li>
            <i-router-link name="item-forecast">Item Forecast</i-router-link>
          </li>
          <li>
            <i-router-link name="company-forecast">Customer Forecast</i-router-link>
          </li>
          <li>
            <i-router-link name="company-item-forecast">Customer Item Forecast</i-router-link>
          </li>
        </ul>
      </template>

      <div v-if="check_group(['sales'])">
        <b>Sales</b>
        <ul>
          <div v-if="$store.state.user.is_tester || DEVELOPMENT">
            <li>
              <i-router-link name="CompanyItems">Account items</i-router-link>
            </li>
          </div>

          <li>
            <i-router-link name="PriceAgreements">Price agreements</i-router-link>
          </li>
          <li>
            <!-- Permissions are checked in the page -->
            <i-router-link name="PriceList">Price list</i-router-link>
          </li>
          <li>
            <i-router-link name="Forecast">Customer forecast</i-router-link>
          </li>
        </ul>
      </div>

      <div v-if="check_group(['purchase'])">
        <b>Purchase</b>
        <ul>
          <li v-if="$store.state.user.permissions">
            <i-router-link name="Backorder">Coulisse backorders</i-router-link>
          </li>
          <li>
            <router-link :to="{ name: 'PurchaseOrder' }">Purchase order</router-link>
          </li>
        </ul>
      </div>

      <div v-if="check_group(['logistics', 'quality'])">
        <b>Logistics</b>
        <ul>
          <li>
            <i-router-link name="IncomingGoodsLegacy">Scan incoming goods</i-router-link>
          </li>
          <li>
            <i-router-link name="ShipmentOverview">Shipment overview</i-router-link>
          </li>
          <li>
            <i-router-link name="IncomingGoodsQualityAssurance">Quality Assurance</i-router-link>
          </li>
          <li>
            <i-router-link name="IncomingGoodsQualityCheck">Quality Check</i-router-link>
          </li>
        </ul>
      </div>
    </div>
  </b-sidebar>
</template>

<script lang="ts">
import { check_permissions } from 'innicore/router'

export default {
  methods: {
    check_group(groups: string[]) {
      return check_permissions({ allowedGroups: groups }, this.$store.state.user)
    },
    get_url(path: string) {
      const prod = 'https://webapp-new.lienesch.com'
      const stag = 'https://staging.webapp-new.lienesch.com'

      if (import.meta.env.PROD) return prod + path

      return stag + path
    },
  },
}
</script>
