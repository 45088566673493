<template>
  <div>
    <h1>Automate your order process</h1>
    <p>
      To make the order process for our customers as easy as possible, we at Lienesch provide multiple options for
      automating your order placement.
    </p>
    <h2>Lienesch excel order template</h2>
    <p>
      The easiest way would be to export your orders into the Lienesch excel template, and upload it into the "Upload
      order" box on the
      <a href="/order"> order page</a>. In the table below you can find an description of all fields in the excel
      template.
    </p>
    <p>
      Download the excel order template here
      <b-button variant="primary" href="/excel/order_template.xlsx" download="LieneschOrder.xlsx">
        <b-icon icon="download" aria-hidden="true" /> Download template
      </b-button>
    </p>
    <b-container fluid class="con">
      <b-row>
        <b-col cols="9">
          <b-table striped responsive="sm" :items="items"></b-table>
        </b-col>
      </b-row>
    </b-container>
    <p>Example of filled in Lienesch excel template:</p>
    <img :src="require('../../assets/order_template.png')" /><br /><br />
    <h2>Custom order template</h2>
    <p>
      If you'd rather upload a data export from you own system (.json, .csv, .xml, .xlsx, etc.), then please contact us
      at <support-email-address></support-email-address>. We will try to program something that automatically translates
      your order.
    </p>
    <h2>Make use of Lienesch API</h2>
    <p>
      To completely automate your order process, let your applications talk to the Lienesch API, hosted at
      <a href="https://api.webapp.lienesch.com/graphql"> https://api.webapp.lienesch.com/graphql</a>. For more
      information on how to use the Lienesch API, please refer to the
      <router-link :to="{ name: 'HelpEDI' }">EDI Help page</router-link>.
    </p>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      customer: null,
      customer_valid: false,
      file: null,
      modal: {
        waiting: true,
        title: 'Waiting on import...',
      },
      items: [
        {
          field: 'Order with',
          description:
            'Fill this with "ItemCode", if you want to order with Lienesch item codes, ' +
            'use "ItemCodeAccount" to order with your own item codes, ' +
            'or use "Description" if you want to order with the Lienesch item description, ' +
            'as you can see on the collection page.',
          example_value: 'itemcodeaccount',
          required: 'Yes',
        },
        {
          field: 'Order reference',
          description:
            'Enter your reference in this field. If let empty, the name of the file will be used as a reference.',
          example_value: 'Order 12345',
          required: 'No',
        },
        {
          field: 'Item',
          description:
            'In this column you can put the items you want to order. ' +
            'If you entered "itemcode" in the "Order with" field, you should use the Lienesch item codes. ' +
            'If you entered "itemcodeaccount" you should use your own item code, etc. ',
          example_value: '110120023520',
          required: 'Yes',
        },
        {
          field: 'Quantity',
          description: "The quantity you'd like to order of this item.",
          example_value: '12',
          required: 'Yes',
        },
        {
          field: 'Unit',
          description:
            'The unit of order quantity, can be either ' +
            '"m" for orders in metres, ' +
            '"m2" for orders in squared meters, or ' +
            '"pc" for pieces. (1 piece being 1 default sales quantity)',
          example_value: 'm',
          required: 'Yes',
        },
        {
          field: 'Requested shipping date',
          description:
            'Requested shipping date for this item, the actual shipping date may vary. ' +
            'To ensure your requested shipping date being met, ' +
            'we recommend our customers to order as far ahead as possible. ' +
            "If let empty, we'll ship the order ASAP.",
          example_value: '21-6-2022',
          required: 'No',
        },
        {
          field: 'Remark',
          description: 'If you want to leave an extra remark for an order line you can use this field.',
          example_value: 'Please ship with UPS Express.',
          required: 'No',
        },
      ],
    }
  },
}
</script>
