import { filteringMixin } from 'bootstrap-vue/src/components/table/helpers/mixin-filtering.js'
import Vue, { computed } from 'vue'

const fnFactory = new filteringMixin().defaultFilterFnFactory

export default Vue.extend({
  provide() {
    return {
      filteredItems: computed(() => this.filteredItems),
      allFilters: computed(() => this.allFilters),
      filterEvents: this.filterEvents,
      filterableFields: computed(() => this.filterableFields),
      magicFilterValue: computed(() => this.magicFilterValue),
    }
  },
  data: () => ({
    allFilters: [],
    magicFilterValue: '',
    filterEvents: new Vue(),
  }),
  computed: {
    filteredItems() {
      let newFilteredItems = this.items
      this.activeFilters.forEach((filter) => (newFilteredItems = newFilteredItems.filter((x) => filter.apply(x))))
      newFilteredItems = this.magicFilterItems(newFilteredItems)
      return newFilteredItems
    },
    filterableFields() {
      return this.fields
        .filter((f) => f.type)
        .map((f) => ({ options: [...new Set(this.items.map((i) => i[f.key]))], ...f }))
    },
    activeFilters() {
      return this.allFilters.filter((filter) => filter.value !== null)
    },
  },

  created() {
    this.filterEvents.$on('update:allFilters', this.updateAllFilters)
    this.filterEvents.$on('update:magicFilterValue', this.updateMagicFilterValue)
  },

  beforeDestroy() {
    this.filterEvents.$off('update:allFilters')
    this.filterEvents.$off('update:magicFilterValue')
  },

  methods: {
    updateAllFilters(allFilters) {
      this.allFilters = allFilters
    },
    updateMagicFilterValue(magicFilterValue) {
      this.magicFilterValue = magicFilterValue
    },
    magicFilterItems(items) {
      const filterFn = fnFactory(this.magicFilterValue)
      return filterFn ? items.filter(filterFn) : items
    },
  },
})
