import { parseItem as defaultParseItem } from 'innicore/common/parseItem'
import utils from 'innicore/mixins/utils'

import type { ItemFragment } from '@/graphql/generated'

export function parseItem(node: ItemFragment) {
  const item = defaultParseItem(node)
  return {
    ...item,

    abc: node.abc_class,
    color: node.color,
    color_code: node.color_code,
    ean_code: node.ean_code,
    is_m2_item: node.is_m2_item,
    leadtime: node.LeadTime,
    material: node.material,
    minimum_order_quantity: node.MinimumOrderQuantity,
    pleat_height: node.pleat_height,
    standard_package_quantity: node.StandardPackageQuantity,
    transparency: node.transparency,
    trend_group1: node.trend_group1,
    trend_group2: node.trend_group2,
    unit: utils.methods.translate_order_unit(node.PackageDescription),
    weave: node.weave,
    width: node.width,
    ud01: node.UserDate_01,
  }
}
