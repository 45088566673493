<template>
  <div>
    <b-card style="max-width: 40rem" class="mt-5 mx-auto" header="Login" header-class="card-header">
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Email">
          <b-form-input v-model="form.email" :state="formState" placeholder="Email" data-cy="email-input" />
        </b-form-group>

        <b-form-group label="Password" invalid-feedback="The email and password combination is not valid.">
          <b-form-input
            v-model="form.password"
            :state="formState"
            type="password"
            placeholder="Password"
            data-cy="password-input"
          />
        </b-form-group>

        <div class="d-flex justify-content-between align-items-center">
          <a href="/forgot-password">Forgot your password?</a>
          <b-button variant="secondary" type="submit">Login</b-button>
        </div>
      </b-form>

      <template #footer>
        Don't have an account yet? <router-link :to="{ name: 'Signup' }">Sign up</router-link>.
      </template>
    </b-card>

    <b-modal id="otp-modal" title="Two-Factor Authentication" hide-footer>
      <component
        :is="otpComponent"
        :error="otpError"
        @update:error="(newOtpError) => (otpError = newOtpError)"
        @submit="onOtpSubmit"
      />
    </b-modal>

    <b-modal id="loading-modal" title="Login Successful" hide-footer>
      <b-container style="text-align: center">
        <b-row>
          <b-col>
            <b-spinner variant="primary" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>Please wait while the webapp is loading data...</b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue'
import { AxiosError } from 'axios'
import { defineComponent } from 'vue'

import { loginRedirect } from 'innicore/common/redirect'
import OtpGoogle from 'innicore/components/auth/OtpGoogle.vue'
import apiMixin from 'innicore/mixins/api_mixin'

import { RefreshOtpDocument, TokenAuthDocument } from '@/graphql/generated'

export default defineComponent({
  mixins: [apiMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      formState: null as boolean | null,
      token: null as string | null,
      payload: null as unknown | null,

      otpError: null as string | null,
    }
  },
  computed: {
    otpComponent() {
      return OtpGoogle
    },
  },
  async mounted() {
    if (this.$store.state.logged_in) {
      await this.fetchDefaultPage()
    }
  },
  methods: {
    async onSubmit() {
      try {
        const response = await this.api_call(
          TokenAuthDocument,
          {
            email: this.form.email,
            password: this.form.password,
          },
          {
            check_auth: false,
          }
        )

        if (!response.data.data?.token_auth) {
          this.formState = false
          this.form.password = ''
        } else {
          this.formState = true
          this.token = response.data.data.token_auth.token
          this.payload = response.data.data.token_auth.payload
          this.$store.commit('set_email', this.form.email)

          this.$bvModal.show('otp-modal')
        }
      } catch (error) {
        const scope = new Sentry.Scope()
        scope.setContext('context', { query: TokenAuthDocument })
        Sentry.captureException(error, scope)

        if (error instanceof AxiosError && !error.response) {
          this.$router.replace({ name: 'TemporarilyUnavailable' })
        }
      }
    },
    async onOtpSubmit(code?: string) {
      this.otpError = null
      try {
        const response = await this.api_call(
          RefreshOtpDocument,
          {
            otp: code,
            token: this.token,
          },
          {
            token: this.token,
          }
        )
        if (response.data.data.OtpVerify.verified) {
          this.$store.commit('set_token', response.data.data.refresh_token.token)
          await this.initAfterLogin()
        } else {
          if (response.data.data.OtpVerify.otp_error) {
            this.otpError = response.data.data.OtpVerify.otp_error
          } else {
            this.otpError = 'Something went wrong, please try again.'
          }
        }
      } catch (error) {
        const scope = new Sentry.Scope()
        scope.setContext('context', { query: RefreshOtpDocument })
        Sentry.captureException(error, scope)

        if (error instanceof AxiosError) {
          if (!error.response) {
            this.$router.replace({ name: 'TemporarilyUnavailable' })
          } else {
            this.otpError = 'Something went wrong, please try again.'
          }
        }
      }
    },
    async initAfterLogin() {
      this.$bvModal.hide('otp-modal')
      this.$bvModal.show('loading-modal')

      await this.$store.dispatch('fetchUserPermissions')
      await this.$store.dispatch('init_sentry_context')
      const promises = [this.$store.dispatch('fetchItems'), this.$store.dispatch('fetchCompanies')]
      await Promise.all(promises)
      await this.fetchDefaultPage()
      this.dispatchStoreFetches()
    },
    dispatchStoreFetches() {},
    async fetchDefaultPage() {
      const userDefaultPage = await this.$store.dispatch('fetchDefaultPage')
      await loginRedirect(this, userDefaultPage)
    },
  },
})
</script>
