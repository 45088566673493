import Vue from 'vue'

import printMixin, { LabelData } from '@/mixins/printerMixin'

export default Vue.extend({
  mixins: [printMixin],
  methods: {
    makeLabel(items: []) {
      const result = []
      items.forEach((item) => {
        const parsed = new LabelData({
          item_code: item.ItemCode,
          batch_code: item.batch_code,
          width: item.batch_width,
          quantity_net: item.quantity_net,
          quantity_gross: item.quantity_gross,
          color_code: item.color_code,
          ean_code: item.ean_code,
        })

        result.push(parsed)
      })
      // defined in printMixin
      return this.sendToPrinter(result, this.printerUrl)
    },
  },
})
