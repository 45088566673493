<script lang="ts">
import { defineComponent } from 'vue'

import BaseAuthProfile from 'innicore/views/auth/AuthProfile.vue'

import { DefaultPage } from '@/graphql/generated'

export default defineComponent({
  extends: BaseAuthProfile,
  methods: {
    getDefaultPageOptions() {
      return [
        { value: null, text: 'Default homepage' },
        { value: DefaultPage.CUT_LENGTH, text: 'Cut length' },
        { value: DefaultPage.PRICE_AGREEMENTS, text: 'Price agreements' },
        { value: DefaultPage.PRICE_LIST, text: 'Price list' },
      ]
    },
  },
})
</script>
