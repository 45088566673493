<template>
  <b-container id="incoming_goods_scan_input" class="con">
    <b-form>
      <div v-if="entering_data">
        <b-form-group :label="'Input ' + mode" label-cols="2" content-cols="8">
          <b-form-input v-if="mode == 'barcode'" v-model="input" type="text" @keydown.enter.prevent="emit_scan" />
          <b-form-textarea v-if="mode == 'excel'" v-model="input" @keydown.enter.prevent="emit_excel" />
        </b-form-group>
      </div>
      <div v-else>
        <b-form-group label="Scan barcode" label-cols="2" content-cols="8">
          <b-button @click="startInput('barcode')">Start scanning barcodes</b-button>
        </b-form-group>
        <b-form-group label="Copy from excel" label-cols="2" content-cols="8">
          <b-button @click="startInput('excel')">Copy from excel</b-button>
        </b-form-group>
      </div>
    </b-form>
  </b-container>
</template>
<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import utils from 'innicore/mixins/utils'

import ParseBarcodeMixin from '@/views/incoming_goods/ParseBarcodeMixin.ts'
import { IncomingGood } from '@/views/incoming_goods/types.ts'

export default {
  name: 'IncomingGoodsScanInput',
  mixins: [ParseBarcodeMixin, utils],
  props: {
    tableMode: {
      type: Number,
      default: 0,
    },
    enterBulkAddFunction: {
      type: Function,
      default: () => {
        return
      },
    },
  },
  data() {
    return {
      input: '',
      mode: '',
    }
  },
  computed: {
    entering_data() {
      return this.tableMode == TABLE_MODE.BULK_ADD
    },
  },
  methods: {
    startInput(mode: string) {
      this.mode = mode
      this.enterBulkAddFunction()
    },
    emit_scan() {
      const parsedBarcode = this.prepareScanIncomingGood(this.input)
      if (parsedBarcode) this.$emit('update:barcode', parsedBarcode)
      this.reset()
    },
    emit_excel() {
      const excel_lines = this.parseExcel(this.input)
      const incoming_goods_objects = []
      for (let index = 0; index < excel_lines.length; index++) {
        const item = this.findBaseItem('ItemCode', excel_lines[index]?.item_code)
        incoming_goods_objects.push(
          new IncomingGood({
            ...excel_lines[index],
            item: item,
            arrival_timestamp: new Date(),
            order_nr: '',
            checked: true,
          })
        )
      }
      this.$emit('update:excel', incoming_goods_objects)
      this.reset()
    },
    reset() {
      this.input = ''
    },
  },
}
</script>
