<script lang="ts">
import AddDataModal from 'innicore/views/price-agreements/AddDataModal.vue'

import { FetchPriceListEssentialsDocument } from '../../graphql/generated'

export default {
  extends: AddDataModal,
  methods: {
    getQuery() {
      return FetchPriceListEssentialsDocument
    },
  },
}
</script>
