<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h1>Change your profile</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
            <b-alert
              class="my-2"
              variant="success"
              :show="alertCountDown"
              dismissible
              fade
              @dismissed="alertCountDown = 0"
              @dismiss-count-down="onAlertCountDownChanged"
            >
              Your profile was updated.
            </b-alert>

            <b-form-group v-for="field in formFields" :key="field.id" :label="field.label">
              <b-form-select
                v-if="field.type === 'select'"
                id="field.id"
                v-model="field.value"
                :disabled="field.disabled"
                :placeholder="field.label"
                :options="field.options"
              />
              <b-form-input
                v-else
                :id="field.id"
                v-model="field.value"
                :type="field.type"
                :disabled="field.disabled"
                :placeholder="field.label"
                required
              />
            </b-form-group>

            <b-button type="submit" class="mr-1" variant="primary"> Save </b-button>
            <b-button type="reset" variant="secondary"> Reset form </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

import { DefaultPage, GetUserDocument, type GetUserQuery, MutateCurrentUserDocument } from '@/graphql/generated'

export default {
  mixins: [api_mixin],
  data() {
    return {
      user: null as GetUserQuery['currentUser'],
      formFields: {
        email: {
          id: 'email-input',
          label: 'Email address',
          type: 'email',
          disabled: true,
          value: '',
        },
        firstName: {
          id: 'first-name-input',
          label: 'First name',
          type: 'text',
          disabled: false,
          value: '',
        },
        lastName: {
          id: 'last-name-input',
          label: 'Last name',
          type: 'text',
          disabled: false,
          value: '',
        },
        defaultPage: {
          id: 'default-page-input',
          label: 'Default page',
          type: 'select',
          disabled: false,
          value: '',
          options: this.getDefaultPageOptions(),
        },
      },
      alertCountDown: 0,
    }
  },
  async mounted() {
    const response = await this.api_call(GetUserDocument)
    this.user = response.data.data.currentUser
    this.onReset()
  },
  methods: {
    getDefaultPageOptions(): { value: DefaultPage | null; text: string }[] {
      return [
        { value: null, text: 'Default homepage' },
        { value: DefaultPage.PRICE_AGREEMENTS, text: 'Price agreements' },
        { value: DefaultPage.PRICE_LIST, text: 'Price list' },
      ]
    },
    async onSubmit() {
      const response = await this.api_call(MutateCurrentUserDocument, {
        input: {
          email: this.formFields.email.value,
          first_name: this.formFields.firstName.value,
          last_name: this.formFields.lastName.value,
          default_page: this.formFields.defaultPage.value ?? null,
        },
      })

      this.user = response.data.data.MutateOwnUser.user
      this.onReset()

      this.showAlert()
    },
    onReset() {
      this.formFields.email.value = this.user.email
      this.formFields.firstName.value = this.user.first_name
      this.formFields.lastName.value = this.user.last_name
      this.formFields.defaultPage.value = this.user.default_page ?? null
    },
    showAlert() {
      this.alertCountDown = 5
    },
    onAlertCountDownChanged(value: number) {
      this.alertCountDown = value
    },
  },
}
</script>
