<template>
  <div>
    <b-container class="con">
      <b-row>
        <b-col cols="2"><b>Upload file</b></b-col>
        <b-col cols="10">
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="file-import-modal"
      hide-footer
      :title="modal.title"
      :size="modal.size"
      :static="true"
      @hidden="resetModal"
    >
      <div style="text-align: center">
        <b-spinner v-if="modal.state === 'waiting'" variant="primary"></b-spinner>

        <div v-if="modal.state === 'error'" style="text-align: left">
          <b-list-group>
            <b-list-group-item v-for="(error, index) in modal.errors" :key="index" variant="danger">
              {{ error.message }}: <br />
              {{ error.details }}
            </b-list-group-item>
          </b-list-group>
        </div>

        <div v-if="modal.state === 'success'">
          Importing file was successfull! check it out at
          <router-link variant="info" :to="{ name: appName }"> {{ appName }}</router-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  mixins: [api_mixin, utils],
  props: {
    afterImport: {
      type: Function,
      default: () => undefined,
    },
    fileParseCallback: {
      type: Function,
      default: () => undefined,
    },
    appName: {
      type: String,
      default: '',
    },
    hideOnSuccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customer: null,
      file: null,
      modal: {
        state: 'waiting',
        size: null,
        title: 'waiting on import...',
        errors: [],
      },
    }
  },
  watch: {
    file: function () {
      if (this.file) {
        this.$bvModal.show('file-import-modal')
        this.sendFile()
      }
    },
  },
  mounted() {
    this.resetModal()
  },
  methods: {
    resetModal() {
      this.modal.state = 'waiting'
      this.modal.size = null
      this.modal.title = 'waiting on import...'
      this.modal.errors = []
    },
    async sendFile() {
      const upload = await this.toUpload(this.file)
      const result = await this.fileParseCallback(upload)
      if (result.errors) {
        this.modal.state = 'error'
        this.modal.size = 'xl'
        this.modal.title = 'errors on importing:'
        this.modal.errors = result.errors
        this.file = null
      } else if (result.success) {
        if (this.hideOnSuccess) {
          this.$bvModal.hide('file-import-modal')
        } else {
          this.modal.state = 'success'
          this.modal.title = 'import was successful!'
        }
      }
      this.afterImport()
    },
  },
}
</script>
