<template>
  <table-tab title="Actions" selector="floating-tabs" floatable>
    <b-container class="con">
      <b-row>
        <b-col>
          <h4>Bulk actions</h4>
          <template v-for="action in allowed_bulk_actions">
            <!-- vue-disable -->
            <div :key="action.key">
              <!-- skipped -->
              <b-input-group>
                <span
                  v-b-tooltip.hover
                  :title="
                    !bulk_actions_enabled && !action.always_enabled
                      ? 'You need to select one or more rows to execute bulk actions on.'
                      : action.disabled
                      ? action.disabled_message
                      : ''
                  "
                >
                  <b-button
                    :variant="action.variant"
                    :disabled="(!bulk_actions_enabled || action.disabled) && !action.always_enabled"
                    :data-action="action.key"
                    @click="execute_bulk_action(action)"
                  >
                    <b-icon :icon="action.icon" />
                    {{ action.title }}
                  </b-button>
                </span>
                <b-input-group-append v-if="action.input_type">
                  <b-form-input
                    v-model="input[action.key]"
                    :type="action.input_type"
                    style="max-width: 250px"
                    :disabled="(!bulk_actions_enabled || action.disabled) && !action.always_enabled"
                  />
                </b-input-group-append>
              </b-input-group>
              <br />
              <br />
            </div>
          </template>
        </b-col>
      </b-row>
    </b-container>
    <slot />
  </table-tab>
</template>

<script lang="ts">
export default {
  name: 'TableTabActions',
  inject: ['bulk_actions', 'allowed_bulk_actions', 'bulk_actions_enabled', 'selectedItems', 'selectedFields'],
  data() {
    return {
      input: {},
    }
  },
  methods: {
    execute_bulk_action(action) {
      if (this.selectedItems.length === 0 && !action.always_enabled) {
        throw 'undefined behaviour; this should not occur, as the bulk buttons should be disabled.'
      }

      if (action.always_enabled) {
        action.execute_bulk(this.input[action.key])
      } else {
        action.execute_bulk(this.selectedItems, this.selectedFields)
      }
      this.$emit('resetTabs')
    },
  },
}
</script>
