interface DateRange {
  valid_from__lte: string | null
  valid_from__gte: string | null
  valid_to__lte: string | null
  valid_to__gte: string | null
}

const setDateRange = (dateRange): DateRange => {
  return {
    valid_from__lte: null,
    valid_from__gte: null,
    valid_to__lte: null,
    valid_to__gte: null,
    ...dateRange,
  }
}

const yesterday = (): string => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date.toISOString().split('T')[0]
}

const today = (): string => {
  return new Date().toISOString().split('T')[0]
}

const tomorrow = (): string => {
  const date = new Date()
  date.setDate(date.getDate() + 1)
  return date.toISOString().split('T')[0]
}

export { yesterday, today, tomorrow }
export type { DateRange }
export default (dateRange): DateRange[] => {
  // Custom date range
  if (dateRange.valid_selected.includes('Custom')) {
    if (!dateRange.valid_from && !dateRange.valid_to) return []
    if (!dateRange.valid_to) return [setDateRange({ valid_from__gte: dateRange.valid_from })]
    if (!dateRange.valid_from) return [setDateRange({ valid_to__lte: dateRange.valid_to })]
    return [setDateRange({ valid_from__gte: dateRange.valid_from, valid_to__lte: dateRange.valid_to })]
  }

  // Past, Today, Future
  const result: DateRange[] = []
  if (dateRange.valid_selected.includes('Past')) {
    result.push(setDateRange({ valid_to__lte: yesterday() }))
  }
  if (dateRange.valid_selected.includes('Today')) {
    result.push(setDateRange({ valid_from__lte: today(), valid_to__gte: today() }))
  }
  if (dateRange.valid_selected.includes('Future')) {
    result.push(setDateRange({ valid_from__gte: tomorrow() }))
  }
  return result
}
