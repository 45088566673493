<template>
  <a :href="`mailto:${support_address}`">{{ support_address }}</a>
</template>

<script lang="ts">
export default {
  name: 'SupportEmailAddress',
  data() {
    return {
      support_address: import.meta.env.VITE_SUPPORT_ADDRESS,
    }
  },
}
</script>
