<template>
  <table-tab title="Filter" selector="floating-tabs" floatable>
    <fields-filter
      :value="allFilters"
      :fields="filterableFields"
      @input="(value) => filterEvents.$emit('update:allFilters', value)"
    />
  </table-tab>
</template>

<script lang="ts">
import FieldsFilter from 'innicore/components/table/FieldsFilter.vue'
import TableTab from 'innicore/components/table/TableTab.vue'

export default {
  name: 'TableTabFilter',
  components: { TableTab, FieldsFilter },
  inject: ['allFilters', 'filterableFields', 'filterEvents'],
}
</script>
