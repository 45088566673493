<template>
  <div id="date-range-selector">
    <b-row>
      <b-col cols="2"><b> Valid date </b></b-col>
      <b-col cols="6">
        <b-form-checkbox-group
          id="valid-checkbox-group"
          v-model="valid_selected"
          :options="valid_options"
        ></b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"><b> Valid from </b></b-col>
      <b-col v-b-tooltip.hover :title="hoverText">
        <b-form-input
          v-model="valid_from_value"
          :disabled="!valid_selected.includes('Custom')"
          type="date"
          :state="valid_from_value ? true : null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"><b> Valid to </b></b-col>
      <b-col v-b-tooltip.hover :title="hoverText">
        <b-form-input
          v-model="valid_to_value"
          :disabled="!valid_selected.includes('Custom')"
          type="date"
          :state="valid_to_value ? true : null"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid_options: ['Past', 'Today', 'Future', 'Custom'],
      valid_from_value: null,
      valid_to_value: null,
      valid_selected: ['Today', 'Future'], // If edited, also edit SelectBy.data.
    }
  },
  computed: {
    dateRange() {
      return {
        valid_selected: this.valid_selected,
        valid_from: this.valid_from_value,
        valid_to: this.valid_to_value,
      }
    },
    dateRangeEnabled() {
      return this.valid_selected.includes('Custom')
    },
    hoverText() {
      return this.dateRangeEnabled ? '' : "Select 'Custom' to select specific dates"
    },
  },
  watch: {
    dateRange() {
      this.$emit('input', this.dateRange)
    },
    valid_selected(new_valid, old_valid) {
      if (new_valid.includes('Custom') && !old_valid.includes('Custom')) {
        this.valid_selected = ['Custom']
      }
      if (old_valid.includes('Custom') && new_valid.length >= 2) {
        this.valid_selected = new_valid.filter((v) => v != 'Custom')
      }
    },
  },
}
</script>
