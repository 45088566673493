<template>
  <table-tab title="Settings" selector="floating-tabs" floatable>
    <b-form-group label="Magic filter:" labl-class="font-weight-bold">
      <b-form-checkbox v-model="settings.magic_filter" switch></b-form-checkbox>
    </b-form-group>
    <b-form-group label="Sticky headers:" label-class="font-weight-bold">
      <b-form-checkbox v-model="settings.sticky_header.enabled" switch />
      <template v-if="settings.sticky_header.enabled">
        <br />
        Table height is currently {{ settings.sticky_header.height + 'px' }}. Use the slider below to adjust its height.
        <br />
        <b-form-input
          v-model="settings.sticky_header.height"
          type="range"
          step="100"
          min="500"
          max="1800"
          style="width: 500px"
        />
      </template>
    </b-form-group>
    <b-form-group label="Show per page:" label-class="font-weight-bold">
      <b-form-radio-group v-model="settings.pagination.per_page" :options="[10, 25, 50, 100]" />
    </b-form-group>
  </table-tab>
</template>
<script setup lang="ts">
import { inject, reactive, watch } from 'vue'
import Vue from 'vue'

const tableSettings: object = inject('tableSettings') as object
const settingsEvents: Vue = inject('settingsEvents') as Vue
let settings = reactive(tableSettings)
watch(
  () => tableSettings,
  (newSettings) => {
    if (settings !== newSettings) {
      settings = newSettings
    }
  }
)
watch(
  () => settings,
  (newSettings) => {
    if (newSettings !== tableSettings) {
      settingsEvents.$emit('update:settings', newSettings)
    }
  }
)
</script>
