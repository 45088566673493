import utils from 'innicore/mixins/utils'

export class Workflow {
  id
  status
  syscreated
  _syscreator
  description
  type
  audit_group
  audited_by
  workflowlines: WorkflowLine[] = [new WorkflowLine(this)]

  constructor(workflowObject: Record<string, unknown> = {}) {
    // @ts-ignore
    const workflowlines = workflowObject.lines?.edges.map((edge) => new WorkflowLine(this, edge.node))
    delete workflowObject.lines
    Object.assign(this, workflowObject)
    this.workflowlines = workflowlines
  }

  get _cellVariants() {
    return { status: utils.methods.status_color(this.status) }
  }

  get syscreator() {
    return this._syscreator.email
  }

  set syscreator(syscreator) {
    this._syscreator = syscreator // note that this should be set as an object
  }
}

export class WorkflowLine {
  id
  workflow
  workflow_sequence
  description
  mutation_type
  content_type
  object_id
  json
  status

  constructor(workflow: Workflow, workflowLineObject: Record<string, unknown> = {}) {
    Object.assign(this, workflowLineObject)
    this.workflow = workflow
  }

  get containsOrderFile() {
    return this.content_type?.model === 'orderfile'
  }

  get parsedJson() {
    return this.json ? JSON.parse(this.json) : null
  }
}
