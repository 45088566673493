<template>
  <div>
    <MaxxisampleOrderEntry>
      <template #title="order">
        <template v-if="!order.order_id">
          <h1>Place Maxxisample orders</h1>
          <p>
            Here you can place Maxxisample orders. Upload your Maxxisample order directly, or create/edit it down below.
          </p>
        </template>
        <template v-else>
          <h1>Maxxisample order</h1>
          <p>
            Here you can view a Maxxisample order, and edit it if it's still a Draft. Sent sample orders can no longer
            be edited.
          </p>
        </template>
      </template>
    </MaxxisampleOrderEntry>
  </div>
</template>

<script lang="ts">
import MaxxisampleOrderEntry from '@/components/maxxisample/MaxxisampleOrderEntry.vue'

export default {
  components: { MaxxisampleOrderEntry },
}
</script>
