<template>
  <b-modal :id="name" centered size="lg" :title="title" @show="addDefaultFilter" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <fields-filter v-model="field_filters" :fields="filterable_fields"></fields-filter>
      </b-row>
      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">{{
              error
            }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="{ cancel, ok }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>
      <hit-button variant="primary" :click="() => getData(ok)"> OK </hit-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Filter from 'innicore/components/table/Filter'
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  props: {
    name: String,
    title: String,
    callback: Function,
    company: String,
  },
  data() {
    return {
      field_filters: [new Filter()],
      errors: [],
      fields: [
        {
          key: 'assortment',
          label: 'Assortment',
          type: String,
        },
        {
          key: 'c01',
          label: 'Label',
          type: String,
        },
        {
          key: 'c06',
          label: 'Collection year',
          type: String,
        },
        {
          key: 'c02',
          label: 'Collection',
          type: String,
        },
        {
          key: 'c03',
          label: 'Pleat height',
          type: String,
        },
        {
          key: 'c08',
          label: 'PMC',
          type: String,
        },
      ],
    }
  },
  computed: {
    filterable_fields() {
      return this.fields
        .filter((f) => f.type)
        .map((f) => ({ options: [...new Set(this.items.map((i) => i[f.key]))], ...f }))
    },
    amount_of_items() {
      let data = this.items
      this.field_filters.forEach((field_filter) => {
        data = data.filter((item) => field_filter.apply(item))
      })
      return data.length
    },
    items() {
      return this.$store.state.lienesch_items.filter(
        (i) => i['assortment'] != 'Service & promotion' && i['assortment'] != 'Componenten'
      )
    },
  },
  methods: {
    addDefaultFilter() {
      this.field_filters[0].set_field({
        key: 'c01',
        label: 'Label',
        type: String,
        options: [...new Set(this.items.map((i) => i['c01']))],
      })
      this.field_filters[0].filter_rule = 'oneof'
      this.field_filters[0].value = ['EIGENCOLLECTIE']
    },
    async getData(okFunction) {
      let data = this.items
      this.field_filters.forEach((field_filter: Filter) => {
        data = data.filter((item) => field_filter.apply(item))
      })
      this.callback(data)
      okFunction()
    },
    resetModal() {
      this.errors = []
      this.field_filters = [new Filter()]
    },
  },
}
</script>
