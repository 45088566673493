<template>
  <div v-if="validFormatter">
    <b-row no-gutters>
      <b-dropdown size="sm" variant="link" :options="allowedAggregators">
        <template v-for="agg in allowedAggregators">
          <b-dropdown-item :id="agg.value" :key="agg.value" @click="selected = agg.value">
            {{ agg.text }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
      {{ selectedAggregator.text }}: {{ value }}
    </b-row>
  </div>
</template>
<script lang="ts">
const AGGREGATORS = [
  {
    text: 'Sum',
    value: 'sum',
    f: (l) => l.reduce((a, b) => a + b),
  },
  {
    text: 'Avg',
    value: 'avg',
    f: (l) => l.reduce((a, b) => a + b) / l.length,
  },
  {
    text: 'Min',
    value: 'min',
    f: (l) => l.reduce((a, b) => Math.min(a, b)),
  },
  {
    text: 'Max',
    value: 'max',
    f: (l) => l.reduce((a, b) => Math.max(a, b)),
  },
]

const ALL_AGGREGATIONS = AGGREGATORS.map((agg) => agg.value)

export default {
  name: 'TableColumnAggregation',
  props: {
    values: {
      type: Array,
      default: () => undefined,
    },
    formatter: {
      type: Function,
      default: (x) => x,
    },
    aggregations: {
      type: Array,
      default: () => ALL_AGGREGATIONS,
      validator: (value) => value.every((v) => ALL_AGGREGATIONS.includes(v)),
    },
  },
  data() {
    return {
      selected: null,
      AGGREGATORS,
    }
  },
  computed: {
    selectedAggregator() {
      return AGGREGATORS.find((x) => x.value === this.selected)
    },
    value() {
      const aggregatedValue = this.selectedAggregator?.f(
        // Number converts null to 0, which could result in a skewed aggregate
        this.values.map((v) => (v === null ? NaN : Number(v))).filter((v) => !isNaN(v))
      )
      if (aggregatedValue === undefined) {
        return null
      }
      return this.formatter(aggregatedValue)
    },
    validFormatter() {
      return this.formatter?.length <= 1
    },
    allowedAggregators() {
      return AGGREGATORS.filter((agg) => this.aggregations.includes(agg.value))
    },
  },
  mounted() {
    this.selected = this.allowedAggregators[0].value
  },
}
</script>
