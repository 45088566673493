import { Cloneable } from 'innicore/types/clonable'

export function clone(o) {
  if (o instanceof Cloneable) {
    return o.clone()
  }
  if (o instanceof Array && o.length > 0 && o[0] instanceof Cloneable) {
    return o.map((item) => item.clone())
  }
  return structuredClone(o)
}
