<template>
  <div>
    <h1>Incoming goods</h1>
    <p>On this page you can scan new incoming goods.</p>

    <incoming-goods-scan-input
      v-if="mutable"
      :table-mode="tableMode"
      :enter-bulk-add-function="enterBulkAddMode"
      @update:barcode="(item) => addFromInput([item])"
      @update:excel="(items) => addFromInput(items)"
    />

    <incoming-goods-shipment-component v-model="shipment" :supplier="supplier" :disabled="shipment_disabled" />

    <incoming-goods-summary-modal
      v-if="mutable"
      :name="summary_modal_name"
      :callback="send_to_exact"
      :items="$refs.ITable.items"
      :title="supplier.cmp_name"
    />

    <AddIncomingGoodsModal
      v-if="shipment"
      title="Manually add to incoming good"
      name="add_to_incoming_good_modal"
      :callback="add_data_callback"
      :company-code="supplier.cmp_wwn"
      :mutate="mutateIncomingGoods"
      :get-shipment-id="getShipmentId"
    />
    <template v-if="$store.state.user.is_employee">
      <i-table
        ref="ITable"
        :mutate-items="mutateIncomingGoods"
        :fetch-items="fetchIncomingGoods"
        :fields="fields"
        :actions="actions"
        :app-name="app_name"
        :allowed-selectors="allowed_selectors"
        :errors="errors"
        :settings="{ sticky_header: { enabled: true, height: 800 } }"
        @update:selectBy="(v) => (select_by = v)"
        @update:tableMode="(tm) => (tableMode = tm)"
      >
        <template v-for="itemAttribute in ['Description', 'ItemCode']" #[editcell(itemAttribute)]="row">
          <collection-item-selector
            :key="row.item[itemAttribute]"
            :value="row.item.item"
            :select_by="itemAttribute"
            @input="(item) => $set(row.item, 'item', item)"
          />
        </template>
        <template #editcell(order_nr)="row">
          <generic-selector
            v-model="row.item['order_nr']"
            :data="available_orders"
            :serializer="(order_nr) => order_nr"
            :disabled="false"
            invalid-feedback-message="This order does not exist!"
            placeholder="Enter and order"
          />
        </template>

        <template #row-details="row">
          <span class="text-danger">
            {{ row.item.error }}
          </span>
        </template>
      </i-table>
    </template>
  </div>
</template>
<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import useTableDefaultFields, { DefaultFieldGroups, mergeFields } from 'innicore/components/table/useTableDefaultFields'
import alerts, { VARIANT } from 'innicore/mixins/alerts'
import api_mixin from 'innicore/mixins/api_mixin.js'
import utils from 'innicore/mixins/utils.js'

import {
  FetchAvailableOrdersDocument,
  FetchIncomingGoodsDocument,
  IncomingGoodStatus,
  MutateShipmentSendToExactDocument,
  MutateShipmentsDocument,
} from '@/graphql/generated'
import AddIncomingGoodsModal from '@/views/incoming_goods/AddToIncomingGoodsModal.vue'
import IncomingGoodsAPIMixin from '@/views/incoming_goods/IncomingGoodsAPIMixin'
import IncomingGoodsPrintMixin from '@/views/incoming_goods/IncomingGoodsPrintMixin'
import IncomingGoodsScanInput from '@/views/incoming_goods/IncomingGoodsScanInput.vue'
import IncomingGoodsShipmentComponent from '@/views/incoming_goods/IncomingGoodsShipmentComponent.vue'
import IncomingGoodsSummaryModal from '@/views/incoming_goods/IncomingGoodsSummaryModal.vue'
import { IncomingGood } from '@/views/incoming_goods/types'

export default {
  name: 'IncomingGoodsLegacy',
  components: {
    IncomingGoodsScanInput,
    AddIncomingGoodsModal,
    IncomingGoodsShipmentComponent,
    IncomingGoodsSummaryModal,
  },
  mixins: [api_mixin, utils, alerts, IncomingGoodsAPIMixin, IncomingGoodsPrintMixin],
  data() {
    return {
      select_by: null,
      available_orders: [],
      summary_modal_name: 'incoming_goods_summary_modal',
      add_incoming_good_modal_name: 'add_to_incoming_good_modal',
      changedFields: [
        {
          key: 'ItemCode',
          optional: false,
          editable: true,
        },
        {
          key: 'Description',
          optional: false,
          editable: true,
        },
        {
          key: 'order_nr',
          editable: true,
        },
        {
          key: 'quantity_gross',
          editable: true,
        },
        {
          key: 'quantity_net',
          editable: true,
        },
        {
          key: 'batch_width',
          editable: true,
        },
      ],
    }
  },
  computed: {
    actions() {
      return [
        {
          key: 'delete',
          title: 'Delete row',
          modes: [TABLE_MODE.BULK_ADD],
        },
        {
          key: 'add_data',
          execute_global: this.supplier ? this.showAddToIncomingGoodsModal : null,
          disallow: !this.mutable,
        },
        {
          key: 'edit',
          disallow: !this.mutable,
        },
        {
          key: 'print_label',
          title: 'Print label',
          icon: 'printer',
          variant: 'primary',
          execute: (item) => this.makeLabel([item]),
          execute_bulk: (items) => this.makeLabel(items),
        },
        {
          key: 'send_to_exact',
          title: 'Send to Exact',
          variant: 'secondary',
          icon: 'upload',
          execute_global: this.show_summary_modal,
          modes: [TABLE_MODE.READ],
          disallow: !this.mutable,
        },
        {
          key: 'times',
          title: '*10',
          variant: 'secondary',
          modes: [TABLE_MODE.BULK_ADD, TABLE_MODE.WRITE],
          disabled: !this.$refs.ITable?.selectedItems?.length,
          execute_global: this.times,
        },
        {
          key: 'divide',
          title: '/10',
          variant: 'secondary',
          modes: [TABLE_MODE.BULK_ADD, TABLE_MODE.WRITE],
          disabled: !this.$refs.ITable?.selectedItems?.length,
          execute_global: this.divide,
        },
      ]
    },
    allowed_selectors() {
      if (this.$store.state.user.is_employee && !this.$route.query.shipment) return [['supplier']]
      return []
    },
    shipment_disabled() {
      return this.tableMode != TABLE_MODE.READ
    },
    reference() {
      return this.shipment?.reference
    },
    mutable() {
      return this.shipment?.status != IncomingGoodStatus.SENT && this.reference
    },
    supplier() {
      return this.select_by?.supplier
    },
    shipment_id() {
      if (this.shipment?.id) return this.shipment.id
      if (this.$route.query.shipment) return this.$route.query.shipment
      return null
    },
  },
  mounted() {
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.SystemInfo],
      mergeFields(this.extraFields, this.changedFields)
    )
  },
  methods: {
    async getShipmentId() {
      if (this.shipment_id) return this.shipment_id

      const new_shipment = await this.api_call(MutateShipmentsDocument, {
        input: [{ company: this.supplier.cmp_wwn, arrival_timestamp: new Date(), reference: this.shipment.reference }],
      })
      this.shipment = new_shipment.data.data.MutateShipments.ShipmentFields[0]
      return this.shipment.id
    },
    async fetchIncomingGoods(filters) {
      if (filters?.supplier) {
        this.available_orders = await this.api_call(FetchAvailableOrdersDocument, {
          company: this.supplier.cmp_wwn,
        }).then((order_response) => {
          return order_response.data.data.ExactOrder.edges.map((obj) => {
            return obj.node.order_id
          })
        })
      }
      if ((filters?.supplier && this.shipment?.id) || this.$route.query.shipment) {
        return this.api_call(FetchIncomingGoodsDocument, {
          shipment: this.shipment_id,
        }).then((incoming_goods_response) => {
          return incoming_goods_response.data.data.incoming_goods.edges.map((obj) => {
            obj.node.arrival_timestamp = new Date(obj.node.arrival_timestamp)
            obj.node.item = this.findBaseItemItemCode(obj.node.item.ItemCode)
            return new IncomingGood(obj.node)
          })
        })
      } else {
        return []
      }
    },
    addFromInput(incoming_goods: []) {
      const full_items = this.$refs.ITable.items.concat(this.$refs.ITable.items_backup)
      const batch_item_codes = full_items.map((item) => [item.batch_code, item.ItemCode])

      incoming_goods.forEach((item) => {
        if (
          !batch_item_codes.some((batch_item) => batch_item[0] == item.batch_code && batch_item[1] == item.ItemCode)
        ) {
          item.checked = true
          this.$refs.ITable.addItem(item)
        } else {
          this.showToast('This box is already scanned', VARIANT.WARNING)
        }
      })
    },
    validate_items() {
      const valid = this.$refs.ITable.items.every((item: IncomingGood) => item.isValid)
      if (!valid) {
        this.$bvModal.msgBoxOk('Alle kolommen moeten gevuld zijn voordat een zending naar exact gestuurd kan worden', {
          title: 'Warning!',
        })
      }
      return valid
    },
    async send_to_exact() {
      if (!this.validate_items()) {
        return
      }
      if (!this.shipment?.id) {
        return
      }
      const response = await this.api_call(MutateShipmentSendToExactDocument, { id: this.shipment?.id })
      if (response.data.data.MutateShipmentSendToExact.Shipment) {
        // Only change the of the current shipment if it was successful
        this.shipment.status = response.data.data.MutateShipmentSendToExact.Shipment.status
      }
    },
    divide() {
      this.$refs.ITable.selectedItems.map((item: IncomingGood) => {
        item.quantity_net = item.quantity_net / 10
        item.quantity_gross = item.quantity_gross / 10
      })
    },
    times() {
      this.$refs.ITable.selectedItems.map((item: IncomingGood) => {
        item.quantity_net = item.quantity_net * 10
        item.quantity_gross = item.quantity_gross * 10
      })
    },

    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    enterBulkAddMode() {
      this.$refs.ITable.add_data_bulk([])
    },
    show_summary_modal() {
      this.$bvModal.show(this.summary_modal_name)
    },
    showAddToIncomingGoodsModal() {
      this.$bvModal.show(this.add_incoming_good_modal_name)
    },
    editcell(key) {
      return `editcell(${key})`
    },
  },
}
</script>
