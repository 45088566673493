<template>
  <LineChart
    ref="graph"
    :plugins="graphPlugins"
    :chart-data="graphData()"
    :chart-options="graphOptions"
    :height="200"
  />
</template>

<script lang="ts">
import { Line as LineChart } from 'vue-chartjs/legacy'

import verticalLinePlugin from 'innicore/components/charts/verticalLinePlugin.js'

export default {
  name: 'IChart',
  components: { LineChart },
  props: {
    chronGraph: {
      type: Boolean,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    vLines: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      graphOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'top' },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Months',
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'meters',
            },
          },
        },
        verticalLines: [],
      },
      graphPlugins: [],
    }
  },
  watch: {
    chronGraph(value) {
      // The vertical lines aren't drawn when the chart is a chronGraph
      if (!value) {
        this.removeVerticalLines()
        this.graphPlugins = this.graphPlugins.filter((plugin) => plugin.id !== 'custom_canvas_vertical_line')
      } else {
        this.graphPlugins.push(verticalLinePlugin())
        this.drawDates(this.vLines, this.labels)
      }
    },
  },
  mounted() {
    if (this.chronGraph && this.vLines.length > 0) {
      // Enable vertical line plugin and draw dates
      this.graphPlugins.push(verticalLinePlugin())
      this.drawDates(this.vLines, this.labels)
    }
  },
  methods: {
    graphData() {
      return {
        labels: this.labels,
        datasets: this.data,
      }
    },
    drawDates(lines, labels) {
      lines?.forEach((line) => {
        line.index = this.getLineIndexByDate(line.date, labels)
      })
      this.graph_options.verticalLines = lines
    },
    getLineIndexByDate(lineDate, labels) {
      function parseFormattedDate(dateString) {
        const [month, year] = dateString.split(" '")
        const monthIndex = new Date(Date.parse(month + ' 1, 2000')).getMonth() + 1
        const yearValue = Number(year) + 2000 // Adjust the base year as needed

        return new Date(yearValue, monthIndex - 1, 1)
      }

      function isDateInMonth(date, targetMonth, targetYear) {
        const month = date.getMonth()
        const year = date.getFullYear()
        return month === targetMonth && year === targetYear
      }

      const parsedMonths = labels.map((label) => parseFormattedDate(label))
      return parsedMonths.findIndex((date) => {
        return isDateInMonth(lineDate, date.getMonth(), date.getFullYear())
      })
    },
    removeVerticalLines() {
      this.graph_options.verticalLines = []
    },
  },
}
</script>

<style scoped></style>
