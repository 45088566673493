<template>
  <div>
    <h1>Price list</h1>
    <p>On this page you can view and update your price lists.</p>

    <generate-new-price-list-modal
      title="Generate new data"
      name="generate_new_data_modal"
      :callback="add_data_from_store"
    />

    <i-table
      ref="ITable"
      :fetch-items="fetchPriceList"
      :fields="fields"
      :actions="actions"
      app-name="default_prices"
      :allowed-selectors="[['date_range']]"
      :errors="errors"
      :mutate-items="mutatePriceLists"
      @update:selectBy="(v) => (select_by = v)"
      @update:tableMode="(tm) => (tableMode = tm)"
    />
  </div>
</template>

<script lang="ts">
import formatDateFilter from 'innicore/common/formatDateFilter'
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import { FetchPriceListDocument, MutatePriceListsDocument } from 'innicore/graphql/generated'
import alerts, { VARIANT } from 'innicore/mixins/alerts'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'
import GenerateNewPriceListModal from 'innicore/views/price-list/GenerateNewPriceListModal.vue'

import { parseItem } from '@/common/parseItem.ts'
import useTableDefaultFields, { DefaultFieldGroups } from '@/components/table/useTableDefaultFieldsCustomer'

export default {
  name: 'PriceList',
  components: { GenerateNewPriceListModal },
  mixins: [api_mixin, utils, alerts],
  data() {
    return {
      select_by: null,
      compare_to: null,
      tableMode: null,
      pricelists: ['new_default_price', 'new_medium_price', 'new_best_price'],
      errors: [],
      fields: [],
      extraFields: this.defaultExtraFields(),
    }
  },
  computed: {
    actions() {
      return [
        {
          key: 'delete',
          title: 'Delete row',
          disallow: this.tableMode !== TABLE_MODE.BULK_ADD,
          disabled: this.tableMode !== TABLE_MODE.BULK_ADD,
        },
        {
          key: 'edit',
          disallow: !this.allow_mutations,
        },
        {
          key: 'generate_new_data',
          modes: [TABLE_MODE.READ],
          icon: 'box-arrow-in-down-left',
          variant: 'b-blue',
          title: 'Generate bulk data',
          disallow: !this.allow_mutations,
          execute_bulk: this.showGenerateNewDataModal,
          always_enabled: this.allow_mutations,
        },
      ]
    },
    allow_mutations() {
      return this.$store.state.user.is_manager
    },
  },
  mounted() {
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
  },
  methods: {
    defaultExtraFields() {
      return [
        {
          key: 'new_best_price',
          label: 'New best price',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-best',
          insertBefore: 'unit',
        },
        {
          key: 'best_price',
          label: 'Best price',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-best',
          insertBefore: 'unit',
        },
        {
          key: 'new_medium_price',
          label: 'New medium price',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-medium',
          insertBefore: 'unit',
        },
        {
          key: 'medium_price',
          label: 'Medium price',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-medium',
          insertBefore: 'unit',
        },
        {
          key: 'new_default_price',
          label: 'New default price',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-default',
          insertBefore: 'unit',
        },
        {
          key: 'default_price',
          label: 'Default price',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-default',
          insertBefore: 'unit',
        },
        {
          key: 'valid_from',
          label: 'Valid from',
          sortable: true,
          optional: true,
          selected: true,
          editable: () => this.tableMode == TABLE_MODE.BULK_ADD,
          type: Date,
          insertBefore: 'unit',
        },
        {
          key: 'valid_to',
          label: 'Valid to',
          sortable: true,
          optional: true,
          selected: true,
          editable: () => this.tableMode == TABLE_MODE.BULK_ADD,
          type: Date,
          insertBefore: 'unit',
        },
        { key: 'hs_code', label: 'Statistical code', optional: true, type: Number, insertBefore: 'sysmodified' },
      ]
    },

    add_data_from_store(data) {
      data.forEach((item) => {
        item.id = undefined
      })
      const item_code_list = this.$refs.ITable.items.map((f) => f.ItemCode)
      data = data.filter((item) => !item_code_list.includes(item.ItemCode))
      this.$refs.ITable.add_data_bulk(data)
    },

    async showGenerateNewDataModal() {
      this.$bvModal.show('generate_new_data_modal')
    },

    getCurrencyDecimals() {
      return 2
    },

    defaultParsePriceListNode(node) {
      const item = parseItem(node.item)
      return {
        ...item,
        id: node.id,
        new_default_price: node.default_price,
        default_price: node.default_price,
        new_medium_price: node.medium_price,
        medium_price: node.medium_price,
        new_best_price: node.best_price,
        best_price: node.best_price,
        valid_to: node.valid_to,
        valid_from: node.valid_from,
        syscreated: node.syscreated,
        syscreator: node.syscreator.email,
        sysmodified: node.sysmodified,
        sysmodifier: node.sysmodifier.email,
      }
    },
    parsePriceListNode(node) {
      return this.defaultParsePriceListNode(node)
    },

    getQuery() {
      return FetchPriceListDocument
    },
    filterPriceListDuplicates(responses) {
      // Because price agreements may overlap in the date range, yet we do fetch them twice,
      // we need to filter out duplicates
      const price_list_ids = []
      const price_list = []
      responses.forEach((response) => {
        response.data.data.price_list.edges.forEach((obj) => {
          const node = this.parsePriceListNode(obj.node)
          if (!price_list_ids.includes(node.id)) {
            price_list_ids.push(node.id)
            price_list.push(node)
          }
        })
      })
      return price_list
    },
    async fetchPriceList(filters) {
      const date_range = filters?.date_range
      if (date_range) {
        // From the date_range selector, we receive [{valid_from, valid_to}, ...] for every selected date range
        const promises = []
        const date_ranges = formatDateFilter(date_range)
        // For every date range, create a promise that fetches the data
        date_ranges.forEach((valid_date_range) => {
          promises.push(this.api_call(this.getQuery(), { ...valid_date_range }))
        })
        // Await all promises and merge the results
        const responses = await Promise.all(promises)
        return this.filterPriceListDuplicates(responses)
      }
    },
    cell(key) {
      return 'cell(' + key + ')'
    },
    getMutation() {
      return MutatePriceListsDocument
    },
    mutatePriceLists(items) {
      const mutation_input = items.map((row) => this.parametersFromRow(row))
      return this.api_call_feedback(this.getMutation(), { input: mutation_input }).then((response) => {
        let errors = []
        if (response.data.errors) {
          errors = errors.concat(response.data.errors.map((error) => JSON.parse(error)))
        }
        if (response.data.data.MutatePriceLists.errors?.length) {
          errors = errors.concat(response.data.data.MutatePriceLists.errors.map((error) => JSON.parse(error)))
        }
        const fields = response.data.data ? response.data.data.MutatePriceLists.price_lists : []
        return { errors: errors, successful: fields.map((item) => this.parsePriceListNode(item)) }
      })
    },
    parametersFromRow(row) {
      if (!row.new_default_price) {
        const error_string = `${row.ItemCode}: default price cannot be empty`
        this.showToast(error_string, VARIANT.DANGER)
        throw error_string
      }
      return {
        id: row.id,
        item: row.ItemCode,
        default_price: row.new_default_price.toFixed(this.getCurrencyDecimals('new_default_price', row)),
        medium_price: row.new_medium_price?.toFixed(this.getCurrencyDecimals('new_medium_price', row)),
        best_price: row.new_best_price?.toFixed(this.getCurrencyDecimals('new_best_price', row)),
        valid_from: row.valid_from,
        valid_to: row.valid_to,
      }
    },
    keydown(event, key, index) {
      const switchFocus = (ref) => {
        this.$refs[ref][0].$children[0].$refs.input.focus()
      }

      switch (event.key) {
        case 'ArrowDown':
          index < this.items.length - 1 && switchFocus(key + (index + 1))
          break
        case 'ArrowUp':
          index > 0 && switchFocus(key + (index - 1))
          break
      }
    },
  },
}
</script>
<style scoped></style>
