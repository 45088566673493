<template>
  <b-container :fluid="fluid">
    <b-row>
      <b-col cols="4" class="d-flex justify-content-center align-items-start">
        <b-img left :src="require('../../assets/auth/duo-security.png')" width="100" />
      </b-col>
      <b-col>
        <p v-if="secret">Please confirm your sign up with the Duo Security app after clicking submit.</p>
        <p v-else>Please confirm your login with the Duo Security app.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-center">
          <div v-if="isSubmitting" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>

          <p v-if="error">
            <b-icon icon="exclamation-triangle-fill" variant="danger" />
            {{ error }}
          </p>
        </div>

        <div v-if="secret || error" class="d-flex justify-content-end">
          <b-button :disabled="isSubmitting" variant="secondary" @click="onSubmit">
            {{ secret ? 'Submit' : 'Try again' }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    secret: {
      type: String as PropType<string | null>,
      default: null,
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  emits: ['submit'],
  data() {
    return {
      isSubmitting: false,
    }
  },
  watch: {
    error(newError) {
      if (newError) {
        this.isSubmitting = false
      }
    },
  },
  mounted() {
    if (!this.secret) {
      this.onSubmit()
    }
  },
  methods: {
    onSubmit() {
      this.isSubmitting = true
      this.$emit('submit')
    },
  },
})
</script>
