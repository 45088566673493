<template>
  <div>
    <template v-if="!floatable">
      <b-tab :key="title" :title="title" :title-link-attributes="{ 'data-tab': tabKey }">
        <div :data-tab-pane="tabKey">
          <slot />
        </div>
      </b-tab>
    </template>
    <template v-else>
      <portal v-if="!tabVisible" :to="selector">
        <b-card class="my-3">
          <div>
            <h5>
              {{ title }}
              <span v-b-tooltip.hover title="Move this tab down">
                <b-icon icon="arrow-down-circle" style="cursor: pointer" scale="0.8" @click="switchTab" />
              </span>
            </h5>
          </div>
          <div :data-tab-pane="tabKey">
            <slot />
          </div>
        </b-card>
      </portal>
      <b-tab :key="title" :title-link-attributes="{ 'data-tab': tabKey }" :disabled="!tabVisible">
        <template #title>
          {{ title }}
          <span v-if="tabVisible" v-b-tooltip.hover title="Move this tab up" class="tab-arrow">
            <b-icon icon="arrow-up-circle" scale="0.8" @click="switchTab" />
          </span>
          <span v-else v-b-tooltip.hover title="Move this tab down" class="tab-arrow text-l-pink">
            <b-icon icon="arrow-down-circle" scale="0.8" @click="switchTab" />
          </span>
        </template>
        <div :data-tab-pane="tabKey">
          <slot />
        </div>
      </b-tab>
    </template>
  </div>
</template>
<script lang="ts">
import { Portal } from 'portal-vue'

/**
 * Wrapper around a tab of a table, allowing it to be extracted and float above the table.
 *
 * Warning: The current implementation re-initializes components when the tab is moved up or down, losing data of the slot components.
 */
export default {
  name: 'TableTab',
  components: { Portal },
  props: {
    floatable: {
      required: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    selector: {
      type: String,
      default: '',
    },
  },
  emits: ['floatChanged'],
  data() {
    return {
      tabVisible: true,
    }
  },
  computed: {
    tabKey() {
      return this.title.toLowerCase()
    },
  },
  methods: {
    switchTab() {
      this.tabVisible = !this.tabVisible
      this.$emit('floatChanged')
    },
  },
}
</script>
<style scoped>
.tab-arrow {
  cursor: pointer !important;
  pointer-events: auto;
}
</style>
