<template>
  <div>
    <h3>Backorder Page</h3>
    <p>Upload file: Entry to upload backorder list file to be automatically parsed.</p>
    <p>Magic filter: Searches through the entire table for mentions of the search value.</p>
    <h3>Process</h3>
    <p>
      If you want to automatically process the backorders of Coulisse upload a file in the upper-section of the webapp
      in the entry that looks like this:
    </p>
    <b-container class="con">
      <b-row>
        <b-col cols="2"><b>Upload file</b></b-col>
        <b-col cols="8">
          <b-form-file :disabled="true" placeholder="Choose a file or drop it here..."></b-form-file>
        </b-col>
      </b-row>
    </b-container>
    <br />
    <p>
      It will make an attempt to parse the .xlsx file. If this fails; check the Common errors section below.
      <br />
      If it succeeds: all entries from the Excel-file will be in the webapp.
      <br />
      <b-button variant="warning">Generate Mutations</b-button> will process all entries that can be automatically
      processed. Some entries will be left with a comment describing why it could not be automatically pushed to Exact.
      <br />Use these comments to manually enter the backorder in Exact, and delete the entry from the webapp. When
      there are no more entries left, you are done with the backorders for this file!
    </p>

    <h4>Common errors</h4>
    <p>
      These are some common errors when using this page in the webapp. Consult these first when encountering an error.
    </p>
    <ul>
      <li>
        If Coulisse changes their format; the parser will not work anymore. Compare the newest backorder list with a
        list from which you know it works, do they have the same structure? If not: Please let us know at the email
        address mentioned below!
      </li>
      <li>
        Unfilled Entries; if there are empty cells in the Excel-file, you should manually edit those before trying to
        upload the file again. The system will error on empty cells.
      </li>
      <li>
        Incorrectly formatted data; Similar to the first point in the list. If Coulisse changes the structure of certain
        data (e.g. changing a number in Excel to text) the parser will not be able to read the file.
      </li>
    </ul>
    <h3>Filters</h3>
    <p>Here we explain how th different filters under the 'filter' tab work.</p>
    <p>
      In the filter tab you can select a specific column to filter on. Depending on the column you get the choice
      between a couple of rules. This is either (=, &lt;, ≤, &gt; and ≥) or (in, not in, contains and =). Underneath we
      explain what every rule does:
    </p>
    <b-table :fields="filters.fields" :items="filters.items"> </b-table>
    <br />
    <h3>No answer?</h3>
    <p>
      <strong>If none of this answers your question, please contact {{ support_address }}.</strong>
    </p>
  </div>
</template>

<script lang="ts">
const FILTERS = {
  fields: [{ key: 'rule' }, { key: 'description' }],
  items: [
    {
      rule: 'in',
      description: 'Include, you can select what to include in the drop-down at the filter value',
    },
    {
      rule: 'not in',
      description: 'Do not include, you can select what not to include in the drop-down at the filter value',
    },
    {
      rule: 'contains',
      description:
        'the filter value can be found somewhere in the column value, does not have to be 1 on 1. ' +
        '(For example contains BASE includes BASE and BASEPERLA.)',
    },
    {
      rule: '=',
      description: 'Is exactly the same as [Filter value(s)]',
    },
    {
      rule: '<',
      description: 'Is smaller than [Filter value(s)]',
    },
    {
      rule: '≤',
      description: 'Is smaller than or the same as [Filter value(s)]',
    },
    {
      rule: '>',
      description: 'Is bigger than [Filter value(s)]',
    },
    {
      rule: '≥',
      description: 'Is bigger than or the same as [Filter value(s)]',
    },
  ],
}
export default {
  name: 'BackorderHelp',
  data() {
    return {
      filters: FILTERS,
      support_address: import.meta.env.VITE_SUPPORT_ADDRESS,
    }
  },
}
</script>
