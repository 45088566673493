import { createRouter } from 'innicore/router'
import UnderConstruction from 'innicore/views/core/UnderConstruction.vue'

import Devs from '@/components/Devs.vue'
import AuthLogin from '@/views/auth/AuthLogin.vue'
import AuthProfile from '@/views/auth/AuthProfile.vue'
import AuthSignUp from '@/views/auth/AuthSignUp.vue'
import BackorderPage from '@/views/backorder/BackorderPage.vue'
import Collection from '@/views/collection/Collection.vue'
import CollectionImporter from '@/views/collection/CollectionImporter.vue'
import CompanyItem from '@/views/company_items/CompanyItems.vue'
import Help from '@/views/core/Help.vue'
import Home from '@/views/core/Home.vue'
import CompanyForecastOverview from '@/views/forecast-prototype/CompanyForecastOverview.vue'
import CompanyItemForecastOverview from '@/views/forecast-prototype/CompanyItemForecastOverview.vue'
import ItemForecastOverview from '@/views/forecast-prototype/ItemForecastOverview.vue'
import ForecastHistory from '@/views/forecast/ForecastHistory.vue'
import ForecastPage from '@/views/forecast/ForecastPage.vue'
import IncomingGoodsLegacy from '@/views/incoming_goods/IncomingGoodsPageLegacy.vue'
import IncomingGoodsNew from '@/views/incoming_goods/IncomingGoodsPageNew.vue'
import QualityAssurance from '@/views/incoming_goods/QualityAssurance.vue'
import QualityCheck from '@/views/incoming_goods/QualityCheck.vue'
import ShipmentOverviewPage from '@/views/incoming_goods/ShipmentOverviewPage.vue'
import MaxxisampleOrderHistory from '@/views/maxxisample/MaxxisampleOrderHistory.vue'
import MaxxisampleOrderPage from '@/views/maxxisample/MaxxisampleOrderPage.vue'
import EDI from '@/views/order/EDI.vue'
import OrderHistory from '@/views/order/OrderHistory.vue'
import OrderPage from '@/views/order/OrderPage.vue'
import OrderWithFile from '@/views/order/OrderWithFile.vue'
import PurchaseOrderPage from '@/views/order/PurchaseOrderPage.vue'
import PriceAgreements from '@/views/price_agreements/PriceAgreements.vue'
import PriceList from '@/views/price_list/PriceList.vue'
import WorkflowOverview from '@/views/workflows/WorkflowOverview.vue'
import OrderWorkflowOrder from '@/views/workflows/order/OrderWorkflowOrder.vue'
import OrderWorkflowOverview from '@/views/workflows/order/OrderWorkflowOverview.vue'

export const router = createRouter([
  { path: '/', name: 'Home', component: Home },
  { path: '/backorder', name: 'Backorder', component: BackorderPage, meta: { allowedGroups: ['purchase'] } },
  { path: '/collection', name: 'Collection', component: Collection },
  { path: '/collection/lienesch', name: 'LieneschCollection', component: UnderConstruction },
  { path: '/company-items', name: 'CompanyItems', component: CompanyItem },
  { path: '/devs', name: 'Developers', component: Devs },
  { path: '/forecast', name: 'Forecast', component: ForecastPage, meta: { allowedGroups: ['sales'] } },
  {
    path: '/forecast/history',
    name: 'ForecastHistory',
    component: ForecastHistory,
    meta: { allowedGroups: ['sales'] },
  },
  { path: '/help', name: 'Help', component: Help },
  { path: '/help/order-template', name: 'HelpOrderTemplate', component: OrderWithFile },
  { path: '/help/edi', name: 'HelpEDI', component: EDI },
  {
    path: '/import/collection',
    name: 'CollectionImporter',
    component: CollectionImporter,
    meta: { allowedGroups: ['sales'] },
  },
  {
    path: '/incoming-goods/add',
    name: 'IncomingGoodsLegacy',
    component: IncomingGoodsLegacy,
    meta: { allowedGroups: ['logistics'] },
  },
  {
    path: '/incoming-goods/check',
    name: 'IncomingGoodsNew',
    component: IncomingGoodsNew,
    meta: { allowedGroups: ['logistics'] },
  },
  {
    path: '/incoming-goods/quality-assurance',
    name: 'IncomingGoodsQualityAssurance',
    component: QualityAssurance,
    meta: { allowedGroups: ['quality'] },
  },
  {
    path: '/incoming-goods/quality-check',
    name: 'IncomingGoodsQualityCheck',
    component: QualityCheck,
    meta: { allowedGroups: ['quality'] },
  },
  {
    path: '/incoming-goods/shipment-overview',
    name: 'ShipmentOverview',
    component: ShipmentOverviewPage,
    meta: { allowedGroups: ['logistics'] },
  },
  { path: '/login', name: 'Login', component: AuthLogin },
  {
    path: '/maxxisample',
    name: 'MaxxisampleOrderHistory',
    component: MaxxisampleOrderHistory,
    meta: { isEmployeeOnly: true },
  },
  {
    path: '/maxxisample/order',
    name: 'MaxxisampleOrder',
    component: MaxxisampleOrderPage,
    meta: { isEmployeeOnly: true },
  },
  {
    path: '/maxxisample/order/:order_id',
    name: 'ViewMaxxisampleOrder',
    component: MaxxisampleOrderPage,
    meta: { isEmployeeOnly: true },
  },
  { path: '/order', name: 'Place order', component: OrderPage },
  { path: '/order/:order_id', name: 'ViewOrder', component: OrderPage },
  { path: '/orders', name: 'OrderHistory', component: OrderHistory },
  {
    path: '/price-agreements',
    name: 'PriceAgreements',
    component: PriceAgreements,
    meta: { allowedGroups: ['sales'] },
  },
  { path: '/price-list', name: 'PriceList', component: PriceList, meta: { allowedGroups: ['sales'] } },
  {
    path: '/purchase-order',
    name: 'PurchaseOrder',
    component: PurchaseOrderPage,
    meta: { allowedGroups: ['purchase'] },
  },
  {
    path: '/purchase-order/:order_id',
    name: 'ViewPurchaseOrder',
    component: PurchaseOrderPage,
    meta: { allowedGroups: ['purchase'] },
  },
  { path: '/profile', name: 'Profile', component: AuthProfile },
  { path: '/settings', name: 'settings', component: UnderConstruction },
  { path: '/signup', name: 'Signup', component: AuthSignUp },
  { path: '/signup/:token', name: 'SignupToken', component: AuthSignUp },
  { path: '/track', name: 'TrackOrder', component: UnderConstruction },

  { path: '/workflows', name: 'Workflows', component: WorkflowOverview },
  { path: '/workflows/order', name: 'OrderWorkflows', component: OrderWorkflowOverview },
  { path: '/workflows/:id/order', name: 'WorkflowOrderEntry', component: OrderWorkflowOrder },

  // New forecast prototype
  { path: '/prototype/forecast', name: 'company-item-forecast', component: CompanyItemForecastOverview },
  { path: '/prototype/forecast/company', name: 'company-forecast', component: CompanyForecastOverview },
  { path: '/prototype/forecast/item', name: 'item-forecast', component: ItemForecastOverview },
])
