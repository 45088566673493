<template>
  <div>
    <OrderWorkflowFile v-if="workflowLine.containsOrderFile" />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

// import { WorkflowLine } from '@/views/workflows/WorkflowLine'
import OrderWorkflowFile from '@/views/workflows/order/OrderWorkflowFile.vue'

const workflowLine = inject('workflowLine')
</script>
