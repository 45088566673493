<template>
  <b-modal :id="name" centered size="lg" :title="title" @ok="handleOK" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <b-col cols="2">
          <b>Item code</b>
        </b-col>
        <b-col>
          <item-selector v-model="item" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b>Your item code</b>
        </b-col>
        <b-col>
          <b-form-input v-model="ItemCodeAccount" :state="ItemCodeAccount_is_valid"></b-form-input>
          <b-form-invalid-feedback :state="ItemCodeAccount_is_valid"
            >Your item code should be between 1 and 30 characters long!</b-form-invalid-feedback
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b>Collection name</b>
        </b-col>
        <b-col>
          <b-form-input v-model="CollectionName"></b-form-input>
        </b-col>
      </b-row>
      <b-row v-if="$store.state.user.is_employee">
        <b-col cols="2">
          <b>Collection start</b>
        </b-col>
        <b-col>
          <b-form-input v-model="CollectionStart" type="date" :state="CollectionStart ? true : null"></b-form-input>
        </b-col>
      </b-row>
      <b-row v-if="$store.state.user.is_employee">
        <b-col cols="2">
          <b>Collection end</b>
        </b-col>
        <b-col>
          <b-form-input v-model="CollectionEnd" type="date" :state="CollectionEnd ? true : null"></b-form-input>
        </b-col>
      </b-row>
      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">{{
              error
            }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  props: {
    callback: Function,
    name: String,
    AccountCode: String,
    itemcode: String,
    title: String,
    mutate: Function,
  },
  data() {
    return {
      item: null,
      ItemCodeAccount: null,
      CollectionName: null,
      CollectionStart: null,
      CollectionEnd: null,
      Description: null,
      id: null,
      errors: [],
    }
  },
  computed: {
    ItemCode() {
      return this.itemcode ? this.itemcode : this.item ? this.item.ItemCode : null
    },
    modal_row() {
      return {
        AccountCode: this.AccountCode,
        ItemCode: this.ItemCode,
        ItemCodeAccount: this.ItemCodeAccount,
        CollectionName: this.CollectionName,
        CollectionStart: this.CollectionStart,
        CollectionEnd: this.CollectionEnd,
        Description: this.Description,
        id: this.id,
      }
    },
    ItemCodeAccount_is_valid() {
      return !!this.ItemCodeAccount && this.ItemCodeAccount.length <= 30
    },
    modal_row_is_valid() {
      return this.ItemCode && this.ItemCodeAccount_is_valid
    },
  },
  methods: {
    handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.modal_row_is_valid) {
        const asyncFunction = async () => {
          const response = await this.mutate(this.modal_row)
          if (response.errors.length) {
            response.errors.forEach((error) => {
              if (error.message.toLowerCase().includes('unique')) {
                this.errors.push('This customer already has a collection for this item.')
              } else if (
                error.message.includes(
                  '[ODBC Driver 18 for SQL Server]Connection is busy with results for another command (0)'
                )
              ) {
                // This is needed because Lienesch db throws this error randomly
                this.errors.push(
                  'De SQL server doet dit soms omdat het oud is, maar 9 van de 10 keer is het wel goed uitgevoerd. Sluit dit schermpje en refresh de table. Groetjes de jongens van Heinen-IT'
                )
              } else {
                this.errors.push(error.message)
              }
            })
          } else {
            this.Description = this.$store.state.lienesch_items.find(
              (item) => item.ItemCode === this.ItemCode
            ).Description
            this.callback(response.successful[0])
            this.$nextTick(() => this.$bvModal.hide(bvModalEvent.componentId))
          }
        }
        asyncFunction()
      }
    },
    resetModal() {
      this.item = null
      this.ItemCodeAccount = null
      this.CollectionName = null
      this.CollectionStart = null
      this.CollectionEnd = null
      this.Description = null
      this.id = null
      this.errors = []
    },
  },
}
</script>
