<script lang="ts">
import Forecast from 'innicore/views/forecast/Forecast.vue'

import { FetchForecastDocument, MutateRollingForecastsDocument } from '@/graphql/generated'

export default {
  extends: Forecast,
  methods: {
    getQuery() {
      return FetchForecastDocument
    },
    getMutation() {
      return MutateRollingForecastsDocument
    },
    parseRollingFC(obj) {
      const rollingFC = this.defaultParseRollingFC(obj)
      return {
        ...rollingFC,
        ItemCodeAccount: obj.CollectionNode?.ItemCodeAccount,
        CollectionEnd: obj.CollectionNode?.CollectionEnd,
        CollectionName: obj.CollectionNode?.CollectionName,
        CollectionStart: obj.CollectionNode?.CollectionStart,
      }
    },
  },
}
</script>
