<template>
  <div>
    <b-container v-if="fields.length > 0" class="con">
      <b-row>
        <b-col cols="12">
          <h4>Bulk edit</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3"><b>Column</b></b-col>
        <b-col cols="2"><b>Edit rule</b></b-col>
        <b-col cols="4"><b>Value</b></b-col>
      </b-row>
      <table-tab-actions-bulk-edit-field
        v-for="field in fields"
        :key="field.key"
        :disabled="disabled"
        :field="field"
        @editField="(rule, value) => $emit('editField', field.key, rule, value)"
      />
    </b-container>
  </div>
</template>

<script lang="ts">
import TableTabActionsBulkEditField from 'innicore/components/table/TableTabActionsBulkEditField.vue'

export default {
  name: 'TableTabActionsBulkEdit',
  components: { TableTabActionsBulkEditField },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['editField'],
}
</script>
