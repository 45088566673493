<template>
  <b-modal :id="name" centered size="l" :title="title" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <b-col cols="4">
          <b>Price list valid on date: </b>
        </b-col>
        <b-col cols="8">
          <typed-form-input v-model="price_list_valid_on" :type="Date" />
        </b-col>
      </b-row>

      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
              {{ error }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="{ cancel, ok }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>
      <hit-button variant="primary" :click="() => getData(ok)"> OK </hit-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { FetchPriceListEssentialsDocument, ItemCondition } from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { parseItem } from '@/common/parseItem'

export default {
  mixins: [api_mixin, utils],
  props: {
    value: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    callback: {
      type: Function,
      require: true,
    },
    items: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      errors: [],
      price_list_valid_on: this.value,
    }
  },
  comoputed: {
    new_price_list_valid_on: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:price_list_valid_on', val)
      },
    },
  },
  methods: {
    getQuery() {
      return FetchPriceListEssentialsDocument
    },
    async getData(okFunction) {
      const response = await this.api_call(this.getQuery(), {
        item__ItemCode__in: this.items
          .filter((item) => [ItemCondition.ACTIVE, ItemCondition.FUTURE].includes(item.Condition))
          .map((item) => item.ItemCode),
        valid_on: this.price_list_valid_on,
      })
      const data = response.data.data.price_list.edges.map((obj) => this.parsePriceListNode(obj.node))
      data.forEach((item) => {
        item.agreed_price = this.items.find((selection) => item.ItemCode == selection.ItemCode).agreed_price
      })
      this.callback(data)
      okFunction()
    },
    resetModal() {
      this.errors = []
    },
    parsePriceListNode(node) {
      const item = parseItem(node.item)
      return {
        ...item,
        default_price: node.default_price,
        medium_price: node.medium_price,
        best_price: node.best_price,
        valid_from: node.valid_from,
        valid_to: node.valid_to,
      }
    },
  },
}
</script>
