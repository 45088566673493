<script lang="ts">
import AddToPriceAgreementsModal from 'innicore/views/price-agreements/AddToPriceAgreementsModal.vue'

import { FetchPriceListItemsDocument } from '../../graphql/generated'

export default {
  extends: AddToPriceAgreementsModal,
  methods: {
    getQuery() {
      return FetchPriceListItemsDocument
    },
  },
}
</script>
