<template>
  <div>
    <h1>Maxxisample order history</h1>
    <p>Here you can view all Maxxisample orders.</p>
    <i-table
      ref="ITable"
      :fetch-items="fetchItems"
      :fields="fields"
      :allowed-selectors="allowed_selectors"
      :actions="actions"
      app-name="maxxisample_order_history"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #cell(order_id)="row">
        <router-link :to="{ name: 'ViewMaxxisampleOrder', params: { order_id: row.item.order_id } }">{{
          row.item.order_id
        }}</router-link>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { MaxxisampleOrdersDocument } from '@/graphql/generated'
import { HITOrder } from '@/views/order/types'

export default {
  mixins: [api_mixin, utils],
  data() {
    return {
      actions: [{ key: 'export_to_excel', disallow: true }],
      select_by: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'status', label: 'Order status', sortable: true },
        { key: 'order_id', label: 'Order number', sortable: true },
        { key: 'reference', label: 'Your reference', sortable: true },
        { key: 'payment', label: 'Euros', sortable: true, formatter: (num) => '€ ' + this.format_num(num) },
        { key: 'order_date', label: 'Order date', sortable: true },
        ...(this.select_by?.customer || this.$store.state.user.is_customer
          ? []
          : [{ key: 'ordby', label: 'Ordered by', sortable: true }]),
      ]
    },
    allowed_selectors() {
      return this.$store.state.user.is_employee ? [['customer']] : []
    },
  },
  methods: {
    async fetchItems(filters) {
      const customer = !this.$store.state.user.is_customer ? filters?.customer : this.$store.state.company
      const response = await this.api_call(MaxxisampleOrdersDocument, { company: customer?.cmp_wwn })
      const data = response.data.data
      const hitOrders = data.Order.edges.map((edge) => new HITOrder({ ...edge.node, hitOrderLines: [] }))
      hitOrders.forEach((order) => {
        order._cellVariants = { status: this.status_color(order.status) }
        order.ordby = order.company.cmp_name
      })
      return hitOrders
    },
  },
}
</script>
