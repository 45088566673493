<template>
  <b-container id="incoming_goods_check_input" class="con">
    <b-form>
      <div v-if="entering_data">
        <b-form-group :label="'Input'" label-cols="2" content-cols="8">
          <b-form-input v-model="input" type="text" @keydown.enter.prevent="emit_scan" />
        </b-form-group>
      </div>
      <div v-else>
        <b-form-group label="Scan barcode" label-cols="2" content-cols="8">
          <b-button @click="startInput()">Scan & Aanpassen</b-button>
        </b-form-group>
      </div>
    </b-form>
  </b-container>
</template>
<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import utils from 'innicore/mixins/utils.js'

import ParseBarcodeMixin from '@/views/incoming_goods/ParseBarcodeMixin.ts'

export default {
  name: 'IncomingGoodsCheckInput',
  mixins: [ParseBarcodeMixin, utils],
  props: {
    tableMode: {
      type: Number,
      default: 0,
    },
    enterEditFunction: {
      type: Function,
      default: () => {
        return
      },
    },
  },
  data() {
    return {
      input: '',
    }
  },
  computed: {
    entering_data() {
      return this.tableMode == TABLE_MODE.WRITE
    },
  },
  methods: {
    reset() {
      this.input = ''
    },
    async startInput() {
      await this.enterEditFunction()
    },
    emit_scan() {
      const parsedBarcode = this.prepareScanIncomingGood(this.input)
      if (parsedBarcode) this.$emit('update:barcode', parsedBarcode)
      this.reset()
    },
    findBaseItem(attribute, value) {
      return this.$store.getters.getAugmentedItemByAttribute(attribute, value)
    },
  },
}
</script>
