<template>
  <div>
    <h3>Forecast general</h3>
    <p>
      Forecast date: Starting moment of the forecast. All the data of before this date is taken into account for the
      forecast of a year successing the date. A new forecast is created every month.
    </p>
    <p>Magic filter: Searches through the entire table for mentions of the search value.</p>
    <b-table :fields="forecastGeneral.fields" :items="forecastGeneral.items"> </b-table>
    <h3>Buttons navigation main table</h3>
    <p>
      The forecasts can be adapted and filtered in many ways, underneath is explained for every button what the
      functionality is:
    </p>
    <!-- Show info button -->
    <b-button variant="info" @click="show_info = !show_info">
      <b-icon icon="question-circle" aria-hidden="true" /> Show button explanations
    </b-button>
    <br />
    <br />
    <b-table v-if="show_info" :fields="buttonInfo.fields" :items="buttonInfo.items">
      <template #cell(button)="row">
        <b-button :variant="row.item.variant">
          <b-icon :icon="row.item.button" />
        </b-button>
      </template>
    </b-table>
    <h3>Actions</h3>
    <p>Here we explain how the different Actions under the 'Actions' tab work.</p>
    <b-table :fields="actions.fields" :items="actions.items">
      <template #cell(button)="row">
        <b-button :variant="row.item.variant"> <b-icon :icon="row.item.button" /> {{ row.item.name }} </b-button>
      </template>
    </b-table>
    <h3>Filters</h3>
    <p>Here we explain how th different filters under the 'filter' tab work.</p>
    <p>
      In the filter tab you can select a specific column to filter on. Depending on the column you get the choice
      between a couple of rules. This is either (=, &lt;, ≤, &gt; and ≥) or (in, not in, contains and =). Underneath we
      explain what every rule does:
    </p>
    <b-table :fields="filters.fields" :items="filters.items"> </b-table>
    <br />
    <h3>No answer?</h3>
    <p><strong>If none of this answers your question, please contact support@innico.nl.</strong></p>
  </div>
</template>

<script lang="ts">
const FORECAST_GENERAL = {
  items: [
    {
      type: 'Machine learning (ML)',
      description:
        'Machine learning is applied to the history of data to make an accurate prediction of what the future data will look like (the forecast)',
    },
    {
      type: 'Linear regression',
      description:
        'With the data available from the last three years, an expected growth of decrease is computed. This change in then added to the data of the past year to make a prediction for how the coming year will look like.',
    },
    {
      type: 'Manual forecast',
      description:
        'A new forecast can be made or a prediction can be changed by an user. The user might have information about external influences not taken into account by the linear regression. The user can then set a certain forecast themselves.',
    },
  ],
  fields: [{ key: 'type' }, { key: 'description' }],
}
const BUTTON_INFO = {
  fields: [{ key: 'button', class: 'tight-column' }, { key: 'description' }],
  items: [
    {
      button: 'calendar2-month',
      variant: 'b-blue',
      description: 'Click this button to uncover more extended information about the forecast',
    },
    {
      button: 'clock-history',
      variant: 'violet',
      description:
        'Click this button to open a new seperate tab with the farecast history of this specific item and company',
    },
    {
      button: 'pencil',
      variant: 'primary',
      description: 'Click this button to edit the monthly forecast.',
    },
    {
      button: 'x-circle',
      variant: 'danger',
      description:
        'Click this button the cancel all made changes to the monthly forecast. All non saved changes will be lost.',
    },
    {
      button: 'check-circle',
      variant: 'success',
      description:
        'Click this button to save the changes made to the monthly forecast. If the total rolling forecast has changed, it will be updated accordingly.',
    },
    {
      button: 'graph-up',
      variant: 'violet',
      description: 'Click this button to toggle the visibility of the info graphic.',
    },
    {
      button: 'question-circle',
      variant: 'info',
      description: 'Click this button to toggle the visibility of the info table.',
    },
  ],
}
const ACTIONS = {
  fields: [{ key: 'button' }, { key: 'description' }],
  items: [
    {
      button: 'calendar2-month',
      variant: 'b-blue disabled',
      name: 'Toggle monthly details',
      description: 'Click this button to uncover more extended information about the forecast for the selected rows',
    },
    {
      button: 'box-arrow-up-right',
      variant: 'secondary disabled',
      name: 'Export to excel',
      description: 'Click this button to export the data from the selected rows to excel',
    },
    {
      button: 'plus',
      variant: 'success disabled',
      name: 'Add data',
      description: 'Click here to add bulk data to the table',
    },
    {
      button: 'clock-history',
      variant: 'violet disabled',
      name: 'Show forecast history',
      description: 'Click this button to open a new seperate tab with the farecast history of the selected items',
    },
    {
      button: 'arrow-repeat',
      variant: 'success disabled',
      name: 'Refresh ML forecast',
      description: 'Click this button to refresh the ML forecast for the selected rows',
    },
  ],
}
const FILTERS = {
  fields: [{ key: 'rule' }, { key: 'description' }],
  items: [
    {
      rule: 'in',
      description: 'Include, you can select what to include in the drop-down at the filter value',
    },
    {
      rule: 'not in',
      description: 'Do not include, you can select what not to include in the drop-down at the filter value',
    },
    {
      rule: 'contains',
      description:
        'the filter value can be found somewhere in the column value, does not have to be 1 on 1. (For example contains BASE includes BASE and BASEPERLA.)',
    },
    {
      rule: '=',
      description: 'Is exactly the same as [Filter value(s)]',
    },
    {
      rule: '<',
      description: 'Is smaller than [Filter value(s)]',
    },
    {
      rule: '≤',
      description: 'Is smaller than or the same as [Filter value(s)]',
    },
    {
      rule: '>',
      description: 'Is bigger than [Filter value(s)]',
    },
    {
      rule: '≥',
      description: 'Is bigger than or the same as [Filter value(s)]',
    },
  ],
}
export default {
  name: 'ForecastHelp',
  data() {
    return {
      show_info: false,
      forecastGeneral: FORECAST_GENERAL,
      buttonInfo: BUTTON_INFO,
      actions: ACTIONS,
      filters: FILTERS,
    }
  },
}
</script>
