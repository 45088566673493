<template>
  <generic-selector
    v-model="rolling_fc_date"
    :data="rolling_fc_dates"
    :disabled="disabled"
    :serializer="serialize_rolling_fc_date"
    :reset-confirmation-prompt="resetConfirmationPrompt"
    invalid-feedback-message="This rolling forecast date does not exist!"
    placeholder="Enter a rolling forecast date..."
  />
</template>

<script lang="ts">
import utils from 'innicore/mixins/utils'

export default {
  mixins: [utils],
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
  },
  computed: {
    default_value() {
      // todo: @matteo @niek talk about the possibility of this giving an error when not in options, and how to handle
      return this.get_current_fc_date()
    },
    rolling_fc_dates() {
      const rolling_fc_dates = [...this.$store.state.rolling_fc_dates]
      rolling_fc_dates.sort()
      rolling_fc_dates.reverse()
      return rolling_fc_dates
    },
    rolling_fc_date: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchRollingFCDates')
    // Only overwrite the value if it is not set yet.
    this.rolling_fc_date = this.value ? this.value.rolling_fc_date : this.default_value
  },
  methods: {
    serialize_rolling_fc_date(rolling_fc_date) {
      return rolling_fc_date ? this.format_fc_date(rolling_fc_date) : ''
    },
  },
}
</script>

<style scoped></style>
