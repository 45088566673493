import Vue, { computed } from 'vue'

import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'

export interface Field {
  key: string
  label: string
  sortable?: boolean
  class?: string
  optional?: boolean
  editable?: boolean
  type?: (value: unknown) => unknown
  checkbox_label?: string
  group?: string
  selected?: boolean
  visible?: boolean
  formatter?: (value: unknown) => unknown
  insertBefore?: string
}

export default Vue.extend({
  provide() {
    return {
      fields: computed(() => this.fields),
      allowedFields: computed(() => this.allowedFields),
      editableFields: computed(() => this.editableFields),
      selectedFields: computed(() => this.selectedFields),
      tableFields: computed(() => this.tableFields),
    }
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // Fields that the user is allowed to see
    allowedFields() {
      return this.fields.filter((f: Field) => this.fieldPassesPermissions(f))
    },

    // Fields that can be edited by the user
    //TODO See if this fits better in a 'TableEditMixin'
    editableFields() {
      if ([TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD].includes(this.tableMode)) {
        return this.allowedFields
          .filter((f: Field) => f.editable)
          .map((f: Field) => (f.type ? f : { ...f, type: String }))
      } else {
        return []
      }
    },
    selectedFields() {
      return this.allowedFields.filter((f: Field) => f.selected).map((f: Field) => f.key)
    },

    tableFields(): Array<Field> {
      const table_fields: Array<Field> = [{ key: '_select', label: '', class: 'fancytable-select' }]
      if (this.sortedItems.filter((item) => item._icons).length > 0) {
        // Is this column icons can be displayed to show certain status of rows
        table_fields.unshift({ key: '_icons', label: '', class: 'actions-column', sortable: true })
      }
      for (const i in this.fields) {
        const field = this.fields[i]
        if (field.optional && !this.selectedFields.includes(field.key)) {
          continue
        }
        if (field.visible === false) {
          continue
        }
        if (field.formatter === undefined && field.type === Boolean && !field.editable) {
          field.formatter = (value) => (value ? 'Yes' : 'No')
        }
        if (field.formatter === undefined && field.type === Date) {
          field.formatter = (value) => value && new Date(value).toLocaleDateString()
        }
        table_fields.push(field)
      }
      if (
        this.allowed_row_actions &&
        this.allowed_row_actions.length > 0 &&
        !this.fields.map((f) => f.key).includes('actions')
      ) {
        table_fields.push({ key: 'actions', label: 'Actions' })
      }
      return table_fields
    },
    booleanSwitchFields(): Array<Field> {
      return this.fields.filter((f: Field) => f.type === Boolean && f.editable)
    },
  },
  methods: {
    fieldPassesPermissions(field) {
      if (field.permissions === undefined) {
        return true
      }
      return field.permissions.map((permission) => this.$store.state.user[permission]).reduce((a, b) => a && b)
    },
  },
})
