<template>
  <div id="app">
    <i-navbar />
    <i-sidebar v-if="isLoggedIn" />
    <b-container fluid>
      <b-row>
        <b-col>
          <router-view :key="$route.fullPath" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import INavbar from 'innicore/components/nav/INavbar.vue'

import ISidebar from '@/components/nav/ISidebar.vue'

const PUBLIC_PAGES = ['Login', 'Signup', 'SignupToken', 'ForgotPassword', 'ResetPassword', 'TemporarilyUnavailable']

export default {
  name: 'App',
  components: {
    INavbar,
    ISidebar,
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.logged_in
    },
  },
  watch: {
    $route() {
      this.check()
    },
    isLoggedIn() {
      this.check()
    },
  },
  mounted() {
    this.check()
    if (this.isLoggedIn) {
      this.$store.dispatch('refreshTokens')
    }
  },
  methods: {
    check() {
      if (!this.isLoggedIn && !PUBLIC_PAGES.includes(this.$route.name)) {
        this.$router.push({ name: 'Login' })
      }
    },
  },
}
</script>
