export class ExactOrder {
  order_id
  company
  order_date
  order_type
  requested_shipping_date
  shipping_date
  reference
  ordered_by
  payment
  status
  exactOrderLines: ExactOrderLine[] = [new ExactOrderLine(this)]
  unit
  status_text
  status_color

  constructor(orderObject = {}) {
    Object.assign(this, orderObject)
  }

  get id() {
    return this.order_id
  }

  get orderLines() {
    return this.exactOrderLines
  }

  toJSON() {
    return JSON.stringify(this.order_id)
  }
}

export class ExactOrderLine {
  id
  order: ExactOrder
  item
  order_quantity
  agreed_price
  payment
  requested_shipping_date
  shipping_date
  remark
  batch
  warehouse
  is_shipped = false

  _select = false

  constructor(order: ExactOrder, orderLineObject: Record<string, unknown> = {}) {
    Object.assign(this, orderLineObject)
    this.order = order
  }

  get order_id() {
    return this.order?.order_id
  }

  get ItemCode() {
    return this.item?.ItemCode
  }

  get Description() {
    return this.item?.Description
  }

  get ItemCodeAccount() {
    return this.item?.ItemCodeAccount
  }

  get order_unit() {
    return this.item.unit
  }
}
