export class OrderFile {
  id
  order
  url
  uploaded_by
  upload_date

  filename

  constructor(orderFileObject: Record<string, unknown> = {}) {
    Object.assign(this, orderFileObject)
  }

  get full_url() {
    return import.meta.env.VITE_BACKEND_ADDRESS + this.url
  }
}
