<script lang="ts">
import MonthlyForecast from 'innicore/views/forecast/MonthlyForecast.vue'

import { MonthlyForecastDataDocument, PreviewForecastDocument } from '@/graphql/generated'

export default {
  extends: MonthlyForecast,
  data() {
    return {
      leadtime: 0,
      leadtime_lock: false,
    }
  },
  computed: {
    headerTooltipTitles() {
      const titles = this.defaultHeaderTooltipTitles
      titles[
        'product_available'
      ] = `With a lead time of ${this.leadtime} days, this is the first date the product is available.`
      return titles
    },
  },
  methods: {
    getQuery() {
      return MonthlyForecastDataDocument
    },
    getPreviewQuery() {
      return PreviewForecastDocument
    },
    calculate_date_available() {
      // You can edit the monthly forecast after the leadtime.
      this.leadtime = this.item.leadtime
      return new Date(new Date().getTime() + this.leadtime * 24 * 3600 * 1000)
    },
    getDateLines() {
      return [
        {
          label: 'Lienesch end date',
          date: new Date(this.value.ud01),
          color: 'blue',
        },
        {
          label: 'Customer end date',
          date: new Date(this.value.CollectionEnd),
          color: 'red',
        },
      ]
    },
  },
}
</script>
