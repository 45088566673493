<template>
  <router-link v-if="enabled" :to="{ name: name }">
    <slot />
  </router-link>
</template>
<script lang="ts">
import { check_permissions } from 'innicore/router'

export default {
  name: 'IRouterLink',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    enabled() {
      return check_permissions(this.$router.resolve({ name: this.name }).route.meta, this.$store.state.user)
    },
  },
}
</script>
