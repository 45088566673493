export enum VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

export default {
  methods: {
    showToast(
      message: Text,
      variant: VARIANT,
      solid = true,
      noCloseButton = true,
      autoHideDelay = 15000,
      appendToast = true
    ) {
      this.$bvToast.toast(message, {
        solid: solid,
        noCloseButton: noCloseButton,
        toaster: 'b-toaster-top-right',
        variant: variant,
        autoHideDelay: autoHideDelay,
        appendToast: appendToast,
      })
    },
  },
}
