<template>
  <b-container :fluid="fluid">
    <b-row v-if="secret" class="mb-3">
      <b-col cols="4" class="d-flex justify-content-center align-items-start">
        <vue-qrcode :value="secret" style />
      </b-col>
      <b-col class="pt-3">
        <p>
          Install the Google Authenticator app on your phone, from the
          <a href="https://apps.apple.com/app/id388497605" target="_blank"> App Store</a> or
          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank"
            >Google Play</a
          >.
        </p>
        <p>
          After installing the app, open it and press the "+" in the corner of the screen. Scan the QR-code, and fill in
          the 6-digit 2FA token below to complete your sign up.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="d-flex justify-content-center align-items-start">
        <b-img left :src="require('../../assets/auth/google-authenticator.png')" width="100" />
      </b-col>
      <b-col>
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            :label="`Please enter your 6-digit Google Authenticator 2FA token.`"
            :invalid-feedback="error ?? 'This 2FA token is invalid.'"
          >
            <b-form-input v-model="code" :disabled="isSubmitting" :state="error ? false : null" type="text" autofocus />
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button :disabled="isSubmitting" variant="secondary" @click="onSubmit(code)">Submit</b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import VueQrcode from 'vue-qrcode'

export default defineComponent({
  components: {
    VueQrcode,
  },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    secret: {
      type: String as PropType<string | null>,
      default: null,
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  emits: ['submit', 'update:error'],
  data() {
    return {
      code: '',
      isSubmitting: false,
    }
  },
  watch: {
    code(newCode) {
      if (newCode.replace(/\D/, '').length === 6) {
        this.onSubmit(newCode)
      }
      if (this.error && newCode.length > 0) {
        this.$emit('update:error', undefined)
      }
    },
    error(newError) {
      if (newError && this.code.length === 6) {
        this.reset()
      }
    },
  },
  methods: {
    onSubmit(code?: string) {
      this.isSubmitting = true
      this.$emit('submit', (code ?? this.code).replace(/\D/, ''))
    },
    reset() {
      this.code = ''
      this.isSubmitting = false
    },
  },
})
</script>
