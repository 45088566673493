<template>
  <div style="width: 80vw">
    <h1>Electronic Data Interchange</h1>
    <p>
      At Lienesch we are willing provide our customers with EDI possibilities for all kinds of business processes. On
      this page an explanation is given on how to place an order through the Lienesch API. The possibilities for EDI are
      endless, for any questions or suggestions you can contact us at <support-email-address></support-email-address>.
    </p>

    <h2>The Lienesch API</h2>
    <p>
      To completely automate your business processes, let your applications talk to the Lienesch API hosted at
      <a :href="api">{{ api }}</a
      >. We make use of GraphQL, this means all API request can be send to the same endpoint. If you're unfamiliar with
      this concept and want to learn more about it, please refer to the
      <a href="https://graphql.org/learn/"> GraphQL documentation</a>.
    </p>

    <h2>Order entry</h2>
    <h4>Query</h4>
    <p>
      The query/mutation of a GraphQL request specifies what data to read, create, update or delete. For the order entry
      process you can use the query below.
    </p>

    <code>
      mutation CreateOrder($order: OrderInput!) { <br />
      &emsp;CreateOrder(order_input: $order) { <br />
      &emsp;&emsp;order { <br />
      &emsp;&emsp;&emsp;order_id <br />
      &emsp;&emsp;} <br />
      &emsp;} <br />
      } <br /> </code
    ><br />

    <h4>Query variables</h4>
    <p>
      The actual information of what to order is inside the query variables. To place the order, you need to parse the
      information into this JSON format.
    </p>
    <code v-html="query_variables" /><br />

    <h4>Request headers</h4>
    <p>
      The only thing we need to specify in the request header is the API token, this is used to verify the authenticity
      of your request. To obtain an API token, please contact us at <support-email-address></support-email-address>. You
      can also use an example token for development/debugging purposes, although this token will only be valid for a
      couple hours.
    </p>
    <b-form-checkbox v-model="show_example_token" switch> Show example API token </b-form-checkbox>
    <code v-html="request_headers" /><br /><br />

    <h4>GraphiQL example</h4>
    <p>
      For debugging purposes we have a<a :href="api"> graphical interface of the GraphQL API</a>. You can make use of it
      by simple pasting your query in the top left corner, as well as the query variables and the request headers in the
      bottom left tabs. On the right you can see the response of the API, in the image below you can view an example of
      a successful response, where the API returns the order number of the order. Down below is the example of a failed
      response, in this specific case no request headers were provided, thus the API was unable to authenticate the
      request.
    </p>
    <img :src="require('@/assets/api_example.png')" /><br /><br />

    <h2>Support</h2>
    <p>
      If you require any help configuring EDI or have questions about the possibilities, feel free to contact us at
      <support-email-address></support-email-address>.
    </p>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      api: '',
      key: '',
      show_example_token: false,
    }
  },
  computed: {
    query_variables() {
      return this.JSONtoHTML(`{
        "order": {
          "Reference": "Example order",
          "RequestedShippingDate": "2022-05-01",
          "Status": "D",
          "IsCustomerItemCodes": false,
          "OrderLines": [
            {
              "RequestedOrderQuantity": 100,
              "RequestedOrderUnit": "m",
              "ItemCode": "110120023520",
              "RequestedShippingDate": "2022-05-01"
            },
            {
              "RequestedOrderQuantity": 100,
              "RequestedOrderUnit": "m",
              "ItemCode": "730741030025",
              "RequestedShippingDate": "2022-05-01"
            }
          ]
        }
      }`)
    },
    request_headers() {
      return this.JSONtoHTML(`{"Authorization": "JWT ${this.show_example_token ? this.key : '&ltAPI_TOKEN&gt'}"}`)
    },
  },
  mounted() {
    this.api = import.meta.env.VITE_API_ADDRESS
    this.key = this.$store.state.token
  },
  methods: {
    // Beun functie lets go
    JSONtoHTML(json_string) {
      return JSON.stringify(JSON.parse(json_string), null, 1).replaceAll('\n', '<br>').replaceAll(' ', '&emsp;')
    },
  },
}
</script>
