<template>
  <b-modal :id="name" centered size="xl" :title="title" @ok="handleOK" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <fields-filter v-model="field_filters" :fields="filterable_fields"></fields-filter>
      </b-row>
      <div v-if="field_filters.length">Adding {{ amount_of_items }} items</div>
      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
              {{ error }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  props: {
    name: String,
    title: String,
    callback: Function,
  },
  data() {
    return {
      field_filters: [],
      date_range: null,
      errors: [],
      fields: [
        {
          key: 'ItemCode',
          label: 'Item code',
          type: String,
        },
        {
          key: 'assortment',
          label: 'Assortment',
          type: String,
        },
        {
          key: 'c01',
          label: 'Label',
          type: String,
        },
        {
          key: 'c06',
          label: 'Collection year',
          type: String,
        },
        {
          key: 'c02',
          label: 'Collection',
          type: String,
        },
      ],
    }
  },
  computed: {
    filterable_fields() {
      return this.fields
        .filter((f) => f.type)
        .map((f) => ({ options: [...new Set(this.$store.state.lienesch_items.map((i) => i[f.key]))], ...f }))
    },
    amount_of_items() {
      let data = this.$store.state.lienesch_items
      this.field_filters.forEach((field_filter) => {
        data = data.filter((item) => field_filter.apply(item))
      })
      return data.length
    },
  },
  methods: {
    async handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.field_filters.length) {
        let data = this.$store.state.lienesch_items

        this.field_filters.forEach((field_filter) => {
          data = data.filter((item) => field_filter.apply(item))
        })

        this.callback(data)
        this.$nextTick(() => this.$bvModal.hide(bvModalEvent.componentId))
      }
    },
    resetModal() {
      this.errors = []
      this.field_filters = []
      this.date_range = null
    },
  },
}
</script>
