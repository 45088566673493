<template>
  <OrderDetails
    :order="order"
    :order-with="orderWith"
    @editAttribute="(key, value) => $emit('editAttribute', key, value)"
    @update:orderWith="(event) => $emit('update:orderWith', event)"
  >
    <template #fields-employee="slotProps">
      <b-col cols="6">
        <b-row>
          <b-col cols="4" scope="row" class="pr-3"><b>Stage</b></b-col>
          <b-col cols="8">
            <b-form-select
              :value="stage"
              :disabled="!slotProps.editing || !order.company"
              :options="stages"
              :state="order.company && !!stage"
              @input="handleStageInput"
            >
              <template #first>
                <b-form-select-option :value="null" disabled> -- Please select an option --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
      </b-col>
    </template>
  </OrderDetails>
</template>

<script lang="ts">
import OrderDetails from '@/components/order/OrderDetails.vue'

const STAGES = {
  THEO: 'Theoretical order',
  DEF: 'Definitive order',
}

export default {
  components: {
    OrderDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderWith: {
      type: String,
      default: '',
    },
  },
  emits: ['editAttribute', 'update:orderWith'],
  data() {
    return {
      stage: undefined,
    }
  },
  computed: {
    stages() {
      return Object.entries(STAGES).map(([value, text]) => ({
        value,
        text,
      }))
    },
  },
  watch: {
    order: {
      handler(newOrder, oldOrder) {
        if (newOrder.referene !== oldOrder.reference) {
          this.updateStageFromReference(newOrder.reference)
        }
      },
      deep: true,
    },
  },
  methods: {
    handleStageInput(stage: keyof typeof STAGES) {
      this.stage = stage

      if (this.stage !== undefined) {
        this.updateReferenceFromStage()
      }
    },
    updateReferenceFromStage() {
      const split = (this.order.reference || '').split('-')
      const referenceWithoutStage = split.length > 1 ? split.slice(1).join('-') : split[0]

      this.$emit('editAttribute', 'reference', `${this.stage}-${referenceWithoutStage}`)
    },
    updateStageFromReference(reference: string) {
      if (!reference.includes('-')) {
        return
      }

      const split = reference.split('-')
      if (this.stage !== split[0]) {
        this.stage = split[0] in STAGES ? split[0] : undefined
      }
    },
  },
}
</script>
