import { Cloneable } from 'innicore/types/clonable'

export type IncomingGoodEANCode = {
  barcode: string
  ean_code: string
  batch_code: string
  quantity_net: number
  quantity_gross: number
  batch_width: number
}
export type IncomingGoodItemCode = {
  barcode: string
  item_code: string
  batch_code: string
  quantity_net: number
  quantity_gross: number
  batch_width: number
}

export class IncomingGood extends Cloneable {
  id
  item
  arrival_timestamp
  batch_code
  quantity_net
  quantity_gross
  batch_width
  order_nr
  barcode
  checked
  damaged
  quality_approved
  overdelivery
  deleted
  _error

  constructor(incomingGoodObject: Record<string, unknown> = {}) {
    super()
    Object.assign(this, incomingGoodObject)
  }

  get ItemCode() {
    return this.item?.ItemCode
  }

  get Description() {
    return this.item?.Description
  }

  get ItemCodecompany() {
    return this.item?.ItemCodecompany
  }

  get _rowVariant() {
    if (this.deleted) return 'active'
    if (!this.id) return 'danger'
    if (!this.checked) return 'danger'
    if (!this.order_nr) return 'warning'
    return 'success'
  }

  set _rowVariant(variant) {
    if (variant == 'danger') this.deleted = true
  }

  get error() {
    return this.overdelivery ? `${this.ItemCode} | ${this.batch_code} is not on this packing slip` : this._error
  }
  set error(value) {
    this._error = value
  }

  get _showDetails() {
    return !!this.error
  }

  set _showDetails(value) {
    // Empty function here is necessary for the way items are added to the table
    // In ITable, if _showDetails is falsy it sets the _showDetails value to false,
    // which is required by the forecast application.
    // This means that a setter is necessary, however this does not need to do anything
    // as the _showDetails value is calculated.
    void 0
  }

  get isValid() {
    const required = ['item', 'batch_code', 'quantity_net', 'quantity_gross', 'batch_width', 'order_nr']
    for (const key of required) {
      if (this[key] == null || this[key] == '') {
        return false
      }
    }
    return true
  }
}

export type Shipment = {
  id: string
  arrival_timestamp: Date
  unlocked: boolean
  reference: string
  status: string
  company: ShipmentCompany
  sysmodified: Date
  syscreated: Date
}

type ShipmentCompany = {
  crdnr: string
  cmp_wwn: string
  cmp_name: string
}
