import Vue, { computed } from 'vue'

import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'

const sortEvents = new Vue()

export default Vue.extend({
  provide() {
    return {
      sortEvents,
      sortBy: computed(() => this.sortBy),
      sortDesc: computed(() => this.sortDesc),
      sortedItems: computed(() => this.sortedItems),
      selectedItems: computed(() => this.selectedItems),
    }
  },
  props: {
    defaultSortBy: {
      default: '',
      type: String,
    },
    defaultSortDesc: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    sortBy: undefined,
    sortDesc: undefined,
    sortedItems: [],
  }),

  computed: {
    selectedItems() {
      return this.sortedItems.filter((item) => item._select)
    },
  },

  watch: {
    sortBy() {
      this.sortItems()
    },
    sortDesc() {
      this.sortItems()
    },
    filteredItems() {
      this.sortItems()
    },
    tableMode(newMode, oldMode) {
      // If we are switching from write to read mode, sort the items, as they might have been edited
      if (newMode === TABLE_MODE.READ && oldMode === TABLE_MODE.WRITE) {
        this.sortItems()
      }
    },
  },

  mounted() {
    this.updateSortBy(this.defaultSortBy)
    this.updateSortDesc(this.defaultSortDesc)
  },

  created() {
    sortEvents.$on('update:sortBy', this.updateSortBy)
    sortEvents.$on('update:sortDesc', this.updateSortDesc)
  },

  methods: {
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateSortDesc(sortDesc) {
      this.sortDesc = sortDesc
    },
    sortCompare(rowA, rowB) {
      const a = rowA[this.sortBy]
      const b = rowB[this.sortBy]
      let result = 0
      if ((typeof a === 'number' && typeof b === 'number') || (a instanceof Date && b instanceof Date)) {
        result = a < b ? -1 : a > b ? 1 : 0
      } else {
        result = this.toString(a).localeCompare(this.toString(b))
      }
      return result * (this.sortDesc ? -1 : 1)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => this.toString(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },
    sortItems() {
      // Don't sort when in write mode, as this shuffles items when they are edited. Do sort when the number of items has changed, as this is the case in orderEntry for example.
      if (this.inWriteMode && this.sortedItems.length === this.filteredItems.length) return
      if (!this.sortBy) {
        this.sortedItems = this.filteredItems
        return
      }
      const newItems = [...this.filteredItems]
      newItems.sort(this.sortCompare)
      this.sortedItems = newItems
    },
  },
})
