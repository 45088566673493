<template>
  <div>
    <b-input-group class="d-block" @click="onClickInput">
      <vue-typeahead-bootstrap
        ref="input"
        :data="sortedOptions"
        :show-on-focus="true"
        :serializer="serializer"
        input-class="w-100"
        highlight-class="vbt-matched-text-custom"
        background-variant="list-group-item list-group-item-primary"
        placeholder=""
        :auto-close="false"
        :max-matches="1000"
        @hit="typeahead_hit_event"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <div class="d-flex align-items-center">
            <b-form-checkbox :checked="value.includes(data)" />
            <span class="ml-4" v-html="htmlText"></span>
          </div>
        </template>
      </vue-typeahead-bootstrap>
    </b-input-group>

    <div class="d-inline-block my-1" style="font-size: 1rem">
      <b-form-tag v-for="tag in value" :key="tag" :title="tag" class="mr-1" @remove="removeTag(tag)">
        {{ serializer(tag) }}
      </b-form-tag>
    </div>
  </div>
</template>

<script lang="ts">
import utils from 'innicore/mixins/utils'

export default {
  name: 'FancyInput',
  mixins: [utils],
  props: {
    options: {
      default: () => [],
      type: Array,
    },
    value: {
      default: () => [],
      type: Array,
    },
    serializer: {
      default: (x) => x,
      type: Function,
    },
  },
  computed: {
    sortedOptions() {
      return [...this.options.map((o) => (o && o !== '' ? o : '<empty>'))].sort()
    },
  },
  methods: {
    typeahead_hit_event(tag) {
      this.$refs.input.inputValue = ''
      if (this.value.includes(tag)) {
        this.$emit(
          'input',
          this.value.filter((x) => x !== tag)
        )
      } else {
        this.$emit('input', [tag, ...this.value])
      }
    },
    removeTag(tag) {
      this.$emit(
        'input',
        this.value.filter((x) => x !== tag)
      )
    },
    onClickInput() {
      this.$refs.input.isFocused = true
    },
  },
}
</script>
