<template>
  <b-modal :id="id" centered size="lg" :title="title" @ok="handleOK" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <b-col cols="2">
          <b>Item</b>
        </b-col>
        <b-col>
          <item-selector v-model="item" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2"> <b>Forecast types</b> </b-col>
        <b-col>
          <generic-selector
            v-model="forecast_type"
            :data="forecast_types"
            :disabled="false"
            :serializer="serialize_type"
          />
        </b-col>
      </b-row>
      <b-row v-if="forecast_type && forecast_type.type == 'MANUAL'">
        <b-col cols="2">
          <b>Rolling forecast</b>
        </b-col>
        <b-col>
          <b-form-input v-model="rolling_fc" type="number" :state="Boolean(rolling_fc)" />
          <b-form-invalid-feedback :state="Boolean(rolling_fc)">Enter a rolling forecast.</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">{{
              error
            }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script lang="ts">
import { ForecastType } from 'innicore/graphql/generated'

export default {
  props: {
    id: String,
    title: String,
    mutate: Function,
    cmp_wwn: String,
    callback: Function,
  },

  data() {
    return {
      item: null,
      rolling_fc: null,
      forecast_type: null,
      forecast_types: [
        {
          type: ForecastType.MACHINE_LEARNING,
          label: 'Machine learning',
        },
        {
          type: ForecastType.LINEAR_REGRESSION,
          label: 'Linear regression',
        },
        {
          type: ForecastType.MANUAL,
          label: 'Manual forecast',
        },
      ],

      errors: [],
    }
  },
  computed: {
    modal_item_is_valid() {
      return this.item && this.forecast_type && (this.rolling_fc || this.forecast_type.type != ForecastType.MANUAL)
    },
  },
  methods: {
    handleCancel() {
      this.item = null
    },
    serialize_type(type) {
      return type ? type.label : ''
    },

    handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.modal_item_is_valid) {
        const asyncFunction = async () => {
          const new_row = {
            cmp_wwn: this.cmp_wwn,
            rolling_fc: this.rolling_fc,
            ItemCode: this.item.ItemCode,
            forecast_type: this.forecast_type.type,
          }
          const response = await this.mutate([new_row], [])
          if (response.errors.length) {
            response.errors.forEach((error) => {
              if (error.message.toLowerCase().includes('unique')) {
                this.errors.push('This customer already has rolling forecast for this item.')
              } else {
                this.errors.push(error)
              }
            })
          } else {
            this.resetModal()
            this.callback(response.successful[0])
            this.$nextTick(() => this.$bvModal.hide(bvModalEvent.componentId))
          }
        }
        asyncFunction()
      } else {
        const error = 'Please fill in all entries'
        if (!this.errors.includes(error)) {
          this.errors.push(error)
        }
      }
    },
    resetModal() {
      this.item = null
      this.rolling_fc = null
      this.forecast_type = null
      this.errors = []
    },
  },
}
</script>
