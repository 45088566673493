<template>
  <div>
    <generic-selector
      v-model="field"
      :data="fields"
      :disabled="disabled"
      :field-selector-options="fieldSelectorOptions"
      :reset-confirmation-prompt="resetConfirmationPrompt"
      :serializer="serialize_datatype"
      invalid-feedback-message="This field does not exist!"
      placeholder="Enter a field name..."
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'FieldSelector',
  props: {
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
    fieldSelectorOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    field: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    fields() {
      return this.create_fields(this.fieldSelectorOptions)
    },
  },
  mounted() {
    // if necessary, this can be made dynamic (based on api)
  },
  methods: {
    serialize_datatype(dataType) {
      return dataType ? dataType.label : ''
    },
    create_fields(fieldSelectorOptions) {
      const field_object_list = []
      for (let i = 0; i < fieldSelectorOptions.length; i++) {
        const input = fieldSelectorOptions[i]
        field_object_list.push({ field: input, label: input })
      }
      return field_object_list
    },
  },
}
</script>

<style scoped></style>
