<template>
  <div>
    <h1>Collection import tool</h1>
    <p v-if="$store.state.user.is_customer">On this page you can easily import your own collection.</p>
    <p>On this page you can easily import a customer collection.</p>

    <h2>Excel template</h2>
    <b-container class="con">
      <b-row>
        <b-col cols="9">
          <p>
            Download the excel template and fill it with the right data. In the table below a description per column is
            given.
          </p>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button variant="primary" :href="excel_template" download="Collection.xlsx">
            <b-icon icon="download" aria-hidden="true" /> Download template
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table striped responsive="sm" :items="table_items"></b-table>
        </b-col>
      </b-row>
    </b-container>

    <h2>Import collection</h2>
    <b-container fluid class="con">
      <b-row>
        <b-col cols="8">
          <p>
            First select a customer, when you've done this an upload box will appear. Upload the edited excel template
            into this box and the collection will be automatically updated. Rows with invalid data will be ignored. To
            check if importing has succeded,
            <router-link variant="info" :to="{ name: 'Collection' }">view the collection</router-link>.
            <br />
            <b>Note:</b> if the excel contains an <i>id</i> column, the file will <b>update</b> the existing collection.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <customer-selector v-if="$store.state.user.is_employee" v-model="customer"></customer-selector>
        </b-col>
      </b-row>
      <b-row v-if="show_upload">
        <b-col cols="8">
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-col>
      </b-row>
    </b-container>

    <b-modal ref="import-modal" hide-footer :title="modal.title" :size="modal.size">
      <div style="text-align: center">
        <b-iconstack v-if="modal.state === 'waiting'" font-scale="5" animation="fade">
          <b-icon stacked icon="arrow-clockwise" animation="spin" variant="primary"></b-icon>
        </b-iconstack>
        <div v-if="modal.state === 'error'" class="text-left">
          Importing collection has failed for some rows... View
          <router-link variant="info" :to="{ name: 'Collection' }"> collection</router-link>.<br /><br />
          <b-list-group>
            <b-list-group-item v-for="(error, index) in modal.errors" :key="index" variant="danger">
              {{ error.message }}: <br />
              {{ error.details }}
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="modal.state === 'success'">
          Importing collection was successfull! Check it out at
          <router-link variant="info" :to="{ name: 'Collection' }"> your collection</router-link>.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { ImportCollectionDocument } from '@/graphql/generated'

export default {
  mixins: [api_mixin, utils],
  data() {
    return {
      customer: null,
      file: null,
      modal: {
        state: 'waiting',
        size: null,
        title: 'Waiting on import...',
        errors: [],
      },
    }
  },
  computed: {
    show_upload() {
      return this.$store.state.user.is_customer || Boolean(this.customer)
    },
    excel_template() {
      if (this.$store.state.user.is_employee) {
        return '/excel/collection_employee.xlsx'
      } else {
        return '/excel/collection_customer.xlsx'
      }
    },
    table_items() {
      let items = [
        {
          column: 'Lienesch item code',
          description: 'Fill this column with a valid Lienesch item code.',
          example_value: '110120023520',
          required: 'Yes',
        },
        {
          column: 'Customer item code',
          description: 'Fill this column with the corresponding item code used by the customer.',
          example_value: 'Basel1200',
          required: 'Yes',
        },
        {
          column: 'Collection name',
          description: 'Fill this column with the collection name this item is part of.',
          example_value: 'Pleated Collection 2021',
          required: 'No',
        },
      ]
      if (this.$store.state.user.is_employee) {
        items = items.concat([
          {
            column: 'Collection start',
            description: 'Fill this column with starting date of the collection.',
            example_value: '1-1-2020',
            required: 'No',
          },
          {
            column: 'Collection end',
            description: 'Fill this column with end date of the collection.',
            example_value: '31-12-2024',
            required: 'No',
          },
        ])
      }
      return items
    },
  },
  watch: {
    file: function () {
      if (this.file) {
        this.$refs['import-modal'].show()
        this.sendFile()
      }
    },
  },
  methods: {
    async sendFile() {
      const upload = await this.toUpload(this.file)
      const args = {
        file: upload,
        debnr: this.$store.state.user.is_employee ? this.customer.debnr : NaN,
      }
      const response = await this.api_call(ImportCollectionDocument, args)

      if (response.data.data.ImportCollection.success) {
        this.modal.state = 'success'
        this.modal.title = 'Import was successful!'
      } else {
        this.modal.state = 'error'
        this.modal.size = 'xl'
        this.modal.title = 'Errors on importing:'
        this.modal.errors = JSON.parse(JSON.parse(response.data.data.ImportCollection.errors))
      }
    },
  },
}
</script>
