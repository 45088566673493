<template>
  <div>
    <OrderEntry :purchase="true" :on-success="onSuccess">
      <template #title="order">
        <template v-if="!order.order_id">
          <h1>Place purchase orders</h1>
          <p>Here you can place puchase orders. Upload your order directly, or create/edit it down below.</p>
        </template>
        <template v-else>
          <h1>Purchase order</h1>
          <p>
            Here you can view an purchase order, and edit it if it's still a Draft. Sent purchase orders can no longer
            be edited.
          </p>
        </template>
      </template>
    </OrderEntry>
  </div>
</template>

<script lang="ts">
import OrderEntry from '@/components/order/OrderEntry.vue'

export default {
  components: { OrderEntry },
  methods: {
    onSuccess(order) {
      this.$router.push({
        name: 'ViewPurchaseOrder',
        params: { order_id: order.order_id },
      })
    },
  },
}
</script>
