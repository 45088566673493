<script lang="ts">
import MaxxisampleOrderDetails from '@/components/maxxisample/MaxxisampleOrderDetails.vue'
import OrderEntry from '@/components/order/OrderEntry.vue'
import { MutateMaxxisampleOrderDocument } from '@/graphql/generated'

export default {
  components: {
    OrderDetails: MaxxisampleOrderDetails,
  },
  extends: OrderEntry,
  computed: {
    showUpload() {
      return true
    },
  },
  methods: {
    hasMinimumOrderQuantity() {
      return false
    },
    getQuantityDecimalPlaces() {
      return 4
    },
    getOrderQuantity(item) {
      return this.getRequestedOrderQuantityInSalesUnit(item)
    },
    getFileOrderAdditionalArguments() {
      return {
        sample: true,
      }
    },
    getMutation() {
      return MutateMaxxisampleOrderDocument
    },
    getMutationData(data) {
      return data.MutateMaxxisampleOrder
    },
    goToOrder(orderId) {
      this.$router.push({
        name: 'ViewMaxxisampleOrder',
        params: { order_id: orderId },
      })
    },
  },
}
</script>
