<template>
  <div v-if="is_mounted && $store.state.user.is_employee">
    <b-container fluid class="con">
      <b-row v-if="show_info">
        <b-col>
          <h3>
            <b-badge pill variant="info">
              <strong>For more info about the forecast module, go to the Help tab at the top of the table.</strong>
            </b-badge>
          </h3>
        </b-col>
      </b-row>
      <b-row>
        <!-- Summary table -->
        <b-col cols="6">
          <h5>Product Forecast</h5>
          <b-table
            :fields="[
              { key: 'product_available', label: 'Product available' },
              { key: 'new_rollingfc', label: 'New rolling forecast' },
              { key: 'rfc_diff', label: 'RFC change' },
              { key: 'ml_rollingfc_preview', label: 'ML forecast' },
            ]"
            :items="[
              {
                product_available: product_available,
                new_rollingfc: new_rollingfc,
                ml_rollingfc_preview: ml_rollingfc_preview,
              },
            ]"
            small
          >
            <template v-for="key in Object.keys(headerTooltipTitles)" #[head(key)]="field">
              <span :key="key" v-b-tooltip.hover :title="headerTooltipTitles[key]">
                {{ field.label }}
              </span>
            </template>

            <template #cell(rfc_diff)>
              <span v-if="rfc_diff > 0" class="text-success">+{{ rfc_diff }}</span>
              <span v-else-if="rfc_diff < 0" class="text-danger">{{ rfc_diff }}</span>
            </template>
          </b-table>
        </b-col>

        <!-- Button group -->
        <b-col cols="6" style="text-align: right">
          <template v-if="!edit_mode">
            <!-- Edit button -->
            <b-button
              v-if="allowEdit"
              v-b-tooltip.hover
              title="Edit monthly forecast"
              variant="primary"
              @click="startEdit()"
            >
              <b-icon icon="pencil" aria-hidden="true" />
            </b-button>

            <!-- Disabled edit button (cannot edit monthly fc when also editing rolling forecast in parent)-->
            <span
              v-else
              v-b-tooltip.hover
              :title="
                item.forecast_type !== 'MANUAL'
                  ? 'You cannot edit monthly details of a ML forecast'
                  : 'You cannot edit a monthly forecast while editing rolling forecasts.'
              "
            >
              <b-button variant="primary" disabled>
                <b-icon icon="pencil" aria-hidden="true" />
              </b-button>
            </span>
          </template>

          <template v-if="edit_mode">
            <!-- Cancel edit button -->
            <b-button v-b-tooltip.hover title="Cancel changes" variant="danger" @click="cancelEdit()">
              <b-icon icon="x-circle" aria-hidden="true" />
            </b-button>
            <div class="divider" />

            <!-- Save edit button -->
            <b-button v-b-tooltip.hover title="Save changes" variant="success" @click="saveEdit()">
              <b-icon icon="check-circle" aria-hidden="true" />
            </b-button>
          </template>

          <!-- Show graph button -->
          <div class="divider" />
          <b-button v-b-tooltip.hover title="Toggle info graphic" variant="violet" @click="show_graph = !show_graph">
            <b-icon icon="graph-up" aria-hidden="true" />
          </b-button>
          <div class="divider" />

          <!-- Show info button -->
          <b-button v-b-tooltip.hover title="More info" variant="info" @click="show_info = !show_info">
            <b-icon icon="question-circle" aria-hidden="true" />
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="!edit_mode">
        <b-col>
          <b-button id="preview_ml_fc_button" variant="primary" @click="preview_ml_fc()">
            <b-icon icon="graph-up" aria-hidden="true" />
            Preview ML forecast
          </b-button>
          <div class="divider" />
          <b-button v-if="item.forecast_type === 'MANUAL'" variant="success" @click="mutateFunction(item, true)">
            <b-icon icon="calculator" aria-hidden="true" />
            Enable ML forecast
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table :fields="fields" :items="[monthlyfc]">
            <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
            <template v-for="i in 12" v-if="edit_mode" #[`cell(month${i})`]="row">
              <b-input-group :key="i">
                <typed-form-input
                  v-model="row.item[row.field.key]"
                  :type="Number"
                  :formatter="Number"
                  :style="i <= edit_from_month ? 'background-color: rgba(255,204,0,0.3);' : null"
                />
              </b-input-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row v-if="show_graph">
        <b-col cols="12">
          <b-form-checkbox-group
            v-model="selected_graph_options"
            :options="[{ text: 'Chronological graph', value: 'chron' }]"
            switches
          />
          <IChart
            :labels="graph_data.labels"
            :chron-graph="chron_graph"
            :v-lines="getDateLines()"
            :data="graph_data.datasets"
          >
          </IChart>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else style="text-align: center">
    <b-spinner variant="primary" />
  </div>
</template>

<script lang="ts">
import 'chart.js/auto'
import regression from 'regression'

import IChart from 'innicore/components/charts/IChart.vue'
import { ForecastType, MonthlyForecastDataDocument, PreviewForecastDocument } from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  name: 'MonthlyForecast',
  components: { IChart },
  mixins: [api_mixin, utils],
  props: {
    value: {
      type: Object,
      required: true,
    },
    allowEdit: {
      type: Boolean,
    },
    mutateFunction: {
      type: Function,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      is_mounted: false,
      fields: [],
      edit_mode: false,
      show_graph: true,
      show_info: false,
      show_details: false,
      customer_specific: false,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      current_month: undefined,
      prev_month: undefined,
      current_year: undefined,
      months_with_years: [],
      date_available: new Date(),
      rollingfc: {},
      old_rollingfc: undefined,
      new_rollingfc: undefined,
      edit_from_month: 0,
      selected_graph_options: ['chron'],
      item_backup: null,
      sales_info: Array(3)
        .fill(0)
        .map(() => Array(12).fill(0)),
      concept_mlfc: null,
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    monthlyfc() {
      return this.item._monthlyfc
    },
    chron_graph() {
      return this.selected_graph_options.includes('chron')
    },
    graph_data() {
      const datas = structuredClone(this.sales_info)
      const ret = {
        labels: this.months,
        datasets: [],
      }
      const graph_colors = ['orange', 'blue', 'green']
      let forecast_data = Object.values(this.monthlyfc)
      if (!this.chron_graph) {
        for (const i in datas) {
          const dataset = {
            label: `Sales (${this.current_month} ${this.current_year - 3 + Number(i)} - ${this.prev_month} ${
              this.current_year - 2 + Number(i)
            })`,
            data: datas[i],
            borderColor: this.color(graph_colors[i]),
            fill: false,
          }
          ret.datasets.push(dataset)
        }
      } else {
        //forecast_data = Array(35).concat(datas[2][11], forecast_data)
        forecast_data = Array(36).concat(forecast_data)

        // Concat sales info into 1 line, and add to graph data
        ret.labels = this.months_with_years
        ret.datasets.push({
          label: `Sales (${this.current_month} ${this.current_year - 3} - ${this.prev_month} ${this.current_year})`,
          data: datas[0].concat(datas[1], datas[2]),
          borderColor: this.color('green'),
          fill: false,
        })

        ret.datasets.push({
          label: 'Linear regression',
          data: this.linear_regression,
          borderColor: this.color('purple'),
          borderDash: [10, 3],
          fill: false,
        })

        // Calculate average sales, and add to graph data
        const avgsales = Array(2).fill(null)
        const sales_info_array = [].concat(this.sales_info[0], this.sales_info[1], this.sales_info[2])
        for (let k = 3; k <= 36; k++) {
          avgsales.push(Math.round(this.ArraySum(structuredClone(sales_info_array).splice(k - 3, 3)) / 3))
        }
        ret.datasets.push({
          label: 'Average sales past 3 months',
          data: avgsales,
          borderColor: this.color('orange'),
          borderDash: [10, 3],
          fill: false,
        })
      }
      // Add forecast data
      ret.datasets.push({
        label: `Forecast (${this.current_month} ${this.current_year} - ${this.prev_month} ${this.current_year + 1})`,
        data: forecast_data,
        borderColor: this.color('red'),
        borderDash: [5, 5],
        fill: false,
      })

      // Add concept ML forecast if applicable
      if (this.concept_mlfc) {
        ret.datasets.push({
          label: `ML Forecast (${this.current_month} ${this.current_year} - ${this.prev_month} ${
            this.current_year + 1
          })`,
          data: this.chron_graph ? Array(36).concat(this.concept_mlfc) : this.concept_mlfc,
          borderColor: this.color('blue'),
          borderDash: [5, 5],
          fill: false,
        })
      }

      return ret
    },
    linear_regression() {
      // Format sales quantities in array where elements are [x, sales], needed for regression.
      const sales = []
      this.sales_info.forEach((year_sales, year_index) => {
        year_sales.forEach((month_sales, month_index) => {
          sales.push([year_index * 12 + month_index, month_sales])
        })
      })
      const linreg = regression.linear(sales)
      return [...Array(48).keys()].map((x) => linreg.predict(x))
    },
    product_available() {
      return (
        String(this.date_available.getDate()) +
        '-' +
        String(this.date_available.getMonth() + 1) +
        '-' +
        String(this.date_available.getFullYear())
      )
    },
    rfc_diff() {
      return this.new_rollingfc - this.old_rollingfc
    },
    ml_rollingfc_preview() {
      if (this.concept_mlfc) {
        const mlfc_sum = Number(this.ArraySum(this.concept_mlfc))
        const growth = Math.floor(((mlfc_sum - this.item.sold_past_year) / this.item.sold_past_year) * 100)
        return `${mlfc_sum} (${growth} %)`
      } else {
        return null
      }
    },
    price() {
      return this.item.agreed_price || this.item.default_price
    },
    defaultHeaderTooltipTitles() {
      return {
        product_available: 'This is the first date the product is available.',
        new_rollingfc: 'When you save the changes made to the monthly forecast, this will be the new rolling forecast.',
        rfc_diff: `When you save the changes made to the monthly forecast, the rolling forecast will be increased or
        decreased by this number.`,
        ml_rollingfc_preview: "This is the total ML forecast, press 'Preview ML forecast' to see.",
      }
    },
    headerTooltipTitles() {
      return this.defaultHeaderTooltipTitles
    },
  },
  watch: {
    monthlyfc: {
      handler: function () {
        this.calculate_new_rollingfc()
      },
      deep: true,
    },
    edit_mode: function (new_value) {
      if (new_value) {
        this.$emit('monthlyfc-counter-increase', 1)
      } else {
        this.$emit('monthlyfc-counter-increase', -1)
      }
    },
  },
  async mounted() {
    const response = await this.api_call(this.getQuery(), {
      AccountCode: this.item.cmp_wwn,
      ItemCode: this.item.ItemCode,
    })
    this.date_available = this.calculate_date_available()
    this.edit_from_month = this.monthDiff(new Date(), this.date_available)
    if (this.date_available.getDate() >= 15) {
      this.edit_from_month++
    }
    response.data.data.Companies.edges[0].node.sales_info.edges.forEach((node) => {
      if (node.node.monthdiff < 0) {
        const i = node.node.monthdiff + 36
        this.sales_info[Math.floor(i / 12)][i % 12] = Number(node.node.SalesQuantity)
      } else {
        this.sales_info[2].push(Number(node.node.SalesQuantity))
      }
    })

    this.current_month = this.months[this.startDate.getMonth()]
    this.prev_month = this.months[this.startDate.getMonth() == 0 ? 11 : this.startDate.getMonth() - 1]
    this.current_year = this.startDate.getFullYear()
    this.months = this.shift_array(this.months)
    // Label with year dates for the chronlogical graph
    let years_completed = 0
    for (let i = 0; i < 48; i++) {
      this.months_with_years.push(
        this.months[i % 12] + " '" + (this.current_year - 3 + years_completed).toString().slice(-2)
      )
      if ((i + this.startDate.getMonth()) % 12 == 11) {
        years_completed++
      }
    }
    const keys = Object.keys(this.monthlyfc)
    for (let i = 0; i < 12; i++) {
      this.fields.push({ key: keys[i], label: this.months[i] })
    }
    this.calculate_new_rollingfc()
    this.old_rollingfc = this.new_rollingfc
    this.is_mounted = true
  },
  beforeDestroy() {
    if (this.edit_mode) {
      this.$emit('monthlyfc-counter-increase', -1)
    }
  },
  methods: {
    getQuery() {
      return MonthlyForecastDataDocument
    },
    getPreviewQuery() {
      return PreviewForecastDocument
    },
    startEdit() {
      this.item_backup = structuredClone(this.value)
      this.$emit('edit-attribute', 'forecast_type', ForecastType.MANUAL)
      this.edit_mode = true
    },
    cancelEdit() {
      this.edit_mode = false
      Object.entries(this.item_backup).forEach((entry) => {
        this.$emit('edit-attribute', entry[0], entry[1])
      })
      this.item_backup = null
    },
    saveEdit() {
      for (const month in this.monthlyfc) {
        const value = this.monthlyfc[month]
        if (value === undefined) {
          this.monthlyfc[month] = 0
        }
      }
      this.mutateFunction(this.item).then(() => {
        this.edit_mode = false
      })
    },
    calculate_new_rollingfc() {
      const a = []
      for (let i = 1; i <= 12; i++) {
        a.push(Number(this.monthlyfc[`month${i}`]))
      }
      this.new_rollingfc = Number(this.ArraySum(a)).toFixed(2)
    },
    shift_array(array) {
      // Shifts array so first month is always current month
      return structuredClone(array).splice(this.startDate.getMonth(), 12).concat(array).splice(0, 12)
    },
    async preview_ml_fc() {
      const options = {
        ItemCode: this.item.ItemCode,
        AccountCode: this.item.cmp_wwn,
      }
      const response = await this.api_call_feedback(
        this.getPreviewQuery(),
        { rfc_id: this.item.id, forecast_type: ForecastType.LINEAR_REGRESSION },
        options
      )
      const mlfc = Object.fromEntries(
        Object.entries(response.data.data.PreviewForecast)
          .filter(([key]) => key.startsWith('month'))
          .map(([key, value]) => [key, Number(value)])
      )

      const concept_mlfc = []
      for (let i = 1; i <= 12; i++) {
        concept_mlfc.push(mlfc[`month${i}`])
      }
      this.concept_mlfc = concept_mlfc
    },
    calculate_date_available() {
      return new Date() // todo: choose a different default date? idk @niek help plox
    },
    head(key) {
      return `head(${key})`
    },
    getDateLines() {
      return [] // Is being overriden in LBV
    },
  },
}
</script>
