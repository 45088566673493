import { IncomingGood } from '@/views/incoming_goods/types.ts'

export function matchScannedBarcode(incoming_goods: [IncomingGood], scanned_incoming_good: IncomingGood) {
  // Find all goods on the packingslip that matches by itemcode
  const relevant_goods = incoming_goods.filter((ig) => ig.ItemCode == scanned_incoming_good.ItemCode && !ig.checked)
  if (!relevant_goods.length) return null

  // Find a good on the packingslip that matches by batch_code
  const found_incoming_good = relevant_goods.find((ig) => ig.batch_code == scanned_incoming_good.batch_code)
  if (found_incoming_good) return found_incoming_good

  // Find a good that does not have a batch_code and has higher or equal quantity to the scanned good
  return relevant_goods.find((ig) => ig.quantity_net <= scanned_incoming_good.quantity_net && !ig.batch_code)
}
