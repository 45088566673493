<template>
  <b-navbar
    toggleable="lg"
    type="light"
    variant="white"
    fixed="top"
    class="navbar-expand-lg border-bottom box-shadow lg-3 p-3 px-lg-4"
  >
    <b-navbar-nav>
      <b-nav-item v-if="$store.state.logged_in" v-b-toggle.sidebar-border>
        <b-icon icon="list" scale="1.5" variant="secondary" />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav>
      <b-nav-item>
        <b-navbar-brand to="/" class="d-flex">
          <img src="../../assets/logo.png" width="100" />
          <b-nav-text style="margin-left: 1rem; font-size: 1.5rem">{{ appTitle }}</b-nav-text>
        </b-navbar-brand>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto d-none d-lg-block">
      <b-nav-item class="ml-auto">
        <b-nav-text class="ml-auto" style="padding-right: 50px; font-size: 1.3rem">
          <b-nav-text style="color: #c30041"> fabulous</b-nav-text> &nbsp;&nbsp;
          <b-nav-text> fabrics</b-nav-text>
        </b-nav-text>
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav v-if="$store.state.email">
      <b-nav-item-dropdown right>
        <template #button-content>
          {{ $store.state.email }}
        </template>
        <b-dropdown-item router-link :to="{ name: 'Profile' }"> <b-icon icon="person" /> Profile </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item router-link :to="{ name: 'Help' }"> <b-icon icon="question-circle" /> Help </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item href="#" @click="logOut">
          <b-icon icon="power" variant="danger" aria-hidden="true" class="danger" /> Sign out
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-text>{{ appVersion }}</b-dropdown-text>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script lang="ts">
export default {
  data() {
    return {
      appTitle: import.meta.env.VITE_TITLE,
      appVersion: import.meta.env.VITE_VERSION,
    }
  },
  methods: {
    logOut() {
      this.$store.commit('log_out')
    },
  },
}
</script>
