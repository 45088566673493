<template>
  <div>
    <b-modal
      :id="commentModalName"
      :title="modalTitle"
      ok-title="Save"
      cancel-title="Close"
      @ok="saveComment"
      @cancel="closeCommentModal"
      @hidden="closeCommentModal"
    >
      <b-form-textarea id="comment_input" v-model="comment" placeholder="Enter comment" rows="3" max-rows="6" />
    </b-modal>

    <b-card>
      <b-table :fields="showDetailsTableFields" :items="item.qualityAssurances">
        <template #cell(trusted_supplier)="qualityAssuranceRow">
          <b-form-checkbox
            v-model="qualityAssuranceRow.item.trusted_supplier"
            :approved="qualityAssuranceRow.item.trusted_supplier"
            switch
            @change="updateTrustedSupplier(qualityAssuranceRow.item)"
          />
        </template>
        <template #cell(quality_at_door)="qualityAssuranceRow">
          <b-form-checkbox
            v-model="qualityAssuranceRow.item.quality_at_door"
            :approved="qualityAssuranceRow.item.quality_at_door"
            switch
            @change="updateQualityAtDoor(qualityAssuranceRow.item)"
          />
        </template>
        <template #cell(quality_before)="qualityAssuranceRow">
          <b-form-checkbox
            v-model="qualityAssuranceRow.item.quality_before"
            :approved="qualityAssuranceRow.item.quality_before"
            switch
            @change="updateQualityBefore(qualityAssuranceRow.item)"
          />
        </template>
        <template #cell(contractors)="qualityAssuranceRow">
          <b-form-checkbox
            v-model="qualityAssuranceRow.item.contractors"
            :approved="qualityAssuranceRow.item.contractors"
            switch
            @change="updateContractors(qualityAssuranceRow.item)"
          />
        </template>
        <template #cell(approved)="qualityAssuranceRow">
          <b-form-checkbox
            id="approved_switch"
            v-model="qualityAssuranceRow.item.approved"
            v-b-tooltip.hover
            title="Look on the help tab for more information"
            :approved="qualityAssuranceRow.item.approved"
            switch
            disabled
          />
        </template>
        <template #cell(actions)="qualityAssuranceRow">
          <b-button id="comment_button" variant="primary" size="sm" @click="showCommentModal(qualityAssuranceRow)">
            <b-icon icon="pencil" aria-hidden="true" />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    parseApproved: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      commentModalName: 'commentModal-' + this.item.ItemCode,
      showDetailsTableFields: [
        { key: 'originalFabric', label: 'Original Fabric', selected: false, sortable: true, editable: false },
        { key: 'quality_at_door', label: 'Entry check', selected: true, sortable: true, editable: false },
        { key: 'quality_before', label: 'Precheck', selected: true, sortable: true, editable: false },
        { key: 'contractors', label: 'Contractors', selected: true, sortable: true, editable: false },
        { key: 'trusted_supplier', label: 'Trusted supplier', selected: true, sortable: true, editable: false },
        { key: 'approved', label: 'Approved', selected: true, sortable: true, editable: false },
        { key: 'actions', label: '', selected: true, sortable: false, editable: false },
      ],
      qualityAssurance: null,
    }
  },
  computed: {
    modalTitle() {
      return `Comment for ${this.qualityAssurance?.originalFabric || 'batch'}`
    },
    comment: {
      get() {
        return this.qualityAssurance?.comment
      },
      set(value) {
        this.qualityAssurance.comment = value
      },
    },
  },
  methods: {
    update(qualityAssurance, data) {
      this.callback({
        ItemCode: qualityAssurance.ItemCode,
        originalFabric: qualityAssurance.originalFabric,
        ...data,
      })
      qualityAssurance.approved = this.parseApproved(qualityAssurance)
      qualityAssurance.item.approved = qualityAssurance.item.qualityAssurances.every((qa) => qa.approved)
    },
    updateTrustedSupplier(qualityAssurance) {
      this.update(qualityAssurance, { trusted_supplier: qualityAssurance.trusted_supplier })
    },
    updateQualityAtDoor(qualityAssurance) {
      this.update(qualityAssurance, { quality_at_door: qualityAssurance.quality_at_door })
    },
    updateQualityBefore(qualityAssurance) {
      this.update(qualityAssurance, { quality_before: qualityAssurance.quality_before })
    },
    updateContractors(qualityAssurance) {
      this.update(qualityAssurance, { contractors: qualityAssurance.contractors })
    },
    // Modal methods
    showCommentModal(qualityAssuranceRow) {
      this.qualityAssurance = qualityAssuranceRow.item
      this.$bvModal.show(this.commentModalName)
    },
    closeCommentModal() {
      this.$bvModal.hide(this.commentModalName)
      this.qualityAssurance = null
    },
    saveComment() {
      this.$bvModal.hide(this.commentModalName)
      this.update(this.qualityAssurance, { comment: this.qualityAssurance.comment })
      this.qualityAssurance = null
    },
  },
}
</script>
