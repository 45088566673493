<template>
  <div>
    <h1>Order history</h1>
    <p>Here you can view all your orders.</p>
    <i-table
      ref="ITable"
      :fetch-items="fetchItems"
      :fields="fields"
      :allowed-selectors="allowed_selectors"
      :actions="actions"
      :mutate-items="mutateOrders"
      app-name="order_history"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #cell(order_id)="row">
        <router-link :to="{ name: 'ViewOrder', params: { order_id: row.item.order_id } }">
          {{ row.item.order_id }}
        </router-link>
      </template>
    </i-table>
  </div>
</template>
<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { MutateOrderDocument, OrderStatus, OrdersDocument } from '@/graphql/generated'
import { ExactOrder } from '@/views/order/exact_types'
import { HITOrder } from '@/views/order/types'

export default {
  mixins: [api_mixin, utils],
  data() {
    return {
      actions: [
        { key: 'export_to_excel', disallow: true },
        { key: 'delete', disallowForRow: (row) => row.item.status !== OrderStatus.DRAFT },
      ],
      select_by: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'status', label: 'Order status', formatter: (status) => this.status_text(status), sortable: true },
        { key: 'order_id', label: 'Order number', sortable: true },
        { key: 'reference', label: 'Your reference', sortable: true },
        { key: 'payment', label: 'Euros', sortable: true, formatter: (num) => '€ ' + this.format_num(num) },
        { key: 'order_date', label: 'Order date', sortable: true },
        ...(this.select_by?.customer || this.$store.state.user.is_customer
          ? []
          : [{ key: 'ordby', label: 'Ordered by', sortable: true }]),
      ]
    },
    allowed_selectors() {
      return this.$store.state.user.is_employee ? [['customer']] : []
    },
  },
  methods: {
    async fetchItems(filters) {
      const customer = !this.$store.state.user.is_customer ? filters?.customer : this.$store.state.company
      const response = await this.api_call(OrdersDocument, { company: customer?.cmp_wwn })
      const data = response.data.data
      const hitOrders = data.Order.edges.map((edge) => new HITOrder({ ...edge.node, hitOrderLines: [] }))
      const exactOrders = data.ExactOrder.edges.map((edge) => new ExactOrder({ ...edge.node, exactOrderLines: [] }))
      const allOrders = [...hitOrders, ...exactOrders]
      allOrders.forEach((order) => {
        order._cellVariants = { status: this.status_color(order.status) }
        order.ordby = order.company.cmp_name
      })
      return allOrders
    },
    async mutateOrders(modifiedOrders, removedOrders = []) {
      let errors = []
      const successful = [] // this one is not really used in deletions, but let's just define it for clarity.
      const deleted = []
      const failed = [] // failed mutations/deletions.
      // only allow for deletion of orders, and no other types of mutations via order history yet.
      const promises = removedOrders.map(async (order) => {
        const response = await this.mutateOrder(order, true)
        if (response.data.errors) {
          errors = errors.concat(response.data.errors)
          failed.push(order)
        } else {
          deleted.push(order)
        }
      })
      // wait for all promises to resolve
      await Promise.all(promises)
      return { errors: errors, successful: successful, deleted: deleted, failed: failed }
    },
    async mutateOrder(order, del = false) {
      return await this.api_call(MutateOrderDocument, { input: { order_id: order.order_id, delete: del } })
    },
  },
}
</script>
