import Vue, { computed, defineComponent } from 'vue'

const DEFAULT_SETTINGS = {
  pagination: {
    current_page: 1,
    per_page: 50,
  },
  sticky_header: {
    enabled: false,
    height: 800,
  },
  magic_filter: true,
}
const settingsEvents = new Vue()
export default defineComponent({
  provide() {
    return {
      tableSettings: computed(() => this.tableSettings),
      settingsEvents: settingsEvents,
    }
  },
  props: {
    settings: {
      default: () => ({}),
      type: Object,
    },
  },
  computed: {
    tableSettings() {
      // put most important source object last
      return Object.assign({}, DEFAULT_SETTINGS, this.settings)
    },
  },
})
