import api_mixin from 'innicore/mixins/api_mixin'

import {
  FetchMinimalCollectionDocument,
  FetchOrderFileDocument,
  FetchSuppliersDocument,
  FetchWorkflowDocument,
  MutateUsersDocument,
  MutateWorkflowLineDocument,
} from '@/graphql/generated'

export const storeLienesch = {
  state: {
    customer_collection: null,
    suppliers: [],
  },
  getters: {
    // Adds ItemCodeAccount to Lienesch items if an entry is found in collection
    augmentedItems(state, getters, rootState) {
      const lieneschItems = rootState.lienesch_items
      getters.getCollectionItems?.forEach((collectionItem) => {
        const lieneschItem = lieneschItems.find((item) => item.ItemCode === collectionItem.ItemCode)
        lieneschItem.ItemCodeAccount = collectionItem.ItemCodeAccount
      })
      return lieneschItems
    },
    getAugmentedItemByAttribute: (state, getters) => (key, ItemCode) => {
      return getters.augmentedItems.find((augmentedItem) => augmentedItem[key] === ItemCode)
    },
    getCollectionItems(state, _getters, rootState) {
      return state.customer_collection
        ?.filter((col_item) =>
          rootState.lienesch_items.find((lienesch_item) => col_item.ItemCode === lienesch_item.ItemCode)
        )
        .map((col_item) => ({
          ...col_item,
          ...rootState.lienesch_items.find((lienesch_item) => col_item.ItemCode === lienesch_item.ItemCode),
        }))
    },
    suppliers(state) {
      return state.suppliers
    },
  },
  mutations: {
    setCustomerCollection(state, customerCollection) {
      state.customer_collection = customerCollection
    },
    async setSuppliers(state, suppliers) {
      state.suppliers = suppliers
    },
  },
  actions: {
    fetchCollection(context, { accountcode }) {
      this.commit('setCustomerCollection', null) // reset collection
      if (
        !context.rootState.user.is_customer ||
        (context.rootState.user.is_customer && context.rootState.company.cmp_wwn === accountcode)
      ) {
        api_mixin.methods
          .api_call(
            FetchMinimalCollectionDocument,
            { ac: accountcode },
            {
              store: this,
            }
          )
          .then((response) => {
            const parseCollectionNode = (item) => {
              return {
                ItemCode: item.ItemCode.ItemCode,
                Description: item.ItemCode.Description,
                ItemCodeAccount: item.ItemCodeAccount,
              }
            }
            const collection = []
            response.data.data.collection.edges.forEach((node) => collection.push(parseCollectionNode(node.node)))
            this.commit('setCustomerCollection', collection)
          })
      }
    },
    async fetchSuppliers() {
      const response = await api_mixin.methods.api_call(
        FetchSuppliersDocument,
        { crdnr__isnull: false },
        { store: this }
      )
      const suppliers = response.data.data.Companies.edges.map((edge) => edge.node)
      this.commit('setSuppliers', suppliers)
    },
    async fetchOrderFile(context, id) {
      // returns a list of order files that match
      const response = await api_mixin.methods.api_call(
        FetchOrderFileDocument,
        {
          id: id,
        },
        { store: this }
      )
      return response.data.data.OrderFile.edges.map((edge) => edge.node)
    },
    async fetchWorkflow(context, id) {
      const response = await api_mixin.methods.api_call(
        FetchWorkflowDocument,
        {
          id: id,
        },
        { store: this }
      )
      return response.data.data.workflows.edges.map((edge) => edge.node)
    },
    async mutateWorkflowLine(context, workflowLine) {
      const input = { id: workflowLine.id, object_id: workflowLine.object_id }
      const response = await api_mixin.methods.api_call(MutateWorkflowLineDocument, { input: input }, { store: this })
      return response.data.data.MutateWorkflowLine.workflow_line
    },
    async mutateUser(context, { user_id, account_code }) {
      const input = { id: user_id, account_code }
      const response = await api_mixin.methods.api_call(MutateUsersDocument, { input: [input] }, { store: this })
      return response.data.data.MutateUsers.UserFields[0]
    },
  },
}
