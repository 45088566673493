<template>
  <div>
    <generic-selector
      v-if="items.length"
      v-model="item"
      :data="items"
      :disabled="disabled"
      :serializer="serialize_item"
      :placeholder="placeholder"
      :reset-confirmation-prompt="resetConfirmationPrompt"
      invalid-feedback-message="This item is not part of the collection!"
      @input="input"
    />

    <template v-else>
      <b-icon id="disable-customer-code-select" icon="question-circle" variant="warning" style="text-align: center" />
      <b-tooltip target="disable-customer-code-select" triggers="hover">
        There are no items available for selection. Please pick another option or make sure there are items in the
        <router-link :to="{ name: 'Collection' }">collection</router-link>.
      </b-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
const select_by_values = ['ItemCode', 'ItemCodeAccount', 'Description']
const placeholders = {
  ItemCode: 'Enter a Lienesch itemcode...',
  ItemCodeAccount: 'Enter a custom itemcode...',
  Description: 'Enter an item description...',
}
export default {
  name: 'CollectionItemSelector',
  props: {
    value: {
      type: Object,
    },
    // Way to deeply entangled with the rest of the code, ignoring this warning for now.
    // eslint-disable-next-line vue/prop-name-casing
    select_by: {
      type: String,
      required: true,
      validator: function (value) {
        return select_by_values.indexOf(value) !== -1
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
  },

  computed: {
    items() {
      const augmentedItems = this.augmentedItems
      return augmentedItems.filter((augmentedItem) => Object.hasOwn(augmentedItem, this.select_by))
    },
    augmentedItems() {
      return this.$store.getters.augmentedItems
    },
    item: {
      get() {
        return this.value ? this.value : null
      },
      set(new_item) {
        if (new_item) {
          this.$emit('input', new_item)
        } else {
          this.$emit('input', null)
        }
      },
    },
    placeholder() {
      return placeholders[this.select_by]
    },
  },
  methods: {
    serialize_item(item) {
      return item[this.select_by] // is a string already
    },
    input(input_value) {
      this.item = input_value
    },
  },
}
</script>

<style scoped></style>
