<template>
  <div style="text-align: center">
    <br /><br />
    <h1>
      <b-icon icon="exclamation-triangle-fill" font-scale="2" variant="warning" />
      The {{ appName }} webapp is temporarily unavailable...
      <b-icon icon="tools" font-scale="2" variant="dark" />
    </h1>
    <br />
    <b-container>
      <b-row>
        <b-col cols="3" class="text-right">
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
        </b-col>
        <b-col cols="6">
          <p>
            Please try again later, we apologize for the inconvenience. The {{ appName }} webapp is currently being
            updated. Our developers are hard at work to improve your user experience!
          </p>
        </b-col>
        <b-col cols="3" class="text-left">
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { defineComponent } from 'vue'

import { loginRedirect } from 'innicore/common/redirect'
import api_mixin from 'innicore/mixins/api_mixin'

export default defineComponent({
  name: 'TemporarilyUnavailable',
  mixins: [api_mixin],
  computed: {
    appName() {
      return this.$constants.appName
    },
  },
  mounted() {
    this.ping()
  },
  methods: {
    async ping() {
      try {
        const result = await axios.get(import.meta.env.VITE_BACKEND_ADDRESS + '/health/')
        if (result.status === 200) {
          if (!this.$store.state.logged_in) {
            await this.$router.replace({ name: 'Login' })
          } else {
            const userDefaultPage = await this.$store.dispatch('fetchDefaultPage')
            await loginRedirect(this, userDefaultPage)
          }
        }
      } catch (error) {
        setTimeout(this.ping, 5000)
      }
    },
  },
})
</script>
