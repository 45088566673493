<template>
  <div>
    <template v-if="is_loading">
      <b-button disabled :variant="variant" :data-action="dataAction" style="float: right">
        <b-spinner small></b-spinner>
        loading...
      </b-button>
    </template>
    <template v-else>
      <b-button
        :variant="variant"
        :disabled="disabled"
        :data-action="dataAction"
        style="float: right"
        @click="execute_click"
      >
        <slot></slot>
      </b-button>
    </template>
  </div>
</template>
<script lang="ts">
export default {
  name: 'HitButton',
  props: {
    click: {
      type: Function,
      require: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataAction: {
      type: String,
      default: undefined,
    },
    extraLoadingSeconds: {
      // This allows us to provide a value that is added to the loading time.
      // It is useful for when we want to show a loading indicator for a minimum amount of time.
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      is_loading: false,
    }
  },
  watch: {
    is_loading(new_value) {
      this.$emit('loading', new_value)
    },
  },
  methods: {
    async execute_click() {
      this.is_loading = true
      try {
        const optionalPromise = this.click()
        if (optionalPromise) {
          await optionalPromise
        }
      } catch (error) {
        this.is_loading = false
        console.error('HIT-Button promise was rejected!')
        console.error(error)
      }

      if (this.extraLoadingSeconds > 0) {
        await new Promise((resolve) => setTimeout(resolve, this.extraLoadingSeconds * 1000))
      }

      this.is_loading = false
    },
  },
}
</script>
