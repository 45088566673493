export class HITOrder {
  order_id
  company
  order_date
  order_type
  requested_shipping_date
  shipping_date
  reference
  ordered_by
  payment
  status
  hitOrderLines: HITOrderLine[] = [new HITOrderLine(this)]
  unit
  status_text
  status_color
  order_file_id
  discount

  constructor(orderObject = {}) {
    Object.assign(this, orderObject)
  }

  get id() {
    return this.order_id
  }

  get orderLines() {
    return this.hitOrderLines
  }

  toJSON() {
    return JSON.stringify(this.order_id)
  }
}

export class HITOrderLine {
  id
  item
  requested_order_quantity
  requested_order_unit
  requested_shipping_date
  cut_length_length
  cut_length_width
  remark
  batch
  warehouse
  is_shipped = false
  discount
  order: HITOrder
  _order_unit

  _select = false

  constructor(order: HITOrder, orderLineObject: Record<string, unknown> = {}) {
    Object.assign(this, orderLineObject)
    this.order = order
  }

  get order_id() {
    return this.order?.order_id
  }

  get ItemCode() {
    return this.item?.ItemCode
  }

  get Description() {
    return this.item?.Description
  }

  get ItemCodeAccount() {
    return this.item?.ItemCodeAccount
  }

  get order_unit() {
    return this.item?.unit === 'pc' ? this.item.unit : this._order_unit ?? this.order?.unit
  }

  set order_unit(value) {
    this._order_unit = value
  }

  get width() {
    return this.item?.c05
  }

  get standard_package_quantity() {
    return this.item?.standard_package_quantity
  }

  get minimum_order_quantity() {
    return this.item?.minimum_order_quantity
  }

  get requested_order_quantity_in_sales_unit(): number {
    const quantity = this.requested_order_quantity ?? 0

    if (!this.item) return quantity
    if (!this.item.unit) return quantity
    if (this.order_unit === undefined) return quantity
    if (this.item.unit === 'pc' && this.order_unit === 'pc') return quantity

    if (this.item.unit === 'm') {
      switch (this.order_unit) {
        case 'm2':
          return (100 * quantity) / this.item.width // width is in cm
        case 'pc':
          return Math.ceil(quantity) * this.item.standard_package_quantity
        case 'm':
          return quantity
      }
    }

    throw Error(`Unrecognized order unit ${this.order_unit} for item unit ${this.item.unit}.`)
  }

  get order_quantity(): number | null {
    // without an item, we shouldn't try to compute the order quantity
    if (!this.item) return null

    const quantity = this.requested_order_quantity_in_sales_unit
    if (quantity === undefined) return null
    if (quantity < this.item.minimum_order_quantity) return this.item.standard_package_quantity
    if (quantity % this.item.standard_package_quantity === 0) return quantity

    return this.item.standard_package_quantity * Math.round(quantity / this.item.standard_package_quantity)
  }
}
