<script lang="ts">
import { defineComponent } from 'vue'

import OtpDuo from 'innicore/components/auth/OtpDuo.vue'
import OtpGoogle from 'innicore/components/auth/OtpGoogle.vue'
import BaseAuthLogin from 'innicore/views/auth/AuthLogin.vue'

export default defineComponent({
  extends: BaseAuthLogin,
  computed: {
    otpComponent() {
      return this.payload && this.payload.uses_duo ? OtpDuo : OtpGoogle
    },
  },
  methods: {
    dispatchStoreFetches() {
      this.$store.dispatch('fetchCostTypes')
    },
  },
})
</script>
