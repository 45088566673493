import Vue from 'vue'

import type { ItemCode } from '@/common/helpers'
import { MutateIncomingGoodsDocument } from '@/graphql/generated'
import type { ShipmentFragment } from '@/graphql/generated'
import { IncomingGood } from '@/views/incoming_goods/types'
import type { Shipment } from '@/views/incoming_goods/types'

export default Vue.extend({
  data() {
    return {
      errors: [],
      fields: [],
      shipment: null,
      tableMode: null,
      printerUrl: 'http://PC-131285/Integration/IncomingGoods/Execute',
      support_address: import.meta.env.VITE_SUPPORT_ADDRESS,
      app_name: 'incoming_goods',
      extraFields: [
        {
          key: 'order_nr',
          label: 'Order number',
          sortable: true,
          optional: true,
          selected: true,
          type: String,
          insertBefore: 'unit',
        },
        {
          key: 'arrival_timestamp',
          label: 'Arrival date',
          sortable: true,
          optional: true,
          selected: false,
          formatter: this.format_date_time,
          type: Date,
          insertBefore: 'unit',
        },
        {
          key: 'batch_code',
          label: 'Batch number',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: String,
          insertBefore: 'unit',
        },
        {
          key: 'quantity_gross',
          label: 'Quantity gross',
          sortable: true,
          optional: true,
          selected: true,
          formatter: (input) => input.toFixed(2),
          type: Number,
          insertBefore: 'unit',
        },
        {
          key: 'quantity_net',
          label: 'Quantity net',
          sortable: true,
          optional: true,
          selected: true,
          formatter: (input) => input.toFixed(2),
          type: Number,
          insertBefore: 'unit',
        },
        {
          key: 'batch_width',
          label: 'Batch width',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          insertBefore: 'unit',
        },
      ],
    }
  },
  watch: {
    shipment(new_value: Shipment, old_value: Shipment) {
      // Skip fetching if the reference stays the same
      if (new_value?.reference == old_value?.reference) return

      if (this.supplier.crdnr) this.app_name = 'incoming_goods||' + this.supplier.crdnr

      this.$refs.ITable.fetch()
    },
  },
  methods: {
    async mutateIncomingGoods(rows, delete_rows) {
      const shipment_id = await this.getShipmentId()
      const mutation_input = rows.map((row) => this.parametersFromRow(row, shipment_id, false))
      const delete_input = delete_rows.map((row) => this.parametersFromRow(row, shipment_id, true))

      return await this.api_call(MutateIncomingGoodsDocument, { input: [...delete_input, ...mutation_input] }).then(
        (response) => {
          if (response.data.errors) return { errors: response.data.errors }

          const fields = response.data.data?.MutateIncomingGoods?.IncomingGoodsFields || []
          this.$refs.ITable.fetch()
          return { successful: fields.map((item) => this.parseIncomingGoods(item)), errors: [] }
        }
      )
    },
    parseShipment(node: ShipmentFragment): Shipment {
      return {
        id: node.id,
        arrival_timestamp: new Date(node.arrival_timestamp),
        company: node.company,
        unlocked: node.unlocked,
        reference: node.reference,
        status: node.status,
      }
    },
    parseIncomingGoods(node: {
      item: ItemCode
      id: string
      arrival_timestamp: Date
      batch_code: string
      quantity_net: number
      quantity_gross: number
      batch_width: number
      order_nr: string
      checked: boolean
      quality_approved: boolean
      damaged: boolean
      overdelivery: boolean
    }) {
      return new IncomingGood({
        item: this.findBaseItemItemCode(node.item.ItemCode),
        id: node.id,
        arrival_timestamp: new Date(node.arrival_timestamp),
        batch_code: node.batch_code,
        quantity_net: Number(node.quantity_net),
        quantity_gross: Number(node.quantity_gross),
        batch_width: Number(node.batch_width),
        order_nr: node.order_nr || '',
        checked: node.checked,
        damaged: node.damaged,
        quality_approved: node.quality_approved,
        overdelivery: node.overdelivery,
      })
    },
    parametersFromRow(row, shipment_id, del) {
      return {
        item: row.ItemCode,
        shipment: shipment_id,
        order_nr: row.order_nr,
        arrival_timestamp: row.arrival_timestamp,
        batch_code: row.batch_code,
        quantity_net: row.quantity_net.toString(), // to ensure that they get parsed to decimal correctly
        quantity_gross: row.quantity_gross.toString(),
        batch_width: row.batch_width,
        barcode: row.barcode,
        checked: row.checked,
        overdelivery: row.overdelivery,
        id: row.id,
        delete: del,
      }
    },
    findBaseItemItemCode(item_code) {
      return this.$store.getters.getAugmentedItemByAttribute('ItemCode', item_code)
    },
  },
})
