<template>
  <div>
    <table-tab title="Columns" selector="floating-tabs" floatable>
      <b-container class="con" fluid>
        <b-row>
          <b-col v-for="group in groupedOptions" :key="group.name">
            <h5
              v-if="
                !(groupedOptions === 1 && group.name === string[0].toUpperCase() + string.substring(1)) &&
                !(group.name === 'Item Attributes 2')
              "
            >
              {{ group.name }}
            </h5>
            <b-form-checkbox-group
              :checked="checked"
              :options="group.columns"
              stacked
              @input="$emit('input', $event)"
            ></b-form-checkbox-group>
          </b-col>
          <slot />
        </b-row>
      </b-container>
    </table-tab>
  </div>
</template>
<script lang="ts">
const IA = 'Item Attributes'
const IA2 = '‎' // Empty UTF-8 character because otherwise the column shifts up
const CA = 'Customer Attributes'
const SI = 'System info'

const DEFAULT_GROUPS = {
  ItemCode: IA,
  Condition: IA,
  Description: IA,
  assortment: IA,
  assortmentDescription: IA,
  ud01: IA,
  abc: IA,
  c02: IA,
  c06: IA,
  color: IA,
  color_code: IA,
  ean_code: IA,
  IsStockItem: IA,
  c01: IA,
  leadtime: IA,
  material: IA,
  minimum_order_quantity: IA2,
  c03: IA2,
  c08: IA2,
  c04: IA2,
  c07: IA2,
  width: IA2,
  standard_package_quantity: IA2,
  hs_code: IA2,
  trend_group1: IA2,
  trend_group2: IA2,
  transparency: IA2,
  unit: IA2,
  weave: IA2,
  debnr: CA,
  cmp_name: CA,
  CollectionName: CA,
  CollectionStart: CA,
  CollectionEnd: CA,
  ItemCodeAccount: CA,
  syscreated: SI,
  syscreator: SI,
  sysmodified: SI,
  sysmodifier: SI,
}
export default {
  name: 'TableTabColumns',
  inject: ['appName'],
  props: {
    checked: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formattedAppName() {
      return (this.appName[0].toUpperCase() + this.appName.substring(1)).replace(/_|-/g, ' ')
    },
    groupedOptions() {
      const options = this.options.reduce(
        (rv, a) => {
          const group = a.group || DEFAULT_GROUPS[a.value]
          if (group) {
            if (!rv.some((x) => x.name === group)) {
              rv.push({ name: group, columns: [] })
              if (group === IA) {
                rv.push({ name: IA2, columns: [] })
              }
            }
            rv.find((x) => x.name === group).columns.push(a)
          } else {
            rv[0].columns.push(a)
          }
          return rv
        },
        [{ name: this.formattedAppName, columns: [] }]
      )
      options.forEach((group) => {
        group.columns.sort((a, b) => {
          if (a.text < b.text) {
            return -1
          }
          if (a.text > b.text) {
            return 1
          }
          return 0
        })
      })
      return options.filter((group) => group.columns.length > 0)
    },
  },
}
</script>
