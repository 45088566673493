<template>
  <div style="text-align: center">
    <h1>
      <b-icon icon="exclamation-triangle-fill" font-scale="2" variant="warning"></b-icon>
      This page is currently under construction...
      <b-icon icon="tools" font-scale="2" variant="dark" />
    </h1>
    <br />
    <b-container>
      <b-row>
        <b-col cols="3" class="text-right">
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
        </b-col>
        <b-col cols="6">
          <p>This feature will be introduced with the launch of {{ appName }} webapp v1.0</p>
        </b-col>
        <b-col cols="3" class="text-left">
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
          <b-icon icon="gear" font-scale="2" variant="primary" animation="spin-reverse" />
          <b-icon icon="gear" font-scale="2" variant="secondary" animation="spin" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    appName() {
      return this.$constants.appName
    },
  },
})
</script>
