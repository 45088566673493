<template>
  <div>
    <generic-selector
      v-model="dataType"
      :data="dataTypes"
      :disabled="disabled"
      :serializer="serialize_datatype"
      :reset-confirmation-prompt="resetConfirmationPrompt"
      invalid-feedback-message="This data type does not exist!"
      placeholder="Enter a data type..."
    ></generic-selector>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DataTypeSelector',
  props: {
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
  },
  data() {
    return {
      dataTypes: [
        { data_type: 'rollingfc', label: 'Rolling forecast' },
        { data_type: 'monthlyfc', label: 'Monthly forecast' },
        { data_type: 'orders', label: 'Orders' },
        { data_type: 'orderlines', label: 'Ordered items' },
        { data_type: 'orderfiles', label: 'Order files' },
      ],
    }
  },
  computed: {
    dataType: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    serialize_datatype(dataType) {
      return dataType ? dataType.label : ''
    },
  },
}
</script>

<style scoped></style>
