<template>
  <div>
    <b-card style="max-width: 40rem" class="mt-5 mx-auto" header="Reset your password" header-class="card-header">
      <b-form @submit.prevent="onSubmit">
        <b-alert :show="alert.message.length > 1" :variant="alert.variant" dismissible @dismissed="alert.message = ''">
          {{ alert.message }}
        </b-alert>

        <b-form-group v-for="field in formFields" :key="field.id" :label="field.label">
          <b-form-input
            :id="field.id"
            v-model="field.value"
            :type="field.type"
            :disabled="field.disabled"
            :placeholder="field.label"
            :state="field.state"
            required
          />
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="secondary" type="submit"> Reset password </b-button>
        </div>
      </b-form>

      <template #footer> Or go back to <router-link :to="{ name: 'Login' }">Login</router-link>. </template>
    </b-card>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue'
import { AxiosError } from 'axios'
import { defineComponent } from 'vue'

import apiMixin from 'innicore/mixins/api_mixin'

import { GetUserAndSecretDocument, ResetPasswordDocument } from '@/graphql/generated'

export default defineComponent({
  mixins: [apiMixin],
  data() {
    return {
      alert: {
        message: '',
        variant: 'success' as 'success' | 'danger',
      },
      formFields: {
        email: {
          id: 'email-input',
          label: 'Email address',
          type: 'email',
          disabled: true,
          value: '',
          state: null,
        },
        password: {
          id: 'password-input',
          label: 'New password',
          type: 'password',
          disabled: false,
          value: '',
          state: null,
        },
        confirmPassword: {
          id: 'confirm-password-input',
          label: 'Confirm password',
          type: 'password',
          disabled: false,
          value: '',
          state: null,
        },
      },
    }
  },
  async mounted() {
    try {
      const response = await this.api_call(
        GetUserAndSecretDocument,
        {},
        {
          token: this.$route.params.token,
        }
      )
      if (!response) {
        this.showAlert(
          'This password reset link has expired. If you still need to sign up, please contact sales support.',
          'danger'
        )
      } else if (!response.data.data.currentUser) {
        this.showAlert(
          'This is an invalid password reset link. Please confirm you have copied the link correctly.',
          'danger'
        )
      } else {
        const user = response.data.data.currentUser
        this.formFields.email.value = user.email
      }
    } catch (error) {
      const scope = new Sentry.Scope()
      scope.setContext('context', { query: GetUserAndSecretDocument })
      Sentry.captureException(error, scope)

      if (error instanceof AxiosError) {
        if (!error.response) {
          this.$router.replace({ name: 'TemporarilyUnavailable' })
        } else {
          this.showAlert('Something went wrong.', 'danger')
        }
      }
    }
  },
  methods: {
    showAlert(message: string, variant: 'success' | 'danger' = 'success') {
      this.alert.message = message
      this.alert.variant = variant
    },
    async onSubmit() {
      if (!this.validate()) {
        return
      }

      const response = await this.api_call(
        ResetPasswordDocument,
        {
          email: this.formFields.email.value,
          password: this.formFields.password.value,
        },
        {
          token: this.$route.params.token,
        }
      )

      if (response.data.data.ResetPassword != null && response.data.data.ResetPassword.success) {
        this.showAlert('Your password has been updated. You can now login.', 'success')
        this.reset()
      } else {
        this.showAlert('Something went wrong.', 'danger')
        this.resetState()
      }
    },
    validate() {
      this.resetState()

      if (this.formFields.password.value.length < 8) {
        this.showAlert('The password is shorter than 8 characters.', 'danger')
        this.formFields.password.state = false
        return false
      }
      if (this.formFields.password.value !== this.formFields.confirmPassword.value) {
        this.showAlert('The passwords are not equal.', 'danger')
        this.formFields.password.state = false
        this.formFields.confirmPassword.state = false
        return false
      }
      return true
    },
    resetState() {
      this.formFields.password.state = null
      this.formFields.confirmPassword.state = null
    },
    reset() {
      this.resetState()

      this.formFields.password.value = ''
      this.formFields.confirmPassword.value = ''
    },
  },
})
</script>
