<template>
  <div id="supplier-selector">
    <template v-if="$store.state.user.is_employee">
      <generic-selector
        v-model="supplier"
        :data="suppliers"
        :disabled="disabled || suppliers.length === 0"
        :serializer="serialize_supplier"
        invalid-feedback-message="This supplier does not exist!"
        placeholder="Enter a supplier..."
      />
    </template>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  name: 'SupplierSelector',
  mixins: [api_mixin],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    supplier: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    suppliers() {
      return this.$store.getters.suppliers
    },
  },
  async mounted() {
    if (!this.$store.getters.suppliers?.length) {
      await this.$store.dispatch('fetchSuppliers')
    }
  },
  methods: {
    serialize_supplier(supplier) {
      return supplier ? Number(supplier.crdnr) + ' - ' + supplier.cmp_name : ''
    },
  },
}
</script>
