import * as Sentry from '@sentry/vue'
import jwt_decode from 'jwt-decode'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import {
  FetchCostTypesDocument,
  FetchDefaultPageDocument,
  FetchRollingFCDatesDocument,
  FetchUserGroupsDocument,
  FetchUsersDocument,
  MinimalFetchCompaniesDocument,
  RefreshTokenDocument,
} from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'

import { parseItem } from '@/common/parseItem'
import { FetchItemsDocument } from '@/graphql/generated'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage,
})

export const store = new Vuex.Store({
  state: {
    token: '',
    logged_in: false,
    email: '',
    company: null,
    lienesch_items: [],
    user: {
      is_customer: false,
      is_employee: false,
      is_dev: false,
      is_fc_customer: false,
      permissions: [],
      default_page: '',
    },
    rolling_fc_dates: [],
    companies: [],
    full_companies: [],
    costTypes: [],
  },
  getters: {
    getItemByItemCode: (state) => (ItemCode) => {
      return state.lienesch_items.find((li) => li.ItemCode === ItemCode)
    },
    customers: (state) => {
      return state.companies.filter((company) => company.debnr != null)
    },
    getCompanyById: (state) => (id) => {
      return state.companies?.find((company) => company.cmp_wwn === id)
    },
  },
  mutations: {
    set_token(state, new_token, refresh = true) {
      state.token = new_token
      state.logged_in = true
      if (refresh) {
        this.dispatch('autoRefresh')
      }
    },
    log_out(state) {
      state.token = ''
      state.logged_in = false
      state.email = ''
      state.company = {}

      this.dispatch('clear_sentry_context')
    },
    set_email(state, email) {
      state.email = email
    },
    set_user(state, user) {
      if (user.is_customer === user.is_employee) {
        throw 'User is either customer or employee!'
      }
      state.user = user
    },
    set_company(state, company) {
      state.company = company
    },
    set_lienesch_items(state, lienesch_items) {
      state.lienesch_items = lienesch_items
    },
    set_rolling_fc_dates(state, rolling_fc_dates) {
      state.rolling_fc_dates = rolling_fc_dates
    },
    set_companies(state, companies) {
      state.companies = companies
    },
    set_full_companies(state, companies) {
      state.full_companies = companies
    },
    setCostTypes(state, costTypes) {
      const sortCostTypes = (a, b) => {
        // we prefer ITEM costs above ACCOUNT costs
        if (a.cost_level !== b.cost_level) {
          return a.cost_level > b.cost_level ? -1 : 1
        }
        return a.description > b.description ? 1 : -1
      }
      state.costTypes = costTypes.sort(sortCostTypes)
    },
  },
  actions: {
    refreshTokens() {
      // Do whatever you need to do to exchange refresh token for access token
      // Finally, call autoRefresh to set up the new timeout
      if (this.state.logged_in) {
        api_mixin.methods
          .api_call(
            RefreshTokenDocument,
            { token: this.state.token },
            {
              check_auth: false,
              store: this,
            }
          )
          .then((response) => {
            if (response) {
              this.commit('set_token', response.data.data.refresh_token.token)
            }
          })
      }
    },
    autoRefresh() {
      const { exp } = jwt_decode(this.state.token)
      const now = Date.now() / 1000 // exp is represented in seconds since epoch
      let timeUntilRefresh = exp - now
      timeUntilRefresh -= 180 // Refresh 3 minutes before it expires
      setTimeout(() => this.dispatch('refreshTokens'), timeUntilRefresh * 1000)
    },
    async fetchUserPermissions(context) {
      const response = await api_mixin.methods.api_call(FetchUserGroupsDocument, null, { store: this })
      const user = {}
      user.permissions = response.data.data.currentUser.groups.edges.map(({ node: group }) => group.name).sort()
      user.is_employee = user.permissions.includes('employees')
      user.is_customer = user.permissions.includes('customers')
      user.is_manager = user.permissions.includes('managers')
      user.is_tester = user.permissions.includes('test')
      if (context.state.user.is_customer) {
        await this.dispatch('fetchCompany')
      }
      this.commit('set_user', user)
    },
    async fetchDefaultPage() {
      const response = await api_mixin.methods.api_call(FetchDefaultPageDocument, null, { store: this })
      return response.data.data.currentUser.default_page_name
    },
    // Fetches the company of a user. Only used when the user is a customer.
    async fetchCompany() {
      api_mixin.methods.api_call(FetchUsersDocument, null, { store: this }).then((response) => {
        const edges = response.data.data.users.edge
        if (edges.length !== 1) {
          throw "There's more than one customer for this user"
        }
        const company = structuredClone(edges[0].node.company)
        this.commit('set_company', company)
      })
    },
    // Fetch and store Items list
    fetchItems() {
      return api_mixin.methods.api_call(FetchItemsDocument, null, { store: this }).then((response) => {
        const lienesch_items = []
        response.data.data.Item.edges.forEach((node) => lienesch_items.push(parseItem(node.node)))
        this.commit('set_lienesch_items', lienesch_items)
      })
    },
    async fetchCompanies(context, filters) {
      if (!context.state.user.is_customer) {
        const query = MinimalFetchCompaniesDocument
        const response = await api_mixin.methods.api_call(query, filters, {
          store: this,
        })
        const companies = response.data.data.Companies.edges.map((edge) => edge.node)
        this.commit('set_companies', companies)
      }
    },
    async fetchCostTypes(context) {
      if (!context.rootState.user.is_employee) return

      const response = await api_mixin.methods.api_call(FetchCostTypesDocument, null, { store: this })
      const costTypes = response.data.data.CostType.edges.map((edge) => edge.node)
      this.commit('setCostTypes', costTypes)
    },

    init_sentry_context(context) {
      Sentry.setUser(context.state.user)
    },
    clear_sentry_context() {
      Sentry.configureScope((scope) => scope.clear())
    },
    async fetchRollingFCDates(context) {
      if (context.state.user.is_employee) {
        const response = await api_mixin.methods.api_call(FetchRollingFCDatesDocument, null, { store: this })
        this.commit('set_rolling_fc_dates', response.data.data.RollingFCDates)
      }
    },
  },
  plugins: [vuexLocalStorage.plugin],
})
