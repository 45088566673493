import * as _ from 'lodash'

import type { Field } from 'innicore/components/table/TableFieldsMixin'

export enum DefaultFieldGroups {
  ItemAttributes,
  CustomerAttributes,
  SystemInfo,
}

const DEFAULT_FIELDS: Array<DefaultField> = [
  {
    key: 'ItemCode',
    label: 'Item code',
    sortable: true,
    optional: true,
    selected: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'Condition',
    label: 'Condition',
    sortable: true,
    optional: true,
    selected: false,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'Description',
    sortable: true,
    label: 'Item description',
    optional: true,
    selected: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'assortment',
    label: 'Assortment number',
    sortable: true,
    optional: true,
    type: Number,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'assortmentDescription',
    label: 'Assortment description',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'IsStockItem',
    label: 'Is Stock Item',
    sortable: true,
    optional: true,
    formatter: (value) => (value ? 'Yes' : 'No'),
    type: Boolean,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'standard_package_quantity',
    label: 'Sales quantity',
    sortable: true,
    optional: true,
    type: Number,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'hs_code',
    label: 'Statistical code',
    sortable: true,
    optional: true,
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'unit',
    sortable: true,
    optional: true,
    label: 'Unit',
    class: 'tight-column',
    type: String,
    defaultGroup: DefaultFieldGroups.ItemAttributes,
  },
  {
    key: 'debnr',
    label: 'Debnr',
    sortable: true,
    optional: true,
    selected: false,
    type: Number,
    defaultGroup: DefaultFieldGroups.CustomerAttributes,
  },
  {
    key: 'cmp_name',
    sortable: true,
    label: 'Customer',
    optional: true,
    selected: false,
    type: String,
    defaultGroup: DefaultFieldGroups.CustomerAttributes,
  },
  { key: 'cmp_wwn', visible: false, label: '', type: String, defaultGroup: DefaultFieldGroups.CustomerAttributes },
  {
    key: 'syscreated',
    label: 'Created on',
    optional: true,
    sortable: true,
    type: Date,
    defaultGroup: DefaultFieldGroups.SystemInfo,
  },
  {
    key: 'syscreator',
    label: 'Created by',
    optional: true,
    sortable: true,
    type: String,
    defaultGroup: DefaultFieldGroups.SystemInfo,
  },
  {
    key: 'sysmodified',
    label: 'Last edit on',
    optional: true,
    sortable: true,
    type: Date,
    defaultGroup: DefaultFieldGroups.SystemInfo,
  },
  {
    key: 'sysmodifier',
    label: 'Last edit by',
    optional: true,
    sortable: true,
    type: String,
    defaultGroup: DefaultFieldGroups.SystemInfo,
  },
]

export interface DefaultField extends Field {
  defaultGroup: DefaultFieldGroups
}

/**
 * Add default table fields to the supplied field array. The 'fields' option can be used to overwrite/add fields.
 */
export default (defaultFields: Array<DefaultFieldGroups> = [], fields: Array<Field> = []): Array<Field> => {
  const selectedDefaultFields: Array<Field> = _.cloneDeep(
    DEFAULT_FIELDS.filter((x) => defaultFields.includes(x.defaultGroup))
  )
  return mergeFields(selectedDefaultFields, fields)
}

/**
 * Merge two arrays of fields. If a field with the same key is found in both arrays, the field in the newFields array will be merged into the original field.
 */
export const mergeFields = (originalFields: Array<Field> = [], newFields: Array<Field> = []): Array<Field> => {
  for (const field of newFields) {
    let index
    if ((index = _.findIndex(originalFields, (x) => x.key === field.key)) !== -1) {
      // If the default fields already have this item, replace it
      _.merge(originalFields[index], field)
    } else {
      // Else, insert it at the specified index.
      const insertionIndex = _.findIndex(originalFields, (x) => x.key === field.insertBefore)
      if (insertionIndex !== -1) {
        originalFields.splice(insertionIndex, 0, field)
      } else {
        // If the returned index is -1, i.e. no index is found, put the field in the back
        originalFields.push(field)
      }
    }
  }
  return originalFields
}
