import Vue, { computed } from 'vue'

import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'

const DEFAULT_ACTIONS = (table) => {
  return [
    {
      key: 'toggle_details',
      title: 'Toggle details',
      icon: 'info-circle',
      variant: 'b-blue',
      disallow: true,
      execute: table.toggleDetails,
    },
    {
      key: 'delete',
      title: 'Delete row',
      icon: 'trash',
      modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
      variant: 'danger',
      execute: table.removeItem,
    },
    {
      key: 'export_to_excel',
      title: 'Export to excel',
      icon: 'box-arrow-up-right',
      variant: 'secondary',
      execute_bulk: table.export_to_excel,
    },
    {
      key: 'refresh',
      title: 'Refresh',
      execute_global: table.fetch,
      is_extra_global: true,
      icon: 'arrow-repeat',
      variant: 'success',
      disallow: table.inWriteMode || table.inBulkAddMode,
    },
    {
      key: 'edit',
      title: 'Edit',
      icon: 'pencil',
      modes: [TABLE_MODE.READ],
      variant: 'primary',
      execute_global: table.enterEditMode,
      disallow: !table.allow_edit,
    },
    {
      key: 'add_data', // abstract action, it doesn't show up unless given an implementation such as 'execute_global'
      title: 'Add data',
      icon: 'plus',
      modes: [TABLE_MODE.READ],
      variant: 'success',
      scale: '1.5',
      execute_bulk: table.add_data_bulk,
    },
    {
      key: 'cancel',
      title: 'Cancel',
      icon: 'x-circle',
      modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
      variant: 'danger',
      execute_global: table.cancelEditMode,
    },
    {
      key: 'save',
      title: 'Save changes',
      icon: 'check-circle',
      modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
      variant: 'success',
      execute_global: table.inBulkAddMode ? table.saveBulkAdd : table.saveEdit,
    },
  ]
}

interface Action {
  key: string
  title: string
  icon: string
  modes: Array<TABLE_MODE>
  variant: string
  execute_global?: () => void
  execute_bulk?: () => void
}

export default Vue.extend({
  provide() {
    return {
      allowedActions: computed(() => this.allowedActions),
      actionAllows: this.actionAllows,
      allowForRow: this.allowForRow,
      allowed_row_actions: computed(() => this.allowed_row_actions),
      row_actions: computed(() => this.row_actions),
    }
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    defaultActions() {
      return DEFAULT_ACTIONS(this)
    },
    allowed_row_actions() {
      return this.row_actions.filter((action) => this.actionAllows(action, this.tableMode))
    },
    row_actions() {
      return this.allowedActions.filter((a) => a.execute)
    },
    allowedActions() {
      const values: Array<Action> = []
      this.defaultActions.forEach((da) => {
        const action = this.actions.find((a) => a.key === da.key)
        const value: Action = { ...da }
        if (action) {
          Object.keys(action).forEach((k) => {
            if (k === 'disabled') {
              // disable the action; grey out the button
              value[k] = value[k] || action[k]
            } else if (k === 'disallow') {
              // don't allow the action at all; don't even show the button
              value[k] = (value[k] && action[k]) || action[k]
            } else {
              value[k] = action[k] // overwrite the default values with values defined in parent
            }
          })
        }
        values.push(value)
      })
      const default_keys = this.defaultActions.filter((a) => a.key).map((a) => a.key)
      return [...values, ...this.actions.filter((a) => !a.key || !default_keys.includes(a.key))]
    },
  },
  methods: {
    actionAllows(action, tableMode) {
      return !action.disallow && (!action.modes || action.modes.includes(tableMode))
    },
    allowForRow(action, row) {
      return !action.disallowForRow || !action.disallowForRow(row)
    },
  },
})
