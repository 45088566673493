<template>
  <div>
    <h1>Coulisse Backorder</h1>
    <p>On this page you can upload Coulisse backorders from Excel. You can also view and update existing backorders.</p>

    <file-importer :file-parse-callback="file_import_callback" :after-import="afterImport" app-name="backorder" />

    <unprocessed-backorder-modal
      :callback="markMutationsAsProcessed"
      :items="unprocessedMutations"
      title="Onverwerkte backorders"
      :name="unprocessed_modal_name"
    />

    <i-table
      ref="ITable"
      :fetch-items="fetchBackorder"
      :mutate-items="mutateBackorders"
      :actions="actions"
      :fields="fields"
      app-name="backorders"
      :errors="errors"
    >
      <template #help-tab>
        <BackorderHelp />
      </template>
      <template #row-details="row">
        <span class="text-danger">{{ row.item.error }}</span>
      </template>
    </i-table>
  </div>
</template>
<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { parseItem } from '@/common/parseItem'
import useTableDefaultFieldsCustomer, { DefaultFieldGroups } from '@/components/table/useTableDefaultFieldsCustomer'
import {
  FetchBackorderDocument,
  FetchUnprocessedBackorderMutationDocument,
  GenerateBackorderMutationsDocument,
  ImportCoulisseBackorderDocument,
  MarkBackorderMutationsProcessedDocument,
  MutateBackorderDocument,
} from '@/graphql/generated'
import BackorderHelp from '@/views/backorder/BackorderHelp.vue'
import UnprocessedBackorderModal from '@/views/backorder/UnprocessedBackorderModal.vue'

export default {
  name: 'BackorderPage',
  components: { BackorderHelp, UnprocessedBackorderModal },
  mixins: [api_mixin, utils],
  data() {
    return {
      select_by: null,
      afterImport: () => null,
      errors: [],
      unprocessedMutations: [],
      unprocessed_modal_name: 'unprocessed',
      fields: [],
      extraFields: this.defaultExtraFields(),
      actions: [
        {
          key: 'generate_backorder_mutations',
          title: 'Generate mutations',
          variant: 'warning',
          execute_global: this.generateBackorderMutations,
          disallow: false,
          modes: [TABLE_MODE.READ],
        },
      ],
      support_address: import.meta.env.VITE_SUPPORT_ADDRESS,
    }
  },
  mounted() {
    this.afterImport = this.$refs.ITable.fetch
    this.fields = useTableDefaultFieldsCustomer([DefaultFieldGroups.ItemAttributes], this.extraFields)
  },
  methods: {
    defaultExtraFields() {
      return [
        {
          key: 'order_number',
          label: 'Order number',
          type: String,
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
        },
        {
          key: 'planned_date',
          label: 'Old shipping date',
          type: Date,
          sortable: true,
          selected: true,
          optional: true,
          editable: true,
        },
        {
          key: 'shipping_date',
          label: 'New shipping date',
          type: Date,
          sortable: true,
          selected: true,
          optional: true,
          editable: true,
        },
        {
          key: 'order_quantity',
          label: 'Order quantity',
          type: Number,
          sortable: true,
          selected: true,
          optional: true,
        },
        {
          key: 'boxes',
          label: 'Amount of boxes',
          type: Number,
          sortable: true,
          selected: true,
          optional: true,
        },
        // These are defined here because using DefaultFieldGroups.SystemInfo would also include sys{created,creator}
        {
          key: 'sysmodified',
          label: 'Last edit on',
          optional: true,
          sortable: true,
          permissions: ['is_employee'],
        },
        {
          key: 'sysmodifier',
          label: 'Last edit by',
          optional: true,
          permissions: ['is_employee'],
        },
      ]
    },
    parseBackoderNode(node) {
      const item = parseItem(node.ItemCode)
      return {
        ...item,
        id: node.id,
        order_number: node.order_number,
        planned_date: node.planned_date,
        shipping_date: node.shipping_date,
        order_quantity: node.order_quantity,
        boxes: node.boxes,
        sysmodified: node.sysmodified,
        error: node.error,

        _showDetails: !!node.error,
      }
    },
    async fetchBackorder() {
      const result = await this.api_call(FetchUnprocessedBackorderMutationDocument)
      if (result.data.data?.coulisse_backorder_mutation?.edges?.length) {
        this.unprocessedMutations = result.data.data?.coulisse_backorder_mutation.edges.map((x) => {
          return { operation: x.node.operation, ...JSON.parse(x.node.changes) }
        })
        this.$bvModal.show(this.unprocessed_modal_name)
      }
      return {
        query: FetchBackorderDocument,
        parameters: {},
        after: (response) => response.data.data.coulisse_backorder.edges.map((obj) => this.parseBackoderNode(obj.node)),
      }
    },
    parametersFromRow(row) {
      return {
        ic: row.ItemCode,
        on: row.order_number,
        pd: row.planned_date,
        sd: row.shipping_date,
        oq: row.order_quantity,
        id: row.id,
      }
    },
    async markMutationsAsProcessed() {
      return await this.api_call(MarkBackorderMutationsProcessedDocument)
    },
    async mutateBackorders(modifiedBackorders, removedBackorders = []) {
      const updatePromises = modifiedBackorders.map((modifiedBackorder) => {
        return this._mutateBackorder(modifiedBackorder, false)
      })
      const deletePromises = removedBackorders.map((removedBackorder) => {
        return this._mutateBackorder(removedBackorder, true)
      })
      const responses = await Promise.all([...updatePromises, ...deletePromises])
      const successful = []
      let errors = []
      responses.forEach((response) => {
        if (response !== null) {
          if (response.errors) {
            errors = errors.concat(response.errors)
          } else {
            successful.push(response)
          }
        } // response being null indicates a successful deletion
      })
      return { errors: errors, successful: successful }
    },
    _mutateBackorder(item, del) {
      const parameters = this.parametersFromRow(item)
      parameters.del = del
      return this.api_call(MutateBackorderDocument, parameters).then((response) => {
        if (response.data.errors) {
          return response.data
        } else if (!del) {
          return this.parseBackoderNode(response.data.data.MutateBackorder.BackorderField)
        } else {
          return null // item was successfully deleted
        }
      })
    },
    async generateBackorderMutations() {
      const ok = await this.$bvModal.msgBoxConfirm(
        'Je krijg zo een mailtje met daarin regels die niet ' +
          'automatisch verwerkt kunnen worden. Zet deze regels handmatig in exact!' +
          '\n\nAls je geen tijd hebt om deze te verwerken druk dan nu op cancel, ' +
          'hierdoor voorkom je dat onverwacht data uit exact "verdwijnt".',
        {
          id: 'generate_mutations_modal',
          title: 'Verdere actie vereist!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Sturen',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      )
      if (ok) {
        const response = await this.api_call(GenerateBackorderMutationsDocument, {})
        const failed = response.data.data.GenerateBackorderMutations.errors.map(this.parseBackoderNode)
        this.$refs.ITable.setItems(failed)
      }
      // todo: provide more feedback about success of generating backorder mutations
    },
    async file_import_callback(upload) {
      const args = {
        file: upload,
      }
      const response = await this.api_call(ImportCoulisseBackorderDocument, args)
      if (response.data.errors) {
        return { errors: response.data.errors }
      } else if (response.data.data.ImportCoulisseBackorder.success) {
        return { success: true }
      } else {
        return { errors: JSON.parse(response.data.data.ImportCoulisseBackorder.errors) }
      }
    },
  },
}
</script>
<style scoped></style>
