<script lang="ts">
import GenerateNewDataModal from 'innicore/views/price-agreements/GenerateNewDataModal.vue'

import { FetchPriceListEssentialsDocument } from '../../graphql/generated'

export default {
  extends: GenerateNewDataModal,
  methods: {
    getQuery() {
      return FetchPriceListEssentialsDocument
    },
  },
}
</script>
