<template>
  <div>
    <OrderEntry>
      <template #title="order">
        <template v-if="!order.order_id">
          <h1>Place orders</h1>
          <p>Here you can place orders. Upload your order directly, or create/edit it down below.</p>
        </template>
        <template v-else>
          <h1>Order</h1>
          <p>Here you can view an order, and edit it if it's still a Draft. Sent orders can no longer be edited.</p>
        </template>
      </template>
    </OrderEntry>
  </div>
</template>

<script lang="ts">
import OrderEntry from '@/components/order/OrderEntry.vue'

export default {
  components: { OrderEntry },
}
</script>
