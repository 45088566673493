<template>
  <div>
    <p>
      Dev-mode: {{ DEVELOPMENT }}<br />
      Staging-mode: {{ STAGING }}
    </p>

    <b-button @click="postToPrinter"> post to printer</b-button>

    <hr />

    <p>Key:</p>
    <code>{{ key }}</code>

    <p>Authorization header:</p>
    <code> { "Authorization": "JWT {{ key }}" } </code>

    <hr />

    <form @submit.prevent="send_query">
      <b-form-textarea id="textarea" v-model="query" rows="15" max-rows="25" />
      <b-button type="submit"> Send Query </b-button>
    </form>

    <hr />

    <h2>Routes</h2>

    <b-row>
      <div v-for="(list, index) in routes" :key="index" class="col">
        <ul>
          <router-link v-for="route in list" :key="route.path" :to="route.path">
            <li>{{ route.name }}</li>
          </router-link>
        </ul>
      </div>
    </b-row>
  </div>
</template>

<script lang="ts">
import axios from 'axios'

import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  data() {
    return {
      waarde: 1,
      item: '',
      email: '',
      password: '',
      query: '',
      error: null,
      line_chart_data: null,
      isMounted: false,
    }
  },
  computed: {
    key() {
      return this.$store.state.token
    },
    routes() {
      const routes = this.$router.getRoutes()
      const list_size = 10

      const result = []
      for (let i = 0; i < routes.length; i += list_size) {
        const chunk = routes.slice(i, i + list_size)
        result.push(chunk)
      }

      return result
    },
  },
  methods: {
    send_query() {
      this.api_call(this.query, null).then((response) => console.warn(response.data))
    },
    postToPrinter() {
      this.api_call(
        `
        query btw_file{
          btw_file
        }
      `
      ).then((response) => {
        const str = response.data.data.btw_file.substring(2).slice(0, -1)
        const data =
          'Artikelcode;Batchnummer;Breedte;Netto;Bruto;Kleur;VierkanteMeter;EANCode;BreedteBarcode\n' +
          '9219220S24020B;12957-27;240;35;35;9220;84;087182531305;2400\n' +
          '9219220S24020B;12957-28;240;34;35;9220;84;087182531305;2400\n'
        const input = JSON.stringify({ btw_binary: str, data: data })
        axios.post('http://localhost:80/Integration/test/Execute', input, {
          headers: { 'Content-Type': 'text/plain' },
        })
      })
    },
  },
}
</script>
