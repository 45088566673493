<template>
  <div>
    <h1>Price agreements</h1>
    <p>On this page you can view and update your existing price agreements.</p>
    <AddToPriceAgreementsModal
      v-if="customer"
      title="Add price agreement"
      name="add_to_price_agreements_modal"
      :callback="add_data_callback"
      :company-id="customer.cmp_wwn"
      :mutate="mutateItems"
      :date-range="select_by?.date_range"
    />

    <GenerateNewDataModal
      v-if="customer"
      :price_list_valid_on.sync="price_list_valid_on"
      title="Generate bulk data"
      name="generate_new_data_modal"
      :callback="add_data_from_store"
    />

    <AddDataModal
      v-if="customer"
      v-model="price_list_valid_on"
      title="Add data"
      name="add_data_modal"
      :callback="add_data_from_store"
      :items="selected_items"
    />

    <GeneratePDFModal v-if="customer" title="Generate PDF" name="generate_pdf_modal" :callback="get_file" />

    <GeneratePDFModal v-if="customer" title="Generate Excel" name="generate_excel_modal" :callback="get_excel_file" />

    <template v-if="$store.state.user.is_employee">
      <i-table
        ref="ITable"
        :fetch-items="fetchItems"
        :mutate-items="mutateItems"
        :fields="fields"
        :actions="actions"
        app-name="price_agreements"
        :allowed-selectors="allowed_selectors"
        :errors="errors"
        default-sort-by="Description"
        :default-sort-desc="true"
        @update:selectBy="(v) => (select_by = v)"
        @update:tableMode="(tm) => (tableMode = tm)"
      >
        <template #help-tab>
          <PriceAgreementsHelp />
        </template>
        <template v-for="price in pricelists" #[cell(price)]="row">
          <div :key="price">
            <b-form-radio
              v-if="tableEditable && editable(row.value, price, row.item)"
              :checked="row.item.pricelist_selected"
              :name="row.item.ItemCode + 'priceList'"
              :value="price"
              @change="(val) => (row.item.agreed_price = row.item[val])"
            >
              {{ '€' + format_num(row.item[price], 4) }}
            </b-form-radio>

            <div v-else>
              {{ formatCurrency(row.item[price], price, row.item) }}
            </div>
          </div>
        </template>
      </i-table>
    </template>
  </div>
</template>

<script lang="ts">
import formatDateFilter from 'innicore/common/formatDateFilter'
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import {
  FetchPriceAgreementsDocument,
  MutatePriceAgreementsDocument,
  PriceQuotationDocument,
  PriceQuotationExcelDocument,
} from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'
import AddDataModal from 'innicore/views/price-agreements/AddDataModal.vue'
import AddToPriceAgreementsModal from 'innicore/views/price-agreements/AddToPriceAgreementsModal.vue'
import GenerateNewDataModal from 'innicore/views/price-agreements/GenerateNewDataModal.vue'
import GeneratePDFModal from 'innicore/views/price-agreements/GeneratePDFModal.vue'
import PriceAgreementsHelp from 'innicore/views/price-agreements/PriceAgreementsHelp.vue'

import { parseItem } from '@/common/parseItem'
import useTableDefaultFields, { DefaultFieldGroups } from '@/components/table/useTableDefaultFieldsCustomer'

export default {
  name: 'PriceAgreements',
  components: { AddToPriceAgreementsModal, GenerateNewDataModal, GeneratePDFModal, AddDataModal, PriceAgreementsHelp },
  mixins: [api_mixin, utils],
  data() {
    return {
      select_by: null,
      errors: [],
      pricelists: ['default_price', 'medium_price', 'best_price'],
      tableMode: null,
      fields: [],
      price_list_valid_on: this.today(),
      extraFields: this.defaultExtraFields(),
    }
  },
  computed: {
    allowed_selectors() {
      if (this.$store.state.user.is_employee) {
        return [['customer', 'item'], ['default_price'], ['date_range']]
      } else {
        return []
      }
    },
    actions() {
      return [
        {
          key: 'add_data',
          execute_global: this.select_by && this.select_by.customer ? this.showAddToPriceAgreementsModal : null,
          execute_bulk: this.showAddDataModal,
        },
        {
          key: 'edit',
          title: 'Edit',
          disabled: this.customer && this.$refs.ITable.items.every((item) => new Date(item.valid_from) <= new Date()),
        },
        {
          key: 'generate_new_data',
          modes: [TABLE_MODE.READ, TABLE_MODE.BULK_ADD],
          icon: 'box-arrow-in-down-left',
          variant: 'b-blue',
          title: 'Generate bulk data',
          disallow: false,
          execute_bulk: this.showGenerateNewDataModal,
          always_enabled: this.customer,
        },
        {
          key: 'generate_pdf',
          modes: [TABLE_MODE.READ],
          icon: 'file-earmark',
          variant: 'primary',
          title: 'Generate PDF quotation',
          disallow: false,
          execute_bulk: this.showGeneratePDFModal,
          always_enabled: this.customer,
        },
        {
          key: 'generate_excel',
          modes: [TABLE_MODE.READ],
          icon: 'file-earmark',
          variant: 'primary',
          title: 'Generate Excel quotation',
          disallow: false,
          execute_bulk: this.showGenerateExcelModal,
          always_enabled: this.customer,
        },
        {
          key: 'set_best_price',
          modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
          icon: 'currency-euro',
          variant: 'prices-best',
          title: 'Set best price',
          disallow: false,
          execute_bulk: (x) => this.setPrice(x, 'best_price'),
        },
        {
          key: 'set_medium_price',
          modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
          icon: 'currency-euro',
          variant: 'prices-medium',
          title: 'Set medium price',
          disallow: false,
          execute_bulk: (x) => this.setPrice(x, 'medium_price'),
        },
        {
          key: 'set_default_price',
          modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
          icon: 'currency-euro',
          variant: 'prices-default',
          title: 'Set default price',
          disallow: false,
          execute_bulk: (x) => this.setPrice(x, 'default_price'),
        },
      ]
    },
    customer() {
      return this.select_by ? this.select_by.customer : null
    },
    allow_edit() {
      return this.customer && this.select_by?.date_range
    },
    tableEditable() {
      return this.tableMode === TABLE_MODE.WRITE || this.tableMode === TABLE_MODE.BULK_ADD
    },
    selected_items() {
      return this.$refs.ITable.selectedItems
    },
  },
  mounted() {
    if (this.$store.state.user.is_customer) {
      this.select_by = { selected: 'customer', customer: this.$store.state.company }
    }
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.CustomerAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
  },
  methods: {
    defaultExtraFields() {
      return [
        {
          key: 'ItemCode',
          optional: false,
        },
        {
          key: 'agreed_price',
          label: 'Agreed price',
          sortable: true,
          optional: true,
          selected: true,
          editable: this.editable,
          type: Number,
          step: 0.0001,
          formatter: this.formatAgreedPrice,
          tdClass: this.cellStyle,
          insertBefore: 'unit',
        },
        {
          key: 'best_price',
          label: 'Best price',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-best',
          insertBefore: 'unit',
        },

        {
          key: 'best_price_diff',
          label: 'Best price diff (%)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: (value) => this.format_num(value, 2),
          tdClass: 'text-right prices-best',
          insertBefore: 'unit',
        },
        {
          key: 'best_price_diff_euros',
          label: 'Best price diff (€)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-best',
          insertBefore: 'unit',
        },
        {
          key: 'medium_price',
          label: 'Medium price',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-medium',
          insertBefore: 'unit',
        },
        {
          key: 'medium_price_diff',
          label: 'Medium price diff (%)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: (value) => this.format_num(value, 2),
          tdClass: 'text-right prices-medium',
          insertBefore: 'unit',
        },
        {
          key: 'medium_price_diff_euros',
          label: 'Medium price diff (€)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-medium',
          insertBefore: 'unit',
        },
        {
          key: 'default_price',
          label: 'Default price',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          step: 0.0001,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-default',
          insertBefore: 'unit',
        },
        {
          key: 'default_price_diff',
          label: 'Default price diff (%)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: (value) => this.format_num(value, 2),
          tdClass: 'text-right prices-default',
          insertBefore: 'unit',
        },
        {
          key: 'default_price_diff_euros',
          label: 'Default price diff (€)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-default',
          insertBefore: 'unit',
        },
        {
          key: 'valid_from',
          label: 'Valid from',
          sortable: true,
          optional: true,
          selected: true,
          editable: () => this.tableMode == TABLE_MODE.BULK_ADD,
          type: Date,
          insertBefore: 'unit',
        },
        {
          key: 'valid_to',
          label: 'Valid to',
          sortable: true,
          optional: true,
          selected: true,
          editable: () => this.tableMode == TABLE_MODE.BULK_ADD,
          type: Date,
          insertBefore: 'unit',
        },
        {
          key: 'price_list_valid_from',
          label: 'Price list valid from',
          sortable: true,
          optional: true,
          selected: false,
          type: Date,
          insertBefore: 'unit',
        },
        {
          key: 'price_list_valid_to',
          label: 'Price list valid to',
          sortable: true,
          optional: true,
          selected: false,
          type: Date,
          insertBefore: 'unit',
        },
        {
          key: 'is_default_price',
          label: 'Is default price',
          sortable: true,
          optional: true,
          selected: false,
          formatter: (value) => (value ? 'Yes' : 'No'),
          type: Boolean,
          insertBefore: 'unit',
        },
        {
          key: 'pricelist_selected',
          label: 'Pricelist selected',
          sortable: true,
          optional: true,
          selected: false,
          type: String,
          insertBefore: 'standard_package_quantity',
        },
      ]
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCurrencyDecimals(key, row) {
      return 2
    },
    formatAgreedPrice(value, key, item) {
      if (!item) {
        return value
      }
      if (item.agreed_price == item.default_price) {
        item.pricelist_selected = 'default_price'
      } else if (item.agreed_price == item.medium_price) {
        item.pricelist_selected = 'medium_price'
      } else if (item.agreed_price == item.best_price) {
        item.pricelist_selected = 'best_price'
      } else {
        item.pricelist_selected = ''
      }
      return '€' + this.format_num(value, 4)
    },

    setPrice(items, tier) {
      items.map((item) => (item.agreed_price = item[tier]))
    },
    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    getMutation() {
      return MutatePriceAgreementsDocument
    },
    mutateItems(rows, removed_rows) {
      const deletion_input = removed_rows.map((row) => this.parametersFromRow(row, true))
      let mutation_input = rows.map((row) => this.parametersFromRow(row, false))
      mutation_input = mutation_input.concat(deletion_input)
      return this.api_call_feedback(this.getMutation(), { input: mutation_input }).then((response) => {
        let errors = []
        if (response.data.errors) {
          errors = errors.concat(response.data.errors)
        }
        if (response.data.data?.MutatePriceAgreements.errors?.length) {
          errors = errors.concat(response.data.data.MutatePriceAgreements.errors)
        }
        const fields = response.data.data ? response.data.data.MutatePriceAgreements.price_agreements : []
        return { errors: errors, successful: fields.map((item) => this.parsePriceAgreementNode(item)) }
      })
    },
    add_data_from_store(data) {
      data.forEach((item) => {
        item.id = undefined
        item.company = this.customer.cmp_wwn
        item.is_default_price = item.default_price === item.agreed_price
      })
      this.$refs.ITable.add_data_bulk(data)
    },
    price_percent_difference(agreed_price, other_price) {
      // Other price can be default, medium or best price
      if (!agreed_price || !other_price) return ''

      return (((agreed_price - other_price) / other_price) * 100).toFixed(2)
    },
    defaultParsePriceAgreementNode(node) {
      const item = parseItem(node.item)

      return {
        ...item,
        company: node.company.cmp_wwn,
        is_default_price: node.price_list?.default_price === node.agreed_price,
        agreed_price: node.agreed_price,
        default_price: node.price_list?.default_price,
        default_price_diff: this.price_percent_difference(node.agreed_price, node.price_list?.default_price),
        default_price_diff_euros: node.agreed_price - node.price_list?.default_price,
        medium_price: node.price_list?.medium_price,
        medium_price_diff: this.price_percent_difference(node.agreed_price, node.price_list?.medium_price),
        medium_price_diff_euros: node.agreed_price - node.price_list?.medium_price,
        best_price: node.price_list?.best_price,
        best_price_diff: this.price_percent_difference(node.agreed_price, node.price_list?.best_price),
        best_price_diff_euros: node.agreed_price - node.price_list?.best_price,
        valid_from: node.valid_from,
        valid_to: node.valid_to,
        price_list_valid_from: node.price_list?.valid_from,
        price_list_valid_to: node.price_list?.valid_to,
        id: node.id,
        debnr: node.company.debnr,
        cmp_name: node.company.cmp_name,
        syscreated: node.syscreated,
        syscreator: node.syscreator.email,
        sysmodified: node.sysmodified,
        sysmodifier: node.sysmodifier.email,
      }
    },
    parsePriceAgreementNode(node) {
      return this.defaultParsePriceAgreementNode(node)
    },

    getQuery() {
      return FetchPriceAgreementsDocument
    },
    filterPriceAgreementDuplicates(responses) {
      // Because price agreements may overlap in the date range, yet we do fetch them twice,
      // we need to filter out duplicates
      const price_agreements_ids = []
      const price_agreements = []
      responses.forEach((response) => {
        response.data.data.prices.edges.forEach((edge) => {
          const node = edge.node
          if (!price_agreements_ids.includes(node.id)) {
            price_agreements_ids.push(node.id)
            price_agreements.push(this.parsePriceAgreementNode(node))
          }
        })
      })
      return price_agreements
    },
    async fetchItems(filters) {
      // this won't happen if we are a customer
      const company = filters?.customer?.cmp_wwn
      const item = filters?.item?.ItemCode
      const date_range = filters?.date_range
      const show_default_prices = filters?.default_price
      if ((company || item) && date_range) {
        const promises = []
        // From the date_range selector, we receive [{valid_from, valid_to}, ...] for every selected date range
        const date_ranges = formatDateFilter(date_range)
        // For every date range, create a promise that fetches the data
        date_ranges.forEach((valid_date_range) => {
          promises.push(this.api_call(this.getQuery(), { company, item, ...valid_date_range }))
        })
        // Await all promises and merge the results
        const responses = await Promise.all(promises)
        const priceAgreements = this.filterPriceAgreementDuplicates(responses)
        return priceAgreements.filter((x) => show_default_prices || x.default_price !== x.agreed_price)
      } else {
        return null
      }
    },
    parametersFromRow(row, del = false) {
      return {
        company: row.company,
        item: row.ItemCode,
        agreed_price: row.agreed_price,
        valid_from: row.valid_from,
        valid_to: row.valid_to,
        id: row.id,
        delete: del,
      }
    },
    async showAddDataModal() {
      this.$bvModal.show('add_data_modal')
    },
    async showAddToPriceAgreementsModal() {
      this.$bvModal.show('add_to_price_agreements_modal')
    },
    async showGeneratePDFModal() {
      this.$bvModal.show('generate_pdf_modal')
    },
    async showGenerateExcelModal() {
      this.$bvModal.show('generate_excel_modal')
    },
    async showGenerateNewDataModal() {
      this.$bvModal.show('generate_new_data_modal')
    },
    cellStyle(value, key, item) {
      const style = 'text-right prices-'

      if (item.agreed_price == item.best_price) {
        return style + 'best'
      }

      if (item.agreed_price < item.best_price) {
        return style + 'below-best'
      }

      if (item.agreed_price == item.default_price) {
        return style + 'default'
      }

      if (item.agreed_price > item.default_price) {
        return style + 'above-default'
      }

      return style + 'medium'
    },
    editable(value, key, item) {
      return (
        this.$store.state.user.is_employee &&
        (new Date(item.valid_from) > new Date() || this.tableMode == TABLE_MODE.BULK_ADD)
      )
    },
    cell(key) {
      return 'cell(' + key + ')'
    },
    getPriceQuotationQuery() {
      return PriceQuotationDocument
    },
    async get_file(valid_from, valid_to, language) {
      // Filters are now empty because we don't use them for this implementation of default prices
      return new Promise<void>((resolve) =>
        this.api_call(this.getPriceQuotationQuery(), {
          company: this.customer.cmp_wwn,
          valid_from: valid_from,
          valid_to: valid_to,
          language,
        }).then((response) => {
          const str = response.data.data.price_quotation
          const linkSource = `data:application/pdf;base64,${str}`
          const downloadLink = document.createElement('a')
          downloadLink.download = `Price agreements for ${this.customer.cmp_name}.pdf`
          downloadLink.href = linkSource
          downloadLink.click()
          resolve()
        })
      )
    },
    getPriceQuotationExcelQuery() {
      return PriceQuotationExcelDocument
    },
    async get_excel_file(valid_from, valid_to, language) {
      // Filters are now empty because we don't use them for this implementation of default prices
      return new Promise((resolve) =>
        this.api_call(this.getPriceQuotationExcelQuery(), {
          company: this.customer.cmp_wwn,
          valid_from: valid_from,
          valid_to: valid_to,
          language,
        }).then((response) => {
          const str = response.data.data.price_quotation_excel
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${str}`
          const downloadLink = document.createElement('a')

          downloadLink.download = `Price agreements for ${this.customer.cmp_name}.xlsx`
          downloadLink.href = linkSource
          downloadLink.click()
          resolve()
        })
      )
    },
  },
}
</script>

<style scoped></style>
