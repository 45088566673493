<template>
  <div>
    <template v-for="workflowLine in workflowLines">
      <WorkflowLineDetails :key="workflowLine.id" :workflow-line="workflowLine" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { Workflow } from '@/views/workflows/Workflow'
import WorkflowLineDetails from '@/views/workflows/WorkflowLineDetails.vue'

const props = defineProps({
  workflow: {
    type: Workflow,
    default: null,
  },
})

const workflowLines = computed(() => props.workflow.workflowlines)
</script>
