<template>
  <div>
    <template v-for="k in Object.keys(select_bys)">
      <select-by
        :key="k"
        :allowed="shadow_allowed[k]"
        :disabled="disabled"
        :field-selector-options="fieldSelectorOptions"
        :value="select_bys[k]"
        @update:selectBy="(val) => update(k, val)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import utils from 'innicore/mixins/utils'

export default {
  mixins: [utils],
  props: {
    alloweds: {
      type: Array,
      default() {
        return []
      },
      validator: (alloweds) => {
        return alloweds.reduce((a, b) => a && typeof b === typeof [])
      },
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    fieldSelectorOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      select_bys: [],
      shadow_allowed: [],
    }
  },
  mounted() {
    if (!this.are_disjoint(this.alloweds)) {
      throw new Error('The allowed selectors for the select-bys component have to be disjoint.')
    }
    this.initValues()
    this.emit()
  },
  methods: {
    /**
     * Initialize the values for the select-bys component.
     * The allowed value for the select-bys component, either in [['',''], [''], ...] format or in
     * [[{key: value}, {key: value }], ...] format. The value is the value that is selected by default.
     */
    initValues() {
      this.shadow_allowed = this.alloweds.map((allowed) => {
        // If a value is defined, it is selected by default.
        const values = allowed.filter((a) => typeof Object.values(a)[0] === 'object')
        this.select_bys.push(values.length > 0 ? values[0] : null)

        // Return the allowed values in [['',''], [''], ...] format
        return allowed.map((a) => {
          if (a instanceof Array) return a
          if (typeof a === typeof {}) return Object.keys(a)[0]
          return a
        })
      })
    },
    emit() {
      this.$emit('input', this.select_bys)
    },
    update(k, val) {
      k = Number(k)
      this.select_bys = [...this.select_bys.slice(0, k), val, ...this.select_bys.slice(k + 1)]
      this.emit()
    },
    are_disjoint(lists) {
      let union = []
      let lengths_sum = 0
      lists.forEach((list) => {
        union = [...union, ...list]
        lengths_sum += list.length
      })
      return new Set(union).size === lengths_sum
    },
  },
}
</script>

<style scoped></style>
