<template>
  <b-modal :id="name" centered size="lg" :title="title" @ok="handleOK" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <b-col cols="2">
          <b>Language</b>
        </b-col>
        <b-col>
          <b-select v-model="language" :options="options"> </b-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b>Valid from</b>
        </b-col>
        <b-col>
          <b-form-input v-model="valid_from" type="date" :state="!!valid_from"> </b-form-input>
          <b-form-invalid-feedback :state="!!valid_from"
            >You need to fill in a valid from date to generate a pricelist</b-form-invalid-feedback
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b>Valid to</b>
        </b-col>
        <b-col>
          <b-form-input v-model="valid_to" type="date" :state="!!valid_to"></b-form-input>
          <b-form-invalid-feedback :state="!!valid_to"
            >You need to fill in a valid to date to generate a pricelist</b-form-invalid-feedback
          >
        </b-col>
      </b-row>

      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">{{
              error
            }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>
      <hit-button variant="primary" :click="handleOK" :disabled="!is_valid"> OK </hit-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  props: {
    callback: Function,
    name: String,
    title: String,
  },
  data() {
    return {
      valid_from: null,
      valid_to: null,
      language: 'EN',
      errors: [],
      options: [
        { value: 'EN', text: 'English' },
        { value: 'NL', text: 'Dutch' },
        { value: 'GER', text: 'German' },
        { value: 'FR', text: 'French' },
      ],
    }
  },
  computed: {
    is_valid() {
      return this.valid_from && this.valid_to && this.language
    },
  },
  methods: {
    handleOK() {
      return new Promise((resolve) => {
        this.callback(this.valid_from, this.valid_to, this.language).then(() => resolve())
      })
    },
    resetModal() {
      this.valid_from = null
      this.valid_to = null
      this.language = 'EN'
      this.errors = []
    },
  },
}
</script>
