import type { PluginObject } from 'vue'
import type { Constructor } from 'vue/types/options'

export interface OptionDefinition {
  value: string
  text: string
}

export interface FieldDefinition {
  key: string
  label: string
  type: Constructor
  editable: boolean
  optional: boolean
  sortable: boolean
  selected?: boolean
  aggregations?: unknown[]
}

export interface ConstantsDefinition {
  appName: string
  select_by: {
    select_options: OptionDefinition[]
    select_values: string[]
  }
  companies: {
    companyFields: FieldDefinition[]
    editable_fields: FieldDefinition[]
    extendedCompanyFields: FieldDefinition[]
  }
  orders: {
    orderUnits: OptionDefinition[]
    orderWiths: OptionDefinition[]
  }
}

export const constants: ConstantsDefinition = {
  appName: 'Innicore',
  select_by: {
    select_options: [
      { value: 'customer', text: 'Customer' },
      { value: 'supplier', text: 'Supplier' },
      { value: 'shipment', text: 'Shipments' },
      { value: 'item', text: 'Item' },
      { value: 'app', text: 'App' },
      { value: 'user', text: 'User' },
      { value: 'data_type', text: 'Data type' },
      { value: 'rolling_fc_date', text: 'Forecast date' },
      { value: 'date_range', text: 'Date range' },
      { value: 'field', text: 'Field Name' },
      { value: 'done', text: 'View all' },
      { value: 'default_price', text: 'Show defaults' },
    ],

    // Generated below
    select_values: [],
  },
  companies: {
    companyFields: [
      {
        key: 'cmp_name',
        label: 'Name',
        sortable: true,
        optional: false,
        type: String,
        editable: false,
      },
      {
        key: 'city',
        label: 'City',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'country',
        label: 'Country',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'cmp_fadd1',
        label: 'Invoice Address',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'cmp_fcity',
        label: 'Invoice City',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'country',
        label: 'Invoice Country',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'cmp_acc_man',
        label: 'Account Manager',
        sortable: true,
        optional: true,
        type: Number,
        editable: false,
      },
      {
        key: 'cmp_tel',
        label: 'Phone',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'cmp_fax',
        label: 'Fax',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'debnr',
        label: 'Debtor',
        sortable: true,
        optional: true,
        selected: true,
        type: Number,
        editable: false,
        aggregations: [],
      },
      {
        key: 'crdnr',
        label: 'Creditor',
        sortable: true,
        optional: true,
        selected: true,
        type: Number,
        editable: false,
        aggregations: [],
      },
      {
        key: 'contact_person_name',
        label: 'Name Contact Person',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'contact_person_email',
        label: 'Email Contact Person',
        sortable: true,
        optional: true,
        selected: true,
        type: String,
        editable: false,
      },
      {
        key: 'country_desc_nl',
        label: 'Country Description NL',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'country_desc_de',
        label: 'Country Description DE',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'country_desc_en',
        label: 'Country Description EN',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'country_desc_fr',
        label: 'Country Description FR',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'payment_condition_desc_nl',
        label: 'Payment Condition NL',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'payment_condition_desc_de',
        label: 'Payment Condition DE',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'payment_condition_desc_en',
        label: 'Payment Condition EN',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
      {
        key: 'payment_condition_desc_fr',
        label: 'Payment Condition FR',
        sortable: true,
        optional: true,
        type: String,
        editable: false,
      },
    ],

    // Generated below
    editable_fields: [],
    extendedCompanyFields: [],
  },
  orders: {
    orderUnits: [
      { value: 'm', text: 'Metres (m)' },
      { value: 'm2', text: 'Squared Metres (m2)' },
      { value: 'pc', text: 'Pieces (pc)' },
    ],
    orderWiths: [
      { text: 'Lienesch item code', value: 'ItemCode' },
      { text: 'Item description', value: 'Description' },
      { text: 'Customer item code', value: 'ItemCodeAccount' },
    ],
  },
}
constants.select_by.select_values = constants.select_by.select_options.map((option) => option.value)
constants.companies.editable_fields = constants.companies.companyFields.filter((field) => field.editable)
constants.companies.extendedCompanyFields = [...constants.companies.companyFields]

export const Constants: PluginObject<void> = {
  install: function (Vue) {
    Vue.prototype.$constants = constants
  },
}
