export const iconEdited = {
  icon: 'patch-plus-fill',
  variant: 'primary',
  hover_message: 'This row has been recently edited.',
}
export const iconCreated = {
  icon: 'patch-plus-fill',
  variant: 'success',
  hover_message: 'This row has been recently added.',
}
