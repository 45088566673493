<template>
  <div>
    <slot name="title" v-bind="order" />

    <b-container v-if="order.company && showUpload" class="con">
      <b-row v-if="!order.order_id">
        <b-col cols="12">
          <slot name="file-upload" />
          <file-importer :file-parse-callback="uploadCallback" app-name="order" :hide-on-success="true" />
        </b-col>
      </b-row>
    </b-container>

    <OrderDetails
      :order="order"
      :order-with.sync="order_with"
      :purchase="purchase"
      @editAttribute="
        (key, value) => {
          order[key] = value
        }
      "
    />

    <i-table
      v-if="showTable"
      ref="ITable"
      :fetch-items="fetchItems"
      :fields="fields"
      :actions="actions"
      app-name="order"
      :settings="{ magic_filter: false, disable_edit_lock: true }"
      :initial-mode="initialMode"
    >
      <template #help-tab>
        <OrderPageHelp />
      </template>
      <template
        v-for="itemAttribute in ['ItemCode', 'Description', 'ItemCodeAccount']"
        #[editcell(itemAttribute)]="row"
      >
        <template v-if="order_with === itemAttribute && isOrderEditable">
          <collection-item-selector
            :key="itemAttribute"
            :value="row.item.item"
            :select_by="itemAttribute"
            @input="(item) => $set(row.item, 'item', item)"
          />
        </template>
      </template>

      <template #editcell(order_unit)="row">
        <b-form-select
          :disabled="!row.item.item"
          :options="getOrderUnitOptions(row.item)"
          :state="!!row.item.order_unit"
          :value="row.item.order_unit"
          @input="(order_unit) => $set(row.item, 'order_unit', order_unit)"
        />
      </template>

      <template v-if="isOrderEditable" #cell(order_quantity)="row">
        {{ getOrderQuantity(row.item) }}
        <template v-if="hasMinimumOrderQuantity() && row.item.order_quantity !== row.item.requested_order_quantity">
          <b-icon :id="'calculation-tooltip-row' + row.item._index" icon="question-circle" variant="warning" />

          <b-tooltip :target="'calculation-tooltip-row' + row.item._index" triggers="hover">
            Your desired quantity differs from the quantity you will order, for more information press the blue button
            on your right.
          </b-tooltip>
        </template>
      </template>

      <template v-if="!isOrderEditable" #cell(discount)="row">
        {{ discountFormatter(row.item.discount) }}
      </template>

      <template #editcell(requested_shipping_date)="row">
        <typed-form-input
          :key="row.item._index"
          :disabled="!row.item.item"
          :state="!!row.item.requested_shipping_date || !!order.requested_shipping_date"
          :type="Date"
          :value="row.item.requested_shipping_date || order.requested_shipping_date"
          @input="
            (requested_shipping_date) => {
              if (requested_shipping_date === order.requested_shipping_date && !row.item.requested_shipping_date) {
                return
              }
              $set(row.item, 'requested_shipping_date', requested_shipping_date)
            }
          "
        />
      </template>
      <template #editcell(remark)="row">
        <typed-form-input
          :key="row.item._index"
          :disabled="!row.item.item"
          :type="String"
          :value="row.item.remark"
          placeholder="Remark"
          @input="(remark) => $set(row.item, 'remark', remark)"
        />
      </template>
      <template #editcell(batch)="row">
        <typed-form-input
          :key="row.item._index"
          :disabled="!row.item.item"
          :type="String"
          :value="row.item.batch"
          placeholder="Batch"
          @input="(batch) => $set(row.item, 'batch', batch)"
        />
      </template>
      <template #editcell(warehouse)="row">
        <typed-form-input
          :key="row.item._index"
          :disabled="!row.item.item"
          :type="String"
          :value="row.item.warehouse"
          placeholder="Warehouse"
          @input="(warehouse) => $set(row.item, 'warehouse', warehouse)"
        />
      </template>
      <template #editcell(discount)="row">
        <typed-form-input
          :key="row.item._index"
          :disabled="!row.item.item || !$store.state.user.is_employee"
          :type="Number"
          :value="row.item.discount || order.discount"
          :state="(row.item.discount || order.discount) && isDiscountValid(row.item.discount || order.discount)"
          placeholder="Discount"
          @input="
            (discount) => {
              if (discount === order.discount && !row.item.discount) {
                return
              }
              $set(row.item, 'discount', discount)
            }
          "
        />
      </template>

      <template #row-details="row">
        <b-card>
          <template v-if="row.item.requested_order_quantity">
            <template v-if="row.item.order_unit === 'm2'">
              The desired quantity {{ getRequestedOrderQuantity(row.item) }} / {{ row.item.item.width }} (cm) equals
              {{ getRequestedOrderQuantityInSalesUnit(row.item) }}
              <br />
            </template>
            <template v-if="!hasMinimumOrderQuantity()">
              <span class="text-success">This looks great!</span>
              <br />
            </template>
            <template v-else>
              <template v-if="row.item.requested_order_quantity < row.item.minimum_order_quantity">
                The desired quantity {{ getRequestedOrderQuantity(row.item) }} is less than the minimum order quantity
                of
                {{ getMinimumOrderQuantity(row.item) }}
                <br />
                <span class="text-danger">
                  If you place this order, the order quantity will be automatically rounded to
                  {{ getMinimumOrderQuantity(row.item) }}
                </span>
                <br />
              </template>
              <template v-else-if="row.item.requested_order_quantity % row.item.item.standard_package_quantity === 0">
                <span class="text-success">This looks great!</span>
                <br />
              </template>
              <template v-else>
                <template v-if="row.item.order_unit !== 'pc'">
                  <span class="text-danger">
                    The ordered quantity will be automatically rounded to {{ getOrderQuantity(row.item) }}
                  </span>
                  as we sell this fabric per {{ getSalesQuantity(row.item) }}.<br />
                </template>
                {{ getRequestedOrderQuantityInSalesUnit(row.item) }} / {{ getSalesQuantity(row.item) }} =
                {{ format_num(row.item.requested_order_quantity / row.item.item.standard_package_quantity, 2) }} =
                {{
                  Math.round(row.item.requested_order_quantity_in_sales_unit / row.item.item.standard_package_quantity)
                }}
                sales quantities
                <br />
                Order quantity = {{ getSalesQuantity(row.item) }} *
                {{
                  Math.round(row.item.requested_order_quantity_in_sales_unit / row.item.item.standard_package_quantity)
                }}
                =
                {{ getOrderQuantity(row.item) }}
                <br />
              </template>
            </template>
          </template>
          <template v-else>
            Please fill out the fields required for calculating the order quantity.
            <br />
          </template>
        </b-card>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import OrderDetails from '@/components/order/OrderDetails.vue'
import { FileOrderDocument, MutateOrderDocument, OrderDocument, OrderStatus } from '@/graphql/generated'
import OrderPageHelp from '@/views/order/OrderPageHelp.vue'
import { ExactOrder, ExactOrderLine } from '@/views/order/exact_types'
import { HITOrder, HITOrderLine } from '@/views/order/types'

export default {
  components: { OrderPageHelp, OrderDetails },
  mixins: [api_mixin, utils],
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
    purchase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      order: new HITOrder(),
      deletedHITOrderLines: [],
      file: null,
      order_with: null,
      all_table_headers: [
        {
          key: 'ItemCode',
          label: 'Lienesch item code',
          optional: true,
          selected: true,
        },
        {
          key: 'Description',
          label: 'Item description',
          optional: true,
          selected: true,
        },
        {
          key: 'ItemCodeAccount',
          label: 'Customer item code',
          optional: true,
        },
        { key: 'requested_order_quantity', label: 'Desired quantity', optional: false, editable: true, type: Number },
        {
          key: 'order_unit',
          label: 'Order unit',
          optional: false,
          editable: true,
        },
        { key: 'width', label: 'Width (cm)', optional: true },
        {
          key: 'standard_package_quantity',
          label: 'Sales quantity',
          optional: true,
          checkbox_text: 'Default sales quantity',
          formatter: this.salesQuantityFormatter,
        },
        {
          key: 'minimum_order_quantity',
          label: 'Minimum order quantity',
          optional: true,
          checkbox_text: 'Minimum order quantity',
          formatter: this.moqFormatter,
        },
        { key: 'order_quantity', label: 'Order quantity', optional: false, formatter: this.orderQuantityFormatter },
        {
          key: 'cut_length_length',
          label: 'Cutlength Length (cm)',
          optional: true,
          customer_disabled: true,
          editable: true,
          type: Number,
        },
        {
          key: 'cut_length_width',
          label: 'Cutlength Width (cm)',
          optional: true,
          customer_disabled: true,
          editable: true,
          type: Number,
        },
        {
          key: 'requested_shipping_date',
          label: 'Requested shipping',
          optional: true,
          selected: true,
          checkbox_text: 'Requested shipping date',
          editable: true,
          type: Date,
        },
        { key: 'remark', label: 'Remark', optional: true, checkbox_text: 'Order line remark' },
        { key: 'batch', label: 'Batch', optional: true, editable: true },
        { key: 'warehouse', label: 'Warehouse', optional: true },
        {
          key: 'discount',
          label: 'Discount',
          optional: true,
          editable: true,
          type: Number,
          formatter: this.discountFormatter,
        },
        { key: 'actions', label: 'Actions', optional: false },
      ],
    }
  },
  computed: {
    fields() {
      if (!this.isOrderEditable) {
        const exclude = ['requested_order_quantity', 'order_unit', 'actions']
        return this.all_table_headers.filter((field) => !exclude.includes(field.key))
      }
      // make sure that the field that corresponds to what is selected in order_with is also editable
      const fields = [...this.all_table_headers]
      if (this.order_with) {
        const orderWithFieldIndex = fields.findIndex((field) => field.key === this.order_with)
        const orderWithField = fields[orderWithFieldIndex]
        const editableField = Object.assign({}, orderWithField)
        editableField.editable = true
        fields[orderWithFieldIndex] = editableField
      }
      return fields
    },
    actions() {
      return [
        {
          key: 'toggle_details',
          disallow: false,
        },
        {
          key: 'add_data',
          modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
          execute_global: this.addOrderLine,
        },
        {
          key: 'save_draft',
          title: 'Save Draft',
          icon: 'file-earmark',
          disabled: !this.isOrderValid,
          modes: [TABLE_MODE.WRITE, TABLE_MODE.BULK_ADD],
          variant: 'secondary',
          execute_global: () => this.submitOrder(OrderStatus.DRAFT),
        },
        {
          key: 'save',
          title: 'Place Order',
          disabled: !this.isOrderValid,
          execute_global: () => this.submitOrder(OrderStatus.SENT),
        },
        {
          key: 'edit',
          disallow: true,
        },
        {
          key: 'cancel',
          disallow: true,
        },
        {
          key: 'delete',
          execute: this.removeItem,
        },
      ]
    },
    orderUnits() {
      return this.$constants.orders.orderUnits
    },
    isOrderDraft() {
      return this.order.status === OrderStatus.DRAFT
    },
    enabled_table_headers() {
      if (this.$store.state.user.is_customer) {
        return this.all_table_headers.filter((header) => !header.customer_disabled)
      }
      return this.all_table_headers
    },
    showTable() {
      const show = !this.isOrderEditable || (!!this.order.unit && this.order_with != null)
      return show
    },
    isOrderEditable() {
      return !this.order.order_id || this.isOrderDraft
    },
    hasValidOrderQuantities() {
      return this.order.orderLines.every((orderLine) => !!orderLine.order_quantity)
    },
    isOrderValid() {
      return (
        (this.order.requested_shipping_date ||
          this.order.orderLines.every((orderLine) => orderLine.requested_shipping_date)) &&
        this.hasValidOrderQuantities
      )
    },
    collectionIsFetched() {
      return !!this.$store.getters.getCollectionItems
    },
    showUpload() {
      return this.collectionIsFetched
    },
    initialMode() {
      return this.isOrderEditable ? TABLE_MODE.WRITE : null
    },
  },
  watch: {
    'order.company': function () {
      if (this.order.company) {
        this.fetchCollection(this.order.company.cmp_wwn)
      } else {
        this.resetOrder()
      }
    },
    order_with() {
      const field = this.all_table_headers.find((field) => field.key === this.order_with)
      field.selected = true
    },
  },
  async mounted() {
    if (this.$route.params.order_id !== undefined) {
      this.getRoutedOrder()
    }
    if (this.$store.state.user.is_customer) {
      // If user is customer, the backend makes sure the right collection gets fetched
      this.order.company = this.$store.state.company
    }
  },
  methods: {
    fetchItems() {
      return this.order.orderLines
    },
    selectFields(keys, select = true) {
      this.all_table_headers
        .filter((field) => keys.includes(field.key))
        .forEach((field) => {
          field.selected = select
        })
    },
    hasMinimumOrderQuantity() {
      return !this.purchase
    },
    getQuantityDecimalPlaces() {
      return 1
    },
    moqFormatter(value, key, item) {
      return this.getMinimumOrderQuantity(item)
    },
    salesQuantityFormatter(value, key, item) {
      return this.getSalesQuantity(item)
    },
    orderQuantityFormatter(value, key, item) {
      return this.getOrderQuantity(item)
    },
    discountFormatter(value) {
      return `${value}%`
    },
    getSalesQuantity(item) {
      return item && item.standard_package_quantity && item.item.unit
        ? `${this.format_num(item.standard_package_quantity, this.getQuantityDecimalPlaces())} (${item.item.unit})`
        : null
    },
    getMinimumOrderQuantity(item) {
      if (!item) return null
      if (!item.minimum_order_quantity) return null
      if (item.minimum_order_quantity === undefined) return null
      if (!item.item) return null
      if (!item.item.unit) return null

      return `${this.format_num(item.minimum_order_quantity, this.getQuantityDecimalPlaces())} (${item.item.unit})`
    },
    getOrderQuantity(item) {
      if (!item.item?.unit) return null
      if (this.purchase) return this.getRequestedOrderQuantityInSalesUnit(item)

      return `${this.format_num(item.order_quantity, this.getQuantityDecimalPlaces())} (${item.item.unit})`
    },
    getRequestedOrderQuantity(item) {
      if (item.item) {
        return `${this.format_num(item.requested_order_quantity, this.getQuantityDecimalPlaces())} (${item.order_unit})`
      }
    },
    getRequestedOrderQuantityInSalesUnit(item) {
      if (item.item?.unit) {
        return `${this.format_num(item.requested_order_quantity_in_sales_unit, this.getQuantityDecimalPlaces())} (${
          item.item.unit
        })`
      }
      return null
    },
    resetOrder() {
      this.order = new HITOrder()
      this.file = null
      this.order_with = null
      this.deletedHITOrderLines = []
    },
    getMutation() {
      return MutateOrderDocument
    },
    getMutationData(data) {
      return data.MutateOrder
    },
    // here?
    async submitOrder(status) {
      if (!this.validateOrderInput()) {
        return
      }
      this.order.status = status

      const response = await this.api_call(
        this.getMutation(),
        {
          input: this.getOrderInput(),
        },
        { feedback_options: true }
      )
      this.onSubmitSuccess(this.getMutationData(response.data.data).order)
    },
    onSubmitSuccess(order) {
      if (!this.onSuccess) {
        if (this.order.order_id) {
          this.$router.go(this.$router.currentRoute) // means we didn't create but updated
        } else {
          this.goToOrder(order.order_id)
        }
      } else {
        this.onSuccess(order)
      }
    },
    goToOrder(orderId) {
      this.$router.push({
        name: 'ViewOrder',
        params: { order_id: orderId },
      })
    },
    validateOrderInput() {
      if (this.$store.state.user.is_employee && !this.order.company) {
        this.$bvModal.msgBoxOk("You can't submit an order without a valid customer.", { title: 'Warning!' })
        return false
      } else if (this.order.reference === '') {
        this.$bvModal.msgBoxOk("You can't submit an order without an order reference.", { title: 'Warning!' })
        return false
      } else if (!this.order.requested_shipping_date) {
        this.$bvModal.msgBoxOk("You can't submit an order without an requested shipping date.", { title: 'Warning!' })
        return false
      } else if (this.order.requested_shipping_date < this.today()) {
        this.$bvModal.msgBoxOk("The requested shipping date can't be earlier than today!", { title: 'Warning!' })
        return false
      } else if (!(this.order instanceof HITOrder)) {
        this.$bvModal.msgBoxOk('It is not possible to modify orders from Exact')
      } else if (!this.order.orderLines.length) {
        this.$bvModal.msgBoxOk('Add at least 1 item to your order.', { title: 'Warning!' })
        return false
      }
      return true
    },
    getOrderInput() {
      const hitOrderLines = this.getHITOrderLines()
      if (!hitOrderLines) return

      return {
        order_id: this.order.order_id,
        order_file_id: this.order.order_file_id,
        company: this.$store.state.user.is_employee ? this.order.company.cmp_wwn : '',
        reference: this.order.reference,
        requested_shipping_date: this.order.requested_shipping_date,
        status: this.order.status,
        order_lines: hitOrderLines,
      }
    },
    getHITOrderLines() {
      const hitOrderLines: Record<string, unknown>[] = []
      for (let i = 0; i < this.order.hitOrderLines.length; i++) {
        const line = this.order.hitOrderLines[i]
        if (!line.item) {
          this.$bvModal.msgBoxOk('Order line ' + (i + 1) + ' has no selected item. Please select one and try again.', {
            title: 'Warning!',
          })
          return
        }
        if (!line.order_quantity) {
          this.$bvModal.msgBoxOk(
            'Order line ' + (i + 1) + ' has no order quantity specified. Please fill out all fields and try again.',
            { title: 'Warning!' }
          )
          return
        }

        const discount = line.discount || this.order.discount
        const parsedDiscount = Number(discount)
        if (!isNaN(parsedDiscount) && (parsedDiscount < 0 || parsedDiscount > 100)) {
          this.$bvModal.msgBoxOk(
            'Order line ' + (i + 1) + ' has a discount below 0 or above 100. Please change it and try again.',
            { title: 'Warning!' }
          )
          return
        }

        hitOrderLines.push({
          id: line.id,
          item: line.ItemCode,
          requested_order_quantity: line.requested_order_quantity,
          requested_order_unit: line.order_unit,
          requested_shipping_date: line.requested_shipping_date || this.order.requested_shipping_date,
          cut_length_length: line.cut_length_length,
          cut_length_width: line.cut_length_width,
          remark: line.remark,
          batch: line.batch,
          warehouse: line.warehouse,
          is_shipped: line.is_shipped,
          discount: isNaN(parsedDiscount) || !this.$store.state.user.is_employee ? undefined : discount,
        })
      }
      return hitOrderLines
    },
    addOrderLine() {
      let orderLine: HITOrderLine | ExactOrderLine | null = null
      if (this.order instanceof ExactOrder) {
        orderLine = new ExactOrderLine(this.order)
      } else if (this.order instanceof HITOrder) {
        orderLine = new HITOrderLine(this.order)
      }
      this.$refs.ITable.addItem(orderLine)
    },
    async fetchCollection(ac) {
      await this.$store.dispatch('fetchCollection', { accountcode: ac })
    },
    async parseOrder(response) {
      const data = response.data.data
      if (data.ExactOrder.edges.length) {
        const node = data.ExactOrder.edges[0].node
        await this.parseExactOrder(node)
      } else if (data.Order.edges.length) {
        const node = data.Order.edges[0].node
        await this.parseHITOrder(node)
      }
      if (!this.isOrderDraft) {
        const remove_cols = ['requested_order_quantity', 'order_unit', 'actions']
        for (let col in remove_cols) {
          col = remove_cols[col]
          const index = this.enabled_table_headers.map((e) => e.key).indexOf(col)
          this.enabled_table_headers.splice(index, 1)
        }
      }
      if (this.order.hitOrderLines?.some((orderLine) => orderLine.discount !== undefined)) {
        const index = this.enabled_table_headers.findIndex((column) => column.key === 'actions')
        this.enabled_table_headers.splice(index, 0, 'discount')
      }
      this.order.company = this.$store.getters.getCompanyById(this.order.company.cmp_wwn)
    },
    async parseExactOrder(node) {
      // Wait for fetch collection, then execute the rest
      await this.fetchCollection(node.company.cmp_wwn)
      const orderLines = node.exact_order_lines
      delete node.exact_order_lines
      const order = new ExactOrder(node)
      order.unit = 'm'
      order.status_text = this.status_text(order.status)
      order.status_color = this.status_color(order.status)
      this.order = order
      this.order_with = 'ItemCode'
      order.exactOrderLines = orderLines.edges.map((orderLineEdge) => {
        const orderLine = orderLineEdge.node
        const ItemCode = orderLine.item.ItemCode
        orderLine.item = this.findBaseItem(ItemCode)
        return new ExactOrderLine(this.order, orderLine)
      })
    },
    async parseHITOrder(node) {
      // Wait for fetch collection, then execute the rest
      await this.fetchCollection(node.company.cmp_wwn)
      const orderLines = node.order_lines
      delete node.order_lines
      const order = new HITOrder(node)
      order.unit = 'm'
      order.status_text = this.status_text(order.status)
      order.status_color = this.status_color(order.status)
      this.order = order
      this.order_with = 'ItemCode'
      order.hitOrderLines = orderLines.edges.map((orderLineEdge) => {
        const orderLine = orderLineEdge.node
        const ItemCode = orderLine.item.ItemCode
        orderLine.item = this.findBaseItem(ItemCode)
        delete orderLine['order_quantity'] // Can't set order_quanity for HITOrderLine, as it's calculated.
        return new HITOrderLine(this.order, orderLine)
      })
    },
    async getRoutedOrder() {
      // console.log(`getting order for route with order_id ${this.$route.params.order_id}`)
      const response = await this.api_call(OrderDocument, { order_id: this.$route.params.order_id })
      await this.parseOrder(response)
    },
    getFileOrderAdditionalArguments() {
      return {}
    },
    async uploadCallback(upload) {
      this.order.reference = upload.name.split('.').slice(0, -1).join('.')
      const args = {
        file: upload,
        company: '',
      }
      if (this.$store.state.user.is_employee) {
        args.company = this.order.company.cmp_wwn
      }
      const response = await this.api_call(FileOrderDocument, {
        ...args,
        ...this.getFileOrderAdditionalArguments(args),
      })
      if (response.data.errors) {
        return { errors: response.data.errors }
      }
      const data = response.data.data.FileOrder
      const parsed_order = JSON.parse(response.data.data.FileOrder.parsed_order)
      this.handleParsedFileOrder(parsed_order, data.order_file.id)
      return { success: true }
    },
    handleParsedFileOrder(parsed_order, order_file_id) {
      this.order.order_file_id = order_file_id
      if (this.order.unit == null) {
        this.order.unit = parsed_order.order_unit
      }
      this.order_with = parsed_order.order_with

      if (parsed_order.reference) {
        this.order.reference = parsed_order.reference
      }

      this.order.hitOrderLines = parsed_order.order_lines.map((orderLine) => {
        const item = this.findBaseItem(orderLine.item) ?? { [this.order_with]: orderLine.item }
        return new HITOrderLine(this.order, {
          item: item,
          requested_order_quantity: orderLine.quantity,
          order_unit: orderLine.requested_order_unit,
          remark: orderLine.remark,
          requested_shipping_date: orderLine.requested_shipping_date,
          batch: orderLine.batch,
          warehouse: orderLine.warehouse,
        })
      })

      if (this.order.hitOrderLines.filter((line) => line.batch != null).length > 0) {
        this.selectFields(['batch', 'warehouse'])
      }
    },
    setCustomer(customer) {
      this.order.company = customer
    },
    getOrderUnitOptions(orderLine) {
      const item = orderLine.item
      if (item && item.unit === 'pc') {
        return ['pc']
      }
      return this.orderUnits.map((e) => e.value)
    },
    findBaseItem(orderWithValue) {
      return this.$store.getters.getAugmentedItemByAttribute(this.order_with, orderWithValue)
      // tries to find an item in the collection which matches the value of the attribute indicated by order_with
    },
    cell(key) {
      return `cell(${key})`
    },
    editcell(key) {
      return `editcell(${key})`
    },
    removeItem(item) {
      const index = this.order.hitOrderLines.indexOf(item)
      this.order.hitOrderLines.splice(index, 1)
    },
    isDiscountValid(discountText: string) {
      const discount = Number(discountText)
      return !isNaN(discount) && discount >= 0 && discount <= 100
    },
  },
}
</script>
