<script lang="ts">
import BasePriceList from 'innicore/views/price-list/PriceList.vue'

import { FetchPriceListDocument, MutatePriceListsDocument } from '@/graphql/generated'

export default {
  extends: BasePriceList,
  data() {
    return {
      extraFields: [
        ...this.defaultExtraFields(),
        // The formatters can only have a single argument for aggregation to work
        {
          key: 'best_price_m2',
          label: 'Best price (m2)',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-best',
          insertBefore: 'new_medium_price',
        },
        {
          key: 'medium_price_m2',
          label: 'Medium price (m2)',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-medium',
          insertBefore: 'new_default_price',
        },
        {
          key: 'default_price_m2',
          label: 'Default price (m2)',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: 'text-right prices-default',
          insertBefore: 'valid_from',
        },
        {
          key: 'is_m2_item',
          label: 'M2 item',
          sortable: true,
          optional: true,
          selected: false,
          type: Boolean,
          insertBefore: 'sysmodified',
        },
      ],
    }
  },
  methods: {
    getCurrencyDecimals(key, row) {
      return key === undefined || key.endsWith('_price_m2') || row === undefined || !row.is_m2_item ? 2 : 4
    },

    getQuery() {
      return FetchPriceListDocument
    },
    getMutation() {
      return MutatePriceListsDocument
    },

    parsePriceListNode(node) {
      const priceList = this.defaultParsePriceListNode(node)
      return {
        ...priceList,
        default_price_m2: node.default_price_m2,
        medium_price_m2: node.medium_price_m2,
        best_price_m2: node.best_price_m2,
      }
    },
  },
}
</script>
