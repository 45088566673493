import VueRouter, { type RouteConfig } from 'vue-router'

import { store } from 'innicore/store'
import AuthForgotPassword from 'innicore/views/auth/AuthForgotPassword.vue'
import AuthLogin from 'innicore/views/auth/AuthLogin.vue'
import AuthProfile from 'innicore/views/auth/AuthProfile.vue'
import AuthResetPassword from 'innicore/views/auth/AuthResetPassword.vue'
import AuthSignUp from 'innicore/views/auth/AuthSignUp.vue'
import Home from 'innicore/views/core/Home.vue'
import NotFound from 'innicore/views/core/NotFound.vue'
import PermissionDenied from 'innicore/views/core/PermissionDenied.vue'
import TemporarilyUnavailable from 'innicore/views/core/TemporarilyUnavailable.vue'
import Logs from 'innicore/views/logs/Logs.vue'
import PriceAgreements from 'innicore/views/price-agreements/PriceAgreements.vue'
import PriceList from 'innicore/views/price-list/PriceList.vue'

export const routes: RouteConfig[] = [
  { path: '/', name: 'Home', component: Home },
  { path: '/forgot-password', name: 'ForgotPassword', component: AuthForgotPassword },
  { path: '/login', name: 'Login', component: AuthLogin },
  { path: '/logs', name: 'Logs', component: Logs, meta: { isEmployeeOnly: true } },
  { path: '/permission-denied', name: 'PermissionDenied', component: PermissionDenied },
  { path: '/price-agreements', name: 'PriceAgreements', component: PriceAgreements, meta: { isEmployeeOnly: true } },
  { path: '/price-list', name: 'PriceList', component: PriceList, meta: { isEmployeeOnly: true } },
  { path: '/profile', name: 'Profile', component: AuthProfile },
  { path: '/reset-password/:token', name: 'ResetPassword', component: AuthResetPassword },
  { path: '/signup', name: 'Signup', component: AuthSignUp },
  { path: '/signup/:token', name: 'SignupToken', component: AuthSignUp },
  { path: '/unavailable', name: 'TemporarilyUnavailable', component: TemporarilyUnavailable },
]

export const notFoundRoute = { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }

function allow_groups(groups, user_permissions) {
  if (groups == undefined) return true
  return groups.some((group) => user_permissions.includes(group))
}

export function check_permissions(route_meta, user) {
  // Check if the user is a manager
  if (user.permissions.includes('managers')) {
    return true
  }
  // Check if page is employee only
  if (route_meta && route_meta?.isEmployeeOnly && !user.is_employee) return false

  // This page is only for the specified groups
  return allow_groups(route_meta?.allowedGroups, user.permissions)
}

export const createRouter = (clientRoutes: RouteConfig[]) => {
  const clientRouteNames = clientRoutes.map(({ name }) => name)

  const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes: [...clientRoutes, ...routes.filter(({ name }) => !clientRouteNames.includes(name)), notFoundRoute],
  })

  router.beforeEach((to, _from, next) => {
    if (check_permissions(to.meta, store.state.user)) return next()
    return next({ name: 'PermissionDenied' })
  })

  return router
}

export const router = createRouter([])
