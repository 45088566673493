<template>
  <b-modal
    :id="name"
    :title="`${row?.item.approved ? 'Approve' : 'Disapprove'} ${row?.item.ItemCode}`"
    centered
    size="lg"
    @ok="approveItem"
  >
    <b-container fluid>
      <b-list-group>
        <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
          {{ error }}
        </b-list-group-item>
      </b-list-group>
      <div class="text-center mt-2">
        <b>Item: {{ row?.item.ItemCode }} - {{ row?.item.Description }}</b
        ><br />
        Use the selector below to select the field you want to approve or disapprove for all original fabrics of this
        item.
      </div>
      <div class="mt-3">
        <b-row>
          <b-col cols="5">
            <generic-selector
              id="item_quality_approved_selector"
              v-model="selector"
              :data="approvedFields"
              :serializer="serializeField"
              invalid-feedback-message="This app does not exist!"
              :disabled="false"
            />
          </b-col>
          <b-col>
            <div style="height: 100%" class="d-flex align-items-center">
              <b-form-checkbox id="item_quality_approved_input" v-model="switchValue" switch />
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="row?.item.qualityAssurances?.length > 0 && row?.item.qualityAssurances?.length < 8" class="mt-4">
        Original fabrics:
        <b-list-group class="mt-1">
          <b-list-group-item v-for="originalFabric in row?.item.qualityAssurances" :key="originalFabric.pos">
            {{ originalFabric.originalFabric }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-container>
  </b-modal>
</template>
<script lang="ts">
export default {
  props: {
    mutateQualityAssurance: {
      type: Function,
      default: () => {
        return
      },
    },
    row: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    parseApproved: {
      type: Function,
      default: () => {
        return
      },
    },
    finalCallback: {
      type: Function,
      default: () => {
        return
      },
    },
  },
  data() {
    return {
      errors: [],
      selector: null,
      approvedFields: [
        { key: 'quality_at_door', label: 'Entry check' },
        { key: 'contractors', label: 'Contractors' },
        { key: 'quality_before', label: 'Precheck' },
        { key: 'trusted_supplier', label: 'Trusted supplier' },
      ],
      switchValue: false,
    }
  },
  methods: {
    async approveItem(defaultEvent) {
      defaultEvent.preventDefault()
      if (!this.selector) {
        this.errors.push('Please select a field to approve or disapprove')
        return
      }
      const data = { ItemCode: this.row.item.ItemCode }
      data[this.selector.key] = this.switchValue
      await this.mutateQualityAssurance(data)
      this.row.item.qualityAssurances.forEach((originalFabric) => {
        originalFabric[this.selector.key] = this.switchValue
        originalFabric.approved = this.parseApproved(originalFabric)
      })
      this.$bvModal.hide(this.name)
      this.finalCallback()
    },
    serializeField(field) {
      return field ? field.label : ''
    },
  },
}
</script>
