import type { ItemFragment } from '@/graphql/generated'

export function parseItem(item: ItemFragment) {
  return {
    ItemCode: item.ItemCode,
    Condition: item.Condition,
    Description: item.Description,
    assortment: item.Assortment,
    assortmentDescription: item.AssortmentDescription,
    CostPriceStandard: item.CostPriceStandard,
    SalesPackagePrice: item.SalesPackagePrice,
    PackageDescription: item.PackageDescription,
    type: item.Type,
    condition: item.Condition,
    hs_code: item.hs_code,
    warehouse: item.Warehouse,

    IsSalesItem: item.IsSalesItem,
    IsPurchaseItem: item.IsPurchaseItem,
    IsAssembled: item.IsAssembled,
    IsStockItem: item.IsStockItem,

    c01: item.label,
    c02: item.collection,
    c03: item.Class_03,
    c04: item.Class_04,
    c05: item.Class_05,
    c06: item.forecast_seasonality_group,
    c07: item.Class_07,
    c08: item.Class_08,
    c09: item.Class_09,
    c10: item.Class_10,
  }
}
