<template>
  <div v-if="$store.state.user.is_employee">
    <h1>Logs</h1>
    <p>On this page you can view logs, and revert changes.</p>

    <i-table
      ref="ITable"
      :actions="actions"
      :allowed_filters="allowed_filters"
      :allowed-selectors="[['app'], ['field']]"
      :fetch-items="fetchItems"
      :field-selector-options="field_selector_options"
      :fields="fields"
      app-name="logs"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #cell(type)="row">
        <b-badge v-if="row.item.type === 'A_0'" variant="success"> Create</b-badge>
        <b-badge v-if="row.item.type === 'A_1'" variant="warning"> Update</b-badge>
        <b-badge v-if="row.item.type === 'A_2'" variant="danger"> Delete</b-badge>
      </template>

      <template #cell(when)="row">
        {{ format_sysmodified(row.item.when) }}
      </template>
      <template #row-details="row">
        <b-card>
          <b-table :fields="calculate_field_details(row)" :items="calculate_item_details(row)">
            <template #cell(old)="row">
              <template v-if="row.item.old === 'None'">
                <b-icon aria-hidden="true" icon="x-circle" variant="danger" />
              </template>
              <template v-else>
                {{ row.item.old }}
              </template>
            </template>

            <template #cell(new)="row">
              <template v-if="row.item.new === 'None'">
                <b-icon aria-hidden="true" icon="x-circle" variant="danger" />
              </template>
              <template v-else>
                {{ row.item.new }}
              </template>
            </template>
          </b-table>
        </b-card>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import { FetchLogsDocument, MutateRevertLogDocument } from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

const MutationType = {
  Create: 0,
  Update: 1,
  Delete: 2,
}

export default {
  name: 'LogsView',
  mixins: [api_mixin, utils],
  data() {
    return {
      defaultFields: [
        { key: 'app', label: 'App', sortable: true, optional: true, type: String },
        { key: 'data_type', label: 'Data type', sortable: true, optional: true, type: String },
        { key: 'type', label: 'Mutation Type', sortable: false, optional: true, selected: true },
        { key: 'data_obj', label: 'Data', sortable: true, optional: true, selected: true, type: String },
        { key: 'data_description', label: 'Data description', sortable: true, optional: true, type: String },
        { key: 'changed_fields', label: 'Changed Fields', sortable: false, type: String },
        { key: 'who', label: 'Who', sortable: true, optional: true, selected: true, type: String },
        { key: 'when', label: 'When', sortable: true, optional: true, selected: true, type: String },
      ],
      defaultActions: [
        {
          key: 'toggle_details',
          title: 'Show details',
          icon: 'file-earmark-diff',
          variant: 'b-blue',
          disallow: false,
        },
        {
          key: 'revert',
          title: 'Revert change',
          icon: 'arrow-counterclockwise',
          variant: 'danger',
          execute: (item) => this.revert(item),
        },
      ],
      select_by: null,
      previous_fetch_param: '',
      field_selector_options: [],
      shadow_field_selector_options: [],
      field: null,
    }
  },
  computed: {
    allowed_filters() {
      return []
    },
    fields() {
      if (this.field) {
        // Reuse many fields, except changed_fields and add old and new
        return [
          this.defaultFields[0],
          this.defaultFields[1],
          this.defaultFields[2],
          this.defaultFields[3],
          this.defaultFields[4],
          {
            key: 'old',
            label: 'Old',
            sortable: false,
            optional: true,
            selected: true,
            variant: 'danger',
            type: String,
          },
          {
            key: 'new',
            label: 'New',
            sortable: false,
            optional: true,
            selected: true,
            variant: 'success',
            type: String,
          },
          this.defaultFields[6],
          this.defaultFields[7],
        ]
      } else {
        return this.defaultFields
      }
    },
    actions() {
      if (this.field) {
        // We do not want to be able to revert here, because we revert all changes
        const result = [
          // Create new result here because we do not want to modify the original
          this.defaultActions[0],
          this.defaultActions[1],
        ]
        result[1].disallowForRow = (row) => !row.item._showDetails
        return result
      }
      return this.defaultActions
    },
  },
  mounted() {
    if (!this.$store.state.user.is_employee) {
      throw 'Not allowed on this page' // Dit is niet nice denk ik. Je ziet deze louter in de console? Auth checking obv router??
    }
  },
  methods: {
    async fetchItems(filters) {
      const app = filters?.app?.app
      this.field = filters?.field?.field
      if (app) {
        this.previous_fetch_param = app
        return {
          query: FetchLogsDocument,
          parameters: {
            app: app,
          },
          after: (response) => {
            let logEntries = []
            response.data.data.Logs.edges.forEach(
              (obj) => (logEntries = logEntries.concat(this.parseLogEntryNode(obj.node)))
            )
            this.field_selector_options = this.shadow_field_selector_options
            return logEntries
          },
        }
      }
    },
    calculate_field_details() {
      return [
        { key: 'field', label: 'Field' },
        { key: 'old', label: 'Old' },
        { key: 'new', label: 'New' },
      ]
    },
    calculate_item_details(row) {
      const ret = []
      const changes = row.item.changes
      for (const key in changes) {
        ret.push({
          field: key,
          old: changes[key][0],
          new: changes[key][1],
          _cellVariants: { old: 'danger', new: 'success' },
        })
      }
      return ret
    },
    parseLogEntryNode(obj) {
      obj.changes = JSON.parse(obj.changes)
      if (this.field && !obj.changes[this.field]) {
        // Filter exists but the obj contains no changes relevant to the filter
        return []
      }
      const result = {
        id: obj.id,
        //Needed to access log_id at revert
        app: obj.content_type.app_label,
        data_type: obj.content_type.model,
        data_obj: obj.object_repr.split('\n')[0],
        data_description: obj.object_repr.split('\n')[1],
        type: obj.action,
        who: obj.actor ? obj.actor.email : 'unknown',
        when: obj.timestamp,
      }

      if (this.field) {
        result.old = obj.changes[this.field][0]
        result.new = obj.changes[this.field][1]
      } else {
        result.changed_fields = this.parseFieldsChanged(obj.changes)
      }
      result.changes = obj.changes

      this.shadow_field_selector_options = [
        ...new Set([...this.shadow_field_selector_options, ...Object.keys(obj.changes)]),
      ]
      return [result]
    },
    parseFieldsChanged(changesJson) {
      return Object.keys(changesJson).join(', ')
    },
    async revert(item) {
      if (item.type === 'A_0') {
        await this.api_call_feedback(MutateRevertLogDocument, { entry_id: item.id, mutationType: MutationType.Create })
        this.$refs.ITable.fetch()
      } else if (item.type === 'A_1') {
        await this.api_call_feedback(MutateRevertLogDocument, { entry_id: item.id, mutationType: MutationType.Update })
        this.$refs.ITable.fetch()
      } else {
        await this.api_call_feedback(MutateRevertLogDocument, { entry_id: item.id, mutationType: MutationType.Delete })
        this.$refs.ITable.fetch()
      }
    },
  },
}
</script>
