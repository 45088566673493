<template>
  <div>
    <vue-typeahead-bootstrap
      ref="typeAhead"
      :value="serialize(value)"
      :data="data"
      :disabled="disabled || valid || !data.length"
      :show-on-focus="true"
      :serializer="serializer"
      :disable-sort="disableSort"
      :placeholder="placeholder"
      :background-variant-resolver="variantFunction"
      :input-class="validation_class(valid)"
      background-variant="list-group-item"
      highlight-class="vbt-matched-text-custom"
      @hit="onHit"
      @input="(val) => (typed = val)"
    >
      <template #append>
        <b-button :disabled="disabled || !resettable" @click="onReset">
          <!-- if you wish to use a different visual for this button, you can do so using v-slot -->
          <slot name="reset-text">
            <b-icon icon="x"></b-icon>
          </slot>
        </b-button>
      </template>
    </vue-typeahead-bootstrap>
    <template v-if="resetConfirmationPrompt">
      <b-modal
        id="reset-modal"
        title="Reset confirmation"
        ok-title="Confirm"
        ok-variant="success"
        cancel-variant="danger"
        @ok="reset()"
      >
        {{ resetConfirmationPrompt }}
      </b-modal>
    </template>

    <b-form-invalid-feedback :state="valid">
      {{ invalidFeedbackMessage }}
    </b-form-invalid-feedback>
  </div>
</template>

<script lang="ts">
import utils from 'innicore/mixins/utils'

export default {
  name: 'GenericSelector',
  mixins: [utils],
  props: {
    value: {
      required: true,
      type: Object,
    },
    data: {
      type: Array,
      required: true,
    },
    serializer: {
      type: Function,
      default: String, // return the String function as the default serializer
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select an option...',
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    invalidFeedbackMessage: {
      type: String,
      default: 'This option does not exist!',
    },
    variantFunction: {
      // Function accepts row data in the selector and ouputs a string which represents the variant of the row
      type: Function,
      default: () => String,
    },
    resetConfirmationPrompt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      typed: null,
    }
  },
  computed: {
    valid() {
      if (this.value) {
        const matchingOption = (option) => JSON.stringify(option) === JSON.stringify(this.value)
        return !!this.data.find(matchingOption)
      }
      if (this.typed) {
        return false
      }
      return null
    },
    resettable() {
      return this.typed || this.value
    },
  },
  watch: {},
  methods: {
    reset() {
      this.emit(null)
      this.typed = null
      this.$refs.typeAhead.inputValue = ''
    },
    onHit(data) {
      this.emit(data)
    },
    onReset() {
      if (this.resetConfirmationPrompt) {
        this.promptResetConfirmation()
        return
      }
      this.reset()
    },
    serialize(o) {
      return o ? this.serializer(o) : ''
    },
    emit(o) {
      this.$emit('input', o)
    },
    promptResetConfirmation() {
      this.$bvModal.show('reset-modal')
    },
  },
}
</script>

<style scoped></style>
