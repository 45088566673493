<template>
  <div>
    <template v-if="type === Boolean">
      <b-form-select
        :name="name"
        :options="[true, false].map((bool) => ({ value: bool, text: formatter(bool) }))"
        :disabled="disabled"
        :state="state"
        :value="updated_value"
        @change="onChange"
      >
        <b-form-select-option :value="null" disabled> -- Please select an option --</b-form-select-option>
      </b-form-select>
    </template>
    <template v-else>
      <b-form-input
        :name="name"
        :value="updated_value"
        :type="type_to_form_type(type)"
        :step="step"
        :placeholder="placeholder ?? type_to_form_type(type)"
        :disabled="!type || disabled"
        :state="state"
        @change="onChange"
        @keydown.down.prevent="$emit('keydown', $event)"
        @keydown.up.prevent="$emit('keydown', $event)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Types from 'innicore/mixins/types'
import utils from 'innicore/mixins/utils'

export default {
  name: 'TypedFormInput',
  mixins: [utils],
  props: {
    name: {
      type: String,
      required: false,
      default: undefined,
    },
    value: {
      // it would be nice to validate the type of this thing, but we can't cross-validate props, since
      // "props are validated before a component instance is created"
      // https://v2.vuejs.org/v2/guide/components-props.html?redirect=true#Prop-Validation
      required: false,
      default: '',
    },
    type: {
      required: true,
      validator: function (value) {
        return value && [Boolean, Date, Number, String, Types.Email].includes(value)
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    step: {
      required: false, // only needed for Number inputs
      type: Number,
      default: null,
    },
    formatter: {
      required: false, // only needed for Boolean inputs
      type: Function,
      default: (bool) => (bool ? 'Yes' : 'No'),
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      typed_value: this.value, // Used to emit the proper type of value
      updated_value: this.value, // Used to update the input field
    }
  },
  computed: {
    fixedAmountOfDecimals: function () {
      if (this.step === 0.01) return 2
      if (this.step === 0.0001) return 4

      return null
    },
  },
  watch: {
    value(newValue) {
      this.onChange(newValue, false)
    },
  },
  methods: {
    process(value): string {
      // Manual processing of the value. Currently only done for Number inputs.
      if (this.type === Number && this.fixedAmountOfDecimals) {
        value = Number(value).toFixed(this.fixedAmountOfDecimals)
      }

      return value
    },
    convertStringToType(value: string) {
      if (!value) return null

      if (this.type === Boolean) return Boolean(value)
      if (this.type === Number) return Number(value)
      if (this.type === String) return String(value)
      if (this.type === Types.Email) return String(value)

      // No parsing here as we do not want to return a Date type, because that would include time information,
      // which breaks graphql queries. Instead, we simply return the string.
      if (this.type === Date) return value

      return value
    },
    onChange(value, emit = true) {
      this.updated_value = this.process(value)

      this.typed_value = this.convertStringToType(this.updated_value)

      if (emit) {
        this.$emit('input', this.typed_value)
      }
    },
  },
}
</script>

<style scoped></style>
