<template>
  <div>
    <select-bys
      v-if="allowedSelectors.length > 0"
      :alloweds="allowedSelectors"
      :disabled="disabled_select_bys"
      :field-selector-options="fieldSelectorOptions"
      @input="update_select_bys"
    />
    <reimagined-table
      ref="ReimaginedTable"
      :actions="allowedActions"
      :fields="allowedFields"
      :fluid="fluid"
      :mode="tableMode"
      :settings="tableSettings"
      :loading="busy"
    >
      <template v-if="inBulkAddMode" #bulk-edit-message>
        <b-alert show variant="warning">You are now adding {{ items.length }} items in bulk</b-alert>
      </template>

      <template #errors>
        <b-list-group>
          <b-list-group-item v-for="(error, index) in Object.keys(error_list)" :key="index" variant="danger">
            {{ error }}
            <ul>
              <li v-for="code in error_list[error]" :key="code">
                {{ code }}
              </li>
            </ul>
          </b-list-group-item>
        </b-list-group>
      </template>
      <template #empty-table>
        <template v-if="busy">
          <b-skeleton-table :columns="tableFields.length" :rows="50" />
        </template>

        <template v-else>
          <template v-if="select_bys">
            <template v-if="nothing_for_selection">
              <p style="text-align: center">
                <b-iconstack font-scale="8">
                  <b-icon icon="cloud" stacked />
                  <b-icon animation="throb" icon="info-circle" scale="0.4" stacked variant="warning" />
                </b-iconstack>
                <br />No data available for these parameters. Please try another filter selection, or add data.
              </p>
            </template>
            <template v-else-if="select_bys_selected">
              <template v-for="selected in select_bys_selected">
                <p :key="selected" style="text-align: center">
                  Please select a
                  {{
                    $constants.select_by.select_options.find((option) => option.value === selected).text.toLowerCase()
                  }}
                  above to load data.
                </p>
              </template>
            </template>
          </template>
        </template>
      </template>
      <template v-for="field in editableFields" #[cell(field.key)]="row">
        <slot
          v-if="typeof field.editable !== 'function' || field.editable(row.value, field.key, row.item)"
          :name="editcell(field.key)"
          v-bind="row"
        >
          <typed-form-input
            :key="field.key"
            :ref="field.key + row.index"
            v-model="row.item[field.key]"
            :step="field.step"
            :type="field.type"
            :placeholder="field.placeholder ?? field.label"
            :formatter="field.formatter"
            @keydown.up.prevent="keydown($event, field.key, row.index)"
            @keydown.down.prevent="keydown($event, field.key, row.index)"
          />
        </slot>
        <template v-else>
          {{ row.value }}
        </template>
      </template>
      <template
        v-if="editableFields.length > 0 && (inWriteMode || inBulkAddMode)"
        #extra-bulk-actions="{ selected_items, selected_fields }"
      >
        <table-tab-actions-bulk-edit
          :fields="editableFields.filter((f) => selected_fields.includes(f.key))"
          :disabled="!extra_bulk_actions_enabled(selected_items)"
          @editField="(key, f, value) => execute_bulk_edit(selected_items, key, f, value)"
        />
      </template>
      <template #lock-errors>
        <b-row>
          <b-col cols="12">
            <b-alert v-model="edit_lock_error.show" dismissible variant="warning">
              <b-icon icon="exclamation-triangle-fill" variant="warning" />
              {{ edit_lock_error.text }}
              <span v-b-tooltip.hover :title="edit_lock_error.info">
                <b-icon icon="question-circle" variant="info" />
              </span>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <template v-for="booleanField in booleanSwitchFields" #[cell(booleanField.key)]="data">
        <slot v-bind="data">
          <template v-if="inWriteMode || inBulkAddMode">
            <b-form-checkbox v-model="data.item[booleanField.key]" switch />
          </template>
          <template v-else>
            <b-icon-check-square-fill v-if="data.item[booleanField.key]" variant="primary" />
            <b-icon-x-square-fill v-else variant="disabled-grey" />
          </template>
        </slot>
      </template>

      <template #cell(syscreated)="row">
        {{ format_sysmodified(row.item.syscreated) }}
      </template>

      <template #cell(sysmodified)="row">
        {{ format_sysmodified(row.item.sysmodified) }}
      </template>

      <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </reimagined-table>
  </div>
</template>

<script lang="ts">
// todo: refactor select_by occurrences to select_bys
import { writeFile, utils as xlsxUtils } from 'xlsx'

import { clone } from 'innicore/common/cloning'
import TableActionsMixin from 'innicore/components/table/TableActionsMixin'
import TableDataMixin, { FETCH_STATE } from 'innicore/components/table/TableDataMixin'
import TableFieldsMixin from 'innicore/components/table/TableFieldsMixin'
import TableFiltersMixin from 'innicore/components/table/TableFiltersMixin'
import { iconCreated, iconEdited } from 'innicore/components/table/TableIconDefaults.ts'
import TableModeMixin, { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import TableSettingsMixin from 'innicore/components/table/TableSettingsMixin'
import TableSortMixin from 'innicore/components/table/TableSortMixin'
import TableTabActionsBulkEdit from 'innicore/components/table/TableTabActionsBulkEdit.vue'
import { MutateEditLockDocument } from 'innicore/graphql/generated'
import alerts, { VARIANT } from 'innicore/mixins/alerts'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  name: 'ITable',
  components: { TableTabActionsBulkEdit },
  mixins: [
    api_mixin,
    utils,
    alerts,
    TableDataMixin,
    TableFieldsMixin,
    TableModeMixin,
    TableFiltersMixin,
    TableSortMixin,
    TableActionsMixin,
    TableSettingsMixin,
  ],
  provide() {
    return {
      appName: this.appName,
    }
  },
  props: {
    mutateItems: {
      type: Function, // todo: rename
      default: null,
    },
    appName: {
      type: String,
      required: true,
    },
    settings: { type: Object, default: () => ({}) }, // todo: implement settings more dynamically. see ReimaginedTable
    fluid: {
      type: Boolean,
      default: true,
    },
    allowedSelectors: {
      type: Array,
      default: () => [], // todo: refactor this and usages to [] default
      required: false,
    },
    fieldSelectorOptions: {
      type: Array,
      required: false,
      default: null,
    },
    // add_data_modal todo: standardize the add data modal someday. tricky part to standardize are the inputs in the modal
  },
  data() {
    return {
      items_backup: [],
      removed_items: [],
      edit_lock_error: {
        show: false,
      },
      bulk_edit: {},
      increase_percentage: {},
      edited_items: 0,
      select_bys: null,
      errors: [],
    }
  },
  computed: {
    allow_edit() {
      return this.mutateItems && this.items.length > 0
    },
    disabled_select_bys() {
      return this.inWriteMode || this.inBulkAddMode || this.busy
    },
    select_bys_value() {
      if (this.select_bys) {
        const select_bys_value = {}
        this.select_bys.forEach((select_by) => {
          if (select_by?.selected) {
            select_bys_value[select_by.selected] = select_by ? select_by[select_by.selected] : null
          }
        })
        if (Object.values(select_bys_value).some((x) => x !== null)) {
          return select_bys_value
        }
      }
      return null
    },
    select_bys_selected() {
      if (this.select_bys) {
        const select_bys_selected = []
        this.select_bys.forEach((select_by) => {
          select_bys_selected.push(select_by?.selected)
        })
        if (this.any(select_bys_selected)) {
          return select_bys_selected
        }
      }
      return null
    },
    nothing_for_selection() {
      return this.select_bys_value && this.all(Object.values(this.select_bys_value))
    },
    edit_lock_name() {
      if (this.select_bys_value) {
        const customer = this.select_bys_value.customer
        const item = this.select_bys_value.item
        if (customer) {
          return `${this.appName}||${customer.debnr}` // todo: change this to cmp_wwn
        } else if (item) {
          return `${this.appName}||${Number(item.ItemCode)}`
        }
        //  todo: implement other select by results
      }
      return `${this.appName}||`
    },
    error_list() {
      const count = {}
      for (const error of this.errors) {
        if (count[error[1]]) {
          count[error[1]].push(error[0])
        } else {
          count[error[1]] = [error[0]]
        }
      }
      return count
    },
  },
  watch: {
    select_bys_value(new_select_bys_value) {
      this.items = []
      this.$emit('update:selectBy', new_select_bys_value)
      this.fetch()
    },
  },
  mounted() {
    if (this.initialMode === TABLE_MODE.WRITE) {
      this.enterEditMode()
    }
  },
  methods: {
    makeToast(itemCount, property) {
      property = property.split('_').join(' ') // Format the property name
      this.showToast(`Updated ${property} of ${itemCount} items`, VARIANT.SUCCESS)
    },
    update_select_bys(new_select_bys) {
      this.select_bys = new_select_bys
    },
    setItems(items) {
      if (items) {
        this.handleNewItems(items)
      }
      this.fetchState = FETCH_STATE.DONE
      this.edited_items = 0
    },
    execute_bulk_edit(selected_items, key, f, value) {
      selected_items.forEach((item) => this.$set(item, key, f(item[key], value)))
      this.edited_items = selected_items.length
      this.makeToast(selected_items.length, key)
    },
    addItem(item) {
      // for calling from outside, when adding items
      item._select = false
      if (!item._showDetails) {
        this.$set(item, '_showDetails', false)
      }
      if (!item._index) {
        this.$set(item, '_index', this.items.length)
      }
      this.sortBy = undefined
      item = this.add_icon(item, iconCreated)
      this.items.unshift(item)
    },
    add_icon(item, icon) {
      if (item._icons && !item._icons.includes(icon)) {
        item._icons.push(icon)
      } else {
        item._icons = [icon]
      }
      return item
    },
    toggleDetails(item) {
      item._showDetails = !item._showDetails
    },
    async MutateEditLock(deleteLock) {
      const params = {
        m: {
          name: this.edit_lock_name,
          delete: deleteLock,
        },
      }
      const response = await this.api_call(MutateEditLockDocument, params)
      return response.data.data.MutateEditLock
    },
    grabMutateEditLock() {
      if (this.tableSettings.disable_edit_lock) {
        return { success: true }
      }
      return this.MutateEditLock(false)
    },
    releaseMutateEditLock() {
      if (this.tableSettings.disable_edit_lock) {
        return { success: true }
      }
      return this.MutateEditLock(false)
    },

    async enterEditMode() {
      const response = await this.grabMutateEditLock()
      if (response.success) {
        this.items_backup = clone(this.items)
        this.tableMode = TABLE_MODE.WRITE
      } else {
        this.edit_lock_error.text = response.message.split(' Info: ')[0]
        this.edit_lock_error.info = response.message.split(' Info: ')[1]
        this.edit_lock_error.show = true
      }
    },

    async cancelEditMode() {
      this.tableMode = TABLE_MODE.READ
      this.items = this.items_backup
      this.items_backup = []
      this.removed_items = []
      this.errors = []
      this.releaseMutateEditLock()
    },

    async saveEdit() {
      this.errors = []
      const items_backup_stringified = this.items_backup.map((item) => JSON.stringify(item))
      const removed_items_stringified = this.removed_items.map((item) => JSON.stringify(item))
      const changed_items = this.items.filter(
        (item) =>
          !items_backup_stringified.includes(JSON.stringify(item)) &&
          !removed_items_stringified.includes(JSON.stringify(item))
      )
      const response = await this.mutateItems(changed_items, this.removed_items)
      if (response.errors) {
        const parsed_errors = response.errors?.map((line) => JSON.parse(JSON.parse(line)))
        this.errors = this.errors.concat(
          parsed_errors?.map((line) => [Object.keys(line)[0], line[Object.keys(line)[0]]])
        )
      }
      this.removed_items.forEach((item) => {
        this.removeFromTable(item)
      })
      this.removed_items = []

      // filter new items
      const new_items = changed_items.filter((item) => !item.id)
      // remove them from the list to be added from the response of the database
      new_items.forEach((item) => changed_items.splice(changed_items.findIndex((e) => e == item)))

      for (const mutatedItem of response.successful) {
        const original_item = changed_items.find((item) => item.id === mutatedItem.id)
        // If the original item is not found we do not set the fields of that item
        if (original_item) {
          Object.keys(mutatedItem).forEach((k) => {
            original_item[k] = mutatedItem[k]
          })
        } else {
          // add the new items to the changed items list with the response from the database
          changed_items.push(mutatedItem)
        }
      }
      if (!this.errors.length) {
        // if there are no errors continue as normal
        changed_items.forEach((item) => {
          this.add_icon(item, iconEdited)
        })
        //deselect all items in the table
        this.selectedItems.forEach((item) => (item._select = false))
        this.returnToReadMode()
      }
    },

    async saveBulkAdd() {
      this.errors = []
      const changed_items = this.items
      let successful_items = []
      const response = await this.mutateItems(changed_items, [])
      if (response.errors) {
        try {
          const parsed_errors = response.errors?.map((line) => JSON.parse(JSON.parse(line)))
          this.errors = this.errors.concat(
            parsed_errors?.map((line) => [Object.keys(line)[0], line[Object.keys(line)[0]]])
          )
        } catch (e) {
          this.errors = this.errors.concat([response.errors?.map((line) => line.message)])
        }
      }
      successful_items = successful_items.concat(response.successful)
      if (!this.errors.length) {
        this.items.length = 0
        this.items = this.items.concat(successful_items)
        this.items.forEach((item) => {
          this.add_icon(item, iconCreated)
        })
        this.items = this.items.concat(this.items_backup)
        this.returnToReadMode()
      } else {
        successful_items.forEach((item) => {
          this.add_icon(item, this.inBulkAddMode ? iconCreated : iconEdited)

          this.items_backup.push(item)
          const index = this.items.indexOf(item)
          if (index > -1) {
            this.items.splice(index, 1)
          }
        })
      }
    },

    async returnToReadMode() {
      this.tableMode = TABLE_MODE.READ
      this.removed_items = []
      this.items_backup = []
      // this.fetch()
      this.releaseMutateEditLock()
    },

    //This function should be able to copy and add multiple rows of data
    async add_data_bulk(selected_items) {
      // Make sure we set bulk add mode to true so the correct function is called on save
      const response = await this.grabMutateEditLock()
      if (response.success) {
        if (!this.inBulkAddMode) {
          this.items_backup = clone(this.items)
          this.tableMode = TABLE_MODE.BULK_ADD
          //This is needed to empty the array without destroying references to it.
          this.items.length = 0
        }

        selected_items.forEach((selected_item) => {
          let item1 = selected_item
          if (selected_item['id']) {
            item1 = clone(this.items_backup.find((item) => item.id === selected_item['id']))
            item1['id'] = undefined
          }
          this.items.push(item1)
        })
      } else {
        this.edit_lock_error.text = response.message.split(' Info: ')[0]
        this.edit_lock_error.info = response.message.split(' Info: ')[1]
        this.edit_lock_error.show = true
      }
      selected_items.forEach((item) => {
        item._select = true
      })
    },

    export_to_excel(selected_items) {
      const selected_fields = JSON.parse(JSON.stringify(this.tableFields))
      // todo: does this include the Manual EG and Computed EG?
      // todo: this function should be default available in the table, since it does not really require any info from the wrapper.
      // todo: this really should be a utils function, and not defined in a component whatsoever, but I don't have time
      //  to write a utils file now, so we'll have to do this later
      // Initialize Excel workbook
      const workbook = xlsxUtils.book_new()
      workbook.Props = {
        Title: 'Lienesch webapp Excel export',
        Subject: 'Excel export',
        Author: 'Lienesch webapp',
        CreatedDate: new Date(),
      }
      workbook.SheetNames.push('export')

      let fields_to_export = selected_fields.map((f) => f.key)
      // We need to seperate fields to export from headers to export because the key might not be human readable
      // The key is needed to get the data from the table
      // This is beun but needed for collection importer to work.
      if (this.appName == 'collection') {
        fields_to_export = ['id'].concat(fields_to_export)
      }
      // Add error to fields to export as we want the errors present in the table to also be reflected in the export
      fields_to_export = fields_to_export.concat(['error'])
      for (const field in fields_to_export) {
        if (fields_to_export[field].startsWith('_')) {
          fields_to_export.splice(field, 1)
        }
      }

      // Construct Items to export
      const export_items = []
      const items = selected_items
      for (let item in items) {
        item = clone(items[item])
        //fields_to_delete.forEach(field => delete item[field])
        for (const key in item) {
          if (!fields_to_export.includes(key)) {
            delete item[key]
          }
        }
        // This is necessary because JSON objects are sorted on creation order
        const new_item = {}
        for (const key in fields_to_export) {
          new_item[fields_to_export[key]] = item[fields_to_export[key]]
        }
        export_items.push(new_item)
      }

      // Construct Headers from exported items
      let headers_to_export = selected_fields.map((f) => f.label)
      if (this.appName == 'collection') {
        headers_to_export = ['id'].concat(headers_to_export)
      }
      for (const field in headers_to_export) {
        if (headers_to_export[field] === '') {
          headers_to_export.splice(field, 1)
        }
      }

      // Initialize worksheet and add to workbook
      const worksheet = xlsxUtils.aoa_to_sheet([headers_to_export])
      xlsxUtils.sheet_add_json(worksheet, export_items, { origin: 'A2', skipHeader: true })
      workbook.Sheets['export'] = worksheet

      // Trigger browser to download Excel file
      writeFile(workbook, 'export.xlsx')
    },
    extra_bulk_actions_enabled(selected_items) {
      return selected_items.length > 0
    },
    cell(o) {
      return `cell(${o})`
    },
    editcell(o) {
      return `editcell(${o})`
    },
    type(t) {
      return this.type_to_form_type(t)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => this.toString(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },
    keydown(event, key, index) {
      const switchFocus = (ref) => {
        this.$refs[ref][0].$children[0].$refs.input.focus()
      }

      switch (event.key) {
        case 'ArrowDown':
          index < this.items.length - 1 && switchFocus(key + (index + 1))
          break
        case 'ArrowUp':
          index > 0 && switchFocus(key + (index - 1))
          break
      }
    },
  },
}
</script>

<style scoped></style>
