<template>
  <div>
    <div class="mb-5 col-6">
      <h1>Welcome to the Lienesch webapp!</h1>
      <p>
        Please note that this is a beta version, if you encounter any bugs, glitches, lack of functionality or other
        problems on the website, please let us know immediately so we can rectify these accordingly. Your help in this
        regard is greatly appreciated! You can contact us at <support-email-address></support-email-address>.
      </p>
    </div>

    <b-card-group deck class="col-10">
      <b-card
        title="Place order"
        :img-src="require('../../assets/jens.jpg')"
        style="min-width: 300px; flex-grow: 25"
        class="mb-2"
        img-top
      >
        <b-card-text>
          Use the order app to easily place your order. You can save your order as a draft, so you can edit it later. Or
          upload an order export to fully automate your order process!
        </b-card-text>

        <b-button href="/order" variant="primary">Place order</b-button>
      </b-card>

      <b-card
        title="Order history"
        :img-src="require('../../assets/magazijn.jpg')"
        style="min-width: 300px; flex-grow: 20"
        class="mb-2"
        img-top
      >
        <b-card-text> Use this app to view all your orders, and to check their current status.</b-card-text>

        <b-button href="/orders" variant="primary">Order history</b-button>
      </b-card>

      <b-card
        title="My Collection"
        :img-src="require('../../assets/mycollection.jpg')"
        img-top
        style="min-width: 300px; flex-grow: 20"
        class="mb-2"
      >
        <b-card-text> View and manage your personal collection.</b-card-text>

        <b-button href="/collection" variant="primary">My collection</b-button>
      </b-card>
    </b-card-group>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      email: '',
      password: '',
      query: '',
      error: null,
      support_address: import.meta.env.VITE_SUPPORT_ADDRESS,
      mailto: `mailto:${import.meta.env.VITE_SUPPORT_ADDRESS}`,
    }
  },
  computed: {
    key() {
      return this.$store.state.token
    },
  },
}
</script>
