<template>
  <b-modal :id="name" :title="title" centered size="xl" @ok="handleOK">
    <b-container fluid>
      <div class="p-2">
        De volgende mutaties staat nog op niet verwerkt in de database, als deze allemaal gedaan zijn druk dan op OK.
        Dit zet ze allemaal op gedaan en zorgt ervoor dat deze niet terug komen in de mail.
      </div>
      <b-table striped :items="formattedItems"></b-table>
    </b-container>
  </b-modal>
</template>
<script lang="ts">
export default {
  props: {
    callback: {
      type: Function,
      default: () => {
        return
      },
    },
    name: { type: String, default: '' },
    title: { type: String, default: 'Title' },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    formattedItems() {
      const formatted_items = []
      for (const item_index in this.items) {
        const curr_item = this.items[item_index]
        const { operation, artcode_id, ...item } = curr_item
        const description = this.$store.getters.getItemByItemCode(artcode_id[1]).Description
        delete item.pk
        let formatted_operation
        if (operation == 'C') {
          for (const key in item) {
            item[key] = item[key][1]
          }
          formatted_operation = 'Create'
        } else if (operation == 'U') {
          for (const key in item) {
            if (item[key][0] == item[key][1]) {
              item[key] = item[key][1]
            } else {
              item[key] = `${item[key][0]} -> ${item[key][1]}`
            }
          }
          formatted_operation = 'Update'
        }
        formatted_items.unshift({
          operation: formatted_operation,
          Itemcode: artcode_id[1],
          description: description,
          ...item,
        })
      }
      return formatted_items
    },
  },
  methods: {
    async handleOK() {
      this.callback()
    },
  },
}
</script>
