<template>
  <div>
    <b-form-checkbox v-model="done" switch />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DoneSelector',
  props: {
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    done: {
      get() {
        return this.value
      },
      set(val: boolean) {
        this.$emit('input', val)
      },
    },
  },
})
</script>
