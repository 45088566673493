<template>
  <b-modal :id="name" :title="title" centered size="lg" @ok="handleOK" @hidden="resetModal">
    <b-container fluid>
      <b-form>
        <b-form-group label="Item code" label-cols="2">
          <item-selector v-model="item" />
        </b-form-group>

        <b-form-group label="Order number" label-cols="2">
          <b-form-input id="OrderNumberInput" v-model="order_nr" />
        </b-form-group>

        <b-form-group label="Batch code" label-cols="2">
          <b-form-input id="BatchCodeInput" v-model="batch_code" />
        </b-form-group>

        <b-form-group label="Quantity netto" label-cols="2">
          <b-form-input id="NettoQuantityInput" v-model="quantity_net" type="number" />
        </b-form-group>

        <b-form-group label="Quantity gross" label-cols="2">
          <b-form-input id="GrossQuantityInput" v-model="quantity_gross" type="number" />
        </b-form-group>

        <b-form-group label="Batch width" label-cols="2">
          <b-form-input id="BatchWidthInput" v-model="batch_width" type="number" />
        </b-form-group>
      </b-form>

      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
              {{ error }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

export default {
  mixins: [api_mixin],
  props: {
    callback: {
      type: Function,
      default: () => {
        return
      },
    },
    name: { type: String, default: '' },
    mutate: {
      type: Function,
      default: () => {
        return
      },
    },
    title: { type: String, default: '' },
    getShipmentId: {
      type: Function,
      default: () => {
        return
      },
    },
  },
  data() {
    return {
      item: null,
      order_nr: null,
      batch_code: null,
      quantity_net: null,
      quantity_gross: null,
      batch_width: null,
      errors: [],
    }
  },
  computed: {
    ItemCode() {
      return this.item?.ItemCode
    },
    modal_row() {
      return {
        ItemCode: this.ItemCode,
        order_nr: this.order_nr,
        batch_code: this.batch_code,
        quantity_net: this.quantity_net,
        quantity_gross: this.quantity_gross,
        batch_width: this.batch_width,
        arrival_timestamp: new Date(),
      }
    },
  },
  methods: {
    async handleOK(bvModalEvent) {
      const response = await this.mutate([this.modal_row], [])
      if (response.errors?.length) {
        response.errors.forEach((error) => {
          error = JSON.parse(error)
          this.errors.push(error.message + ': \n' + error.details)
        })
      } else {
        this.callback(response.successful[0])
        this.$nextTick(() => this.$bvModal.hide(bvModalEvent.componentId))
      }
    },
    resetModal() {
      this.item = null
      this.order_nr = null
      this.batch_code = null
      this.quantity_net = null
      this.quantity_gross = null
      this.batch_width = null
      this.errors = []
    },
    serialize_item(item) {
      return item ? item.ItemCode + ' - ' + item.Description : ''
    },
  },
}
</script>
