<script lang="ts">
import BasePriceAgreements from 'innicore/views/price-agreements/PriceAgreements.vue'

import {
  FetchPriceAgreementsDocument,
  MutatePriceAgreementsDocument,
  PriceQuotationDocument,
  PriceQuotationExcelDocument,
} from '@/graphql/generated'
import AddDataModal from '@/views/price_agreements/AddDataModal.vue'
import AddToPriceAgreementsModal from '@/views/price_agreements/AddToPriceAgreementsModal.vue'
import GenerateNewDataModal from '@/views/price_agreements/GenerateNewDataModal.vue'

export default {
  components: { AddToPriceAgreementsModal, AddDataModal, GenerateNewDataModal },
  extends: BasePriceAgreements,
  data() {
    return {
      extraFields: [
        ...this.defaultExtraFields(),
        {
          key: 'ItemCodeAccount',
          selected: false,
          insertBefore: 'agreed_price',
        },
        {
          key: 'agreed_price_m2',
          label: 'Agreed price (m2)',
          sortable: true,
          optional: true,
          selected: false,
          type: Number,
          formatter: this.formatCurrency,
          tdClass: this.cellStyleM2,
          insertBefore: 'best_price',
        },
        {
          key: 'best_price_m2',
          label: 'Best price (m2)',
          sortable: true,
          optional: true,
          selected: false,
          type: Number,
          variant: 'danger',
          formatter: this.formatCurrency,
          tdClass: 'text-right',
          insertBefore: 'medium_price',
        },
        {
          key: 'medium_price_m2',
          label: 'Medium price (m2)',
          sortable: true,
          optional: true,
          selected: false,
          type: Number,
          variant: 'warning',
          formatter: this.formatCurrency,
          tdClass: 'text-right',
          insertBefore: 'default_price',
        },
        {
          key: 'default_price_m2',
          label: 'Default price (m2)',
          sortable: true,
          optional: true,
          selected: false,
          type: Number,
          variant: 'success',
          formatter: this.formatCurrency,
          tdClass: 'text-right',
          insertBefore: 'valid_from',
        },
        { key: 'is_m2_item', label: 'M2 item', optional: true, type: Boolean, insertBefore: 'syscreated' },
      ],
    }
  },
  methods: {
    getCurrencyDecimals(key, row) {
      return row.is_m2_item ? 4 : 2
    },

    cellStyleM2(value, key, item) {
      if (item.agreed_price_m2 == null) return 'text-right'

      const styling = 'text-right prices-'

      if (item.agreed_price_m2 == item.best_price_m2) return styling + 'best'

      if (item.agreed_price_m2 < item.best_price_m2) return styling + 'below-best'

      if (item.agreed_price_m2 == item.default_price_m2) return styling + 'default'

      if (item.agreed_price_m2 > item.default_price_m2) return styling + 'above-default'

      return styling + 'medium'
    },
    getQuery() {
      return FetchPriceAgreementsDocument
    },
    getMutation() {
      return MutatePriceAgreementsDocument
    },
    getPriceQuotationQuery() {
      return PriceQuotationDocument
    },
    getPriceQuotationExcelQuery() {
      return PriceQuotationExcelDocument
    },

    parsePriceAgreementNode(node) {
      const priceAgreement = this.defaultParsePriceAgreementNode(node)
      return {
        ...priceAgreement,
        agreed_price_m2: node.agreed_price_m2,
        ItemCodeAccount: node.CollectionNode?.ItemCodeAccount,
        CollectionEnd: node.CollectionNode?.CollectionEnd,
        CollectionName: node.CollectionNode?.CollectionName,
        CollectionStart: node.CollectionNode?.CollectionStart,
        default_price_m2: node.price_list?.default_price_m2,
        medium_price_m2: node.price_list?.medium_price_m2,
        best_price_m2: node.price_list?.best_price_m2,
      }
    },
  },
}
</script>
