<script lang="ts">
import { defineComponent } from 'vue'

import OtpDuo from 'innicore/components/auth/OtpDuo.vue'
import OtpGoogle from 'innicore/components/auth/OtpGoogle.vue'
import BaseAuthSignUp from 'innicore/views/auth/AuthSignUp.vue'

export default defineComponent({
  extends: BaseAuthSignUp,
  computed: {
    otpComponent() {
      return this.user && this.user.uses_duo ? OtpDuo : OtpGoogle
    },
  },
})
</script>
