<template>
  <b-modal :id="name" centered size="lg" :title="title" @show="fetchItems" @ok="handleOK" @hidden="resetModal">
    <b-container class="con">
      <b-form>
        <b-form-group label="Item code" label-cols="2">
          <generic-selector
            v-model="item"
            :data="price_list_items"
            :disabled="!price_list_items.length"
            :serializer="serialize_item"
            invalid-feedback-message="This item does not exist!"
            placeholder="Enter an item..."
          />
        </b-form-group>
        <b-form-group label="Your agreed price" label-cols="2">
          <typed-form-input v-model="agreed_price" :type="Number" />
        </b-form-group>

        <b-form-group v-if="$store.state.user.is_employee" label="Valid from" label-cols="2">
          <typed-form-input v-model="valid_from" :type="Date" :state="valid_from ? true : null" />
        </b-form-group>

        <b-form-group v-if="$store.state.user.is_employee" label="Valid to" label-cols="2">
          <typed-form-input v-model="valid_to" :type="Date" :state="valid_to ? true : null" />
        </b-form-group>
      </b-form>
      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">{{
              error
            }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import { FetchPriceListItemsDocument, ItemCondition } from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  mixins: [api_mixin, utils],
  props: {
    callback: Function,
    name: String,
    companyId: String,
    mutate: Function,
    title: String,
    DateRange: Object,
  },
  data() {
    return {
      item: null,
      agreed_price: null,
      valid_from: null,
      valid_to: null,
      Description: null,
      id: null,
      price_list_items: [],
      errors: [],
    }
  },
  computed: {
    ItemCode() {
      return this.itemcode ? this.itemcode : this.item ? this.item.ItemCode : null
    },
    modal_row_is_valid() {
      return this.ItemCode
    },
    modal_row() {
      return {
        company: this.companyId,
        ItemCode: this.ItemCode,
        agreed_price: this.agreed_price,
        valid_from: this.valid_from,
        valid_to: this.valid_to,
        id: this.id,
      }
    },
  },
  methods: {
    handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.modal_row_is_valid) {
        this.mutate([this.modal_row], []).then((response) => {
          if (response.errors?.length) {
            response.errors.forEach((error) => {
              error = JSON.parse(error)
              if (error.message.toLowerCase().includes('unique')) {
                this.errors.push('This customer already has a price agreement for this item.')
              } else {
                this.errors.push(error.message + ': \n' + error.details)
              }
            })
          } else {
            this.callback(response.successful[0])
            this.$nextTick(() => this.$bvModal.hide(bvModalEvent.componentId))
          }
        })
      }
    },
    getQuery() {
      return FetchPriceListItemsDocument
    },
    async fetchItems() {
      if (this.price_list_items.length) {
        return
      }

      const valid_from = this.DateRange.valid_from
        ? this.DateRange.valid_from
        : this.DateRange.valid_to
        ? null
        : this.today()
      const valid_to = this.DateRange.valid_to
        ? this.DateRange.valid_to
        : this.DateRange.valid_from
        ? null
        : this.today()
      if (valid_from == this.valid_from || valid_to == this.valid_to) {
        return
      }

      this.valid_from = valid_from
      this.valid_to = valid_to
      const response = await this.api_call(this.getQuery(), {
        valid_from: valid_from,
        valid_to: valid_to,
        item__Condition__in: [ItemCondition.ACTIVE, ItemCondition.FUTURE],
      })
      const nodes = response.data.data.price_list.edges
      this.price_list_items = nodes.map((obj) => obj.node.item)
    },
    resetModal() {
      this.item = null
      this.agreed_price = null
      this.Description = null
      this.id = null
      this.errors = []
    },
    serialize_item(item) {
      return item ? item.ItemCode + ' - ' + item.Description : ''
    },
  },
}
</script>
