<template>
  <b-modal :id="name" centered size="xl" :title="title" @hidden="resetModal">
    <b-container class="con">
      <b-row>
        <fields-filter v-model="field_filters" :fields="filterable_fields" />
      </b-row>
      <b-row>
        <b-col cols="3">
          <b>Start date of Price list: </b>
        </b-col>
        <b-col cols="2">
          <typed-form-input v-model="new_price_list_valid_on" :type="Date" />
        </b-col>
      </b-row>

      <b-row v-if="errors.length">
        <b-col cols="2">
          <b>Error:</b>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(error, index) in errors" :key="index" variant="danger">
              {{ error }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="{ cancel, ok }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>
      <hit-button variant="primary" :click="() => getData(ok)"> OK</hit-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Filter from 'innicore/components/table/Filter'
import { FetchPriceListEssentialsDocument, ItemCondition } from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { parseItem } from '@/common/parseItem'

export default {
  mixins: [api_mixin, utils],
  props: {
    price_list_valid_on: String,
    name: String,
    title: String,
    callback: Function,
  },
  data() {
    return {
      errors: [],
      field_filters: [],
      fields: [
        {
          key: 'assortmentDescription',
          label: 'Assortment description',
          type: String,
        },
        {
          key: 'c01',
          label: 'Label',
          type: String,
        },
        {
          key: 'c06',
          label: 'Collection year',
          type: String,
        },
        {
          key: 'c02',
          label: 'Collection',
          type: String,
        },
        {
          key: 'c03',
          label: 'Pleat height',
          type: String,
        },
        {
          key: 'c08',
          label: 'PMC',
          type: String,
        },
        {
          key: 'Condition',
          label: 'Condition',
          type: String,
        },
      ],
    }
  },
  computed: {
    filterable_fields() {
      return this.fields
        .filter((f) => f.type)
        .map((f) => ({ options: [...new Set(this.items.map((i) => i[f.key]))], ...f }))
    },
    amount_of_items() {
      let data = this.items
      this.field_filters.forEach((f) => {
        data = data.filter((item) => f.apply(item))
      })
      return data.length
    },
    items() {
      return this.$store.state.lienesch_items.filter(
        (i) => !['Service & promotion', 'Componenten'].includes(i['assortment'])
      )
    },
    new_price_list_valid_on: {
      get() {
        return this.price_list_valid_on
      },
      set(val) {
        this.$emit('update:price_list_valid_on', val)
      },
    },
  },
  mounted() {
    this.addDefaultFilters()
  },
  methods: {
    addDefaultFilters() {
      // Label should be EIGENCOLLECTIE
      const label_filter = new Filter({
        key: 'c01',
        label: 'Label',
        type: String,
        options: [...new Set(this.items.map((i) => i['c01']))],
      })
      label_filter.filter_rule = 'oneof'
      label_filter.value = ['EIGENCOLLECTIE']

      // Condition should be A(ctive) or F(uture)
      const condition_filter = new Filter({
        key: 'Condition',
        label: 'Condition',
        type: String,
        options: [...new Set(this.items.map((i) => i['Condition']))],
      })
      condition_filter.filter_rule = 'oneof'
      condition_filter.value = [ItemCondition.ACTIVE, ItemCondition.FUTURE]

      this.field_filters.push(label_filter, condition_filter)
    },
    getQuery() {
      return FetchPriceListEssentialsDocument
    },
    async getData(okFunction) {
      let data = await this.api_call(this.getQuery(), {
        valid_on: this.price_list_valid_on,
      }).then((response) => {
        return response.data.data.price_list.edges.map((obj) => this.parsePriceListNode(obj.node))
      })
      this.field_filters.forEach((f) => {
        data = data.filter((item) => f.apply(item))
      })

      data.forEach((item) => {
        item.agreed_price = item.default_price
      })

      this.callback(data)
      okFunction()
    },
    resetModal() {
      this.errors = []
    },
    parsePriceListNode(node) {
      const item = parseItem(node.item)
      return {
        ...item,
        default_price: node.default_price,
        medium_price: node.medium_price,
        best_price: node.best_price,
        valid_from: node.valid_from,
        valid_to: node.valid_to,
      }
    },
  },
}
</script>
